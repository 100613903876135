import { PageLayout } from '@retail/components';
import { MgSupplierItemSearchContainer } from '@retail/products/containers';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { useState } from 'react';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { Paper } from '@mui/material';

export function MgSupplierItemSearchPage() {
  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);
  const { draftAssortmentId } = useDepartmentAssortments();

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 0,
          pt: 2,
          overflow: 'hidden',
        }}
      >
        <MgSupplierItemSearchContainer
          includeAddButton
          clickableRow
          checkedItems={checkedItems}
          assortmentId={draftAssortmentId}
          setCheckedItems={setCheckedItems}
        />
      </Paper>
    </PageLayout>
  );
}
