import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { DiscountGroup } from '@retail/calculus/types';
import { FilterChip } from '@retail/components';

interface Props {
  discountGroups: DiscountGroup[];
  selectedDiscountGroups: DiscountGroup[];
  isFetching: boolean;
  setPage: (page: number) => void;
  selectDiscountGroups: (productGroup: DiscountGroup[]) => void;
}

export function SelectDiscountGroupsAutocomplete({
  discountGroups,
  selectedDiscountGroups,
  isFetching,
  setPage,
  selectDiscountGroups,
}: Props) {
  const t = useProductsTFunction();
  return (
    <Stack gap={0.5}>
      <Typography
        component="label"
        htmlFor="discountGroups"
        variant="body2"
        color={({ palette }) => palette.grey.A400}
      >
        {t('products.search.discountGroup.label')}
      </Typography>
      <Autocomplete
        size="small"
        id="discountGroups"
        disabled={isFetching}
        multiple
        filterSelectedOptions
        disableClearable
        renderTags={(discountGroups) =>
          discountGroups.map((discountGroup) => (
            <FilterChip
              key={discountGroup.id}
              id={discountGroup.id}
              name={discountGroup.name}
              onDelete={() => {
                selectDiscountGroups(
                  selectedDiscountGroups.filter(({ id }) => id !== discountGroup.id)
                );
              }}
            />
          ))
        }
        value={selectedDiscountGroups}
        onChange={(_, newValue) => {
          selectDiscountGroups(newValue);
          setPage(0);
        }}
        options={discountGroups}
        getOptionLabel={({ name, code }) => (name ? `${code} ${name}` : code)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('products.search.discountGroup.searchInDiscountGroup')}
          />
        )}
      />
    </Stack>
  );
}
