import {
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { mgPrisConfig, mgPrisRoutes } from '@retail/mgpris/config';
import { AppLayout, AuthContainerPage, OrgUnitAccessChecker } from '@retail/app/pages';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import {
  BrandsProvider,
  MyOrgUnitsProvider,
  SelectedOrgUnitProvider,
} from '@retail/app/stores/selected-context';
import * as Sentry from '@sentry/react';
import { BaseRoute, mgPdmRoutes, mgpRoutes } from './Routes';
import { useEffect } from 'react';

const routesForMgp = mgpRoutes();
const routesForPdm = mgPdmRoutes();

// init Sentry in the same file as Sentry.withSentryReactRouterV6Routing
// see https://github.com/getsentry/sentry-javascript/issues/5309
if (mgPrisConfig.sentryDsn) {
  Sentry.init({
    dsn: mgPrisConfig.sentryDsn,
    environment: mgPrisConfig.env,
    release: mgPrisConfig.version,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes: React.FC = () => {
  return (
    <SentryRoutes>
      <Route
        path={mgPrisRoutes.root.fullRoutePath}
        element={
          <AuthContainerPage>
            <SuspenseWithSentryErrorBoundary>
              <BrandsProvider>
                <MyOrgUnitsProvider>
                  <OrgUnitAccessChecker>
                    <SelectedOrgUnitProvider>
                      <AppLayout>
                        <Outlet />
                      </AppLayout>
                    </SelectedOrgUnitProvider>
                  </OrgUnitAccessChecker>
                </MyOrgUnitsProvider>
              </BrandsProvider>
            </SuspenseWithSentryErrorBoundary>
          </AuthContainerPage>
        }
      >
        {/* All following routes are only shown to authorized users, due to AuthContainerPage */}
        <Route path="" element={<BaseRoute />} />
        {routesForMgp}
        {routesForPdm}
      </Route>
    </SentryRoutes>
  );
};
