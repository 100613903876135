let isTokenRefreshing = false;
let tokenRefreshCallbacks: ((token: string) => void)[] = [];

function notifyTokenRefreshCallbacks(newToken: string): void {
  tokenRefreshCallbacks.forEach((callback) => {
    try {
      callback(newToken);
    } catch (error) {
      console.error('Error executing token refresh callback:', error);
    }
  });
  tokenRefreshCallbacks = [];
}

function addTokenRefreshCallback(): Promise<string> {
  return new Promise((resolve) => {
    tokenRefreshCallbacks.push((token) => {
      try {
        resolve(token);
      } catch (error) {
        console.error('Error in registered callback:', error);
      }
    });
  });
}

export async function handleTokenRefresh(refreshFunction: () => Promise<string>): Promise<string> {
  if (isTokenRefreshing) {
    return addTokenRefreshCallback();
  }

  isTokenRefreshing = true;

  try {
    const newToken = await refreshFunction();
    if (!newToken) {
      throw new Error('Invalid token: refreshFunction must return a non-empty string');
    }

    notifyTokenRefreshCallbacks(newToken);
    return newToken;
  } catch (error: any) {
    console.error('Error during token refresh:', error.message);
    tokenRefreshCallbacks = [];
    throw error;
  } finally {
    isTokenRefreshing = false;
  }
}
