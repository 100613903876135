import { useFetchMgItem } from '@retail/products/data-access';
import { MgItem } from '@retail/products/types';

import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { MgItemDialog } from './MgItemDialog';

interface Props {
  itemNumber: number;
  open: boolean;
  onClose: () => void;
  assortmentId?: number;
  showAddItem?: boolean;
  showMonitorItem?: boolean;
}

export const MgItemDialogContainer = withDialogSuspenseErrorBoundary(
  ({ itemNumber, assortmentId, open, showAddItem, showMonitorItem, onClose }: Props) => {
    const { data: mgItemDto } = useFetchMgItem(
      {
        mgItemNumber: itemNumber,
        assortmentId,
      },
      { suspense: true }
    );

    const mgItem = MgItem.fromDto(mgItemDto!);

    return (
      <MgItemDialog
        isOpen={open}
        mgItem={mgItem}
        showAddItem={showAddItem}
        showMonitorItem={showMonitorItem}
        onClose={onClose}
      />
    );
  }
);
