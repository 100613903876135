import {
  useSelectDiscountGroups,
  useSelectProductGroupsInAssortment,
  useSelectSearchQuery,
  useSelectSuppliers,
} from '@retail/products/context';
import { useDisclosure } from '@shared/hooks';
import { useCallback, useMemo } from 'react';

interface Props {
  assortmentId: number;
}

export const useAssortmentFilters = ({ assortmentId }: Props) => {
  const {
    isOpen: itemsWithAgreementPrice,
    onToggle: setOnlyItemsWithAgreementPrice,
    onClose: removeOnlyItemsWithAgreementPrice,
  } = useDisclosure();

  const { suppliers, selectedSupplier, selectSupplier, selectSupplierById } = useSelectSuppliers({
    assortmentId,
    inAssortment: true,
  });

  const { discountGroups, selectedDiscountGroup, selectDiscountGroup, selectDiscountGroupById } =
    useSelectDiscountGroups({
      assortmentId,
      selectedSuppliers: selectedSupplier ? [selectedSupplier] : null,
    });

  const { productGroups, selectedProductGroup, selectProductGroup, selectProductGroupByNr } =
    useSelectProductGroupsInAssortment({
      assortmentId,
      selectedSupplierId: selectedSupplier?.mgSupplierId,
      selectedDiscountGroupId: selectedDiscountGroup?.id,
    });

  const { query: searchQuery, setQuery: setSearchQuery } = useSelectSearchQuery();

  const resetFilters = useCallback(() => {
    setSearchQuery('');
    selectProductGroup(null);
    selectDiscountGroup(null);
    selectSupplier(null);
    removeOnlyItemsWithAgreementPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      searchFilters: {
        selectedSupplier,
        selectedProductGroup,
        selectedDiscountGroup,
        searchQuery,
        itemsWithAgreementPrice,
      },
      setSearchFilters: {
        selectSupplier,
        selectProductGroup,
        selectDiscountGroup,
        selectSupplierById,
        selectProductGroupByNr,
        selectDiscountGroupById,
        setSearchQuery,
        setOnlyItemsWithAgreementPrice,
      },
      filterOptions: {
        suppliers,
        productGroups,
        discountGroups,
      },
      resetFilters,
    }),
    [
      selectedSupplier,
      selectedProductGroup,
      selectedDiscountGroup,
      searchQuery,
      itemsWithAgreementPrice,
      selectSupplier,
      selectProductGroup,
      selectDiscountGroup,
      selectSupplierById,
      selectProductGroupByNr,
      selectDiscountGroupById,
      setSearchQuery,
      setOnlyItemsWithAgreementPrice,
      suppliers,
      productGroups,
      discountGroups,
      resetFilters,
    ]
  );
};
