import { HierarchyLevels } from '@retail/calculus/types';
import { PriceType, PurchaseConditionDTO } from '../dto';

interface PurchaseConditionProps {
  id?: number;
  assortmentId: number;
  mgSupplierId: number;
  bindingType: HierarchyLevels;
  mgSupplierItemId?: number;
  productGroup?: string;
  productGroupName?: string;
  levelProductGroupName?: string;
  mgItemNumber?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom: Date;
  validTo?: Date;
  priceType: PriceType;
  active: boolean;
  agreementId: number;
  agreementText: string;
  inherited: boolean;
}

export class PurchaseCondition {
  id?: number;
  assortmentId: number;
  mgSupplierId: number;
  bindingType: HierarchyLevels;
  mgSupplierItemId?: number;
  productGroup?: string;
  productGroupName?: string;
  levelProductGroupName?: string;
  mgItemNumber?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom: Date;
  validTo?: Date;
  priceType: PriceType;
  active: boolean;
  agreementId: number;
  agreementText: string;
  inherited: boolean;

  constructor({
    id,
    assortmentId,
    mgSupplierId,
    bindingType,
    mgSupplierItemId,
    productGroup,
    productGroupName,
    levelProductGroupName,
    mgItemNumber,
    primaryText,
    secondaryText,
    validFrom,
    validTo,
    priceType,
    active,
    agreementId,
    agreementText,
    inherited,
  }: PurchaseConditionProps) {
    this.id = id;
    this.assortmentId = assortmentId;
    this.mgSupplierId = mgSupplierId;
    this.bindingType = bindingType;
    this.mgSupplierItemId = mgSupplierItemId;
    this.productGroup = productGroup;
    this.productGroupName = productGroupName;
    this.levelProductGroupName = levelProductGroupName;
    this.mgItemNumber = mgItemNumber;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.priceType = priceType;
    this.active = active;
    this.agreementId = agreementId;
    this.agreementText = agreementText;
    this.inherited = inherited;
  }

  static fromDto = (dto: PurchaseConditionDTO): PurchaseCondition =>
    new PurchaseCondition({
      id: dto.id,
      assortmentId: dto.assortmentId,
      mgSupplierId: dto.mgSupplierId,
      bindingType: dto.bindingType,
      mgSupplierItemId: dto.mgSupplierItemId,
      productGroup: dto.productGroup,
      productGroupName: dto.productGroupName,
      levelProductGroupName: dto.levelProductGroupName,
      mgItemNumber: dto.mgItemNumber,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: new Date(dto.validFrom),
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      priceType: dto.priceType,
      active: dto.active,
      agreementId: dto.agreementId,
      agreementText: dto.agreementText,
      inherited: dto.inherited,
    });
}
