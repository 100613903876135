import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { ContainedButton, TextButton } from '@shared/components';

interface DialogProps {
  action: () => void;
  toggleModal: () => void;
  isOpen: boolean;
  name: string;
}

export const DeleteDialog = ({ action, toggleModal, isOpen, name }: DialogProps) => {
  const t = useAppTFunction();
  return (
    <Dialog open={isOpen} onClose={toggleModal} maxWidth="sm">
      <DialogTitle>
        {t('assortment.competitors.deleteDialogTitle', { storeName: name })}
      </DialogTitle>

      <DialogContent>
        <Typography>{t('assortment.competitors.deleteDialogDesc', { storeName: name })}</Typography>
      </DialogContent>

      <DialogActions>
        <TextButton onClick={toggleModal}>{t('common.cancel')}</TextButton>

        <ContainedButton color="secondary" size="small" onClick={action}>
          {t('assortment.competitors.deleteButtonText')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
