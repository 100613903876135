import { MgSupplierItem, MgSupplierItemPackage, RecommendedPrice } from '@retail/products/types';
import { useCallback, useEffect, useMemo } from 'react';
import { Packages } from './Packages';
import { RecommendedPrices } from './RecommendedPrices';
import { SalesPriceSummary } from './SalesPriceSummary';
import { useProductCardStore } from './store';
import {
  CostPriceRow,
  FreightAccordion,
  MarkupAccordion,
  MarkupAccordionSummary,
  PurchaseAccordion,
} from './Calculus';
import { Divider, Stack } from '@mui/material';

interface Props {
  selectedSupplierItem: MgSupplierItem;
  selectedSupplierItemPackage: MgSupplierItemPackage;
  recommendedPrice?: RecommendedPrice;
  includeVat: boolean;
  submitMarkupValues: () => void;
}

export function ProductCard({
  selectedSupplierItem,
  selectedSupplierItemPackage,
  recommendedPrice,
  includeVat,
  submitMarkupValues,
}: Props) {
  const {
    selectedPackageType,
    editingField,
    getSalesPriceEditState,
    setEditingField,
    closeEditingField,
    changeSelectedPackage,
    setNewSalesCoverage,
    setNewSalesFactor,
    setNewSalesPrice,
    newMarkup,
    resetMarkup,
    resetState,
  } = useProductCardStore((state) => state);
  const { currentSalesPrices } = selectedSupplierItem;

  const mgPackages = MgSupplierItem.getPackages(selectedSupplierItem.packages || []);
  const selectedUnit = selectedSupplierItemPackage.vilmaUnit.unit;

  const currentPurchasePrice = useMemo(
    () => currentSalesPrices.getCurrentPurchasePriceForUnit(selectedUnit),
    [currentSalesPrices, selectedUnit]
  );

  const currentCostPrice = useMemo(
    () => currentSalesPrices.getCurrentCostPriceForUnit(selectedUnit),
    [currentSalesPrices, selectedUnit]
  );

  const currentMarkupForPackage = useMemo(
    () => selectedSupplierItem.getMarkupConditionForPackage(selectedPackageType),
    [selectedPackageType, selectedSupplierItem]
  );

  const currentSalesPrice = useMemo(() => {
    const priceForUnit = currentSalesPrices
      .getBracketPackagePrice('F-PAK')
      ?.bracketMarkupPrice.getPriceForUnit(selectedUnit);

    if (!priceForUnit) return undefined;

    return includeVat ? priceForUnit.price.priceWithVat : priceForUnit.price.priceWithoutVat;
  }, [includeVat, currentSalesPrices, selectedUnit]);

  useEffect(() => {
    resetMarkup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeVat]);

  useEffect(() => {
    return () => resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplierItem.mgSupplierItemId]);

  const selectRecommendedPrice = useCallback(
    (recommendedPrice: number) =>
      setNewSalesPrice(recommendedPrice, selectedSupplierItemPackage, currentSalesPrices, true),
    [currentSalesPrices, selectedSupplierItemPackage, setNewSalesPrice]
  );

  const setSalesPrice = useCallback(
    (salesPrice: number) =>
      setNewSalesPrice(salesPrice, selectedSupplierItemPackage, currentSalesPrices, includeVat),
    [includeVat, currentSalesPrices, selectedSupplierItemPackage, setNewSalesPrice]
  );

  const setSalesFactor = useCallback(
    (salesFactor: number) =>
      setNewSalesFactor(salesFactor, selectedSupplierItemPackage, currentSalesPrices),
    [currentSalesPrices, selectedSupplierItemPackage, setNewSalesFactor]
  );

  const setSalesCoverage = useCallback(
    (salesCoverage: number) =>
      setNewSalesCoverage(salesCoverage, selectedSupplierItemPackage, currentSalesPrices),
    [currentSalesPrices, selectedSupplierItemPackage, setNewSalesCoverage]
  );

  const salesPriceEditState = useMemo(
    () => getSalesPriceEditState(selectedSupplierItemPackage, currentSalesPrices),
    [getSalesPriceEditState, currentSalesPrices, selectedSupplierItemPackage]
  );

  return (
    <Stack divider={<Divider />}>
      <Packages
        packages={mgPackages}
        selectedPackage={selectedPackageType}
        basePriceUnit={selectedSupplierItem.basePriceUnit}
        setSelectedPackage={changeSelectedPackage}
      />

      {recommendedPrice && (
        <RecommendedPrices
          {...recommendedPrice}
          selectRecommendedPrice={selectRecommendedPrice}
          resetPrice={resetMarkup}
        />
      )}

      <PurchaseAccordion
        purchaseCondition={currentSalesPrices.purchaseCondition}
        unit={selectedUnit}
        purchasePrice={currentPurchasePrice?.price?.price}
      />

      <FreightAccordion
        freightCondition={selectedSupplierItem.currentSalesPrices.freightCondition}
      />

      <CostPriceRow costPrice={currentCostPrice?.price?.price} unit={selectedUnit} />

      <MarkupAccordion
        markupCondition={currentMarkupForPackage}
        accordionSummary={
          <MarkupAccordionSummary
            currentSalesFactor={currentMarkupForPackage?.salesFactor}
            newSalesFactor={
              newMarkup && {
                value: newMarkup.salesFactor,
                isApproximate:
                  newMarkup.editedField === 'price' || newMarkup.editedField === 'coverage',
              }
            }
            editProps={{
              openEditMode: () => setEditingField('factor'),
              cancelEditMode: closeEditingField,
              isEditing: editingField === 'factor',
              onChangeSalesFactor: setSalesFactor,
            }}
          />
        }
      />

      <SalesPriceSummary
        currentSalesPrice={currentSalesPrice}
        newSalesPrice={
          newMarkup?.salesPriceIncVat && newMarkup?.salesPrice
            ? {
                value: includeVat ? newMarkup.salesPriceIncVat : newMarkup.salesPrice,
                isApproximate: newMarkup.editedField !== 'price',
              }
            : undefined
        }
        currentSalesCoverage={currentMarkupForPackage?.salesPriceCoverage}
        newSalesCoverage={
          newMarkup
            ? {
                value: newMarkup.salesCoverage,
                isApproximate: newMarkup.editedField !== 'coverage',
              }
            : undefined
        }
        salesPriceEditState={salesPriceEditState}
        onChangeSalesCoverage={setSalesCoverage}
        closeEditingField={closeEditingField}
        editingField={editingField}
        packageUnit={selectedUnit}
        setEditingField={setEditingField}
        onChangeSalesPrice={setSalesPrice}
        includeVat={includeVat}
        resetMarkupValues={resetMarkup}
        submitMarkupValues={submitMarkupValues}
      />
    </Stack>
  );
}
