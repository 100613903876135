import { Stack, styled, Typography } from '@mui/material';

interface Props {
  topLine: string | number | React.ReactNode;
  bottomLine?: string | number | React.ReactNode;
  centerAlign?: boolean;
  lineThroughTopLine?: boolean;
  lineThroughBottomLine?: boolean;
  gap?: 'small' | 'medium';
  nullableBottomLine?: boolean;
}

export const StackedTextCell = ({
  topLine,
  bottomLine,
  centerAlign,
  lineThroughTopLine = false,
  lineThroughBottomLine = false,
  gap = 'medium',
  nullableBottomLine = false,
}: Props) => {
  return (
    <Stack flexDirection="row">
      <Stack
        textAlign={centerAlign ? 'center' : 'start'}
        overflow="hidden"
        textOverflow="ellipsis"
        gap={gap === 'medium' ? 0.5 : 0}
        justifyContent={centerAlign ? 'center' : undefined}
        alignItems={centerAlign ? 'center' : undefined}
      >
        <TopLineTypography
          variant="subtitle2"
          // @ts-ignore
          component="span"
          lineThroughTopLine={lineThroughTopLine}
        >
          {topLine}
        </TopLineTypography>

        {(bottomLine || !nullableBottomLine) && (
          <Typography
            lineHeight={1.2}
            component="span"
            variant="subtitle2"
            sx={{
              textDecoration: bottomLine && lineThroughBottomLine ? 'line-through' : undefined,
            }}
          >
            <span>{bottomLine ?? '-'}</span>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const TopLineTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'lineThroughTopLine' && prop !== 'isInline',
})<{ lineThroughTopLine?: boolean; isInline?: boolean }>(
  ({ theme, lineThroughTopLine, isInline }) => ({
    display: isInline ? 'inline' : undefined,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.grey[900],
    textDecoration: lineThroughTopLine ? 'line-through' : undefined,
  })
);
