import { Stack, Typography } from '@mui/material';
import { useSelectedOrgUnit, useUserBrands } from '@retail/app/stores/selected-context';
import { useToast } from '@retail/hooks';
import { useBrandAssortments } from '@retail/mgpdm/context';
import { pdmRoutes } from '@retail/mgpris/config';
import {
  AssortmentsList,
  CreateAssortmentManuallyDialog,
  CreateAssortmentMenu,
  IdentifiableColumn,
  ImportAssortmentDialog,
  NewAssortment,
  SuccessCreatingAssortment,
} from '@retail/monitoring-assortment/components';
import { useMonitoringAssortmentActions } from '@retail/monitoring-assortment/context';
import { useCreateMonitoringAssortment } from '@retail/monitoring-assortment/data-access';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { FetchError } from '@shared/fetch-utils';
import { useDisclosure } from '@shared/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const columnsToRetrieve = new Set<IdentifiableColumn>(['finfo', 'gtin', 'mgItem']);

export function MonitoringAssortmentsOverview() {
  const t = useAssortmentTFunction();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { selectedBrandLogos } = useUserBrands();
  const { selectedOrgUnitId } = useSelectedOrgUnit();

  const { handleValidateItemsOnImportedAssortment } = useMonitoringAssortmentActions();

  const { isOpen: isSuccessModalOpen, onToggle: toggleSuccessModal } = useDisclosure(false);
  const { isOpen: isImportModalOpen, onToggle: toggleImportModal } = useDisclosure(false);
  const { isOpen: isCreateManuallyModalOpen, onToggle: toggleCreateManuallyModal } =
    useDisclosure(false);

  const { monitoringAssortments } = useBrandAssortments();

  const { mutateAsync: createAssortmentAsync, isLoading } = useCreateMonitoringAssortment();

  const navigateToItemSearch = useCallback(() => {
    navigate(pdmRoutes.items.getFullLinkPath());
  }, [navigate]);

  const submitCreateAssortment = (newAssortment: NewAssortment) => {
    createAssortmentAsync({
      body: {
        title: newAssortment.title,
        itemCodes: { mgItemNumbers: newAssortment.itemCodes.mgItemNumbers || [] },
        orgUnitId: selectedOrgUnitId,
      },
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['brand-assortments'] });
        if (isImportModalOpen) {
          toast.success(t('assortment.admin.create.success', { title: newAssortment.title }));
          toggleImportModal();
        } else {
          toggleCreateManuallyModal();
          toggleSuccessModal();
        }
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('assortment.admin.create.error.generic'));
      });
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between" whiteSpace="nowrap" p={3} pb={0}>
        <Typography variant="h6" component="h1">
          {t('assortment.monitoringAssortment')}
        </Typography>
        <CreateAssortmentMenu
          openImportModal={toggleImportModal}
          openCreateManuallyModal={toggleCreateManuallyModal}
        />
      </Stack>
      <AssortmentsList
        assortments={monitoringAssortments}
        linkTo={(assortmentId: number) =>
          pdmRoutes.marketInsight.assortments.assortment.getFullLinkPath({ assortmentId })
        }
        selectedOrgUnitId={selectedOrgUnitId}
        brandLogoUri={selectedBrandLogos.circularLogo}
      />
      <CreateAssortmentManuallyDialog
        open={isCreateManuallyModalOpen}
        onClose={toggleCreateManuallyModal}
        onSubmit={submitCreateAssortment}
        isLoading={isLoading}
      />
      <ImportAssortmentDialog
        open={isImportModalOpen}
        onClose={toggleImportModal}
        onSubmit={submitCreateAssortment}
        getInvalidItemCodes={handleValidateItemsOnImportedAssortment}
        columnsToRetrieve={columnsToRetrieve}
        isLoading={isLoading}
      />
      <SuccessCreatingAssortment
        isOpen={isSuccessModalOpen}
        closeDialog={toggleSuccessModal}
        navigateToItemSearch={navigateToItemSearch}
      />
    </Stack>
  );
}
