import * as yup from 'yup';
import { ValidationMessageKeys } from '@retail/form/i18n';

export interface ValidationError<MessageKeysT extends string> {
  /** key into translation file */
  key: MessageKeysT | ValidationMessageKeys;

  /** token values */
  values?: { [key: string]: any };
}

const testValue = (regex: RegExp) => (value: string | undefined) => {
  if (!value) {
    return true;
  }
  return regex.test(value);
};

const nameAndNumbersRegex = /^[a-zA-Z0-9 .'\-æøåäö]+$/i;
const lettersRegex = /^[a-zæøåäö]+$/i;
const lettersAndSpaceRegex = /^[a-zæøåäö\s]+$/i;
const numbersRegex = /^\d+$/;
export const decimalNumberRegex = /^\d+([.,]\d+)?$/;
const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const percentageRegex = /^([1-9]?[0-9]((\.|,)\d+)?|100((\.|,)?0+)?)$/;
export const percentageIncludingNegativeRegex = /^(-?)([1-9]?[0-9]([.,]\d+)?|100([.,]?0+)?)$/;
export const numberWithOptionalDecimalRegex = /^[1-9]([.,]\d+)?$|^(0+([.,]([0-9]+)))$|^10$/;
export const numberBetween1And2Regex = /^1([.,]\d+)?$|^2([.,]0+)?$|^2$/;

export const nameAndNumbers = <MessageKeysT extends string = ValidationMessageKeys>(
  errorMessage: ValidationError<MessageKeysT> = { key: 'validation.nameAndNumbers' }
) => yup.string().matches(nameAndNumbersRegex, { message: errorMessage });

export const letters = <MessageKeysT extends string = ValidationMessageKeys>(
  errorMessage: ValidationError<MessageKeysT> = { key: 'validation.letters' }
) => yup.string().matches(lettersRegex, { message: errorMessage });

export const lettersAndSpace = <MessageKeysT extends string = ValidationMessageKeys>(
  errorMessage: ValidationError<MessageKeysT> = { key: 'validation.lettersAndSpace' }
) => yup.string().matches(lettersAndSpaceRegex, { message: errorMessage });

export const numberString = <MessageKeysT extends string = ValidationMessageKeys>(
  errorMessage: ValidationError<MessageKeysT> = { key: 'validation.number' }
) => yup.string().matches(numbersRegex, { message: errorMessage });

export const decimalNumberString = <MessageKeysT extends string = ValidationMessageKeys>(
  errorMessage: ValidationError<MessageKeysT> = { key: 'validation.decimalNumber' }
) => yup.string().matches(decimalNumberRegex, { message: errorMessage });

export const phone = <MessageKeysT extends string = ValidationMessageKeys>(
  errorMessage: ValidationError<MessageKeysT> = { key: 'validation.phone' }
) => yup.string().test('isPhone', { message: errorMessage }, testValue(phoneRegex));
