import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { usePurchaseActions } from '@retail/purchase-agreements/context';
import { PriceType } from '@retail/purchase-agreements/types';
import { useCallback, useState } from 'react';
import { SelectItemAgreements } from '../ActivateAgreement/SelectItemAgreements';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AssortmentItem } from '@retail/my-assortment/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useAppTFunction } from '@retail/app/i18n';
import { useFetchConditions } from '@retail/calculus/data-access';
import { ConditionState } from '@retail/calculus/types';

interface Props {
  open: boolean;
  onClose: () => void;
  assortmentItem: AssortmentItem;
  assortmentId: number;
  mgSupplierId: number;
}

function ChangeSelectedItemAgreementDialog({
  onClose,
  assortmentItem,
  assortmentId,
  mgSupplierId,
}: Props) {
  const t = useAppTFunction();
  const { activateOrChangeCondition, isLoadingPostChanges } = usePurchaseActions({
    mgSupplierId,
  });

  const { data: conditionDtos = [] } = useFetchConditions({
    conditionTypes: ['PURCHASE'],
    conditionState: ConditionState.ACTIVE,
    assortmentId,
    mgSupplierIds: [mgSupplierId],
    mgItemNumbers: [assortmentItem.mgItemNumber],
    includeOwnerConditions: true,
  });
  const purchaseCondition = conditionDtos[0]?.purchaseConditions[0];

  const [selectedAgreement, selectAgreement] = useState<number | undefined>(
    purchaseCondition?.id ? purchaseCondition?.agreementId : undefined
  );
  const [priceType, setPriceType] = useState<PriceType | undefined>(purchaseCondition?.priceType);

  const handleSelectAgreement = useCallback((agreement?: number, priceType?: PriceType) => {
    selectAgreement(agreement);
    setPriceType(priceType);
  }, []);

  const submitAgreements = useCallback(() => {
    activateOrChangeCondition({
      agreementId: selectedAgreement!,
      mgItemNumber: assortmentItem.mgItemNumber,
      fromDate: new Date(),
      priceType,
    });

    onClose();
  }, [
    activateOrChangeCondition,
    assortmentItem.mgItemNumber,
    onClose,
    priceType,
    selectedAgreement,
  ]);

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t('purchaseAgreements.agreement.actions.editConditionFor', {
          name: assortmentItem.primaryText,
        })}
      </DialogTitle>

      <DialogContent>
        <SelectItemAgreements
          mgItemNumber={assortmentItem.mgItemNumber}
          supplierId={mgSupplierId}
          selectedAgreement={selectedAgreement}
          selectedPriceType={priceType}
          selectAgreement={handleSelectAgreement}
        />
      </DialogContent>

      <DialogActions>
        <TextButton size="small" onClick={onClose}>
          {t('common.cancel')}
        </TextButton>
        <ContainedButton
          size="small"
          disabled={!selectedAgreement}
          loading={isLoadingPostChanges}
          onClick={submitAgreements}
        >
          {t('purchaseAgreements.agreement.actions.setToApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(ChangeSelectedItemAgreementDialog);
