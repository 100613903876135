import { FC } from 'react';
import { CustomIconButton } from '@retail/components';
import { Check, Clear } from '@mui/icons-material';
import { Stack } from '@mui/material';

interface Props {
  onSubmit: () => void;
  onCancel: () => void;
}

export const ConfirmEditButtons: FC<Props> = ({ onSubmit, onCancel }) => (
  <Stack flexDirection="row">
    <CustomIconButton onClick={onSubmit} icon={<Check />} />
    <CustomIconButton onClick={onCancel} icon={<Clear />} />
  </Stack>
);
