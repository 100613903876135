import { FC } from 'react';
import { StyledTextField } from '../Calculus/CalculusInfoRow';
import { useForm } from 'react-hook-form';
import { salesCoverageSchema, SalesCoverageSchema } from '@retail/markup/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { Stack } from '@mui/material';
import { ConfirmEditButtons } from '../ConfirmEditButtons';
import { HelperText } from '@shared/components';

interface Props {
  defaultValue: number;
  onSubmit: (value: number) => void;
  onCancel: () => void;
}

export const SalesCoverageInput: FC<Props> = ({ defaultValue, onSubmit, onCancel }) => {
  const t = useCalculusTFunction();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SalesCoverageSchema>({
    defaultValues: { salesCoverage: defaultValue },
    resolver: yupResolver(salesCoverageSchema(t)),
  });

  const _onSubmit = handleSubmit(({ salesCoverage }) => {
    onSubmit(salesCoverage);
  });

  return (
    <Stack alignItems="flex-end">
      <Stack direction="row" alignItems="center">
        <StyledTextField
          {...register('salesCoverage')}
          size="small"
          error={!!errors.salesCoverage?.message}
        />
        <ConfirmEditButtons onSubmit={_onSubmit} onCancel={onCancel} />
      </Stack>
      {errors.salesCoverage?.message && (
        <HelperText id="salesCoverageInputError" status="error">
          {errors.salesCoverage?.message}
        </HelperText>
      )}
    </Stack>
  );
};
