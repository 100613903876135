import { DialogContent, DialogTitle } from '@mui/material';
import { FollowFixedPriceForm, FollowFixedPriceSchema } from '@retail/fixed-price/components';
import { useFixedPriceTFunction } from '@retail/fixed-price/i18n';
import { Dialog } from '@shared/components';
import { useLevelSelect } from '@retail/calculus/context';
import { useFormContext } from 'react-hook-form';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useEffect } from 'react';

interface Props {
  supplierId: number;
  open: boolean;
  onClose: () => void;
}

export function FollowFixedPriceDialog({ supplierId, open, onClose }: Props) {
  const t = useCalculusTFunction();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    clearErrors,
  } = useFormContext<FollowFixedPriceSchema>();

  const level = watch('level');
  const itemSearch = watch('itemSearch');

  const { productGroups, mgSupplierItems, isLoadingFetchItems } = useLevelSelect({
    supplierId,
    selectedProductGroup: level === 'productGroup',
    selectedDiscountGroup: level === 'discountGroup',
    itemSearch,
    onItemSearchSuccess: (results) => {
      if (!results.result.length) {
        setError('item', { message: t('calculus.error.itemSearch.noResults') });
      }
    },
    onItemSearchError: (err) => {
      console.error(err);
      setError('item', { message: t('calculus.error.itemSearch.generic') });
    },
  });

  useEffect(() => {
    clearErrors('item');
  }, [clearErrors, itemSearch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t('calculus.followFixedPriceCondition')}</DialogTitle>
      <DialogContent>
        <FollowFixedPriceForm
          productGroups={productGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
        />
      </DialogContent>
    </Dialog>
  );
}
