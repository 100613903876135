import { Divider, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import {
  useAssortmentFilters,
  useIncludeVatMyAssortment,
  useMemberAssortments,
} from '@retail/my-assortment/context';
import { Feature, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { MyAssortmentFilters } from '../Filters';
import { AddItemsButton, AssortmentTabs, ExcludeVatSwitch } from '@retail/my-assortment/components';
import { AddItemsToDraft } from './AddItemsToDraft';
import { useDisclosure } from '@shared/hooks';
import { AssortmentTab } from '@retail/my-assortment/types';
import { AssortmentItemsContainer } from '../AssortmentItems/AssortmentItemsContainer';
import { useFeatureContext } from '@retail/app/stores/selected-context';
import { useMgPrisConfig } from '@retail/app/stores/app-config';

interface Props {
  departmentDraftAssortmentId: number;
}

export function MyAssortmentContainer({ departmentDraftAssortmentId }: Props) {
  const { draftAssortmentId: memberDraftAssortmentId, memberId } = useMemberAssortments();
  const featureContext = useFeatureContext();
  const { featureFlags } = useMgPrisConfig();

  const { isOpen: isAddItemsDialogOpen, onToggle: toggleAddItemsDialog } = useDisclosure(false);
  const [selectedTab, setSelectedTab] = useState<AssortmentTab>(AssortmentTab.MY_ASSORTMENT);
  const { isOpen: showFilters, onToggle: toggleFilters } = useDisclosure(true);
  const { includeVat, toggleIncludeVat } = useIncludeVatMyAssortment();
  const { searchFilters, setSearchFilters, filterOptions, resetFilters } = useAssortmentFilters({
    assortmentId: departmentDraftAssortmentId,
  });

  const onToggleAddItemsDialog = useCallback(() => {
    toggleAddItemsDialog();
    resetFilters();
  }, [resetFilters, toggleAddItemsDialog]);

  return (
    <Stack flexGrow={1} overflow="hidden">
      <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-end" pt={2} px={3}>
        <AssortmentTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

        <Stack flexDirection="row" gap={1} pb={1} alignSelf="flex-end">
          <ExcludeVatSwitch toggleVat={includeVat} handleToggleVat={toggleIncludeVat} />

          <Feature
            features={featureFlags}
            name="my-assortment:items:write"
            context={featureContext}
            renderEnabled={() => <AddItemsButton onClick={onToggleAddItemsDialog} />}
          />
          {isAddItemsDialogOpen && (
            <AddItemsToDraft
              memberId={memberId}
              draftAssortmentId={memberDraftAssortmentId}
              closeDialog={onToggleAddItemsDialog}
            />
          )}
        </Stack>
      </Stack>

      <Divider />

      <Stack overflow="hidden" flexGrow={1} flexDirection="row">
        <SuspenseWithSentryErrorBoundary>
          <AssortmentItemsContainer
            assortmentId={departmentDraftAssortmentId}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            filterOptions={filterOptions}
            selectedTab={selectedTab}
            showFilters={showFilters}
            toggleFilters={toggleFilters}
            toggleAddItemsDialog={onToggleAddItemsDialog}
          />
        </SuspenseWithSentryErrorBoundary>

        {showFilters && (
          <MyAssortmentFilters
            {...searchFilters}
            {...setSearchFilters}
            {...filterOptions}
            showFilters={showFilters}
            closeFilters={toggleFilters}
          />
        )}
      </Stack>
    </Stack>
  );
}
