import { BaseConfig, envType, FeatureFlag, MgPrisConfig } from '@retail/mgpris/config-types';

const env = (process.env['NX_PUBLIC_REACT_APP_ENV'] || 'dev') as envType;
const version = process.env['NX_PUBLIC_REACT_APP_VERSION'] || 'local';
const buildTime = process.env['NX_PUBLIC_BUILD_TIMESTAMP'];

const baseConfig: BaseConfig = {
  env,
  version,
  buildTime: buildTime ? Number(buildTime) : undefined,
  appInfo: {
    name: 'MG Pris',
    key: 'mgpris',
  },
  country: 'SE',
  sentryDsn: 'https://e3cd88ae61f54917bb65c433bc5f7317@o344472.ingest.sentry.io/4504948992573440',
  muiLicenseKey:
    '6d8435cc381f6517e52e0d009c746a83Tz0xMDEwNzEsRT0xNzYyMDcxNTg1MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
  retailerLogoBaseUrl: 'https://www.byggmaterialpriser.se/static/images/logos',
};

const assortmentWriteFeatureEnabled: FeatureFlag = {
  name: 'my-assortment:items:write',
  enabled: true,
};

const devConfig: MgPrisConfig = {
  ...baseConfig,
  mpe: { url: 'https://mg-price-engine.datahub-dev.mestergruppen.cloud' },
  auth0: {
    clientId: 'UYjBrp5ppcwJUL5b5UxvRdOfmZFs5Buo',
    domain: 'dev.id.mestergruppen.cloud',
    audience: 'mg.pps',
    mpeAudience: 'mg.mpe',
  },
  featureFlags: [
    {
      name: 'bracket-price-table',
      enabled: true,
    },
    {
      name: 'fixed-price',
      enabled: true,
    },
    assortmentWriteFeatureEnabled,
  ],
  gaMeasurementId: 'G-VKQD1381K7',
};

const localDevConfig: MgPrisConfig = {
  ...devConfig,
  mpe: { url: 'http://localhost:8080' },
  featureFlags: [
    {
      name: 'bracket-price-table',
      enabled: true,
    },
    {
      name: 'fixed-price',
      enabled: true,
    },
    assortmentWriteFeatureEnabled,
  ],
  gaMeasurementId: undefined,
  sentryDsn: undefined,
};

const stageConfig: MgPrisConfig = {
  ...baseConfig,
  mpe: { url: 'https://mg-price-engine.datahub-stage.mestergruppen.cloud' },
  auth0: {
    clientId: 'ZrXG1bqDMluUcStracHu4ICTb6GOjIxm',
    domain: 'stage.id.mestergruppen.cloud',
    audience: 'mg.pps',
    mpeAudience: 'mg.mpe',
  },
  featureFlags: [
    {
      name: 'bracket-price-table',
      enabled: true,
    },
    {
      name: 'fixed-price',
      enabled: true,
    },
    assortmentWriteFeatureEnabled,
  ],
  gaMeasurementId: 'G-L50QSGM4KC',
  clarity: {
    id: 'nva1srvcoy',
  },
};

const localStageConfig: MgPrisConfig = {
  ...stageConfig,
  mpe: { url: 'http://localhost:8080' },
  featureFlags: [
    {
      name: 'bracket-price-table',
      enabled: true,
    },
    {
      name: 'fixed-price',
      enabled: true,
    },
    assortmentWriteFeatureEnabled,
  ],
  gaMeasurementId: undefined,
  sentryDsn: undefined,
};

const prodConfig: MgPrisConfig = {
  ...baseConfig,
  mpe: { url: 'https://mg-price-engine.datahub-prod.mestergruppen.cloud' },
  auth0: {
    clientId: 'Ee7lS0kFJ2p4etdsMj1rlNyHOnYuTaqE',
    domain: 'id.mestergruppen.cloud',
    audience: 'mg.pps',
    mpeAudience: 'mg.mpe',
  },
  featureFlags: [
    {
      name: 'bracket-price-table',
      enabled: false,
    },
    {
      name: 'fixed-price',
      enabled: false,
    },
    {
      name: 'my-assortment:items:write',
      enabled: ({ locationType }) => locationType === 'retail',
    },
  ],
  gaMeasurementId: 'G-KWYZQBT33Z',
  // hotjar: {
  //   id: 3465595,
  //   version: 6,
  // },
  clarity: {
    id: 'jmqq9u2kpx',
  },
};

export const mgPrisEnvConfigs: { [key in envType]: MgPrisConfig } = {
  localDev: localDevConfig,
  dev: devConfig,
  localStage: localStageConfig,
  stage: stageConfig,
  prod: prodConfig,
};

export const mgPrisConfig: MgPrisConfig = mgPrisEnvConfigs[env];
