import { Stack } from '@mui/material';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { ConditionState } from '@retail/calculus/types';
import { AddButton, CalculusActionPortal } from '@retail/components';
import { Supplier } from '@retail/suppliers/types';
import { UniversalFormProvider } from '@shared/components';
import { followFixedPriceSchema } from '@retail/fixed-price/components';
import { FollowFixedPriceDialog } from './FollowFixedPrice';

interface Props {
  selectedSupplier: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  toggleCreateDialog: () => void;
}

export function FixedPriceConditions({
  selectedSupplier,
  isCreateOpen,
  toggleCreateDialog,
}: Props) {
  const t = useCalculusTFunction();
  return (
    <Stack
      flexGrow={1}
      gap={2}
      overflow="auto"
      borderTop={({ palette }) => `1px solid ${palette.divider}`}
    >
      <CalculusActionPortal>
        <AddButton onClick={toggleCreateDialog}>
          {t('calculus.followFixedPriceCondition')}
        </AddButton>
      </CalculusActionPortal>

      {isCreateOpen && (
        <UniversalFormProvider
          schema={followFixedPriceSchema(t)}
          defaultValues={followFixedPriceSchema(t).getDefault()}
        >
          <FollowFixedPriceDialog
            supplierId={selectedSupplier.mgSupplierId}
            open={true}
            onClose={toggleCreateDialog}
          />
        </UniversalFormProvider>
      )}
    </Stack>
  );
}
