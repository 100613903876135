import { CalculationAdjustmentDTO } from '../../dto';

export class CalculationAdjustment {
  purchaseCondition: boolean;
  freightCondition: boolean;
  markupCondition: boolean;
  agreementPrice: boolean;

  constructor(dto: CalculationAdjustmentDTO) {
    this.purchaseCondition = dto.purchaseCondition;
    this.freightCondition = dto.freightCondition;
    this.markupCondition = dto.markupCondition;
    this.agreementPrice = dto.agreementPrice;
  }

  static fromDto(dto: CalculationAdjustmentDTO): CalculationAdjustment {
    return new CalculationAdjustment(dto);
  }
}
