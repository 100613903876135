import {
  CompetitorPriceDTO,
  MgPriceDTO,
  RetailerPriceDTO,
  Variant,
  VariantCompetitorPrice,
} from '@retail/products/types';
import { Competitor, RetailerCompetitor, StoreCompetitor } from '@retail/retailer/types';

export const applicableCompetitorPrice = ({ primaryPrice, convertedPrice }: CompetitorPriceDTO) =>
  convertedPrice || primaryPrice;

export function renderCompetitorPrice(competitorPrice: CompetitorPriceDTO) {
  return renderPrice(applicableCompetitorPrice(competitorPrice));
}

export function renderPrice(price?: number) {
  if (price === null || price === undefined) return undefined;

  return Number(price.toFixed(2));
}

export function formatPrice(price: number, locale: string, alwaysIncludeDecimals = false): string {
  const isInteger = price % 1 === 0;
  return (isInteger && !alwaysIncludeDecimals)
    ? price.toLocaleString(locale)
    : price.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

export function toFixedIfNecessary(num: number, maxNumberOfDecimals: number) {
  return +num.toFixed(maxNumberOfDecimals);
}

export function renderPercent(percent?: number) {
  if (percent !== undefined) {
    return Number(percent).toFixed(0);
  } else return percent;
}

export function renderNumberFixed(fixed: number, price?: number) {
  if (price !== undefined) {
    return Number(price).toFixed(fixed);
  } else return price;
}

// If negative => retailer er dyrere
// if positive 0> retailer er billigere
export function renderDiffPrice(price?: number) {
  if (price !== undefined) {
    if (price > 0) {
      return '-' + Number(price).toFixed(2);
    } else if (price < 0) {
      return '+' + Math.abs(Number(price)).toFixed(2);
    } else {
      return Number(price).toFixed(2);
    }
  } else return price;
}

// 20 - 40 = -20 => Retail er 20 kr dyrere
// 20 - 15 = +5 => Retail er 5 kr billigere
export function diffPrice(mgPrice?: number, retailPrice?: number) {
  if (retailPrice !== undefined && mgPrice !== undefined) {
    return mgPrice - retailPrice;
  }
  return undefined;
}

// export const calcDG = (
//   price: MgPrice,
//   adjustedPrice: number,
//   adjustedDg: number
// ) => {
//   const sPrice = price.salgsPris + adjustedPrice;
//   return ((sPrice - price.innkjopsPris) / sPrice) * 100 + adjustedDg;
// };

export const calcPrice = (price: MgPriceDTO) => {
  // const dg = price.dekningsgrad + adjustedDg;
  // const dgx = dg / 100 - 1;
  const i = price.innkjopsPris * -1;
  // return i / dgx + adjustedPrice;
  return i;
};

// export const pickPrice = (
//   price: MgPrice,
//   adjustedPrice: number,
//   adjustedDg: number
// ) => {
//   if (adjustedPrice === 0 && adjustedDg === 0) {
//     return price.salgsPris;
//   }
//   return calcPrice(price, adjustedDg, adjustedPrice);
// };

// export const pickDg = (
//   price: MgPrice,
//   adjustedPrice: number,
//   adjustedDg: number
// ) => {
//   if (adjustedPrice === 0 && adjustedDg === 0) {
//     return 0;
//   }
//   return calcDG(price, adjustedPrice, adjustedDg);
// };

export interface CompetitorPriceUI {
  key: string;
  price?: CompetitorPriceDTO;
}

export function toRetailPrice(
  competitor: Competitor,
  competitorPrices: RetailerPriceDTO[]
): CompetitorPriceUI {
  const priceRetailer = competitorPrices.find(
    (price) => price.retailerId === competitor.retailer().id
  );

  if (!priceRetailer) {
    return {
      key: competitor.key(),
    };
  }

  if (competitor.type() === 'retailer') {
    return {
      key: competitor.key(),
      price: priceRetailer.nationalPrices[0],
    };
  } else {
    const storePrice = priceRetailer.stores.find((store) => store.storeId === competitor.id);

    return {
      key: competitor.key(),
      price: storePrice?.localPrices[0],
    };
  }
}

export function findLowestStorePriceForVariants(
  storeCompetitor: StoreCompetitor,
  variants: Variant[]
): VariantCompetitorPrice | undefined {
  return variants.reduce((tmpLowest, variant) => {
    const variantRetailer = variant.competitorPrice?.retailers?.find(
      ({ retailerId }) => retailerId === storeCompetitor.retailer().id
    );

    if (!variantRetailer) {
      return tmpLowest;
    }

    const variantStorePrice = variantRetailer.stores.find(
      ({ storeId }) => storeId === storeCompetitor.id
    )?.localPrices?.[0];

    if (
      hasLowerPrice({
        priceToCheck: variantStorePrice,
        priceComparedTo: tmpLowest?.price,
      })
    ) {
      return {
        productName: variant.varetekst1,
        nobbNr: variant.nobbNr,
        price: variantStorePrice,
      };
    }

    return tmpLowest;
  }, undefined as VariantCompetitorPrice | undefined);
}

export function findLowestRetailerPriceForVariants(
  retailerCompetitor: RetailerCompetitor,
  variants: Variant[]
): VariantCompetitorPrice | undefined {
  return variants.reduce((tmpLowest, variant) => {
    const variantRetailer = variant.competitorPrice?.retailers?.find(
      ({ retailerId }) => retailerId === retailerCompetitor.id
    );

    if (!variantRetailer) {
      return tmpLowest;
    }

    const variantNationalPrice = variantRetailer.nationalPrices[0];

    if (
      hasLowerPrice({
        priceToCheck: variantNationalPrice,
        priceComparedTo: tmpLowest?.price,
      })
    ) {
      return {
        productName: variant.varetekst1,
        nobbNr: variant.nobbNr,
        price: variantNationalPrice,
      };
    }
    return tmpLowest;
  }, undefined as VariantCompetitorPrice | undefined);
}

export function findLowestPriceForVariants(
  selectedCompetitor: Competitor,
  variants: Variant[]
): VariantCompetitorPrice | undefined {
  switch (selectedCompetitor.type()) {
    case 'retailer':
      return findLowestRetailerPriceForVariants(selectedCompetitor, variants);
    case 'store':
      return findLowestStorePriceForVariants(selectedCompetitor as StoreCompetitor, variants);
  }
}

const hasLowerPrice = ({
  priceToCheck,
  priceComparedTo,
}: {
  priceToCheck: CompetitorPriceDTO | undefined;
  priceComparedTo: CompetitorPriceDTO | undefined;
}): boolean => {
  if (!priceComparedTo) {
    return true;
  }
  if (!priceToCheck) {
    return false;
  }

  return applicableCompetitorPrice(priceToCheck) < applicableCompetitorPrice(priceComparedTo);
};

interface GenericPrice {
  changed?: string;
}

export const sortPriceByChanged = (price1: GenericPrice, price2: GenericPrice) => {
  const time1 = price1.changed ? new Date(price1.changed).getTime() : -1;
  const time2 = price2.changed ? new Date(price2.changed).getTime() : -1;

  return time2 - time1;
};

export const getCurrentPrice = <T extends GenericPrice>(prices: T[]) =>
  prices.sort(sortPriceByChanged)[0];
