import { alpha, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Chip } from '@shared/components';
import { FieldValueView } from '../FieldValueView';
import { SalesFactorInput } from './SalesFactorInput';
import { EditFieldButton } from '../EditFieldButton';
import { useTranslation } from 'react-i18next';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

interface Props {
  currentSalesFactor?: number;
  newSalesFactor?: {
    value: number;
    isApproximate: boolean;
  };
  editProps?: {
    isEditing: boolean;
    openEditMode: () => void;
    cancelEditMode: () => void;
    onChangeSalesFactor: (value: number) => void;
  };
}

export function MarkupAccordionSummary({
  currentSalesFactor,
  newSalesFactor,
  editProps,
}: Props) {
  const {
    i18n: { language },
  } = useTranslation();
  const { palette } = useTheme();
  const t = useMyAssortmentTFunction();
  const hasNewSalesFactor = !!newSalesFactor && currentSalesFactor !== newSalesFactor?.value;

  return (
    <Stack component={Grid} container flexDirection="row" alignItems="center">
      <Grid item xs={5}>
        <Typography fontWeight={500}>{t('myAssortment.markup')}</Typography>
      </Grid>
      <Grid item>
        {editProps?.isEditing ? (
          <SalesFactorInput
            defaultValue={newSalesFactor?.value || currentSalesFactor || 0}
            onSubmit={editProps.onChangeSalesFactor}
            onCancel={editProps.cancelEditMode}
          />
        ) : (
          <Stack flexDirection="row" alignItems="center">
            <Chip
              backgroundColor={alpha(palette.grey.A100, 0.4)}
              label={
                <FieldValueView
                  oldValue={
                    currentSalesFactor
                      ? currentSalesFactor.toLocaleString(language, { maximumFractionDigits: 6 })
                      : '-'
                  }
                  newValue={
                    newSalesFactor?.value
                      ? newSalesFactor.value.toLocaleString(language, { maximumFractionDigits: 6 })
                      : '-'
                  }
                  hasNewValue={hasNewSalesFactor}
                  approximatelyEqual={newSalesFactor?.isApproximate}
                />
              }
              sx={{
                border: `1px solid ${palette.divider}`,
                minWidth: 68,
                height: hasNewSalesFactor ? 42 : 32,
              }}
            />
            {editProps?.openEditMode && <EditFieldButton onEdit={editProps.openEditMode} />}
          </Stack>
        )}
      </Grid>
    </Stack>
  );
}
