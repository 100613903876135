import { Menu, MenuButton } from '@retail/components';
import { Add } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { EyeIcon } from '@shared/custom-icons';

interface Props {
  onAddToNewMonitoringAssortments: () => void;
  onAddToExistingMonitoringAssortments?: () => void;
}

export const ActionsMenu: React.FC<Props> = ({
  onAddToNewMonitoringAssortments,
  onAddToExistingMonitoringAssortments,
}) => {
  const t = useAssortmentTFunction();
  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton size="small" color="secondary" variant="outlined" startIcon={<EyeIcon />}>
            {t('assortment.monitorItem')}
          </MenuButton>

          <MenuItem
            LinkComponent={Button}
            onClick={() => {
              onClose();
              onAddToNewMonitoringAssortments();
            }}
          >
            {t('assortment.addToNewAssortment')}
            <Add />
          </MenuItem>

          {!!onAddToExistingMonitoringAssortments && (
            <MenuItem
              LinkComponent={Button}
              onClick={() => {
                onClose();
                onAddToExistingMonitoringAssortments();
              }}
            >
              {t('assortment.chooseExistingAssortment')}
            </MenuItem>
          )}
        </>
      )}
    </Menu>
  );
};
