import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { AddItemsTabs, UploadAssortmentContainer } from '@retail/my-assortment/components';
import { useAssortmentImport } from '@retail/my-assortment/context';
import { DraftTabs, ItemsDTO } from '@retail/my-assortment/types';
import { isValidItem } from '@retail/my-assortment/utils';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback, useEffect, useState } from 'react';
import { MgSupplierItemSearchContainer } from '../MgSupplierItemSearch';
import { useAssortmentActions } from '@retail/mgpdm/context';

interface Props {
  baseAssortmentId: number;
  closeDialog: () => void;
}

export function AddItemsToAssortment({ baseAssortmentId, closeDialog }: Props) {
  const t = useAppTFunction();

  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);
  const [selectedTab, setSelectedTab] = useState<DraftTabs>(DraftTabs.ADD_ITEMS);

  const { updateAssortmentItems, updateItemsIsLoading } = useAssortmentActions();

  const onSubmit = useCallback(
    (selectedItems: MgSupplierItemSearchResult[]) => {
      const items = selectedItems.map((item) => {
        return {
          mgSupplierId: item.mgParticipantAccountId,
          mgItemNumber: item.mgItemNumber,
          priority: 'ZERO',
        } as ItemsDTO;
      });
      updateAssortmentItems(items, () => {
        setCheckedItems([]);
        closeDialog();
      });
    },
    [closeDialog, updateAssortmentItems]
  );

  const { importedItems, validationState, resetImportedAssortmentStates, loadingValidateItems } =
    useAssortmentImport({ assortmentId: baseAssortmentId });

  const onImportItems = useCallback(() => {
    const validItems = importedItems.filter((item) => isValidItem(item, validationState));
    updateAssortmentItems(validItems, () => closeDialog());
  }, [closeDialog, importedItems, updateAssortmentItems, validationState]);

  useEffect(() => {
    if (selectedTab !== DraftTabs.IMPORT) resetImportedAssortmentStates();
  }, [resetImportedAssortmentStates, selectedTab]);

  const handleSubmit = useCallback(() => {
    if (selectedTab === DraftTabs.ADD_ITEMS) {
      onSubmit(checkedItems);
    } else {
      onImportItems();
    }
  }, [checkedItems, onImportItems, onSubmit, selectedTab]);

  return (
    <Dialog open onClose={closeDialog} fullScreen sx={{ padding: 4 }}>
      <DialogTitle>
        <AddItemsTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          includeCentral={false}
        />
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexGrow: 1 }}>
        <Stack display="flex" flexGrow={1}>
          {selectedTab === DraftTabs.ADD_ITEMS && (
            <MgSupplierItemSearchContainer
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
          )}

          {selectedTab === DraftTabs.IMPORT && (
            <Stack display="flex" flexGrow={1} justifyContent="center">
              <UploadAssortmentContainer isLarge />
            </Stack>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <TextButton onClick={closeDialog}>{t('myAssortment.cancel')}</TextButton>
        <ContainedButton
          color="secondary"
          disabled={checkedItems.length === 0 && importedItems.length === 0}
          loading={loadingValidateItems || updateItemsIsLoading}
          onClick={handleSubmit}
          size="small"
        >
          {selectedTab === DraftTabs.IMPORT
            ? t('myAssortment.actions.addApprovedItems')
            : t('myAssortment.actions.addItems')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
