import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { DiscountGroupDTO } from '@retail/calculus/types';
import { useMPEFetch } from '@retail/app/hooks';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

export function useFetchDiscountGroups(
  { supplierId }: { supplierId: number },
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<DiscountGroupDTO[]>({
    method: 'GET',
    queryKey: ['discount-groups', supplierId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.suppliers.supplier.discountGroups.getFullLinkPath({ supplierId }),
      searchParams: {
        supplierId,
      },
    }),
    ...queryOptions,
  });
}
interface Props extends QueryOptions {
  assortmentId?: number;
  supplierIds?: number[];
}

export function useFetchAllDiscountGroups({ assortmentId, supplierIds, ...queryOptions }: Props) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<DiscountGroupDTO[]>({
    method: 'GET',
    queryKey: ['discount-groups', supplierIds, assortmentId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.discountGroups.getFullLinkPath(),
      searchParams: {
        assortmentId,
        supplierIds,
      },
    }),
    ...queryOptions,
  });
}
