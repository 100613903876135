import { alpha, Backdrop } from '@mui/material';
import { BodyLoader } from '../Loader';

interface Props {
  isLoading: boolean;
}

export const LoadingBackdrop = ({ isLoading }: Props) => {
  return (
    <Backdrop
      sx={{
        bgcolor: ({ palette }) => alpha(palette.common.white, 0.7),
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
      open={isLoading}
    >
      <BodyLoader />
    </Backdrop>
  );
};
