import { MgItem } from '@retail/products/types';
import { MgItemDetails } from './MgItemDetails';
import {
  useAssortmentItemsActions,
  useDepartmentAssortments,
  useMemberAssortments,
} from '@retail/my-assortment/context';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';

interface Props {
  mgItem: MgItem;
  hiddenBreadcrumbs?: boolean;
  showMonitorItem?: boolean;
  showAddItem?: boolean;
}

export function MgItemContainer({
  mgItem,
  showMonitorItem,
  hiddenBreadcrumbs,
  showAddItem,
}: Props) {
  const { draftAssortmentId: memberDraftAssortmentId } = useMemberAssortments();
  const { monitoringAssortments } = useDepartmentAssortments();

  const { addItemsToAssortment } = useAssortmentItemsActions();

  const navigate = useNavigate();
  const navigateToAssortment = useCallback(
    (assortmentId: number, selectedAssortment: MonitoringAssortment | null) => {
      navigate(
        selectedAssortment
          ? mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath({ assortmentId })
          : mgPrisRoutes.root.getFullLinkPath()
      );
    },
    [navigate]
  );

  return (
    <MgItemDetails
      mgItem={mgItem}
      assortmentId={memberDraftAssortmentId}
      monitoringAssortments={monitoringAssortments}
      showMonitorItem={showMonitorItem}
      hiddenBreadcrumbs={hiddenBreadcrumbs}
      showAddItem={showAddItem}
      addItemsToAssortment={addItemsToAssortment}
      navigateToAssortment={navigateToAssortment}
    />
  );
}
