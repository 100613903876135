import { PageLayout } from '@retail/components';
import { Paper } from '@shared/components';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { useState } from 'react';
import { MgSupplierItemSearchContainer } from '@retail/mgpdm/containers';
import { useBrandAssortments } from '@retail/mgpdm/context';

export function SupplierItemSearchPage() {
  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);
  const { baseAssortmentId } = useBrandAssortments();

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 0,
          pt: 2,
          overflow: 'hidden',
        }}
      >
        <MgSupplierItemSearchContainer
          includeAddButton
          clickableRow
          checkedItems={checkedItems}
          assortmentId={baseAssortmentId}
          setCheckedItems={setCheckedItems}
        />
      </Paper>
    </PageLayout>
  );
}
