import { Box, Stack, Typography } from '@mui/material';
import { OutlinedButton, Paper } from '@shared/components';
import { marketInsight1, marketInsight2 } from '@assets/images';
import { Link } from 'react-router-dom';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';

interface Props {
  competitorsRoutePath: string;
}
export function WelcomeCards({ competitorsRoutePath }: Props) {
  const t = useAssortmentTFunction();

  return (
    <Stack flexDirection="row" gap={2}>
      <Stack component={Paper} flexDirection="row" justifyContent="space-between" width="60%">
        <Box mr={2}>
          <Typography component="h1" variant="h6" marginBottom={1.5}>
            {t('assortment.infoCards.welcome')}
          </Typography>
          <Typography variant="body1" maxWidth={550} sx={{ pt: 1, color: 'text.secondary' }}>
            {t('assortment.infoCards.welcomeDesc')}
          </Typography>
        </Box>
        <img
          src={marketInsight1}
          width="170px"
          height="150px"
          alt="marketInsight1"
          style={{ opacity: 0.7 }}
        />
      </Stack>

      <Stack component={Paper} flexDirection="row" justifyContent="space-between" width="40%">
        <Box mr={2}>
          <Typography component="h1" variant="h6" marginBottom={1.5}>
            {t('assortment.infoCards.competitors')}
          </Typography>

          <Typography variant="body1" marginBottom={3} sx={{ pt: 1, color: 'text.secondary' }}>
            {t('assortment.infoCards.competitorsDesc')}
          </Typography>
          <OutlinedButton
            component={Link}
            to={competitorsRoutePath}
            size="small"
            variant="secondary"
          >
            {t('assortment.competitors.heading')}
          </OutlinedButton>
        </Box>
        <img
          src={marketInsight2}
          width="170px"
          height="150px"
          alt="marketInsight2"
          style={{ opacity: 0.7 }}
        />
      </Stack>
    </Stack>
  );
}
