import { useMemo } from 'react';
import { AssortmentItem, AssortmentTab } from '@retail/my-assortment/types';
import {
  CopyToClipboard,
  FormattedPrice,
  StackedTextCell,
  TopLineTypography,
} from '@retail/components';
import { dataGridBoldClass } from '@shared/styles';
import { ItemStatus } from './ItemStatus/ItemStatus';
import dayjs from 'dayjs';
import { Box, Stack } from '@mui/material';
import { DecreaseIcon, IncreaseIcon } from '@shared/custom-icons';
import { formatPrice, supplierSearchParamKey } from '@retail/products/utils';
import { AssortmentTableActions } from './AssortmentTableActions';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-premium';
import { BracketPriceCell } from './BracketPriceCell';
import { FilterCellAction } from './FilterCellAction';
import { PricePerUnit } from '@retail/products/components';

type GridColAssortmentItem = GridColDef<AssortmentItem>;

export interface ShowColumnProps {
  selectedTab: AssortmentTab;
  showBracketPriceColumns: boolean;
}

interface Props {
  includeVat: boolean;
  deleteItem: (itemId: number) => void;
  getSupplierCalculusLink?: (supplierId: number) => string;
  selectSupplier: (supplierId: number) => void;
  selectProductGroup: (productGroupNumber: string) => void;
  selectDiscountGroup: (
    discountGroupId: number,
    addContiguousSearchParamKey?: { searchParam: string; value: string }
  ) => void;
  selectRowId: (rowId: number) => void;
}

type ColumnConfig = {
  show: (props: ShowColumnProps) => boolean;
  getColumn: () => GridColAssortmentItem;
};

const baseColumnProps: Partial<GridColDef> = {
  width: 130,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

export const useAssortmentColumns = ({
  includeVat,
  deleteItem,
  getSupplierCalculusLink,
  selectSupplier,
  selectProductGroup,
  selectDiscountGroup,
  selectRowId,
}: Props): ColumnConfig[] => {
  const { t } = useMyAssortmentTranslation();
  const { i18n } = useTranslation();

  return useMemo(
    () => [
      {
        show: () => true,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'mgItemFinfoNumber',
          headerName: t('myAssortment.columns.mgItemFinfoNumber'),

          renderCell: ({ row }) => (
            <StackedTextCell
              topLine={
                <CopyToClipboard value={row.mgItemNumber} hasIconButtonPadding={false}>
                  {row.mgItemNumber}
                </CopyToClipboard>
              }
              bottomLine={row.finfoNumber}
            />
          ),
        }),
      },
      {
        show: () => true,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'itemText',
          cellClassName: () => dataGridBoldClass,
          headerName: t('myAssortment.columns.itemText'),

          width: 250,
          renderCell: ({ row }) => (
            <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
          ),
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'status',
          headerAlign: 'center',
          align: 'center',
          width: 140,
          headerName: t('myAssortment.columns.itemStatus'),
          renderCell: ({ value }) => <ItemStatus status={value} />,
        }),
      },
      {
        show: () => true,
        getColumn: () => ({
          ...baseColumnProps,
          width: 200,
          field: 'supplier',

          headerName: t('myAssortment.columns.supplier'),
          renderCell: ({ row }) => (
            <StackedTextCell
              topLine={
                <TopLineTypography variant="subtitle2" isInline>
                  {row.mgSupplierName}

                  <FilterCellAction
                    onFilterClick={() => selectSupplier(row.mgSupplierId)}
                    filterTitle={t('myAssortment.actions.selectSupplierAsQuickFilter')}
                  />
                </TopLineTypography>
              }
              bottomLine={row.mgSupplierId}
            />
          ),
        }),
      },
      {
        show: () => true,
        getColumn: () => ({
          ...baseColumnProps,
          width: 200,
          field: 'itemGroup',

          headerName: t('myAssortment.columns.itemGroup'),
          renderCell: ({ row }) => (
            <StackedTextCell
              topLine={
                <TopLineTypography variant="subtitle2" isInline>
                  {row.groupNumber}

                  {row.groupNumber && (
                    <FilterCellAction
                      onFilterClick={() => selectProductGroup(row.groupNumber)}
                      filterTitle={t('myAssortment.actions.selectProductGroupAsQuickFilter')}
                    />
                  )}
                </TopLineTypography>
              }
              bottomLine={row.groupDescription}
            />
          ),
        }),
      },
      {
        show: () => true,
        getColumn: () => ({
          ...baseColumnProps,
          width: 200,
          field: 'discountGroup',
          headerName: t('myAssortment.columns.discountGroup'),

          renderCell: ({ row }) => (
            <StackedTextCell
              topLine={
                <TopLineTypography variant="subtitle2" isInline>
                  {row.discountGroup?.name}
                  {row.discountGroup?.code && (
                    <FilterCellAction
                      onFilterClick={() => {
                        selectDiscountGroup(row.discountGroup?.id ?? null, {
                          searchParam: supplierSearchParamKey,
                          value: `${row.mgSupplierId}`,
                        });
                      }}
                      filterTitle={t('myAssortment.actions.selectDiscountGroupAsQuickFilter')}
                    />
                  )}
                </TopLineTypography>
              }
              bottomLine={row.discountGroup?.code}
            />
          ),
        }),
      },
      {
        show: () => true,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'purchasePrice',
          headerName: t('myAssortment.columns.purchasePrice'),
          width: 180,

          renderCell: ({ row }) => {
            const primaryPackageUnit = row.primaryPackageUnit;
            const currentPurchasePrice = row.currentSalesPrices?.purchasePrice;
            const priceDate = currentPurchasePrice
              ? dayjs(currentPurchasePrice.validFrom).format('ll')
              : null;
            const purchasePriceForUnit = row.getCurrentPurchasePriceForUnit(primaryPackageUnit);
            const purchasePrice = purchasePriceForUnit
              ? formatPrice(purchasePriceForUnit.price.price, i18n.language)
              : '-';
            const unitDescription = purchasePriceForUnit?.supplierItemPackage?.unit?.description;

            return (
              <StackedTextCell
                nullableBottomLine
                topLine={
                  <PricePerUnit
                    price={purchasePrice}
                    unit={primaryPackageUnit}
                    description={unitDescription}
                  />
                }
                bottomLine={priceDate}
              />
            );
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.PRICE_CHANGES,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'futurePurchasePrice',
          headerName: t('myAssortment.columns.futurePurchasePrice'),
          width: 180,

          renderCell: ({ row }) => {
            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePriceCalculation = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const purchasePriceInPrimaryUnit =
              fPakFuturePriceCalculation?.purchasePrice?.getPriceForUnit(row.primaryPackageUnit);

            if (!(purchasePriceInPrimaryUnit && fPakFuturePriceCalculation)) return '-';

            return (
              <StackedTextCell
                nullableBottomLine
                topLine={t('myAssortment.pricePerUnit', {
                  price: formatPrice(purchasePriceInPrimaryUnit.price.price, i18n.language),
                  unit: row.primaryPackageUnit,
                })}
                bottomLine={dayjs(fPakFuturePriceCalculation.validFrom).format('ll')}
              />
            );
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.PRICE_CHANGES,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'purchasePriceChange',
          headerName: t('myAssortment.columns.change'),

          renderCell: ({ row }) => {
            const primaryPackageUnit = row.primaryPackageUnit;
            const purchasePrice = row.getCurrentPurchasePriceForUnit(primaryPackageUnit);

            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePriceCalculation = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const futurePurchasePrice =
              fPakFuturePriceCalculation?.purchasePrice?.getPriceForUnit(primaryPackageUnit);

            if (!purchasePrice && !futurePurchasePrice) return '-';
            else if (!!purchasePrice && !!futurePurchasePrice) {
              const changeInValue = futurePurchasePrice.price.price - purchasePrice.price.price;
              return (
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  {changeInValue > 0 ? (
                    <IncreaseIcon color="warning" fontSize="small" />
                  ) : (
                    <DecreaseIcon color="success" fontSize="small" />
                  )}
                  {t('myAssortment.recommendedPrices.value', {
                    value: formatPrice(changeInValue, i18n.language),
                  })}
                </Stack>
              );
            } else {
              return t('myAssortment.recommendedPrices.value', { value: 0 });
            }
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'costPriceInSalesUnit',
          width: 160,
          headerName: t('myAssortment.columns.costPriceInSalesUnit'),
          renderCell: ({ row }) => {
            const primaryPackageUnit = row.primaryPackageUnit;

            const validFrom = row.currentSalesPrices?.costPrice?.validFrom;
            const priceDate = validFrom ? dayjs(validFrom).format('ll') : undefined;

            const currentCostPrice = row.getCurrentCostPriceForUnit(primaryPackageUnit);

            const costPrice = currentCostPrice
              ? formatPrice(currentCostPrice.price.price, i18n.language)
              : '-';

            const unitDescription = currentCostPrice?.supplierItemPackage.unit.description;

            return (
              <StackedTextCell
                nullableBottomLine
                topLine={
                  <PricePerUnit
                    price={costPrice}
                    unit={primaryPackageUnit}
                    description={unitDescription}
                  />
                }
                bottomLine={priceDate}
              />
            );
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'costPrice',
          width: 160,

          headerName: t('myAssortment.columns.costPriceInBasePriceUnit'),
          renderCell: ({ row }) => {
            const basePriceUnit = row.basePriceUnit;

            const validFrom = row.currentSalesPrices?.costPrice?.validFrom;
            const priceDate = validFrom ? dayjs(validFrom).format('ll') : undefined;

            const currentCostPrice = row.getCurrentCostPriceForUnit(basePriceUnit);

            const costPrice = currentCostPrice
              ? formatPrice(currentCostPrice.price.price, i18n.language)
              : '-';

            const unitDescription = currentCostPrice?.supplierItemPackage.unit.description;

            return (
              <StackedTextCell
                nullableBottomLine
                topLine={
                  <PricePerUnit
                    price={costPrice}
                    unit={basePriceUnit}
                    description={unitDescription}
                  />
                }
                bottomLine={priceDate}
              />
            );
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'salesPrice',
          headerName: t('myAssortment.columns.salesPrice'),
          width: 180,
          renderCell: ({ row }) => {
            const primaryPackageUnit = row.primaryPackageUnit;
            const fPakUnitPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
            const packageSalesPrice =
              fPakUnitPrice?.bracketMarkupPrice.getPriceForUnit(primaryPackageUnit);
            const price = includeVat
              ? packageSalesPrice?.price.priceWithVat
              : packageSalesPrice?.price.priceWithoutVat;

            const unitDescription = packageSalesPrice?.supplierItemPackage.unit.description;

            const validFrom = fPakUnitPrice?.validFrom
              ? dayjs(fPakUnitPrice?.validFrom).format('ll')
              : undefined;

            return (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                {fPakUnitPrice && (
                  <ItemStatus
                    status={fPakUnitPrice.bracketMarkupPrice.status}
                    errors={fPakUnitPrice.bracketMarkupPrice.errors}
                  />
                )}
                <StackedTextCell
                  topLine={
                    <PricePerUnit
                      price={price ? formatPrice(price, i18n.language) : '-'}
                      unit={primaryPackageUnit}
                      description={unitDescription}
                    />
                  }
                  nullableBottomLine
                  bottomLine={validFrom}
                />
              </Box>
            );
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          cellClassName: () => dataGridBoldClass,
          width: 120,
          field: 'salesPriceCoverage',
          headerName: t('myAssortment.columns.salesCoverage'),
          renderCell: ({ row }) => {
            const fPakMarkup =
              row.currentSalesPrices?.getBracketPackagePrice('F-PAK')?.bracketMarkupPrice.markup;
            return fPakMarkup?.salesPriceCoverage
              ? `${formatPrice(Number(fPakMarkup?.salesPriceCoverage.toFixed(2)), i18n.language)} %`
              : '-';
          },
        }),
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          width: 180,
          field: 'salesPriceDpak',
          headerName: t('myAssortment.columns.salesPriceDpak'),
          renderCell: ({ row }) => (
            <BracketPriceCell
              priceCalculation={row.currentSalesPrices}
              includeVat={includeVat}
              bracketClassCode="D-PAK"
            />
          ),
        }),
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          cellClassName: () => dataGridBoldClass,
          width: 120,
          field: 'salesPriceDpakCoverage',
          headerName: t('myAssortment.columns.salesCoverageDpak'),
          renderCell: ({ row }) => {
            const salesPriceCoverage =
              row.currentSalesPrices?.getBracketPackagePrice('D-PAK')?.bracketMarkupPrice?.markup
                ?.salesPriceCoverage;

            return salesPriceCoverage
              ? `${formatPrice(Number(salesPriceCoverage.toFixed(2)), i18n.language)} %`
              : '-';
          },
        }),
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          width: 180,
          field: 'salesPriceTpak',
          headerName: t('myAssortment.columns.salesPriceTpak'),
          renderCell: ({ row }) => (
            <BracketPriceCell
              priceCalculation={row.currentSalesPrices}
              includeVat={includeVat}
              bracketClassCode="T-PAK"
              centerAlign={false}
            />
          ),
        }),
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          cellClassName: () => dataGridBoldClass,
          width: 120,
          field: 'salesPriceTpakCoverage',
          headerName: t('myAssortment.columns.salesCoverageTpak'),
          renderCell: ({ row }) => {
            const salesPriceCoverage =
              row.currentSalesPrices?.getBracketPackagePrice('T-PAK')?.bracketMarkupPrice?.markup
                ?.salesPriceCoverage;

            return salesPriceCoverage
              ? `${formatPrice(Number(salesPriceCoverage.toFixed(2)), i18n.language)} %`
              : '-';
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'futureSalesPrice',
          headerName: t('myAssortment.columns.futurePrice'),
          width: 150,
          renderCell: ({ row }) => {
            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePrices = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const fPakPrimaryUnitPrice = fPakFuturePrices?.getSalesPriceForUnit(
              row.primaryPackageUnit
            );

            if (!fPakPrimaryUnitPrice) return '-';

            return (
              <StackedTextCell
                topLine={
                  <FormattedPrice
                    price={
                      includeVat
                        ? fPakPrimaryUnitPrice.price.priceWithVat
                        : fPakPrimaryUnitPrice.price.priceWithoutVat
                    }
                    includeVat={false}
                  />
                }
                bottomLine={
                  fPakFuturePrices?.validFrom ? dayjs(fPakFuturePrices.validFrom).format('ll') : ''
                }
              />
            );
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          width: 150,
          field: 'priorityValidFrom',
          headerName: t('myAssortment.columns.addedToAssortmentDate'),
          renderCell: ({ row }) =>
            row.priorityValidFrom ? dayjs(row.priorityValidFrom).format('ll') : '-',
        }),
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          width: 150,
          field: 'source',
          headerName: t('myAssortment.columns.source'),
        }),
      },
      {
        show: () => true,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'actions',
          width: 125,
          sortable: false,
          hideable: false,
          disableColumnMenu: true,
          renderCell: ({ row }) => (
            <AssortmentTableActions
              deleteItem={() => deleteItem(row.id)}
              calculationLink={getSupplierCalculusLink && getSupplierCalculusLink(row.mgSupplierId)}
              viewItemProductCard={() => selectRowId(row.id)}
            />
          ),
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          width: 150,
          field: 'discontinuation',
          headerName: t('myAssortment.columns.discontinuation'),
          renderCell: ({ row }) => {
            return (
              <StackedTextCell
                topLine={row.discontinuationCode}
                bottomLine={
                  row.discontinuationDate ? dayjs(row.discontinuationDate).format('ll') : null
                }
              />
            );
          },
        }),
      },
    ],
    [
      deleteItem,
      getSupplierCalculusLink,
      i18n.language,
      includeVat,
      selectDiscountGroup,
      selectProductGroup,
      selectRowId,
      selectSupplier,
      t,
    ]
  );
};
