import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { BracketPriceTable, BracketPriceTableProps } from './BracketPriceTable';
import { TextButton } from '@retail/components';
import { EditButtonIcon } from '@shared/custom-icons';
import { useAppTFunction } from '@retail/app/i18n';

interface Props extends BracketPriceTableProps {
  onEdit: () => void;
}

export const BracketPriceAccordion: React.FC<Props> = ({ onEdit, ...tableProps }) => {
  const t = useAppTFunction();

  return (
    <Accordion>
      <AccordionSummary>
        <Typography>{t('myAssortment.discounts.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        <TextButton
          onClick={onEdit}
          endIcon={<EditButtonIcon />}
          sx={{ alignSelf: 'flex-end', mx: 3 }}
        >
          {t('markup.actions.edit')}
        </TextButton>
        <BracketPriceTable {...tableProps} />
      </AccordionDetails>
    </Accordion>
  );
};
