import { useMountEffect } from '@shared/hooks';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

const availableLanguageCodes = ['no', 'se'] as const;
type Language = (typeof availableLanguageCodes)[number];

const dayjsLanguageCodes: Record<Language, string> = {
  no: 'nb',
  se: 'sv',
};

export function useLanguageSelector() {
  const { i18n } = useTranslation();
  const { changeLanguage, language } = i18n;

  const switchLanguage = useCallback(
    (languageCode: Language) => {
      document.querySelector('html')?.setAttribute('lang', languageCode);
      changeLanguage(languageCode);
      dayjs.locale(dayjsLanguageCodes[languageCode]);
      localStorage.setItem('selectedLanguage', languageCode);
    },
    [changeLanguage]
  );

  useMountEffect(() => {
    const initLang = localStorage.getItem('selectedLanguage') || language;
    if (availableLanguageCodes.includes(initLang as Language)) {
      switchLanguage(initLang as Language);
    } else {
      switchLanguage('se');
    }
  });

  return useMemo(
    () => ({
      switchLanguage,
      language,
    }),
    [language, switchLanguage]
  );
}
