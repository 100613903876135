import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { Brand, Department, Member } from '@retail/auth/types';
import { useUserBrands } from './BrandContext';
import { useNavigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useMountEffect } from '@shared/hooks';
import { useMyOrgUnits } from './MyOrgUnitsProvider';
import * as Sentry from '@sentry/react';

export const selectedOrgUnitKey = 'selectedOrgUnit';

interface SelectedOrgUnit {
  type: 'BRAND' | 'MEMBER' | 'DEPARTMENT';
  id: number;
}

type SelectedOrgUnitState = {
  selectedDepartment: Department | undefined;
  selectedMember: Member | undefined;
  selectedBrandContext: Brand | undefined;
  selectDepartment: (department: Department) => void;
  selectMember: (member: Member) => void;
  selectBrand: (brand: Brand) => void;
  selectedOrgUnitId: number;
};

const SelectedOrgUnitContext = createContext<SelectedOrgUnitState>({} as SelectedOrgUnitState);

const SelectedOrgUnitProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate();

  const { selectedBrand } = useUserBrands();
  const { departments, members } = useMyOrgUnits();

  /**
   * Ignore member context for now
   */
  const selectedOrgUnit: SelectedOrgUnit = useMemo(() => {
    const storedOrgUnitString: string = localStorage.getItem(selectedOrgUnitKey) || '';
    if (storedOrgUnitString) {
      const parsedOrgUnit: SelectedOrgUnit | null = JSON.parse(storedOrgUnitString);
      if (
        parsedOrgUnit?.type === 'DEPARTMENT' &&
        departments.some(({ orgUnitId }) => orgUnitId === parsedOrgUnit.id)
      ) {
        return parsedOrgUnit;
      } else if (parsedOrgUnit?.type === 'BRAND' && selectedBrand) {
        return parsedOrgUnit;
      }
    }

    const firstDepartment = departments[0];
    return { type: 'DEPARTMENT', id: firstDepartment.orgUnitId };
  }, [departments, selectedBrand]);

  useEffect(() => {
    Sentry.setTag('orgUnitId', selectedOrgUnit.id);
  }, [selectedOrgUnit]);

  useMountEffect(() => {
    const storedOrgUnitString = localStorage.getItem(selectedOrgUnitKey);
    if (!storedOrgUnitString) {
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(selectedOrgUnit));
    }
  });

  const selectedDepartment = useMemo(() => {
    if (selectedOrgUnit.type === 'DEPARTMENT') {
      return departments.find(({ orgUnitId }) => orgUnitId === selectedOrgUnit.id);
    }
    return undefined;
  }, [departments, selectedOrgUnit.id, selectedOrgUnit.type]);

  const selectedMember = useMemo(() => {
    if (selectedOrgUnit.type === 'MEMBER') {
      return members.find(({ orgUnitId }) => orgUnitId === selectedOrgUnit.id);
    }
    return undefined;
  }, [members, selectedOrgUnit.id, selectedOrgUnit.type]);

  const selectedBrandContext = useMemo(() => {
    if (selectedOrgUnit.type === 'BRAND') {
      return selectedBrand;
    }
    return undefined;
  }, [selectedBrand, selectedOrgUnit.type]);

  const selectDepartment = useCallback(
    (department: Department) => {
      const departmentOrgUnit: SelectedOrgUnit = {
        type: 'DEPARTMENT',
        id: department.orgUnitId,
      };
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(departmentOrgUnit));
      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  const selectMember = useCallback(
    (member: Member) => {
      const memberOrgUnit: SelectedOrgUnit = {
        type: 'MEMBER',
        id: member.orgUnitId,
      };
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(memberOrgUnit));
      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  const selectBrand = useCallback(
    (brand: Brand) => {
      const brandOrgUnit: SelectedOrgUnit = {
        type: 'BRAND',
        id: brand.orgUnitId,
      };
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(brandOrgUnit));
      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  const value: SelectedOrgUnitState = useMemo(
    () => ({
      selectedDepartment,
      selectedMember,
      selectedBrandContext,
      selectBrand,
      selectMember,
      selectDepartment,
      selectedOrgUnitId: selectedOrgUnit.id,
    }),
    [
      selectBrand,
      selectDepartment,
      selectMember,
      selectedBrandContext,
      selectedDepartment,
      selectedMember,
      selectedOrgUnit.id,
    ]
  );

  return (
    <SelectedOrgUnitContext.Provider value={value}>
      {props.children}
    </SelectedOrgUnitContext.Provider>
  );
};

const useSelectedOrgUnit = () => {
  return useContext(SelectedOrgUnitContext);
};

export { SelectedOrgUnitProvider, useSelectedOrgUnit };
