import { AssortmentItemDTO } from '../dto';
import { AssortmentItemStatus } from './AssortmentItemStatus';
import { FuturePriceCalculation, PackagePrice, PriceCalculation } from '@retail/products/types';
import { DiscountGroup } from '@retail/calculus/types';

export type AssortmentItemProps = {
  id: number;
  assortmentId: number;
  mgItemNumber: number;
  assortmentItemId: number;
  mgSupplierItemId: number;
  priority: string;
  priorityValidFrom: Date;
  primaryText: string;
  secondaryText: string;
  mgSupplierId: number;
  mgSupplierName: string;
  finfoNumber: number;
  groupNumber: string;
  groupDescription: string;
  discountGroup: DiscountGroup;
  basePriceUnit: string;
  primaryPackageUnit: string;
  currentSalesPrices: PriceCalculation;
  discontinuationCode: number;
  discontinuationDate?: Date;
  futureSalesPrices: FuturePriceCalculation[];
  futurePriorities: AssortmentItem[];
  gtinPurchasePrices?: boolean;
  status: AssortmentItemStatus;
  source?: string;
};

export class AssortmentItem {
  id: number;
  assortmentId: number;
  mgItemNumber: number;
  assortmentItemId: number;
  mgSupplierItemId: number;
  priority: string;
  priorityValidFrom: Date;
  primaryText: string;
  secondaryText: string;
  mgSupplierId: number;
  mgSupplierName: string;
  finfoNumber: number;
  groupNumber: string;
  groupDescription: string;
  discountGroup: DiscountGroup;
  basePriceUnit: string;
  primaryPackageUnit: string;
  currentSalesPrices: PriceCalculation;
  discontinuationCode: number;
  discontinuationDate?: Date;
  futureSalesPrices: FuturePriceCalculation[];
  futurePriorities: AssortmentItem[];
  gtinPurchasePrices?: boolean;
  status: AssortmentItemStatus;
  source?: string;

  constructor({
    id,
    assortmentId,
    mgItemNumber,
    assortmentItemId,
    mgSupplierItemId,
    priority,
    priorityValidFrom,
    primaryText,
    secondaryText,
    mgSupplierId,
    mgSupplierName,
    finfoNumber,
    groupNumber,
    groupDescription,
    discountGroup,
    basePriceUnit,
    primaryPackageUnit,
    currentSalesPrices,
    discontinuationCode,
    discontinuationDate,
    futureSalesPrices,
    futurePriorities,
    gtinPurchasePrices,
    status,
    source,
  }: AssortmentItemProps) {
    this.id = id;
    this.assortmentId = assortmentId;
    this.mgItemNumber = mgItemNumber;
    this.assortmentItemId = assortmentItemId;
    this.mgSupplierItemId = mgSupplierItemId;
    this.priority = priority;
    this.priorityValidFrom = priorityValidFrom;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.finfoNumber = finfoNumber;
    this.groupNumber = groupNumber;
    this.groupDescription = groupDescription;
    this.discountGroup = discountGroup;
    this.basePriceUnit = basePriceUnit;
    this.primaryPackageUnit = primaryPackageUnit;
    this.currentSalesPrices = currentSalesPrices;
    this.discontinuationCode = discontinuationCode;
    this.discontinuationDate = discontinuationDate;
    this.futureSalesPrices = futureSalesPrices;
    this.futurePriorities = futurePriorities;
    this.gtinPurchasePrices = gtinPurchasePrices;
    this.status = status;
    this.source = source;
  }

  getCurrentCostPriceForUnit = (unit: string): PackagePrice | undefined => {
    return this.currentSalesPrices?.getCurrentCostPriceForUnit(unit);
  };

  getCurrentPurchasePriceForUnit = (unit: string): PackagePrice | undefined => {
    return this.currentSalesPrices?.getCurrentPurchasePriceForUnit(unit);
  };

  static fromDTO = (dto: AssortmentItemDTO): AssortmentItem =>
    new AssortmentItem({
      id: dto.id,
      assortmentId: dto.assortmentId,
      mgItemNumber: dto.mgItemNumber,
      assortmentItemId: dto.assortmentItemId,
      mgSupplierItemId: dto.mgSupplierItemId,
      priority: dto.priority,
      priorityValidFrom: new Date(dto.priorityValidFrom),
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      mgSupplierId: dto.mgSupplierId,
      mgSupplierName: dto.mgSupplierName,
      finfoNumber: dto.finfoNumber,
      groupNumber: dto.groupNumber,
      groupDescription: dto.groupDescription,
      discountGroup: dto.discountGroup,
      basePriceUnit: dto.basePriceUnit,
      primaryPackageUnit: dto.primaryPackageUnit,
      currentSalesPrices: PriceCalculation.fromDto(dto.currentSalesPrices),
      discontinuationCode: dto.discontinuationCode,
      discontinuationDate: dto.discontinuationDate ? new Date(dto.discontinuationDate) : undefined,
      futureSalesPrices: dto.futureSalesPrices?.map(FuturePriceCalculation.fromDto) || [],
      futurePriorities: dto.futurePriorities?.map(AssortmentItem.fromDTO) || [],
      status: dto.status,
      source: dto.source,
    });
}
