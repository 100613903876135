import { Percent } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { TextInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { useFreightTFunction } from '@retail/freight/i18n';
import { CreateFreightConditionSchema, EditFreightConditionSchema } from './freightConditionSchema';
import { calculateFromPercentage, calculateToPercentage } from '@retail/utils';
import { ConditionDatesForm } from '@retail/calculus/components';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';

export function FreightConditionFormBase() {
  const t = useFreightTFunction();
  const { i18n } = useTranslation();

  const { control } = useFormContext<CreateFreightConditionSchema, EditFreightConditionSchema>();
  const freightFactorController = useController({ name: 'freightFactor', control });
  const percentAddonController = useController({ name: 'percentAddon', control });

  const freightFactorValue = freightFactorController.field.value
    ? formatPrice(freightFactorController.field.value, i18n.language)
    : percentAddonController.field.value
    ? formatPrice(calculateFromPercentage(percentAddonController.field.value), i18n.language)
    : '';
  const freightPercentAddonValue = percentAddonController.field.value
    ? formatPrice(percentAddonController.field.value, i18n.language)
    : '';

  return (
    <Stack gap={3}>
      <Stack flexDirection="row" gap={1}>
        <TextInputField
          {...percentAddonController.field}
          value={freightPercentAddonValue}
          error={percentAddonController.fieldState.error?.message}
          margin="none"
          required
          label={t('freight.form.percent')}
          size="small"
          fullWidth
          placeholder={t('freight.actions.writePercentAddonSurcharge')}
          endAdornment={<Percent />}
          onChange={(e) => {
            const value = e.target.value;
            const valueAsNumber = Number(value.replace(',', '.'));
            if (valueAsNumber) {
              freightFactorController.field.onChange(calculateFromPercentage(valueAsNumber));
            } else if (!value) {
              freightFactorController.field.onChange('');
            }

            percentAddonController.field.onChange(value);
          }}
        />

        <TextInputField
          {...freightFactorController.field}
          value={freightFactorValue}
          error={freightFactorController.fieldState.error?.message}
          margin="none"
          required
          label={t('freight.columns.freightSurcharge')}
          size="small"
          fullWidth
          placeholder={t('freight.actions.writeFreightSurcharge')}
          onChange={(e) => {
            const value = e.target.value;
            const valueAsNumber = Number(value.replace(',', '.'));
            if (valueAsNumber) {
              percentAddonController.field.onChange(calculateToPercentage(valueAsNumber));
            } else if (!value) {
              percentAddonController.field.onChange('');
            }
            freightFactorController.field.onChange(value);
          }}
        />
      </Stack>
      <ConditionDatesForm labelAlignment="row" excludeToDate />
    </Stack>
  );
}
