import { Grid, Link, Typography, useTheme } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import Tooltip from '@mui/material/Tooltip';
import { CopyToClipboard } from '@retail/components';
import { QuestionIcon } from '@shared/custom-icons';
import { Chip } from '@shared/components';
import { MgItemDetailsProductGroup } from './MgItemDetailsProductGroup';
import { MgItem } from '@retail/products/types';
import { MgItemDetailsPackaging } from './MgItemDetailsPackaging';

export interface Props {
  mgItem: MgItem;
  isSuperUser: boolean;
}

export function MgItemView({ mgItem, isSuperUser }: Props) {
  const { id, replacedByMgItemNumber } = mgItem;

  const t = useProductsTFunction();
  const { palette } = useTheme();

  const primarySupplierItem = mgItem.getPrimarySupplierItem();
  const itemGroupLabel = primarySupplierItem?.bk04Varegruppe?.getLabel();

  return (
    <Grid container spacing={2}>
      <Grid item fontWeight={600} xs={6} lg={4}>
        {t('products.mgItem.number.label')}
      </Grid>

      <Grid item fontWeight="medium" xs={6} lg={8} display="flex" alignItems="center">
        <CopyToClipboard value={id}>
          <Chip backgroundColor={palette.info[300]} textColor="#3C6B80" label={id} />
        </CopyToClipboard>

        <Tooltip arrow title={t('products.mgItem.number.description')}>
          <QuestionIcon sx={{ color: palette.text.secondary }} fontSize="small" />
        </Tooltip>
      </Grid>

      <MgItemDetailsProductGroup label={itemGroupLabel} />

      <Grid item fontWeight={600} xs={6} lg={4}>
        {t('products.mgItem.supplierItem.mainSupplier')}
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
        {primarySupplierItem?.mgSupplierName}
      </Grid>

      <Grid item fontWeight={600} xs={6} lg={4}>
        {t('products.mgItem.supplierItem.finfoNumber.label')}
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8}>
        {primarySupplierItem?.finfoNumber ? (
          <CopyToClipboard value={primarySupplierItem?.finfoNumber}>
            <Typography color={palette.text.secondary}>
              {primarySupplierItem?.finfoNumber}
            </Typography>
          </CopyToClipboard>
        ) : (
          ''
        )}
      </Grid>

      <Grid item fontWeight={600} xs={6} lg={4}>
        {t('products.mgItem.supplierItem.secondaryText')}
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
        {primarySupplierItem?.secondaryText}
      </Grid>

      {replacedByMgItemNumber && (
        <Grid item fontWeight={600} xs={6} lg={4} sx={{ display: 'flex', alignItems: 'center' }}>
          {t('products.mgItem.details.replacedByItem')}
        </Grid>
      )}
      {replacedByMgItemNumber && (
        <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
          <CopyToClipboard value={replacedByMgItemNumber}>
            <Link href={`/items/${replacedByMgItemNumber}`}>
              <Typography variant="body2">{replacedByMgItemNumber}</Typography>
            </Link>
          </CopyToClipboard>
        </Grid>
      )}

      <Grid item sx={{ overflow: 'auto', width: '100%', mt: 3 }}>
        <MgItemDetailsPackaging uniquePackages={mgItem.getPackages()} isSuperUser={isSuperUser} />
      </Grid>
    </Grid>
  );
}
