import { Clear } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FilterChip } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { SearchIcon } from '@shared/custom-icons';
import { KeyboardEventHandler, useCallback, useState } from 'react';

interface Props {
  articleIdSearch: string[];
  isDisabled: boolean;
  setPage?: (newPage: number) => void;
  onSubmitSearch: (search: string) => void;
  onRemoveSearch: (articleId: string) => void;
}

export function ArticleIdSearchField({
  articleIdSearch,
  isDisabled,
  setPage,
  onSubmitSearch,
  onRemoveSearch,
}: Props) {
  const [searchFieldInput, setSearchFieldInput] = useState('');
  const t = useProductsTFunction();
  const submitSearch = useCallback(() => {
    onSubmitSearch(searchFieldInput);
    setSearchFieldInput('');
    setPage && setPage(0);
  }, [onSubmitSearch, searchFieldInput, setPage]);

  const onKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        submitSearch();
      }
    },
    [submitSearch]
  );

  return (
    <Stack gap={0.5}>
      <Typography component="label" htmlFor="freeSearch" variant="body2" color="text.secondary">
        {t('products.search.articleId')}
      </Typography>

      <TextField
        id="freeSearch"
        size="small"
        variant="outlined"
        disabled={isDisabled}
        placeholder={t('products.search.seachArticleIds')}
        value={searchFieldInput}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment: (
            <Box gap={0.5} alignSelf="center">
              {articleIdSearch.length === 0 && (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" color="disabled" />
                </InputAdornment>
              )}
              <Stack>
                {articleIdSearch.map((id) => {
                  return (
                    <FilterChip key={id} id={id} name={id} onDelete={() => onRemoveSearch(id)} />
                  );
                })}
              </Stack>
            </Box>
          ),
          endAdornment: searchFieldInput ? (
            <Stack flexDirection="row">
              <Button onClick={submitSearch}>{t('products.search.openSearch.search')}</Button>
              <IconButton
                size="small"
                onClick={() => {
                  setSearchFieldInput('');
                }}
              >
                <Clear />
              </IconButton>
            </Stack>
          ) : undefined,
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchFieldInput(event.target.value);
        }}
      />
    </Stack>
  );
}
