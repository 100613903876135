import { TableCell, styled } from '@mui/material';

interface DocumentationTableCellProps {
  hasBorderLeft?: boolean;
  index?: number;
}

export const DocumentationTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'hasBorderLeft' && prop !== 'index',
})<DocumentationTableCellProps>(({ theme, hasBorderLeft = true, index }) => ({
  align: 'center',
  borderLeft: hasBorderLeft ? `1px solid ${theme.palette.grey[100]}` : undefined,
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  fontWeight: 600,
  backgroundColor:
    (index || index === 0) && index % 2 === 0 ? theme.palette.grey[50] : theme.palette.common.white,
}));

export const DocumentationKeyTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'hasBorderLeft' && prop !== 'index',
})<{ index: number }>(({ theme, index }) => ({
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  paddingLeft: theme.spacing(6),
  backgroundColor: index % 2 === 0 ? theme.palette.grey[50] : theme.palette.common.white,
}));
