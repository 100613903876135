import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { MgpHeader } from './MgpHeader';
import { MgpAppSidebar } from './MgpAppSidebar';
import { mgprisHeaderHeight } from '@shared/styles';
import { GlobalDrawer } from '../GlobalDrawer';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { PdmAppSidebar } from './PdmAppSidebar';
import { PdmHeader } from './PdmHeader';

interface Props {
  showDrawer?: boolean;
}

export function AppLayout({ children, showDrawer = true }: PropsWithChildren<Props>) {
  const { selectedBrandContext } = useSelectedOrgUnit();

  return (
    <Box display="flex" flexGrow="1">
      {selectedBrandContext ? <PdmHeader /> : <MgpHeader />}
      {selectedBrandContext ? <PdmAppSidebar /> : <MgpAppSidebar />}

      <Box display="flex" flexDirection="column" flexGrow="1" marginTop={mgprisHeaderHeight}>
        <SuspenseWithSentryErrorBoundary>
          {showDrawer && !selectedBrandContext ? <GlobalDrawer>{children}</GlobalDrawer> : children}
        </SuspenseWithSentryErrorBoundary>
      </Box>
    </Box>
  );
}
