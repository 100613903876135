export enum Source {
  MPE = 'MPE',
  SOLVE = 'SOLVE',
}

export enum AssortmentTypeDTO {
  BASE = 'BASE',
  DRAFT = 'DRAFT',
  MONITORING = 'MONITORING',
}

export type OrgUnitTypeDTO = 'BRAND' | 'DATAOWNER' | 'LOCATION';

export interface AssortmentOrgUnitDTO {
  type: OrgUnitTypeDTO;
  id: number;
  name: string;
}

export type AssortmentDTO = {
  id: number;
  title: string;
  source: Source;
  updatedAt: string;
  createdAt: string;
  type: AssortmentTypeDTO;
  readOnly: boolean;
  parent?: number;
  owner?: AssortmentOrgUnitDTO;
  memberId?: string;
  departmentId?: string;
  fromDate?: string;
  size: number;
};
