import { Stack, styled, Typography } from '@mui/material';
import { isValidElement, ReactNode } from 'react';

interface Props {
  prevValue?: string | number | React.ReactNode;
  newValue?: string | number | React.ReactNode;
  lineThrough?: boolean;
  centered?: boolean;
}

export function StackedTableCellWithChangeInValue({
  prevValue,
  newValue,
  lineThrough = false,
  centered = true,
}: Props) {
  // Helper function to determine if a value is ReactNode
  const isReactNode = (value: unknown): value is ReactNode =>
    isValidElement(value) || typeof value === 'object' || typeof value === 'function';

  if (isReactNode(newValue) || isReactNode(prevValue)) {
    return (
      <Stack textAlign={centered ? 'center' : 'left'} padding={centered ? undefined : 'none'}>
        {newValue || prevValue || '-'}
      </Stack>
    );
  }

  const newValueTrimmed = newValue ? String(newValue).trim() : '';
  const prevValueTrimmed = prevValue ? String(prevValue).trim() : '';

  if (!newValueTrimmed && !prevValueTrimmed) {
    return (
      <Stack textAlign={centered ? 'center' : 'left'} padding={centered ? undefined : 'none'}>
        -
      </Stack>
    );
  }

  if (!newValueTrimmed && prevValueTrimmed) {
    return (
      <StyledPrevValueTypography variant="subtitle2" lineThrough>
        {prevValueTrimmed}
      </StyledPrevValueTypography>
    );
  }

  if (prevValueTrimmed !== newValueTrimmed) {
    return (
      <Stack textAlign={centered ? 'center' : 'left'} padding={centered ? undefined : 'none'}>
        <StyledNewValueTypography variant="body2">{newValueTrimmed}</StyledNewValueTypography>
        <StyledPrevValueTypography variant="subtitle2" lineThrough={lineThrough}>
          {prevValueTrimmed}
        </StyledPrevValueTypography>
      </Stack>
    );
  }

  if (prevValueTrimmed === newValueTrimmed && lineThrough) {
    return (
      <Stack textAlign={centered ? 'center' : 'left'} padding={centered ? undefined : 'none'}>
        <StyledPrevValueTypography variant="body2" lineThrough={lineThrough}>
          {newValueTrimmed}
        </StyledPrevValueTypography>
      </Stack>
    );
  }

  return (
    <Stack textAlign={centered ? 'center' : 'left'} padding={centered ? undefined : 'none'}>
      <StyledNewValueTypography variant="body2">{newValueTrimmed}</StyledNewValueTypography>
    </Stack>
  );
}

export const StyledNewValueTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.grey[900],
}));

export const StyledPrevValueTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'lineThrough',
})<{ lineThrough?: boolean }>(({ lineThrough }) => ({
  textDecoration: lineThrough ? 'line-through' : undefined,
}));
