import { AssortmentDTO, AssortmentOrgUnitDTO } from '@retail/my-assortment/types';

export type OrgUnitType = 'BRAND' | 'DATAOWNER' | 'LOCATION';

export class AssortmentOrgUnit {
  type: OrgUnitType;
  id: number;
  name: string;

  constructor({ type, id, name }: AssortmentOrgUnitDTO) {
    this.type = type;
    this.id = id;
    this.name = name;
  }
}

export class MonitoringAssortment {
  readonly title: string;
  readonly id: number;
  readonly readOnly: boolean;
  readonly owner?: AssortmentOrgUnit;
  readonly size: number;

  constructor({ title, id, readOnly, owner, size }: AssortmentDTO) {
    this.title = title;
    this.id = id;
    this.readOnly = readOnly;
    this.owner = owner;
    this.size = size;
  }
}
