import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { FilterChip } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  suppliers: Supplier[];
  selectedSuppliers: Supplier[];
  isFetching: boolean;
  setPage: (page: number) => void;
  selectSuppliers: (option: Supplier[], supplierIdToDelete?: number) => void;
}

export function SelectSuppliersAutocomplete({
  suppliers,
  selectedSuppliers,
  isFetching,
  setPage,
  selectSuppliers,
}: Props) {
  const t = useProductsTFunction();
  return (
    <Stack gap={0.5}>
      <Typography component="label" htmlFor="supplierSelect" variant="body2" color="text.secondary">
        {t('products.supplier.name')}
      </Typography>
      <Autocomplete
        id="supplierSelect"
        disabled={isFetching}
        size="small"
        multiple
        filterSelectedOptions
        disableClearable
        renderTags={(suppliers) =>
          suppliers.map((supplier) => (
            <FilterChip
              key={supplier.mgSupplierId}
              id={supplier.mgSupplierId}
              name={supplier.name}
              onDelete={() => {
                selectSuppliers(
                  selectedSuppliers.filter(
                    ({ mgSupplierId }) => mgSupplierId !== supplier.mgSupplierId
                  ),
                  supplier.mgSupplierId
                );
              }}
            />
          ))
        }
        isOptionEqualToValue={(option, value) => option.mgSupplierId === value.mgSupplierId}
        value={selectedSuppliers}
        onChange={(_, newValue) => {
          selectSuppliers(newValue);
          setPage(0);
        }}
        options={suppliers}
        getOptionLabel={({ name, mgSupplierId }) => `${name} ${mgSupplierId}`}
        renderOption={(props, option) => (
          <Stack
            component="li"
            {...props}
            key={option.mgSupplierId}
            borderBottom={({ palette }) => `1px solid ${palette.divider}`}
          >
            <Typography>{option.name}</Typography> {option.mgSupplierId}
          </Stack>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder={t('products.supplier.searchForSuppliers')} />
        )}
      />
    </Stack>
  );
}
