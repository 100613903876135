import { SvgIcon, SvgIconProps } from '@mui/material';

export const MGPrisIconWhite = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        width="43"
        height="42"
        viewBox="0 0 43 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.9614 5.06269V0H21.4691L12.5244 8.93983L3.57979 0H0V25.0489H5.06365V8.64151L12.5244 16.0985L19.9843 8.64151V23.5554L19.9881 25.0489H42.9614V9.84057H37.8977V19.9853H25.0518V5.06269H42.9614Z"
          fill="url(#paint0_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3398 28.6094H43.0013V30.2709H41.3398V28.6094Z"
          fill="url(#paint1_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8281 30.4844H35.4896V32.1468H33.8281V30.4844Z"
          fill="url(#paint2_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8281 32.3633H35.4896V34.0248H33.8281V32.3633Z"
          fill="url(#paint3_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.707 34.2422H37.3695V35.9046H35.707V34.2422Z"
          fill="url(#paint4_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4609 34.2422H41.1234V35.9046H39.4609V34.2422Z"
          fill="url(#paint5_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3398 36.1172H43.0013V37.7787H41.3398V36.1172Z"
          fill="url(#paint6_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3398 37.9961H43.0013V39.6586H41.3398V37.9961Z"
          fill="url(#paint7_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8281 39.8711H35.4896V41.5326H33.8281V39.8711Z"
          fill="url(#paint8_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.707 28.6094H37.3695V30.2709H35.707V28.6094Z"
          fill="url(#paint9_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5859 28.6094H39.2474V30.2709H37.5859V28.6094Z"
          fill="url(#paint10_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4609 28.6094H41.1234V30.2709H39.4609V28.6094Z"
          fill="url(#paint11_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5859 34.2422H39.2474V35.9046H37.5859V34.2422Z"
          fill="url(#paint12_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.707 39.8711H37.3695V41.5326H35.707V39.8711Z"
          fill="url(#paint13_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5859 39.8711H39.2474V41.5326H37.5859V39.8711Z"
          fill="url(#paint14_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4609 39.8711H41.1234V41.5326H39.4609V39.8711Z"
          fill="url(#paint15_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1797 28.6094H14.8412V30.2709H13.1797V28.6094Z"
          fill="url(#paint16_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0586 28.6094H16.7211V30.2709H15.0586V28.6094Z"
          fill="url(#paint17_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9336 28.6094H18.5951V30.2709H16.9336V28.6094Z"
          fill="url(#paint18_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0586 36.1172H16.7211V37.7787H15.0586V36.1172Z"
          fill="url(#paint19_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9336 37.9961H18.5951V39.6586H16.9336V37.9961Z"
          fill="url(#paint20_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 28.6094H12.9632V30.2709H11.3008V28.6094Z"
          fill="url(#paint21_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 30.4844H12.9632V32.1468H11.3008V30.4844Z"
          fill="url(#paint22_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8125 30.4844H20.474V32.1468H18.8125V30.4844Z"
          fill="url(#paint23_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 32.3633H12.9632V34.0248H11.3008V32.3633Z"
          fill="url(#paint24_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8125 32.3633H20.474V34.0248H18.8125V32.3633Z"
          fill="url(#paint25_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 34.2422H12.9632V35.9046H11.3008V34.2422Z"
          fill="url(#paint26_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1797 34.2422H14.8412V35.9046H13.1797V34.2422Z"
          fill="url(#paint27_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0586 34.2422H16.7211V35.9046H15.0586V34.2422Z"
          fill="url(#paint28_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9336 34.2422H18.5951V35.9046H16.9336V34.2422Z"
          fill="url(#paint29_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 36.1172H12.9632V37.7787H11.3008V36.1172Z"
          fill="url(#paint30_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 37.9961H12.9632V39.6586H11.3008V37.9961Z"
          fill="url(#paint31_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3008 39.8711H12.9632V41.5326H11.3008V39.8711Z"
          fill="url(#paint32_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8125 39.8711H20.474V41.5326H18.8125V39.8711Z"
          fill="url(#paint33_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.54688 30.4844H9.20934V32.1468H7.54688V30.4844Z"
          fill="url(#paint34_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.54688 32.3633H9.20934V34.0248H7.54688V32.3633Z"
          fill="url(#paint35_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 28.6094H1.70152V30.2709H0.0390625V28.6094Z"
          fill="url(#paint36_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.91797 28.6094H3.57946V30.2709H1.91797V28.6094Z"
          fill="url(#paint37_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.79297 28.6094H5.45543V30.2709H3.79297V28.6094Z"
          fill="url(#paint38_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.67188 28.6094H7.33337V30.2709H5.67188V28.6094Z"
          fill="url(#paint39_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 30.4844H1.70152V32.1468H0.0390625V30.4844Z"
          fill="url(#paint40_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 32.3633H1.70152V34.0248H0.0390625V32.3633Z"
          fill="url(#paint41_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 34.2422H1.70152V35.9046H0.0390625V34.2422Z"
          fill="url(#paint42_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.91797 34.2422H3.57946V35.9046H1.91797V34.2422Z"
          fill="url(#paint43_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.79297 34.2422H5.45543V35.9046H3.79297V34.2422Z"
          fill="url(#paint44_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.67188 34.2422H7.33337V35.9046H5.67188V34.2422Z"
          fill="url(#paint45_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 36.1172H1.70152V37.7787H0.0390625V36.1172Z"
          fill="url(#paint46_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 37.9961H1.70152V39.6586H0.0390625V37.9961Z"
          fill="url(#paint47_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0390625 39.8711H1.70152V41.5326H0.0390625V39.8711Z"
          fill="url(#paint48_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4453 28.6094H26.1068V30.2709H24.4453V28.6094Z"
          fill="url(#paint49_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 28.6094H27.9818V30.2709H26.3203V28.6094Z"
          fill="url(#paint50_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1992 28.6094H29.8607V30.2709H28.1992V28.6094Z"
          fill="url(#paint51_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 30.4844H27.9818V32.1468H26.3203V30.4844Z"
          fill="url(#paint52_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 32.3633H27.9818V34.0248H26.3203V32.3633Z"
          fill="url(#paint53_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 34.2422H27.9818V35.9046H26.3203V34.2422Z"
          fill="url(#paint54_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 36.1172H27.9818V37.7787H26.3203V36.1172Z"
          fill="url(#paint55_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 37.9961H27.9818V39.6586H26.3203V37.9961Z"
          fill="url(#paint56_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4453 39.8711H26.1068V41.5326H24.4453V39.8711Z"
          fill="url(#paint57_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3203 39.8711H27.9818V41.5326H26.3203V39.8711Z"
          fill="url(#paint58_linear_12085_11100)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1992 39.8711H29.8607V41.5326H28.1992V39.8711Z"
          fill="url(#paint59_linear_12085_11100)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_12085_11100"
            x1="45.1095"
            y1="17.2211"
            x2="6.81813"
            y2="-8.81779"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_12085_11100"
            x1="43.0844"
            y1="29.7517"
            x2="41.2129"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_12085_11100"
            x1="35.5727"
            y1="31.6273"
            x2="33.7009"
            y2="30.8856"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_12085_11100"
            x1="35.5727"
            y1="33.5056"
            x2="33.7012"
            y2="32.7635"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_12085_11100"
            x1="37.4526"
            y1="35.3851"
            x2="35.58"
            y2="34.6427"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_12085_11100"
            x1="41.2065"
            y1="35.3851"
            x2="39.334"
            y2="34.6427"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_12085_11100"
            x1="43.0844"
            y1="37.2595"
            x2="41.2129"
            y2="36.5174"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_12085_11100"
            x1="43.0844"
            y1="39.139"
            x2="41.2126"
            y2="38.3973"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_12085_11100"
            x1="35.5727"
            y1="41.0134"
            x2="33.7012"
            y2="40.2713"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_12085_11100"
            x1="37.4526"
            y1="29.7517"
            x2="35.5803"
            y2="29.0089"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_12085_11100"
            x1="39.3305"
            y1="29.7517"
            x2="37.459"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_12085_11100"
            x1="41.2065"
            y1="29.7517"
            x2="39.3342"
            y2="29.0089"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_12085_11100"
            x1="39.3305"
            y1="35.3851"
            x2="37.4587"
            y2="34.6434"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_12085_11100"
            x1="37.4526"
            y1="41.0134"
            x2="35.5803"
            y2="40.2706"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_12085_11100"
            x1="39.3305"
            y1="41.0134"
            x2="37.459"
            y2="40.2713"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_12085_11100"
            x1="41.2065"
            y1="41.0134"
            x2="39.3342"
            y2="40.2706"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_12085_11100"
            x1="14.9243"
            y1="29.7517"
            x2="13.0528"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_12085_11100"
            x1="16.8042"
            y1="29.7517"
            x2="14.9319"
            y2="29.0089"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_12085_11100"
            x1="18.6782"
            y1="29.7517"
            x2="16.8067"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_12085_11100"
            x1="16.8042"
            y1="37.2595"
            x2="14.9319"
            y2="36.5167"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_12085_11100"
            x1="18.6782"
            y1="39.139"
            x2="16.8064"
            y2="38.3973"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_12085_11100"
            x1="13.0464"
            y1="29.7517"
            x2="11.1741"
            y2="29.0089"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_12085_11100"
            x1="13.0464"
            y1="31.6273"
            x2="11.1738"
            y2="30.8849"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_12085_11100"
            x1="20.5571"
            y1="31.6273"
            x2="18.6853"
            y2="30.8856"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_12085_11100"
            x1="13.0464"
            y1="33.5056"
            x2="11.1741"
            y2="32.7628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_12085_11100"
            x1="20.5571"
            y1="33.5056"
            x2="18.6856"
            y2="32.7635"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_12085_11100"
            x1="13.0464"
            y1="35.3851"
            x2="11.1738"
            y2="34.6427"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_12085_11100"
            x1="14.9243"
            y1="35.3851"
            x2="13.0525"
            y2="34.6434"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_12085_11100"
            x1="16.8042"
            y1="35.3851"
            x2="14.9316"
            y2="34.6427"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_12085_11100"
            x1="18.6782"
            y1="35.3851"
            x2="16.8064"
            y2="34.6434"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_12085_11100"
            x1="13.0464"
            y1="37.2595"
            x2="11.1741"
            y2="36.5167"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_12085_11100"
            x1="13.0464"
            y1="39.139"
            x2="11.1738"
            y2="38.3966"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_12085_11100"
            x1="13.0464"
            y1="41.0134"
            x2="11.1741"
            y2="40.2706"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_12085_11100"
            x1="20.5571"
            y1="41.0134"
            x2="18.6856"
            y2="40.2713"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_12085_11100"
            x1="9.29246"
            y1="31.6273"
            x2="7.41989"
            y2="30.8849"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_12085_11100"
            x1="9.29246"
            y1="33.5056"
            x2="7.42018"
            y2="32.7628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_12085_11100"
            x1="1.78465"
            y1="29.7517"
            x2="-0.0876275"
            y2="29.0089"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_12085_11100"
            x1="3.66254"
            y1="29.7517"
            x2="1.79106"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_12085_11100"
            x1="5.53855"
            y1="29.7517"
            x2="3.66628"
            y2="29.0089"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_12085_11100"
            x1="7.41644"
            y1="29.7517"
            x2="5.54496"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_12085_11100"
            x1="1.78465"
            y1="31.6273"
            x2="-0.0879235"
            y2="30.8849"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint41_linear_12085_11100"
            x1="1.78465"
            y1="33.5056"
            x2="-0.0876284"
            y2="32.7628"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint42_linear_12085_11100"
            x1="1.78465"
            y1="35.3851"
            x2="-0.0879235"
            y2="34.6427"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint43_linear_12085_11100"
            x1="3.66254"
            y1="35.3851"
            x2="1.79076"
            y2="34.6434"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint44_linear_12085_11100"
            x1="5.53855"
            y1="35.3851"
            x2="3.66598"
            y2="34.6427"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint45_linear_12085_11100"
            x1="7.41644"
            y1="35.3851"
            x2="5.54467"
            y2="34.6434"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint46_linear_12085_11100"
            x1="1.78465"
            y1="37.2595"
            x2="-0.0876275"
            y2="36.5167"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint47_linear_12085_11100"
            x1="1.78465"
            y1="39.139"
            x2="-0.0879235"
            y2="38.3966"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint48_linear_12085_11100"
            x1="1.78465"
            y1="41.0134"
            x2="-0.0876284"
            y2="40.2706"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint49_linear_12085_11100"
            x1="26.1899"
            y1="29.7517"
            x2="24.3184"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint50_linear_12085_11100"
            x1="28.0649"
            y1="29.7517"
            x2="26.1934"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint51_linear_12085_11100"
            x1="29.9438"
            y1="29.7517"
            x2="28.0723"
            y2="29.0096"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint52_linear_12085_11100"
            x1="28.0649"
            y1="31.6273"
            x2="26.1931"
            y2="30.8856"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint53_linear_12085_11100"
            x1="28.0649"
            y1="33.5056"
            x2="26.1934"
            y2="32.7635"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint54_linear_12085_11100"
            x1="28.0649"
            y1="35.3851"
            x2="26.1931"
            y2="34.6434"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint55_linear_12085_11100"
            x1="28.0649"
            y1="37.2595"
            x2="26.1934"
            y2="36.5174"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint56_linear_12085_11100"
            x1="28.0649"
            y1="39.139"
            x2="26.1931"
            y2="38.3973"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint57_linear_12085_11100"
            x1="26.1899"
            y1="41.0134"
            x2="24.3184"
            y2="40.2713"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint58_linear_12085_11100"
            x1="28.0649"
            y1="41.0134"
            x2="26.1934"
            y2="40.2713"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <linearGradient
            id="paint59_linear_12085_11100"
            x1="29.9438"
            y1="41.0134"
            x2="28.0723"
            y2="40.2713"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#FFFFFF" />
            <stop offset="0.286458" stopColor="#FFFFFF" />
            <stop offset="0.484375" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
