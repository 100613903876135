import { create } from 'zustand/react';
import { ProductCardState } from './ProductCardState';
import { MgPackageType } from '@retail/products/types';
import {
  calculateCoverageFromSalesFactor,
  calculateNewSalesFactor,
  calculateSalesCoverageFromCostAndSalesPrice,
  calculateSalesFactorFromDG,
  roundToMaxDecimalCount,
  STANDARD_VAT_FACTOR,
} from '@retail/utils';

const MAX_SALES_FACTOR_DECIMALS = 6;

const initState: Pick<ProductCardState, 'selectedPackageType' | 'editingField' | 'newMarkup'> = {
  selectedPackageType: 'PRIMARY',
  editingField: undefined,
  newMarkup: undefined,
};

export const useProductCardStore = create<ProductCardState>((set) => ({
  ...initState,

  setEditingField: (field) => set({ editingField: field }),

  closeEditingField: () => set({ editingField: undefined }),

  changeSelectedPackage: (selectedPackageType: MgPackageType) =>
    set({ selectedPackageType, newMarkup: undefined, editingField: undefined }),

  getSalesPriceEditState: (selectedPackage, priceCalculation) => {
    if (!selectedPackage?.mgPackage?.calculatedCount) {
      return 'noCalculationForPackage';
    }
    const selectedUnit = selectedPackage.vilmaUnit.unit;
    const costPrice = priceCalculation.getCurrentCostPriceForUnit(selectedUnit);

    if (!costPrice) {
      return 'noCostPrice';
    }
    return 'editable';
  },

  setNewSalesFactor: (newSalesFactor, selectedPackage, priceCalculation) => {
    const fPakPackagePrice = priceCalculation.getBracketPackagePrice('F-PAK');
    const salesPricesForUnit = fPakPackagePrice?.bracketMarkupPrice.getPriceForUnit(
      selectedPackage.vilmaUnit.unit
    );
    const currentSalesPrice = salesPricesForUnit?.price.priceWithoutVat;
    const currentSalesPriceIncVat = salesPricesForUnit?.price.priceWithVat;
    const currentSalesFactor = fPakPackagePrice?.bracketMarkupPrice.markup?.salesFactor;

    const newSalesPrice =
      currentSalesPrice &&
      currentSalesFactor &&
      (currentSalesPrice / currentSalesFactor) * newSalesFactor;
    const newSalesPriceIncVat =
      currentSalesPriceIncVat &&
      currentSalesFactor &&
      (currentSalesPriceIncVat / currentSalesFactor) * newSalesFactor;

    const newSalesCoverage = calculateCoverageFromSalesFactor(newSalesFactor);

    return set({
      newMarkup: {
        salesFactor: roundToMaxDecimalCount(newSalesFactor, MAX_SALES_FACTOR_DECIMALS),
        salesCoverage: roundToMaxDecimalCount(newSalesCoverage, MAX_SALES_FACTOR_DECIMALS),
        salesPrice: newSalesPrice,
        salesPriceIncVat: newSalesPriceIncVat,
        editedField: 'factor',
      },
      editingField: undefined,
    });
  },

  setNewSalesCoverage: (newSalesCoverage, selectedPackage, priceCalculation) => {
    const fPakPackagePrice = priceCalculation.getBracketPackagePrice('F-PAK');
    const salesPricesForUnit = fPakPackagePrice?.bracketMarkupPrice.getPriceForUnit(
      selectedPackage.vilmaUnit.unit
    );
    const currentSalesPrice = salesPricesForUnit?.price.priceWithoutVat;
    const currentSalesPriceIncVat = salesPricesForUnit?.price.priceWithVat;
    const currentSalesFactor = fPakPackagePrice?.bracketMarkupPrice.markup?.salesFactor;

    const newSalesFactor = calculateSalesFactorFromDG(newSalesCoverage);

    const newSalesPrice =
      currentSalesPrice &&
      currentSalesFactor &&
      (currentSalesPrice / currentSalesFactor) * newSalesFactor;
    const newSalesPriceIncVat =
      currentSalesPriceIncVat &&
      currentSalesFactor &&
      (currentSalesPriceIncVat / currentSalesFactor) * newSalesFactor;

    return set({
      newMarkup: {
        salesCoverage: roundToMaxDecimalCount(newSalesCoverage, MAX_SALES_FACTOR_DECIMALS),
        salesFactor: roundToMaxDecimalCount(newSalesFactor, MAX_SALES_FACTOR_DECIMALS),
        salesPrice: newSalesPrice,
        salesPriceIncVat: newSalesPriceIncVat,
        editedField: 'coverage',
      },
      editingField: undefined,
    });
  },

  setNewSalesPrice: (newSalesPrice, selectedPackage, priceCalculation, includeVat) => {
    const fPakPackagePrice = priceCalculation.getBracketPackagePrice('F-PAK');
    const selectedUnit = selectedPackage.vilmaUnit.unit;
    const salesPriceForUnit = fPakPackagePrice?.bracketMarkupPrice.getPriceForUnit(selectedUnit);
    const currentCostPrice = priceCalculation.getCurrentCostPriceForUnit(selectedUnit);

    if (!currentCostPrice) {
      throw new Error('Cannot set new sales price without having a cost price');
    }

    const vatFactor = salesPriceForUnit?.price.vatFactor || STANDARD_VAT_FACTOR;

    const newSalesCoverage = calculateSalesCoverageFromCostAndSalesPrice(
      currentCostPrice.price.price,
      newSalesPrice,
      includeVat ? vatFactor : undefined
    );
    const newSalesFactor = calculateNewSalesFactor(
      currentCostPrice.price.price,
      newSalesPrice,
      includeVat ? vatFactor : undefined
    );

    return set({
      newMarkup: {
        salesPrice: includeVat ? newSalesPrice / vatFactor : newSalesPrice,
        salesPriceIncVat: includeVat ? newSalesPrice : newSalesPrice * vatFactor,
        editedField: 'price',
        salesCoverage: roundToMaxDecimalCount(newSalesCoverage, 6),
        salesFactor: roundToMaxDecimalCount(newSalesFactor, 6),
      },
      editingField: undefined,
    });
  },

  resetMarkup: () => set({ newMarkup: undefined, editingField: undefined }),

  resetState: () => set(initState),
}));
