import { useProductsTFunction } from '@retail/products/i18n';
import { Box, Stack, Typography } from '@mui/material';
import { ExternalLinkIcon } from '@shared/custom-icons';
import { EquivalentItemTooltip } from '../MgItem/EquivalentItemTooltip';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import { TrendingDown, TrendingUp } from '@mui/icons-material';

interface CompPrice {
  unit: string;
  price: number;
  priceDate: string;
}

interface Props {
  setItemToView: (mgItemNumber: number) => void;
  excludeVat?: boolean;
  currentPrice: CompPrice;
  prevPrice?: CompPrice;
  url?: string;
  eqMgItemNumberSrc?: number;
}

export function CompetitorPriceCell({
  currentPrice,
  prevPrice,
  setItemToView,
  excludeVat,
  url,
  eqMgItemNumberSrc,
}: Props) {
  const { i18n } = useTranslation();
  const t = useProductsTFunction();

  return (
    <Tooltip
      title={
        prevPrice && (
          <Stack alignItems="center">
            <Typography>{t('products.prices.competitors.historic.lastPrice')}</Typography>
            <CompetitorPriceCell
              setItemToView={setItemToView}
              currentPrice={prevPrice}
              url={url}
              eqMgItemNumberSrc={eqMgItemNumberSrc}
              excludeVat={excludeVat}
            />
          </Stack>
        )
      }
    >
      <Stack alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle2">
            {t('products.prices.pricePerUnit', {
              price: formatPrice(
                excludeVat ? currentPrice.price * 0.75 : currentPrice.price,
                i18n.language
              ),
              unit: currentPrice.unit,
            })}
          </Typography>
          {url && (
            <Box component="a" href={url} target="_blank" display="flex">
              <ExternalLinkIcon
                fontSize="inherit"
                titleAccess={t('products.productList.competitorPrice.openInNew')}
              />
            </Box>
          )}
          {eqMgItemNumberSrc && (
            <EquivalentItemTooltip
              onClick={() => setItemToView(eqMgItemNumberSrc)}
              mgItemNumber={eqMgItemNumberSrc}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2">{dayjs(currentPrice.priceDate).format('ll')}</Typography>
          {prevPrice &&
            (prevPrice.price < currentPrice.price ? (
              <TrendingUp color="success" fontSize="small" />
            ) : (
              <TrendingDown color="error" fontSize="small" />
            ))}
        </Stack>
      </Stack>
    </Tooltip>
  );
}
