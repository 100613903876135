import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { Chip } from '@shared/components';
import { Box } from '@mui/system';

export interface Props {
  label?: string;
}

export const MgItemDetailsProductGroup = ({ label }: Props) => {
  const { palette, breakpoints } = useTheme();
  const t = useProductsTFunction();

  const isLessThanLarge = useMediaQuery(breakpoints.down('lg'));
  const isLessThanMedium = useMediaQuery(breakpoints.down('md'));

  return (
    <>
      <Grid item fontWeight={600} xs={6} lg={4}>
        {t('products.search.productGroup.label')}
      </Grid>

      <Grid item fontWeight="medium" xs={6} lg={8} color={(theme) => theme.palette.text.secondary}>
        {label ? (
          <Box display="flex" alignItems="center">
            <Chip
              backgroundColor={palette.success[100]}
              textColor="#346B3A"
              label={
                isLessThanLarge ? (
                  <Typography
                    variant="body2"
                    sx={{
                      maxWidth: isLessThanMedium ? 120 : 200,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {label}
                  </Typography>
                ) : (
                  label
                )
              }
            />
          </Box>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
};
