import { Stack, Typography } from '@mui/material';
import { CopyToClipboard, InfoChip } from '@retail/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  mgItemNr: number;
  finfoNumber: number;
  direction?: 'row' | 'column';
}

export function ItemInfo({ mgItemNr, finfoNumber, direction = 'row' }: Props) {
  const t = usePurchaseAgreementsTFunction();
  return (
    <Stack flexDirection={direction} gap={direction === 'row' ? 2 : 0} alignItems="center">
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="subtitle2">
          {t('purchaseAgreements.agreement.columns.mgItemNr')}
        </Typography>

        <CopyToClipboard value={mgItemNr}>
          <InfoChip label={mgItemNr} />
        </CopyToClipboard>
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="subtitle2">
          {t('purchaseAgreements.agreement.columns.finfoNr')}
        </Typography>

        <CopyToClipboard value={finfoNumber}>
          <InfoChip label={finfoNumber} />
        </CopyToClipboard>
      </Stack>
    </Stack>
  );
}
