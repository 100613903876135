import { Coordinates, Store } from '@retail/retailer/types';
import { Divider, FormControl, FormGroup, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { SelectStoreCheckbox } from './SelectStoreCheckbox';
import { calculateDistanceFromCompetitorInKm } from '@retail/retailer/utils';
import { sortByLowerPropValue } from '@shared/utils';

interface Props {
  stores: Store[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedStores: number[];
  myCoordinates: Coordinates | undefined;
  logoUrl: string;
}

export function StoreCompetitorsSelector({
  stores,
  selectedStores,
  onChange,
  myCoordinates,
  logoUrl,
}: Props) {

  const storesWithDistance = useMemo(() => {
    return stores
      .map((store) => {
        return {
          id: store.id,
          name: store.name,
          distance:
            store.coordinates &&
            myCoordinates &&
            calculateDistanceFromCompetitorInKm(store.coordinates, myCoordinates),
        };
      })
      .sort(sortByLowerPropValue('distance'));
  }, [myCoordinates, stores]);
  return (
    <FormControl component="fieldset">
      <Stack component={FormGroup} divider={<Divider sx={{ my: 2 }} />}>
        {storesWithDistance.map((store) => {
          return (
            <SelectStoreCheckbox
              key={store.id}
              {...store}
              checked={selectedStores.includes(store.id)}
              onChange={onChange}
              logoUrl={logoUrl}
            />
          );
        })}
      </Stack>
    </FormControl>
  );
}
