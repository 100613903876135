import {
  useAddItemsToMonitoringAssortment,
  useDeleteItemsFromMonitoringAssortment,
  useDeleteMonitoringAssortment,
  useExportAssortmentToExcel,
  usePatchTitleOrItemsOfMonitoringAssortment,
  useValidateImportedItems,
} from '@retail/monitoring-assortment/data-access';
import { useQueryClient } from '@tanstack/react-query';
import { ItemCodes } from '@retail/monitoring-assortment/types';
import { downloadResponseFile } from '@shared/fetch-utils';

export interface ItemsMgAssortmentArgs {
  assortmentId: number;
  mgItemNumbers: number[];
  finfoNumbers?: number[];
  gtinCodes?: number[];
}

export interface ReplaceMgAssortmentArgs {
  assortmentId: number;
  title: string;
}

export function useMonitoringAssortmentActions() {
  const queryClient = useQueryClient();

  const { mutateAsync: mutatePostAsync, isLoading: isLoadingPost } =
    useAddItemsToMonitoringAssortment();

  const { mutateAsync: mutateDeleteItemsAsync, isLoading: isLoadingDeleteItems } =
    useDeleteItemsFromMonitoringAssortment();

  const { mutateAsync: mutateDeleteAsync, isLoading: isLoadingDelete } =
    useDeleteMonitoringAssortment();

  const { mutateAsync: mutateChangeTitleAsync, isLoading: isLoadingChangeTitle } =
    usePatchTitleOrItemsOfMonitoringAssortment();

  const { mutateAsync: mutateValidateItemsAsync } = useValidateImportedItems();

  const { mutateAsync: mutateExportAssortmentToExcel, isLoading: isLoadingExcel } =
    useExportAssortmentToExcel();

  const handleAddItemsToMgAssortment = async ({
    mgItemNumbers,
    finfoNumbers,
    gtinCodes,
    assortmentId,
  }: ItemsMgAssortmentArgs) => {
    const updatedAssortment = await mutatePostAsync({
      body: {
        mgItemNumbers,
        finfoNumbers,
        gtinCodes,
      },
      urlVariables: assortmentId,
    });
    await queryClient.invalidateQueries({ queryKey: ['monitoring-assortment'] });
    queryClient.invalidateQueries({ queryKey: ['assortments-for-mg-item'] });

    return updatedAssortment;
  };

  const handleDeleteItemsFromMgAssortment = async ({
    mgItemNumbers,
    assortmentId,
  }: ItemsMgAssortmentArgs) => {
    const updatedAssortment = await mutateDeleteItemsAsync({
      body: {
        mgItemNumbers,
      },
      urlVariables: assortmentId,
    });
    await queryClient.invalidateQueries({ queryKey: ['monitoring-assortment'] });
    queryClient.invalidateQueries({ queryKey: ['assortments-for-mg-item'] });

    return updatedAssortment;
  };

  const handlePatchTitleOrItemsOfMgAssortment = async ({
    title,
    assortmentId,
  }: ReplaceMgAssortmentArgs) => {
    await mutateChangeTitleAsync({
      body: { title },
      urlVariables: assortmentId,
    });
    await queryClient.invalidateQueries({ queryKey: ['monitoring-assortment'] });
  };

  const handleDeleteMgAssortment = async (assortmentId: number) => {
    await mutateDeleteAsync({ urlVariables: assortmentId });
  };

  const handleValidateItemsOnImportedAssortment = async (itemCodes: ItemCodes) => {
    return await mutateValidateItemsAsync({
      body: {
        finfoNumbers: itemCodes.finfoNumbers,
        gtinCodes: itemCodes.gtinCodes,
        mgItemNumbers: itemCodes.mgItemNumbers,
      },
    });
  };

  const exportAssortmentToExcel = async (assortmentId: number) => {
    return mutateExportAssortmentToExcel(assortmentId).then(
      downloadResponseFile({ filenameFallback: 'mgpris-export.xlsx' })
    );
  };

  return {
    handleAddItemsToMgAssortment,
    handleDeleteItemsFromMgAssortment,
    handleDeleteMgAssortment,
    handlePatchTitleOrItemsOfMgAssortment,
    handleValidateItemsOnImportedAssortment,
    exportAssortmentToExcel,
    isLoading: isLoadingPost || isLoadingDeleteItems || isLoadingDelete || isLoadingChangeTitle,
    isLoadingExcel,
  };
}
