import { FC, useCallback, useMemo, useState } from 'react';
import { DraftDrawerTable } from '@retail/calculus/components';
import { useCalculusConditions } from '@retail/calculus/context';
import { ContainedButton } from '@shared/components';
import { DraftActionsRowPortal } from '@retail/components';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useDisclosure } from '@shared/hooks';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { PriceListDialogContainer } from '@retail/purchase-agreements/containers';
import { useLanguageSelector } from '@retail/app/hooks';
import { Box } from '@mui/material';

export const DraftContainer: FC = () => {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const {
    isOpen: isPriceListDialogOpen,
    onToggle: togglePriceListDialog,
    onClose: closePriceListDialog,
  } = useDisclosure(false);

  const [agreement, setAgreement] = useState<PurchaseAgreement['id'] | undefined>(undefined);
  const [productGroup, setProductGroup] = useState<string | undefined>(undefined);
  const [selectedConditionSupplierIds, setSelectedConditionSupplierIds] = useState<number[]>([]);

  const {
    draftConditions,
    removeConditionsIsLoading,
    handleDeleteConditions,
    activateDraft,
    activateConditionIsLoading,
  } = useCalculusConditions();

  const getConditionsForSupplier = useCallback(() => {
    const supplierConditions: number[] = [];

    selectedConditionSupplierIds.forEach((supplierId) => {
      const conditions = draftConditions?.find(
        (condition) => condition.mgSupplierId === supplierId
      );

      const conditionIds = [
        ...(conditions?.purchaseConditions?.map((condition) => condition.id) || []),
        ...(conditions?.freightConditions?.map((condition) => condition.id) || []),
        ...(conditions?.markupConditions?.map((condition) => condition.id) || []),
      ];

      supplierConditions.push(...conditionIds);
    });

    return supplierConditions;
  }, [draftConditions, selectedConditionSupplierIds]);

  const submitDeleteSupplierConditions = useCallback(() => {
    handleDeleteConditions(getConditionsForSupplier());
  }, [getConditionsForSupplier, handleDeleteConditions]);

  const submitDraft = useCallback(() => {
    activateDraft(getConditionsForSupplier());
  }, [activateDraft, getConditionsForSupplier]);

  const isLoading = useMemo(
    () => activateConditionIsLoading || removeConditionsIsLoading,
    [activateConditionIsLoading, removeConditionsIsLoading]
  );

  const handleSetRowToSeePrices = (agreementId?: number, productGroup?: string) => {
    togglePriceListDialog();
    setAgreement(agreementId);
    setProductGroup(productGroup);
  };

  return (
    <Box height="100%">
      <DraftDrawerTable
        draftConditions={draftConditions ?? []}
        isLoading={isLoading}
        language={language}
        selectedConditionSupplierIds={selectedConditionSupplierIds}
        handleDeleteConditions={handleDeleteConditions}
        handleSetRowToSeePrices={handleSetRowToSeePrices}
        onSelectConditionSupplierIds={setSelectedConditionSupplierIds}
      />

      {agreement && (
        <PriceListDialogContainer
          open={isPriceListDialogOpen}
          onClose={closePriceListDialog}
          agreementId={agreement}
          productGroupId={productGroup}
        />
      )}

      <DraftActionsRowPortal>
        <ContainedButton
          color="warning"
          size="small"
          disabled={!selectedConditionSupplierIds.length}
          onClick={submitDeleteSupplierConditions}
          loading={isLoading}
        >
          {selectedConditionSupplierIds.length === 1
            ? t('calculus.actions.disapproveChange', { count: selectedConditionSupplierIds.length })
            : t('calculus.actions.disapproveChanges', {
                count: selectedConditionSupplierIds.length,
              })}
        </ContainedButton>

        <ContainedButton
          onClick={submitDraft}
          size="small"
          disabled={!selectedConditionSupplierIds.length}
          loading={isLoading}
        >
          {selectedConditionSupplierIds.length === 1
            ? t('calculus.actions.approveChange', { count: selectedConditionSupplierIds.length })
            : t('calculus.actions.approveChanges', { count: selectedConditionSupplierIds.length })}
        </ContainedButton>
      </DraftActionsRowPortal>
    </Box>
  );
};
