import { Autocomplete, Stack, TextField } from '@mui/material';
import { HierarchyLevels, SelectedLevel } from '@retail/calculus/types';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { useCallback, useEffect, useState } from 'react';
import { ItemInfoPaper } from './ItemInfo';
import { SearchResultItem } from '@retail/components';

interface Props {
  supplierId: number;
  mgItemId?: number;
  mgItems: MgSupplierItemSearchResult[];
  isLoading: boolean;
  selectLevel: (selectedLevel: SelectedLevel) => void;
  setSearchTerm: (searchTerm: string) => void;
}

export function SelectMgItem({
  supplierId,
  mgItemId,
  mgItems,
  isLoading,
  selectLevel,
  setSearchTerm,
}: Props) {
  const t = useProductsTFunction();

  const [selectedItem, selectItem] = useState<MgSupplierItemSearchResult | undefined>(undefined);

  useEffect(() => {
    if ((!selectedItem && mgItemId) || selectedItem !== mgItemId) {
      selectItem(mgItems.find((item) => item.mgItemNumber === mgItemId));
    }

    if (!mgItemId) {
      selectItem(undefined);
    }
  }, [mgItemId, mgItems, selectItem, selectedItem]);

  const onSelectItem = useCallback(
    (item?: MgSupplierItemSearchResult) => {
      selectItem(item);
      item
        ? selectLevel({
            mgSupplierItem: { id: item.mgItemNumber, name: item.primaryText },
            level: HierarchyLevels.item,
          })
        : selectLevel({ supplierId, level: HierarchyLevels.supplier });
    },
    [selectItem, selectLevel, supplierId]
  );

  return (
    <Stack gap={1} pb={1}>
      <Autocomplete
        options={mgItems}
        loading={isLoading}
        size="small"
        getOptionLabel={({ primaryText, finfoNumber }) => primaryText + finfoNumber}
        onChange={(_, newValue) => onSelectItem(newValue || undefined)}
        value={selectedItem || null}
        isOptionEqualToValue={(option, value) => option.mgItemNumber === value.mgItemNumber}
        renderInput={(params) => (
          <TextField
            {...params}
            hiddenLabel
            variant="outlined"
            placeholder={t('products.search.searchItems')}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        )}
        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
        noOptionsText={t('products.search.noResults')}
        renderOption={(props, option) => (
          <SearchResultItem key={option.mgItemNumber} liProps={{ ...props }} {...option} />
        )}
        ListboxProps={{
          sx: {
            '& li': {
              borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
              '&:last-child': { borderBottom: '0px' },
            },
          },
        }}
      />
      {selectedItem && <ItemInfoPaper {...selectedItem} />}
    </Stack>
  );
}
