import { toast, ToastContent, ToastOptions } from 'react-toastify';

export type ToastAlertFunction = (
  content: ToastContent,
  options?: Omit<ToastOptions, 'type'>
) => string | number;

export interface ToastAlert {
  success: ToastAlertFunction;
  error: ToastAlertFunction;
  warning: ToastAlertFunction;
  dark: ToastAlertFunction;
  info: ToastAlertFunction;
}

export type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'dark';

const defaultOptions: ToastOptions = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export function useToast(): ToastAlert {
  const alert: (type: TypeOptions) => ToastAlertFunction =
    (type) =>
    (content, options = {}) =>
      toast[type](content, { ...defaultOptions, ...options });

  return {
    success: alert('success'),
    error: alert('error'),
    warning: alert('warning'),
    dark: alert('dark'),
    info: alert('info'),
  };
}
