import { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Typography } from '@mui/material';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { ExpandMore } from '@mui/icons-material';

interface Props {
  assortments: MonitoringAssortment[];
  selectedAssortment: MonitoringAssortment | null;
  selectAssortment: (assortment: MonitoringAssortment | null) => void;
  alternativeLabel?: string;
  width?: string | number | (string | number)[];
  groupByType?: boolean;
}

export function AssortmentPicker({
  assortments,
  selectedAssortment,
  selectAssortment,
  alternativeLabel,
  width,
}: Props) {
  const t = useAssortmentTFunction();
  const alfabeticallySortedAssortments: MonitoringAssortment[] = useMemo(
    () => assortments.sort((a1, a2) => a1.title.localeCompare(a2.title, 'no')),
    [assortments]
  );

  if (alfabeticallySortedAssortments.length === 0) {
    return <Typography>{t('assortment.noAssortmentExists')}</Typography>;
  }

  return (
    <Autocomplete
      disablePortal
      popupIcon={<ExpandMore />}
      options={alfabeticallySortedAssortments}
      size="small"
      getOptionLabel={(option) => option.title}
      onChange={(_, newValue) => selectAssortment(newValue)}
      value={selectedAssortment}
      sx={{ width: width || 500 }}
      renderInput={(params) => (
        <TextField {...params} label={alternativeLabel || t('assortment.assortment.singular')} />
      )}
      renderOption={(props, { id, title }) => (
        <Box component="li" {...props} key={id}>
          {title}
        </Box>
      )}
      noOptionsText={t('assortment.picker.noOptions')}
    />
  );
}
