import { create } from 'zustand/react';
import dayjs from 'dayjs';
import {
  AcceptedColumn,
  ImportAgreementPricesState,
  MgItemPurchasePrice,
  RejectedRow,
} from './types';
import {
  getIsRecognizedHeaderPredicate,
  gtinRegex,
  recognizedGtinHeadings,
  recognizedMgItemNrHeadings,
  recognizedPurchasePriceHeadings,
  recognizedValidFromHeadings,
  recognizedValidToHeadings,
} from './utils';

export const useImportAgreementPricesStore = create<ImportAgreementPricesState>((set) => ({
  importedItemPrices: undefined,
  rejectedRows: undefined,
  importedFile: undefined,

  importSpreadsheet: (rows, file) => {
    const [headerRow, ...dataRows] = rows;

    const mgItemNumberColumnIndex = headerRow.findIndex((header) =>
      recognizedMgItemNrHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
    );
    const priceColumnIndex = headerRow.findIndex((header) =>
      recognizedPurchasePriceHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
    );
    const validFromColumnIndex = headerRow.findIndex((header) =>
      recognizedValidFromHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
    );
    const validToColumnIndex = headerRow.findIndex((header) =>
      recognizedValidToHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
    );
    const gtinColumnIndex = headerRow.findIndex((header) =>
      recognizedGtinHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
    );

    const updatedState = dataRows.reduce(
      (tmpResult, row, index) => {
        const rejectedColumns: AcceptedColumn[] = [];
        const mgItemNumber = Number(row.at(mgItemNumberColumnIndex));
        const price = Number(`${row.at(priceColumnIndex)}`?.replace(',', '.'));
        const validFrom = dayjs(`${row.at(validFromColumnIndex)}`, 'YYYY-MM-DD');
        const validToValue = row.at(validToColumnIndex);
        const validTo = validToValue ? dayjs(`${validToValue}`, 'YYYY-MM-DD') : undefined;
        const gtinValue = row.at(gtinColumnIndex);

        if (Number.isNaN(mgItemNumber)) rejectedColumns.push('mgItem');
        if (Number.isNaN(price)) rejectedColumns.push('purchasePrice');
        if (!validFrom.isValid()) rejectedColumns.push('validFrom');
        if (validTo && !validTo.isValid()) rejectedColumns.push('validTo');
        if (gtinValue && !gtinRegex.test(`${gtinValue}`)) rejectedColumns.push('gtin');

        if (rejectedColumns.length) {
          tmpResult.rejectedRows.push({ columns: rejectedColumns, index });
        } else {
          tmpResult.importedItemPrices.push({
            mgItemNumber,
            price,
            validFrom: validFrom.format('YYYY-MM-DD'),
            validTo: validTo?.format('YYYY-MM-DD'),
            gtinCode: gtinValue ? Number(gtinValue) : undefined,
          });
        }
        return tmpResult;
      },
      { importedItemPrices: [], rejectedRows: [] } as {
        importedItemPrices: MgItemPurchasePrice[];
        rejectedRows: RejectedRow[];
      }
    );

    set({ ...updatedState, importedFile: file });
  },

  clearState: () =>
    set({ importedItemPrices: undefined, rejectedRows: undefined, importedFile: undefined }),
}));
