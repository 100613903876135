import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { ContainedButton, Dialog, OutlinedButton } from '@shared/components';
import { useCallback } from 'react';
import { MarkupCondition } from '@retail/calculus/types';
import { useMarkupActions } from '@retail/markup/context';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  mgSupplierId: number;
  activeMarkupCondition: MarkupCondition;
}

// Delete templates and deactivate templates
export function DeleteOrDeactivateMarkupDialog({
  activeMarkupCondition,
  mgSupplierId,
  isOpen,
  onClose,
}: Props) {
  const t = useMarkupTFunction();

  const { deactivateMarkupCondition } = useMarkupActions({ mgSupplierId });

  const handleDeactivateCondition = useCallback(() => {
    deactivateMarkupCondition({
      mgSupplierLevel: !activeMarkupCondition.productGroup && !activeMarkupCondition.mgItemNumber,
      groupNumber: activeMarkupCondition.productGroup,
      discountGroup: activeMarkupCondition.discountGroup,
      mgItemNumber: activeMarkupCondition.mgItemNumber,
      gtin: activeMarkupCondition.markupGtin,
    });

    onClose();
  }, [activeMarkupCondition, deactivateMarkupCondition, onClose]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {t('markup.actions.deactivateDialogTitle', {
          markupName: activeMarkupCondition.markupTemplateName,
        })}
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6" fontWeight="medium">
          {t('markup.actions.confirmDeactivate')}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', alignSelf: 'flex-end' }}>
        <OutlinedButton size="small" onClick={onClose}>
          {t('markup.actions.cancel')}
        </OutlinedButton>

        <ContainedButton color="warning" size="small" onClick={handleDeactivateCondition}>
          {t('markup.actions.deactivate')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
