import { Navigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { NotFoundError, PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Paper } from '@shared/components';
import { useSelectSupplier } from '@retail/calculus/context';
import { CalculusTabs, SelectSupplierAutocomplete } from '@retail/calculus/components';
import { CalculusTabContainer } from '@retail/calculus/containers';
import { CalculusTabOptions, ConditionState } from '@retail/calculus/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { AssortmentItemsCalculusView } from '@retail/my-assortment/containers';
import { PurchaseContainer } from '@retail/purchase-agreements/containers';
import { FreightConditions } from '@retail/freight/containers';
import { MarkupContainer } from '@retail/markup/containers';
import { useDisclosure, useSelectedSearchParamState } from '@shared/hooks';
import { useEffect, useState } from 'react';
import { calculusTabsParamKey, productGroupSearchParamKey } from '@retail/products/utils';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useSelectLocalStorageSupplier } from '@retail/calculus/hooks';

export function CalculusForSupplierPage() {
  const t = useAppTFunction();
  const { lastSelectedSupplier } = useSelectLocalStorageSupplier();

  const { supplierId } = useParams<{ supplierId: string }>();
  const { suppliers, selectedSupplier, navigateToSupplier } = useSelectSupplier({
    supplierId: Number(supplierId),
  });
  const {
    draftAssortmentId: departmentDraftAssortmentId,
    baseAssortmentId: departmentBaseAssortmentId,
  } = useDepartmentAssortments();

  const tabs = [
    { label: t('markup.itemPrices'), value: CalculusTabOptions.ITEMS },
    { label: t('app.routes.agreements'), value: CalculusTabOptions.PURCHASE },
    { label: t('app.routes.freight'), value: CalculusTabOptions.FREIGHT },
    { label: t('app.routes.markup'), value: CalculusTabOptions.MARKUP },
  ];

  const [calculusArea, setCalculusArea] = useSelectedSearchParamState({
    options: tabs.map((tab) => tab.value),
    getOptionId: (tab) => tab,
    searchParamKey: calculusTabsParamKey,
    resetContiguousSearchParamKey: productGroupSearchParamKey,
    initialValue: CalculusTabOptions.ITEMS,
  });

  useEffect(() => {
    if (calculusArea === null) {
      setCalculusArea(CalculusTabOptions.ITEMS);
    }
  }, [calculusArea, setCalculusArea]);

  const { isOpen: isCreateOpen, onToggle: openCreate, onClose: closeCreate } = useDisclosure();
  const [conditionState, setConditionState] = useState<ConditionState>(ConditionState.ACTIVE);

  if (!supplierId) {
    return (
      <Navigate
        to={{
          pathname: mgPrisRoutes.calculus.supplier.getFullLinkPath({
            supplierId: lastSelectedSupplier ?? suppliers[0]?.mgSupplierId,
          }),
          search: `?${calculusTabsParamKey}=${calculusArea} `,
        }}
        replace
      />
    );
  }

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Stack height="100%" overflow="hidden">
        <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <CalculusTabs
            tabs={tabs}
            currentCalculusArea={calculusArea ?? CalculusTabOptions.ITEMS}
            setCalculusArea={setCalculusArea}
          />
          {selectedSupplier && (
            <SelectSupplierAutocomplete
              suppliers={suppliers}
              selectedSupplier={selectedSupplier}
              onSelectSupplier={navigateToSupplier}
            />
          )}
        </Stack>
        <Paper
          paddingY="dense"
          sx={{
            p: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          {selectedSupplier ? (
            <Stack flexGrow={1} overflow="hidden">
              <SuspenseWithSentryErrorBoundary>
                <Stack flexGrow={1} p={0} overflow="hidden">
                  {calculusArea === CalculusTabOptions.ITEMS && (
                    <AssortmentItemsCalculusView
                      departmentDraftAssortmentId={departmentDraftAssortmentId}
                      mgSupplierId={selectedSupplier.mgSupplierId}
                    />
                  )}
                  {calculusArea !== CalculusTabOptions.ITEMS && (
                    <CalculusTabContainer
                      draftAssortmentId={departmentDraftAssortmentId}
                      baseAssortmentId={departmentBaseAssortmentId}
                      currentCalculusArea={calculusArea ?? CalculusTabOptions.PURCHASE}
                      selectedSupplier={selectedSupplier}
                      conditionState={conditionState}
                      setConditionState={setConditionState}
                      openCreate={openCreate}
                    >
                      {calculusArea === CalculusTabOptions.PURCHASE && (
                        <PurchaseContainer
                          typesToShow={conditionState}
                          selectedSupplier={selectedSupplier}
                          isCreateOpen={isCreateOpen}
                          closeCreate={closeCreate}
                        />
                      )}

                      {calculusArea === CalculusTabOptions.FREIGHT && (
                        <FreightConditions
                          supplier={selectedSupplier}
                          typesToShow={conditionState}
                          isCreateOpen={isCreateOpen}
                          closeCreate={closeCreate}
                        />
                      )}

                      {calculusArea === CalculusTabOptions.MARKUP && (
                        <MarkupContainer
                          selectedSupplier={selectedSupplier}
                          typesToShow={conditionState}
                          isCreateOpen={isCreateOpen}
                          closeCreate={closeCreate}
                        />
                      )}
                    </CalculusTabContainer>
                  )}
                </Stack>
              </SuspenseWithSentryErrorBoundary>
            </Stack>
          ) : (
            <NotFoundError
              title={t('freight.supplier.notFound.title')}
              subtitle={t('freight.supplier.notFound.subtitle', { supplierId })}
              buttonText={t('freight.supplier.notFound.goBack')}
            />
          )}
        </Paper>
      </Stack>
    </PageLayout>
  );
}
