import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from './AuthContext';
import { UserOrgUnitsDTO } from './dto';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

export function useFetchMyOrgUnitsApi(brandId: string, queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<UserOrgUnitsDTO>({
    method: 'GET',
    queryKey: ['my-org-units', brandId, token],
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.brands.brand.myOrgUnits.getFullLinkPath({ brandId }),
    }),
    token,
    ...queryOptions,
  });
}
