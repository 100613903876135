import React, { PropsWithChildren } from 'react';
import { Stack, useTheme } from '@mui/material';

export const SalesPriceSummaryWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { palette } = useTheme();

  return (
    <Stack px={2} py={1.5} bgcolor={palette.success[50]}>
      {children}
    </Stack>
  );
};
