import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment, styled, TextField, TextFieldProps } from '@mui/material';
import { SearchIcon } from '@shared/custom-icons';
import { forwardRef } from 'react';

const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<Pick<SearchInputFieldProps, 'variant'>>(({ theme, variant }) => ({
  ...theme.typography.body3,
  background: theme.palette.primary.A100,
  borderRadius: theme.shape.borderRadius * 2, // must have this, adds border-radius for textfield. Border-radius on fieldset doesnt cut it.
  '& input': {
    color: theme.palette.grey[700],

    '&::placeholder': {
      color: theme.palette.text.disabled,
      ...theme.typography.body2,
      fontWeight: 450,
      opacity: 1,
    },
  },
  '& .MuiOutlinedInput-root': {
    background: theme.palette.grey[300],
    color: theme.palette.grey[700],

    '& fieldset': {
      border: `1.5px solid ${
        variant === 'filled' ? theme.palette.primary.A100 : theme.palette.grey.A100
      }`,
      borderRadius: theme.shape.borderRadius * 2,
    },

    '& .MuiFocused': {
      '& .MuiInputAdornment-positionStart': {
        color: theme.palette.grey[700],
      },
    },

    '& :not(.MuiFocused)': {
      '& .MuiInputAdornment-positionStart': {
        color: theme.palette.text.disabled,
      },
    },
  },

  margin: 0,
  height: 'fit-content',
}));

export interface SearchInputFieldProps
  extends Pick<TextFieldProps, 'onChange' | 'value' | 'margin' | 'placeholder' | 'fullWidth'> {
  onReset?: () => void;
  variant?: 'filled' | 'outlined';
}

const SearchInputField = forwardRef(
  (
    {
      margin = 'normal',
      variant = 'outlined',
      fullWidth = false,
      placeholder,
      onReset,
      value,
      ...rest
    }: SearchInputFieldProps,
    ref
  ) => {
    return (
      <StyledInput
        variant={variant}
        value={value}
        {...rest}
        inputRef={ref}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              onClick={onReset}
              edge="end"
              style={{ visibility: value && onReset ? 'visible' : 'hidden' }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        }}
        margin={margin}
        size="small"
        autoComplete="off"
        fullWidth={fullWidth}
        placeholder={placeholder}
      />
    );
  }
);

export default SearchInputField;
