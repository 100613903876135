import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, OutlinedButton } from '@shared/components';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppTFunction } from '@retail/app/i18n';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import {
  EditFreightConditionSchema,
  editFreightConditionSchema,
  FreightConditionFormBase,
} from '@retail/freight/components';
import { FreightCondition } from '@retail/calculus/types';
import { useFreightConditionActions } from '@retail/freight/context';
import dayjs from 'dayjs';

interface Props {
  isOpen: boolean;
  supplierId: number;
  freightConditionToEdit: FreightCondition;
  onClose: () => void;
}

export function EditFreightConditionDialog({
  supplierId,
  freightConditionToEdit,
  isOpen,
  onClose,
}: Props) {
  const t = useAppTFunction();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();
  const { editOrCreateFreightCondition } = useFreightConditionActions({
    draftAssortmentId: draftAssortmentId,
    departmentId: departmentId,
  });

  const formMethods = useForm<EditFreightConditionSchema>({
    defaultValues: {
      percentAddon: freightConditionToEdit.percentAddon,
      fromDate: freightConditionToEdit.validFrom,
      toDate: freightConditionToEdit.validTo ? new Date(freightConditionToEdit.validTo) : undefined,
    },
    resolver: yupResolver(editFreightConditionSchema(t)),
  });

  const onEdit = useCallback(
    (data: EditFreightConditionSchema) => {
      editOrCreateFreightCondition(
        {
          mgSupplierId: supplierId,
          groupNumber: freightConditionToEdit.productGroup,
          discountGroup: freightConditionToEdit.discountGroup,
          mgItemNumber: freightConditionToEdit.mgItemNumber,
          validFrom: dayjs(data.fromDate).isAfter(dayjs()) ? data.fromDate : new Date(),
          freight: {
            percentAddon: Number(data.percentAddon),
          },
        },
        onClose
      );
    },
    [freightConditionToEdit, editOrCreateFreightCondition, supplierId, onClose]
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>
        {t('freight.form.editFreightConditionFor', {
          condition: freightConditionToEdit.getDisplayText(),
        })}
      </DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <FreightConditionFormBase />
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ mt: 1 }}>
        <OutlinedButton size="small" onClick={onClose}>
          {t('common.cancel')}
        </OutlinedButton>

        <ContainedButton
          size="small"
          disabled={Object.keys(formMethods?.formState?.errors).length > 0}
          onClick={formMethods.handleSubmit(onEdit)}
        >
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
