import { QueryOptions } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { buildUrl } from '@shared/utils';
import { ItemAgreementDTO, PriceListItemDTO } from '@retail/purchase-agreements/types';
import { useMPEFetch } from '@retail/app/hooks';
import { PaginationResultDTO } from '@retail/products/types';
import { mpeApi } from '@retail/mgpris/config';

interface SearchParams {
  groupNumbers?: string[];
  mgItemNumbers?: number[];
  current?: boolean;
  page?: number;
  size?: number;
  query?: string;
}

interface SearchParamsPriceList extends SearchParams {
  agreementId: number;
}

export function useFetchPriceList(
  {
    agreementId,
    groupNumbers,
    mgItemNumbers,
    current = true,
    page,
    size,
    query,
  }: SearchParamsPriceList,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PaginationResultDTO<ItemAgreementDTO[]>>({
    method: 'GET',
    queryKey: ['priceList', page, agreementId, groupNumbers, mgItemNumbers, current, size, query],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.purchaseAgreements.agreement.prices.getFullLinkPath({ agreementId }),
      searchParams: {
        page,
        size,
        query,
        groupNumbers,
        mgItemNumbers,
        current,
      },
    }),
    ...queryOptions,
  });
}

interface SearchParamsPriceLists extends SearchParams {
  agreementIds: number[];
}

export function useFetchPriceLists(
  { agreementIds, groupNumbers, page, size, query }: SearchParamsPriceLists,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PaginationResultDTO<PriceListItemDTO[]>>({
    method: 'GET',
    queryKey: ['priceLists', page, agreementIds, groupNumbers, size, query],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.purchaseAgreements.prices.getFullLinkPath(),
      searchParams: { page, size, query, groupNumbers: groupNumbers, active: true },
    }),
    ...queryOptions,
  });
}
