import { useMemo } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import {
  CustomColumnMenu,
  DataGridPagination,
  StackedTextCell,
} from '@retail/components';
import { dataGridBoldClass } from '@shared/styles';
import { ItemAgreement } from '@retail/purchase-agreements/types';
import { Pagination } from '@shared/hooks';
import { AssortmentItem } from '@retail/my-assortment/types';
import { AssortmentItemActions } from './AssortmentItemActions';
import { LoupeOutlined } from '@mui/icons-material';
import { useAppTFunction } from '@retail/app/i18n';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import { PricePerUnit } from '@retail/products/components';
import dayjs from 'dayjs';
import { ItemStatus } from '../AssortmentTable/ItemStatus/ItemStatus';

interface Props {
  items: AssortmentItem[];
  totalItems: number;
  mgItemClicked?: ItemAgreement;
  language: string;
  viewItemCalculusCard: (itemId: number) => void;
  setEditItemAgreement: (item: AssortmentItem) => void;
}

export const AssortmentItemsCalculusViewTable = ({
  items,
  totalItems,
  language,
  page,
  pageSize,
  setPage,
  setPageSize,
  viewItemCalculusCard,
  setEditItemAgreement,
}: Props & Pagination) => {
  const t = useAppTFunction();
  const { i18n } = useTranslation();

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      sortable: false,
      headerName: '',
      headerAlign: 'left',
      align: 'left',
      width: 140,
    }),
    []
  );
  const columns: GridColDef<AssortmentItem>[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'primaryText',
        cellClassName: () => dataGridBoldClass,
        headerName: t('myAssortment.columns.itemText'),
        width: 290,
        renderCell: ({ row }) => (
          <Stack flexDirection="row">
            <ItemStatus status={row.status} isCentered={false} mr={1} />
            <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
          </Stack>
        ),
      },
      {
        ...baseColumnProps,
        field: 'mgItemId',
        width: 160,
        headerName: t('myAssortment.columns.mgItemFinfoNumber'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />
        ),
      },
      {
        ...baseColumnProps,
        width: 180,
        field: 'itemGroup',
        headerName: t('myAssortment.columns.itemGroup'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.groupNumber} bottomLine={row.groupDescription} />
        ),
      },
      {
        ...baseColumnProps,
        width: 220,
        field: 'discountGroup',
        headerName: t('myAssortment.columns.discountGroup'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.discountGroup?.name} bottomLine={row.discountGroup?.code} />
        ),
      },
      {
        ...baseColumnProps,
        field: 'purchasePrice',
        headerName: t('myAssortment.columns.purchasePrice'),
        renderCell: ({ row }) => {
          const primaryPackageUnit = row.primaryPackageUnit;
          const purchasePriceForUnit = row.getCurrentPurchasePriceForUnit(primaryPackageUnit);
          const unitDescription = purchasePriceForUnit?.supplierItemPackage.unit.description;

          const purchasePrice = purchasePriceForUnit
            ? formatPrice(purchasePriceForUnit.price.price, i18n.language)
            : '-';

          const currentPurchasePrice = row.currentSalesPrices?.purchasePrice;
          const priceDate = currentPurchasePrice
            ? dayjs(currentPurchasePrice.validFrom).format('ll')
            : null;

          return (
            <StackedTextCell
              nullableBottomLine
              topLine={
                <PricePerUnit
                  price={purchasePrice}
                  unit={primaryPackageUnit}
                  description={unitDescription}
                />
              }
              bottomLine={
                <Stack>
                  {row.gtinPurchasePrices ? (
                    <Tooltip title={t('purchaseAgreements.priceList.morePrices')}>
                      <div>
                        <LoupeOutlined fontSize="small" />
                      </div>
                    </Tooltip>
                  ) : null}
                  {priceDate}
                </Stack>
              }
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'costPriceInSalesUnit',
        headerName: t('myAssortment.columns.costPriceInSalesUnit'),
        renderCell: ({ row }) => {
          const primaryPackageUnit = row.primaryPackageUnit;
          const costPriceForUnit = row.getCurrentCostPriceForUnit(primaryPackageUnit);
          const unitDescription = costPriceForUnit?.supplierItemPackage?.unit?.description;

          const costPrice = costPriceForUnit
            ? formatPrice(costPriceForUnit.price.price, i18n.language)
            : '-';

          const currentCostPrice = row.currentSalesPrices?.costPrice;
          const priceDate = currentCostPrice
            ? dayjs(currentCostPrice.validFrom).format('ll')
            : null;

          return (
            <StackedTextCell
              nullableBottomLine
              topLine={
                <PricePerUnit
                  price={costPrice}
                  unit={primaryPackageUnit}
                  description={unitDescription}
                />
              }
              bottomLine={priceDate}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'salesPrice',
        headerName: t('myAssortment.columns.salesPrice'),
        width: 160,
        renderCell: ({ row }) => {
          const primaryPackageUnit = row.primaryPackageUnit;
          const fPakPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
          const price = fPakPrice?.bracketMarkupPrice.getPriceForUnit(primaryPackageUnit);

          const formattedPrice = price
            ? formatPrice(price.price.priceWithoutVat, i18n.language)
            : '-';

          const priceDate = fPakPrice ? dayjs(fPakPrice.validFrom).format('ll') : null;

          const unitDescription = fPakPrice?.bracketMarkupPrice.calculationPackage.unit.description;

          return (
            <Stack flexDirection="row" gap={1} alignItems="center">
              <ItemStatus status={fPakPrice?.bracketMarkupPrice.status} isCentered={false} />
              <StackedTextCell
                nullableBottomLine
                topLine={
                  <PricePerUnit
                    price={formattedPrice}
                    unit={primaryPackageUnit}
                    description={unitDescription}
                  />
                }
                bottomLine={priceDate}
              />
            </Stack>
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'actions',
        width: 80,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <AssortmentItemActions
            viewCalculusCard={() => viewItemCalculusCard(row.mgSupplierItemId)}
            editItemAgreement={() => setEditItemAgreement(row)}
          />
        ),
      },
    ],
    [baseColumnProps, i18n.language, setEditItemAgreement, t, viewItemCalculusCard]
  );
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          getRowId={(row) => row.mgItemNumber}
          columns={columns}
          rows={items}
          rowHeight={70}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_ITEMS_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_ITEMS_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          pagination={true}
          paginationMode="server"
          slots={{
            columnMenu: CustomColumnMenu,
            pagination: () => <DataGridPagination />,
          }}
          rowCount={totalItems}
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={(model) => {
            if (model.page !== page) setPage(model.page);
            if (model.pageSize !== pageSize) setPageSize(model.pageSize);
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
};
