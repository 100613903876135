import { Typography, useTheme } from '@mui/material';
import { Chip } from '@shared/components';

interface Props {
  label: string | number;
  size?: 'medium' | 'small';
}

export const InfoChip = ({ label, size = 'small' }: Props) => {
  const { palette } = useTheme();
  return (
    <Chip
      size={size}
      backgroundColor={palette.info[300]}
      sx={{ border: `1px solid ${palette.divider}` }}
      label={
        <Typography color="text.secondary" variant="body2">
          {label}
        </Typography>
      }
    />
  );
};
