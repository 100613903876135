import { forwardRef } from 'react';
import { Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { ExportSchema } from './exportSchema';
import { Paper } from '@shared/components';
import { ExportDateTimePicker } from './ExportDateTimePicker';
import { useMountEffect } from '@shared/hooks';

interface Props {
  onUpdateDateTimePicker: (fromDate: Date | null) => Promise<void>;
}

export const ExportDialogForm = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const t = useAppTFunction();

  const { control, setValue } = useFormContext<ExportSchema>();
  const allArticlesController = useController({ name: 'allArticles', control });
  const onlyChangesController = useController({ name: 'onlyChanges', control });
  const fromDateController = useController({ name: 'fromDate', control });

  useMountEffect(() => {
    if (fromDateController.field.value) {
      setValue(onlyChangesController.field.name, true);
    } else setValue(allArticlesController.field.name, true);
  });

  return (
    <Paper variant="outlined" paddingY="dense" ref={ref} {...props}>
      <Stack flexDirection="row" alignItems="center">
        <FormControlLabel
          control={
            <Controller
              name="onlyChanges"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Checkbox
                  onChange={(e) => {
                    onChange(e.target.checked);
                    setValue(allArticlesController.field.name, !e.target.checked);
                  }}
                  onBlur={onBlur}
                  checked={!!value}
                  name={name}
                  inputRef={ref}
                />
              )}
            />
          }
          label={
            <Typography variant="body2" sx={{ mr: 1.5 }}>
              {t('export.changesFromDate')}
            </Typography>
          }
        />

        <ExportDateTimePicker onUpdateDateTimePicker={props.onUpdateDateTimePicker} />
      </Stack>

      <Typography variant="body2" fontStyle="italic" sx={{ my: 1 }}>
        {t('export.or')}
      </Typography>

      <FormControlLabel
        control={
          <Controller
            name="allArticles"
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Checkbox
                onChange={(e) => {
                  onChange(e.target.checked);
                  setValue(onlyChangesController.field.name, !e.target.checked);
                }}
                onBlur={onBlur}
                checked={!!value}
                name={name}
                inputRef={ref}
              />
            )}
          />
        }
        label={
          <Typography variant="body2" sx={{ mr: 1.5 }}>
            {t('export.allArticles')}
          </Typography>
        }
      />

      <Divider
        sx={{
          my: 1,
        }}
      />
    </Paper>
  );
});
