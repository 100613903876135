import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, OutlinedButton } from '@shared/components';
import { useAppTFunction } from '@retail/app/i18n';
import { useController, useFormContext } from 'react-hook-form';
import {
  CreateFreightConditionForm,
  CreateFreightConditionSchema,
} from '@retail/freight/components';
import { useCallback, useEffect } from 'react';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { useLevelSelect } from '@retail/calculus/context';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useFreightConditionActions } from '@retail/freight/context';

interface Props {
  onClose: () => void;
  open: boolean;
  supplierId: number;
}

function CreateFreightConditionDialog({ onClose, supplierId }: Props) {
  const t = useAppTFunction();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();
  const { editOrCreateFreightCondition, isLoadingPost } = useFreightConditionActions({
    draftAssortmentId: draftAssortmentId,
    departmentId: departmentId,
  });

  const { handleSubmit, control, watch, setError, clearErrors } =
    useFormContext<CreateFreightConditionSchema>();
  const levelController = useController({ name: 'level', control });
  const itemSearch = watch('itemSearch');

  const { productGroups, mgSupplierItems, discountGroups, isLoadingFetchItems } = useLevelSelect({
    supplierId,
    selectedProductGroup: levelController.field.value === 'productGroup',
    selectedDiscountGroup: levelController.field.value === 'discountGroup',
    itemSearch,
    onItemSearchSuccess: (results) => {
      if (!results.result.length) {
        setError('item', { message: t('calculus.error.itemSearch.noResults') });
      }
    },
    onItemSearchError: (err) => {
      console.error(err);
      setError('item', { message: t('calculus.error.itemSearch.generic') });
    },
  });

  useEffect(() => {
    clearErrors('item');
  }, [clearErrors, itemSearch]);

  const onSubmit = useCallback(
    (data: CreateFreightConditionSchema) => {
      editOrCreateFreightCondition(
        {
          mgSupplierId: supplierId,
          groupNumber:
            data.productGroup?.groupNumber ??
            data.mainGroup?.groupNumber ??
            data.overGroup?.groupNumber,
          discountGroup: data.discountGroup?.code,
          mgItemNumber: data.item?.mgItemNumber,
          validFrom: data.fromDate,
          freight: {
            percentAddon: Number(data.percentAddon),
          },
        },
        onClose
      );
    },
    [editOrCreateFreightCondition, supplierId, onClose]
  );

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogTitle>{t('freight.newFreightCondition')}</DialogTitle>

      <DialogContent>
        <CreateFreightConditionForm
          productGroups={productGroups}
          discountGroups={discountGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
        />
      </DialogContent>

      <DialogActions>
        <OutlinedButton size="small" onClick={onClose}>
          {t('common.cancel')}
        </OutlinedButton>

        <ContainedButton
          onClick={handleSubmit((data) => onSubmit(data))}
          disabled={isLoadingPost}
          size="small"
        >
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(CreateFreightConditionDialog);
