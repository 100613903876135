import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { AssortmentItemDTO, ItemsDTO } from '@retail/my-assortment/types';
import { MgPackageType, PaginationResultDTO } from '@retail/products/types';
import { useMPEFetch, useMutateMPEFetch } from '@retail/app/hooks';
import { mpeApi } from '@retail/mgpris/config';

interface Props extends QueryOptions {
  assortmentId: number;
  supplierIds?: number[];
  groupNumbers?: string[];
  discountGroupIds?: number[];
  query?: string;
  includeOwner?: boolean;
  inAssortment?: boolean;
  withAgreementPrices?: boolean;
  nPrices?: number;
  calculationPackages?: MgPackageType[];
  page: number;
  size: number;
}

export function useFetchAssortmentItems({
  assortmentId,
  supplierIds,
  groupNumbers,
  discountGroupIds,
  query,
  includeOwner = false,
  inAssortment,
  withAgreementPrices,
  nPrices,
  calculationPackages,
  page,
  size,
  ...queryOptions
}: Props) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PaginationResultDTO<AssortmentItemDTO[]>>({
    method: 'GET',
    queryKey: [
      'assortmentItems',
      assortmentId,
      supplierIds,
      includeOwner,
      inAssortment,
      groupNumbers,
      discountGroupIds,
      query,
      withAgreementPrices,
      nPrices,
      calculationPackages,
      page,
      size,
    ],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.items.getFullLinkPath({ assortmentId }),
      searchParams: {
        supplierIds,
        groupNumbers,
        discountGroupIds,
        includeOwner,
        inAssortment,
        query,
        withAgreementPrices,
        nPrices,
        calculationPackages,
        page,
        size,
      },
    }),
    ...queryOptions,
  });
}

interface PostProps {
  memberId: string;
}

export function useUpdateAssortmentItems({
  assortmentId,
  memberId,
  ...queryOptions
}: Pick<Props, 'assortmentId'> & PostProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, ItemsDTO[]>({
    method: 'POST',
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.members.member.assortments.assortment.items.getFullLinkPath({
        assortmentId,
        memberId,
      }),
    }),
    token,
    ...queryOptions,
  });
}

export function useDeleteItemsFromDraft({
  assortmentId,
  memberId,
  ...queryOptions
}: Pick<Props, 'assortmentId'> & PostProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, number[]>({
    method: 'DELETE',
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.members.member.assortments.assortment.deleteItems.getFullLinkPath({
        assortmentId,
        memberId,
      }),
    }),
    token,
    ...queryOptions,
  });
}
