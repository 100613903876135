import { MainGroupDTO, OverGroupDTO, SupplierWithProductGroupsDTO, UnderGroupDTO } from '../dtos';
import { FreightCondition } from './FreightCondition';
import { MarkupCondition } from './MarkupCondition';
import { PurchaseCondition } from './PurchaseCondition';

export type GroupTypes = 'OVERGRUPPE' | 'HOVEDGRUPPE' | 'UNDERGRUPPE';
export interface ProductGroupProps {
  id: number;
  type: GroupTypes;
  groupNumber: string;
  name: string;
  numberOfItems: number;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  numberOfPurchaseConditions: number;
  numberOfFreightConditions: number;
  numberOfMarkupConditions: number;
}

export class ProductGroup {
  id: number;
  type: GroupTypes;
  groupNumber: string;
  name: string;
  numberOfItems: number;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  numberOfPurchaseConditions: number;
  numberOfFreightConditions: number;
  numberOfMarkupConditions: number;

  constructor({
    id,
    type,
    groupNumber,
    name,
    numberOfItems,
    purchaseCondition,
    freightCondition,
    markupCondition,
    numberOfPurchaseConditions,
    numberOfFreightConditions,
    numberOfMarkupConditions,
  }: ProductGroupProps) {
    this.id = id;
    this.type = type;
    this.groupNumber = groupNumber;
    this.name = name;
    this.numberOfItems = numberOfItems;
    this.purchaseCondition = purchaseCondition;
    this.freightCondition = freightCondition;
    this.markupCondition = markupCondition;
    this.numberOfPurchaseConditions = numberOfPurchaseConditions;
    this.numberOfFreightConditions = numberOfFreightConditions;
    this.numberOfMarkupConditions = numberOfMarkupConditions;
  }
}

export class UnderGroup extends ProductGroup {
  static fromDto = (dto: UnderGroupDTO): UnderGroup =>
    new UnderGroup({
      ...dto,
      freightCondition: dto.freightCondition && FreightCondition.fromDto(dto.freightCondition),
      markupCondition: dto.markupCondition && MarkupCondition.fromDto(dto.markupCondition),
      purchaseCondition: dto.purchaseCondition && PurchaseCondition.fromDto(dto.purchaseCondition),
    });
}

interface MainGroupProps extends ProductGroupProps {
  subGroups: UnderGroup[];
}

export class MainGroup extends ProductGroup {
  subGroups: UnderGroup[];

  constructor({ subGroups, ...superProps }: MainGroupProps) {
    super(superProps);
    this.subGroups = subGroups;
  }

  static fromDto = (dto: MainGroupDTO): MainGroup =>
    new MainGroup({
      ...dto,
      subGroups: dto.subGroups.map(UnderGroup.fromDto),
      freightCondition: dto.freightCondition && FreightCondition.fromDto(dto.freightCondition),
      markupCondition: dto.markupCondition && MarkupCondition.fromDto(dto.markupCondition),
      purchaseCondition: dto.purchaseCondition && PurchaseCondition.fromDto(dto.purchaseCondition),
    });
}

export interface OverGroupProps extends ProductGroup {
  subGroups: MainGroup[];
}

export class OverGroup extends ProductGroup {
  subGroups: MainGroup[];

  constructor({ subGroups, ...superProps }: OverGroupProps) {
    super(superProps);
    this.subGroups = subGroups;
  }
  static fromDto = (dto: OverGroupDTO): OverGroup =>
    new OverGroup({
      ...dto,
      subGroups: dto.subGroups.map(MainGroup.fromDto),
      freightCondition: dto.freightCondition && FreightCondition.fromDto(dto.freightCondition),
      markupCondition: dto.markupCondition && MarkupCondition.fromDto(dto.markupCondition),
      purchaseCondition: dto.purchaseCondition && PurchaseCondition.fromDto(dto.purchaseCondition),
    });
}

export interface SupplierWithProductGroupsProps {
  mgSupplierId: number;
  mgSupplierName: string;
  numberOfItems: number;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  numberOfPurchaseConditions: number;
  numberOfFreightConditions: number;
  numberOfMarkupConditions: number;
  productGroups: OverGroup[];
}

export class SupplierWithProductGroups {
  mgSupplierId: number;
  mgSupplierName: string;
  numberOfItems: number;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  numberOfPurchaseConditions: number;
  numberOfFreightConditions: number;
  numberOfMarkupConditions: number;
  productGroups: OverGroup[];

  constructor({
    mgSupplierId,
    mgSupplierName,
    numberOfItems,
    purchaseCondition,
    freightCondition,
    markupCondition,
    numberOfPurchaseConditions,
    numberOfFreightConditions,
    numberOfMarkupConditions,
    productGroups,
  }: SupplierWithProductGroupsProps) {
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.numberOfItems = numberOfItems;
    this.purchaseCondition = purchaseCondition;
    this.freightCondition = freightCondition;
    this.markupCondition = markupCondition;
    this.numberOfPurchaseConditions = numberOfPurchaseConditions;
    this.numberOfFreightConditions = numberOfFreightConditions;
    this.numberOfMarkupConditions = numberOfMarkupConditions;
    this.productGroups = productGroups;
  }

  static flatten = (overGroups: OverGroup[]): ProductGroup[] =>
    overGroups.flatMap((overGroup: OverGroup) => [
      {
        id: overGroup.id,
        type: overGroup.type,
        groupNumber: overGroup.groupNumber,
        name: overGroup.name,
        numberOfItems: overGroup.numberOfItems,
        purchaseCondition: overGroup.purchaseCondition,
        freightCondition: overGroup.freightCondition,
        markupCondition: overGroup.markupCondition,
        numberOfPurchaseConditions: overGroup.numberOfPurchaseConditions,
        numberOfFreightConditions: overGroup.numberOfFreightConditions,
        numberOfMarkupConditions: overGroup.numberOfMarkupConditions,
      },
      ...overGroup.subGroups.flatMap((mainGroup: MainGroup) => [
        {
          id: overGroup.id,
          type: mainGroup.type,
          groupNumber: mainGroup.groupNumber,
          name: mainGroup.name,
          numberOfItems: mainGroup.numberOfItems,
          purchaseCondition: mainGroup.purchaseCondition,
          freightCondition: mainGroup.freightCondition,
          markupCondition: mainGroup.markupCondition,
          numberOfPurchaseConditions: mainGroup.numberOfPurchaseConditions,
          numberOfFreightConditions: mainGroup.numberOfFreightConditions,
          numberOfMarkupConditions: mainGroup.numberOfMarkupConditions,
        },
        ...mainGroup.subGroups,
      ]),
    ]);

  static fromDto = (dto: SupplierWithProductGroupsDTO): SupplierWithProductGroups =>
    new SupplierWithProductGroups({
      mgSupplierId: dto.mgSupplierId,
      mgSupplierName: dto.mgSupplierName,
      numberOfItems: dto.numberOfItems,
      purchaseCondition: dto.purchaseCondition && PurchaseCondition.fromDto(dto.purchaseCondition),
      freightCondition: dto.freightCondition && FreightCondition.fromDto(dto.freightCondition),
      markupCondition: dto.markupCondition && MarkupCondition.fromDto(dto.markupCondition),
      numberOfPurchaseConditions: dto.numberOfPurchaseConditions,
      numberOfFreightConditions: dto.numberOfFreightConditions,
      numberOfMarkupConditions: dto.numberOfMarkupConditions,
      productGroups: dto.productGroups.map(OverGroup.fromDto),
    });
}
