import { IconButton, ListItem, Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { EmptyCircularFallbackImage, Image } from '@retail/components';
import { TrashIcon } from '@shared/custom-icons';
import { DeleteDialog } from './DeleteDialog';
import { useDisclosure } from '@shared/hooks';

interface SelectedCompetitorProps {
  name: string;
  distance: number | undefined;
  url: string;
  removeCompetitor: () => void;
}

export function CompetitorView({ removeCompetitor, name, url, distance }: SelectedCompetitorProps) {
  const t = useAppTFunction();
  const { isOpen, onToggle } = useDisclosure(false);

  return (
    <ListItem divider sx={{ justifyContent: 'space-between', py: 2 }}>
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Image src={url} fallback={() => <EmptyCircularFallbackImage size="30px" />} />
        <Typography fontWeight="medium">{name}</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" gap={2}>
        {distance && (
          <Typography variant="subtitle2" color="text.secondary">
            {t('assortment.competitors.distanceFromMe', {
              distance: distance.toFixed(1),
            })}
          </Typography>
        )}
        <IconButton onClick={onToggle}>
          <TrashIcon stroke="text.secondary" fontSize="small" />
        </IconButton>
      </Stack>
      <DeleteDialog action={removeCompetitor} toggleModal={onToggle} isOpen={isOpen} name={name} />
    </ListItem>
  );
}
