import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { prisinnsikt as LogoNo, mgPris as LogoSe } from '@assets/images';
import { useAppTFunction } from '@retail/app/i18n';
import { useAuthContext } from '@retail/auth/data-access';

export function PublicErrorPage() {
  const t = useAppTFunction();
  const language = useTranslation().i18n.language;
  const { logout } = useAuthContext();

  return (
    <>
      <Box
        display="flex"
        bgcolor="white"
        height="80px"
        justifyContent="center"
        alignItems="center"
        component="header"
      >
        {language === 'se' ? <LogoSe /> : <LogoNo />}
      </Box>
      <Box display="flex" flexDirection="column" component="main">
        <Stack alignItems="center" spacing={4}>
          <Typography>{t('app.actions.error.generic')}</Typography>
          <Button onClick={() => logout()}>{t('app.actions.logOut')}</Button>
        </Stack>
      </Box>
    </>
  );
}
