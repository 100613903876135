import { useMemo, useState } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { NavigationTreeItem as NavTreeItem } from './NavigationTreeItem';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

export interface TreeNode {
  id: string;
  label: React.ReactNode;
  onClick?: () => void;
  onSelect: () => void;
  childNodes?: TreeNode[];
  defaultExpanded?: boolean;
}

interface Props {
  nodes: TreeNode[];
  selectedNodeId: string | null;
}

function getAllDefaultExpandedIds(nodes: TreeNode[]): string[] {
  return nodes
    .filter(({ defaultExpanded }) => defaultExpanded)
    .flatMap(({ id, childNodes }) => {
      if (childNodes?.length) {
        return getAllDefaultExpandedIds(childNodes).concat(id);
      }
      return [id];
    });
}

export function NavigationTreeView({ nodes, selectedNodeId }: Props) {
  const initialExpandedList = useMemo(() => getAllDefaultExpandedIds(nodes), [nodes]);
  const [expandedItems, setExpandedItems] = useState<string[]>(initialExpandedList);

  const handleToggle = (_event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  return (
    <SimpleTreeView
      expandedItems={expandedItems}
      onExpandedItemsChange={handleToggle}
      selectedItems={selectedNodeId}
      slots={{ collapseIcon: () => <ExpandMore color="primary" />, expandIcon: ChevronRight }}
    >
      {nodes.map((node) => (
        <NavTreeItem key={node.id} depth={0} {...node} />
      ))}
    </SimpleTreeView>
  );
}
