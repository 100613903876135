import { Grid, Paper, Stack } from '@mui/material';
import { PageLayout } from '@retail/components';
import { ExportContainer } from '@retail/export/containers';

export function ExportPage() {
  return (
    <PageLayout maxWidth="full" spacing={2} fixedHeight>
      <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}>
        <Stack component={Grid} flexGrow={1} flexDirection="row" overflow="hidden" container>
          <ExportContainer />
        </Stack>
      </Paper>
    </PageLayout>
  );
}
