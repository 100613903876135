export const convertToCSVString = (objArray: any[], delimiter: ',' | ';'): string => {
  if (!Array.isArray(objArray) || objArray.length === 0) {
    throw new Error('Input must be a non-empty array of objects.');
  }

  // Create the CSV header row from keys of the first object
  const keys = Object.keys(objArray[0]);
  const header = keys.join(delimiter);

  // Create the CSV data rows
  const rows = objArray.map((obj) =>
    keys.map((key) => (obj[key] != null ? obj[key] : '')).join(delimiter)
  );

  // Combine header and rows into a single CSV string
  return [header, ...rows].join('\r\n');
};

// Convert CSV to JSON
const convertCsvToJson = (csv: string, delimeter: string) => {
  const [header] = csv.split('\n');
  const keys = header.split(delimeter);

  // Create an array with a single object
  const jsonResult = keys.reduce((acc: any, key: any) => {
    acc[key] = null; // Assigning null as placeholder value for each key
    return acc;
  }, {});

  return [jsonResult];
};

export const generateTemplateCsv = (csvHeaders: string, delimeter: ',' | ';') => {
  const jsonObject = convertCsvToJson(csvHeaders, delimeter);

  return convertToCSVString(jsonObject, delimeter);
};

type FileName = `${string}.${string}`;

export const downloadBlobAsFile = (blob: Blob, fileName: FileName) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadTemplateCsv = (
  csvHeaders: string,
  fileName: FileName,
  delimeter: ',' | ';' = ';'
) => {
  const csvContent = generateTemplateCsv(csvHeaders, delimeter);

  const blob = new Blob([csvContent], { type: 'text/csv' });

  downloadBlobAsFile(blob, fileName);
};
