import { articleIdsSearchParamKey } from '@retail/products/utils';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSelectArticleIds = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const articleIds = useMemo(
    () => searchParams.get(articleIdsSearchParamKey)?.split(',') ?? [],
    [searchParams]
  );

  const setArticleIds = useCallback(
    (articleId: string) =>
      setSearchParams((prevParams) => {
        if (articleIds?.includes(articleId)) {
          prevParams.set(articleIdsSearchParamKey, articleIds.join(','));
        } else {
          prevParams.set(articleIdsSearchParamKey, articleIds.concat(articleId).join(','));
        }
        return prevParams;
      }),
    [articleIds, setSearchParams]
  );

  const removeArticleId = useCallback(
    (articleId: string) => {
      setSearchParams((prevParams) => {
        if (articleIds.length > 1) {
          prevParams.set(
            articleIdsSearchParamKey,
            articleIds.filter((id) => id !== articleId).join(',')
          );
        } else {
          prevParams.delete(articleIdsSearchParamKey);
        }

        return prevParams;
      });
    },
    [articleIds, setSearchParams]
  );

  const resetArticleIds = useCallback(() => {
    setSearchParams((prevParams) => {
      prevParams.delete(articleIdsSearchParamKey);
      return prevParams;
    });
  }, [setSearchParams]);

  return {
    articleIds,
    setArticleIds,
    removeArticleId,
    resetArticleIds,
  };
};
