import { UnitDTO } from '../../dto';

export class Unit {
  description?: string;
  unit: string;

  constructor(dto: UnitDTO) {
    this.description = dto.description;
    this.unit = dto.unit;
  }

  static fromDto = (dto: UnitDTO): Unit => new Unit(dto);
}
