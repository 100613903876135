import { Stack } from '@mui/material';
import { MgItem, MgSupplierItem, MgSupplierItemPackage } from '@retail/products/types';
import { MgSupplierItemPackagesTable } from './MgSupplierItemPackagesTable';
import { SupplierButtonGroup } from './SupplierButtonGroup';
import { useState } from 'react';
import { sortSupplierItemPackageType } from '@retail/products/utils';

interface Props {
  mgItem: MgItem;
}

export type SupplierItemPackage = MgSupplierItemPackage & {
  mgSupplierName: string;
  mgSupplierId: number;
};

export function SupplierItems({ mgItem }: Props) {
  const [selectedSupplier, setSelectedSupplier] = useState<Partial<MgSupplierItem> | null>(null);

  const suppliers = mgItem.getSuppliers();
  const packages: SupplierItemPackage[] = [];

  mgItem.supplierItems.forEach((supplierItem) => {
    supplierItem.packages.forEach((pkg) => {
      packages.push({
        ...pkg,
        mgSupplierName: supplierItem.mgSupplierName,
        mgSupplierId: supplierItem.mgSupplierId,
      });
    });
  });

  const sortedPackages = packages.sort(sortSupplierItemPackageType);

  return (
    <Stack gap={3}>
      <SupplierButtonGroup
        suppliers={suppliers}
        selectedSupplier={selectedSupplier}
        setSelectedSupplier={setSelectedSupplier}
      />
      <MgSupplierItemPackagesTable packages={sortedPackages} selectedSupplier={selectedSupplier} />
    </Stack>
  );
}
