import { Markup } from './BracketPriceState';
import {
  MgSalesPackageClassCode,
  MgSupplierItemPackage,
  PriceCalculation,
} from '@retail/products/types';
import {
  calculateCoverageFromSalesFactor,
  calculateNewSalesFactor,
  calculateSalesCoverageFromCostAndSalesPrice,
  calculateSalesFactorFromDG,
  roundToMaxDecimalCount,
  STANDARD_VAT_FACTOR,
} from '@retail/utils';

export const calculateMarkupFromSalesPrice = (
  newSalesPrice: number,
  packageClassCode: MgSalesPackageClassCode,
  priceCalculation: PriceCalculation,
  includeVat: boolean,
  packages: MgSupplierItemPackage[],
  fPakPriceOverride?: number
): Markup => {
  const currentCostPriceInFpakUnit = priceCalculation.getCurrentCostPriceForClassCode('F-PAK');

  if (!currentCostPriceInFpakUnit) {
    throw new Error('Cannot set sales price without having a cost price');
  }

  const packageGtin = packages.find((p) => p.classCode === packageClassCode)?.gtinCode;

  if (!packageGtin) {
    throw new Error('Cannot set sales price without having a package gtin');
  }

  const bracketPackagePrice = priceCalculation.getBracketPackagePrice(packageClassCode);
  const bracketPriceInFpakUnit =
    bracketPackagePrice?.bracketMarkupPrice.getPriceForClassCode('F-PAK');

  const vatFactor = bracketPriceInFpakUnit?.price.vatFactor || STANDARD_VAT_FACTOR;

  const newSalesCoverage = calculateSalesCoverageFromCostAndSalesPrice(
    currentCostPriceInFpakUnit.price,
    newSalesPrice,
    includeVat ? vatFactor : undefined
  );
  const newSalesFactor = calculateNewSalesFactor(
    currentCostPriceInFpakUnit.price,
    newSalesPrice,
    includeVat ? vatFactor : undefined
  );

  const newSalesPriceWithoutVat = includeVat ? newSalesPrice * vatFactor : newSalesPrice;

  const fPakPriceInFpakUnit = priceCalculation
    .getBracketPackagePrice('F-PAK')
    ?.bracketMarkupPrice.getPriceForClassCode('F-PAK');
  const discount =
    packageClassCode === 'F-PAK'
      ? undefined
      : fPakPriceInFpakUnit
      ? (((fPakPriceOverride || fPakPriceInFpakUnit.price.priceWithoutVat) -
          newSalesPriceWithoutVat) /
          (fPakPriceOverride || fPakPriceInFpakUnit.price.priceWithoutVat)) *
        100
      : undefined;

  return {
    salesFactor: roundToMaxDecimalCount(newSalesFactor, 6),
    salesCoverage: roundToMaxDecimalCount(newSalesCoverage, 6),
    salesPrice: newSalesPriceWithoutVat,
    salesPriceIncVat: includeVat ? newSalesPrice : newSalesPrice * vatFactor,
    discount,
    gtin: packageGtin,
  };
};

export const calculateMarkupFromCoverage = (
  newSalesCoverage: number,
  packageClassCode: MgSalesPackageClassCode,
  priceCalculation: PriceCalculation,
  packages: MgSupplierItemPackage[],
  fPakPriceOverride?: number
): Markup => {
  const packageGtin = packages.find((p) => p.classCode === packageClassCode)?.gtinCode;
  if (!packageGtin) {
    throw new Error('Cannot set sales price without having a package gtin');
  }

  const bracketPackagePrice = priceCalculation.getBracketPackagePrice(packageClassCode);
  const bracketPriceInFpakUnit =
    bracketPackagePrice?.bracketMarkupPrice.getPriceForClassCode('F-PAK');
  const currentSalesPrice = bracketPriceInFpakUnit?.price.priceWithoutVat;
  const currentSalesPriceIncVat = bracketPriceInFpakUnit?.price.priceWithVat;
  const currentSalesFactor = bracketPackagePrice?.bracketMarkupPrice.markup?.salesFactor;

  const newSalesFactor = calculateSalesFactorFromDG(newSalesCoverage);

  const newSalesPriceWithoutVat =
    currentSalesPrice &&
    currentSalesFactor &&
    (currentSalesPrice / currentSalesFactor) * newSalesFactor;
  const newSalesPriceIncVat =
    currentSalesPriceIncVat &&
    currentSalesFactor &&
    (currentSalesPriceIncVat / currentSalesFactor) * newSalesFactor;

  const fPakPriceInFpakUnit = priceCalculation
    .getBracketPackagePrice('F-PAK')
    ?.bracketMarkupPrice.getPriceForClassCode('F-PAK');

  const discount =
    packageClassCode === 'F-PAK'
      ? undefined
      : fPakPriceInFpakUnit && newSalesPriceWithoutVat
      ? (((fPakPriceOverride || fPakPriceInFpakUnit.price.priceWithoutVat) -
          newSalesPriceWithoutVat) /
          (fPakPriceOverride || fPakPriceInFpakUnit.price.priceWithoutVat)) *
        100
      : undefined;

  return {
    salesFactor: roundToMaxDecimalCount(newSalesFactor, 6),
    salesCoverage: roundToMaxDecimalCount(newSalesCoverage, 6),
    salesPrice: newSalesPriceWithoutVat,
    salesPriceIncVat: newSalesPriceIncVat,
    discount,
    gtin: packageGtin,
  };
};

export const calculateMarkupFromDiscount = (
  newDiscount: number,
  packageClassCode: MgSalesPackageClassCode,
  priceCalculation: PriceCalculation,
  packages: MgSupplierItemPackage[],
  fPakPriceOverride?: number
): Markup => {
  const currentCostPriceInFpakUnit = priceCalculation.getCurrentCostPriceForClassCode('F-PAK');

  if (!currentCostPriceInFpakUnit) {
    throw new Error('Cannot set discount without having a cost price');
  }

  const fPakPackagePrice = priceCalculation.getBracketPackagePrice('F-PAK');
  const fPakPriceInFpakUnit = fPakPackagePrice?.bracketMarkupPrice.getPriceForClassCode('F-PAK');

  if (!fPakPriceInFpakUnit) {
    throw new Error('Cannot set discount without having a F-PAK price');
  }

  const packageGtin = packages.find((p) => p.classCode === packageClassCode)?.gtinCode;
  if (!packageGtin) {
    throw new Error('Cannot set sales price without having a package gtin');
  }

  const fPakPriceWithoutVat = fPakPriceOverride || fPakPriceInFpakUnit.price.priceWithoutVat;
  const newSalesPriceWithoutVat = fPakPriceWithoutVat * (1 - newDiscount / 100);
  const newSalesPriceIncVat = fPakPriceWithoutVat * (1 - newDiscount / 100);

  const salesFactor = newSalesPriceWithoutVat / currentCostPriceInFpakUnit.price;

  return {
    salesPrice: newSalesPriceWithoutVat,
    salesPriceIncVat: newSalesPriceIncVat,
    salesFactor: roundToMaxDecimalCount(salesFactor, 6),
    salesCoverage: roundToMaxDecimalCount(calculateCoverageFromSalesFactor(salesFactor), 6),
    discount: newDiscount,
    gtin: packageGtin,
  };
};
