import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { TextButton } from '@retail/components';
import { ContainedButton, Dialog } from '@shared/components';
import { useFormContext } from 'react-hook-form';
import { ExportSchema } from './exportSchema';
import { ExportDialogForm } from './ExportDialogForm';

interface Props {
  isLoading: boolean;
  onSubmit: (data: ExportSchema) => void;
  onUpdateDateTimePicker: (fromDate: Date | null) => Promise<void>;
  onClose: () => void;
}

export const ExportDialog = ({ isLoading, onSubmit, onClose, onUpdateDateTimePicker }: Props) => {
  const t = useAppTFunction();

  const { handleSubmit } = useFormContext<ExportSchema>();

  const handleFormSubmit = (data: ExportSchema) => {
    onSubmit(data);
    onClose();
  };

  const handleClose = () => onClose();

  return (
    <Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogTitle pb={0}>{t('export.exportFile')}</DialogTitle>

        <DialogContent>
          <ExportDialogForm onUpdateDateTimePicker={onUpdateDateTimePicker} />
        </DialogContent>

        <DialogActions sx={{ mt: 3 }}>
          <TextButton onClick={onClose} disabled={isLoading}>
            {t('common.cancel')}
          </TextButton>

          <ContainedButton color="secondary" loading={isLoading} type="submit">
            {t('export.download')}
          </ContainedButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
