import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { MgPdmResources } from '../types/MgPdmResources';

export const usePdmTranslation = () => useMessagesTranslation<MgPdmResources>();

export type MgPdmMessageKey = MessagePaths<MgPdmResources>;

export const useMgPdmTFunction = () => usePdmTranslation().t;

export type MgPdmTFunction = ReturnType<typeof useMgPdmTFunction>;
