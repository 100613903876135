import { PriceStatus } from '@retail/calculus/types';
import { VatPriceDTO } from '../../dto';

export class VatPrice {
  priceWithVat: number;
  priceWithoutVat: number;
  vatFactor: number;
  currency: string;
  status: PriceStatus;

  constructor(dto: VatPriceDTO) {
    this.priceWithVat = dto.priceWithVat;
    this.priceWithoutVat = dto.priceWithoutVat;
    this.vatFactor = dto.vatFactor;
    this.currency = dto.currency;
    this.status = dto.status;
  }

  static fromDto(dto: VatPriceDTO): VatPrice {
    return new VatPrice(dto);
  }
}
