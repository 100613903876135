import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher, PPSFetchError, RequestContext, RequestMethods } from './fetcher';

export interface UseMutateFetchOptions<URLVariables> {
  url: string | ((variables: URLVariables | undefined) => string);
  method: RequestMethods;
  token?: string;
  context?: RequestContext;
  headers?: HeadersInit;
}

interface RejectedProps<URLVariables, ResultT> {
  onRejected?: (
    error: any,
    retryRequest: (retryProps: UseMutateFetchOptions<URLVariables>) => Promise<ResultT>
  ) => any;
}

interface VariablesT<TBody, TURLVariables> {
  body?: TBody;
  urlVariables?: TURLVariables;
}

export type UseMutateFetchProps<ResultT, BodyT, URLVariables> = (
  | Omit<
      UseMutationOptions<ResultT, PPSFetchError, VariablesT<BodyT, URLVariables> | undefined>,
      'mutationFn' | 'context'
    >
  | undefined
) &
  UseMutateFetchOptions<URLVariables> &
  RejectedProps<URLVariables, ResultT>;

export function useMutateFetch<ResultT, BodyT = unknown, URLVariables = any>({
  url,
  method,
  context,
  token,
  headers,
  onRejected,
  ...mutationOptions
}: UseMutateFetchProps<ResultT, BodyT, URLVariables>) {
  const fetch = async (fetchVariables: VariablesT<BodyT, URLVariables> = {}) =>
    fetcher<ResultT>({
      url: typeof url === 'function' ? url(fetchVariables?.urlVariables) : url,
      method,
      token,
      body: fetchVariables?.body ? JSON.stringify(fetchVariables?.body) : undefined,
      context,
      headers,
    }).catch((error) => {
      if (onRejected) {
        return onRejected(error, (retryProps) => {
          return fetcher<ResultT>({
            url:
              typeof retryProps.url === 'function'
                ? retryProps.url(fetchVariables?.urlVariables)
                : retryProps.url,
            method: retryProps.method,
            token: retryProps.token,
            body: fetchVariables?.body ? JSON.stringify(fetchVariables?.body) : undefined,
            context: retryProps.context,
            headers: retryProps.headers,
          });
        });
      } else {
        throw error;
      }
    });

  return useMutation<ResultT, PPSFetchError, VariablesT<BodyT, URLVariables>>(
    fetch,
    mutationOptions
  );
}
