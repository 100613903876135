import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { ContainedButton, Dialog } from '@shared/components';
import { useCallback, useEffect, useMemo } from 'react';
import { useFetchActiveMarkupCondition } from '@retail/markup/data-access';
import { useFormContext } from 'react-hook-form';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import {
  CreateMarkupForm,
  CreateMarkupSchema,
  itemPackageLevelValue,
} from '@retail/markup/components';
import { useLevelSelect } from '@retail/calculus/context';
import { MarkupCondition } from '@retail/calculus/types';
import { Supplier } from '@retail/suppliers/types';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { MgItem } from '@retail/products/types';
import { useMarkupActions } from '@retail/markup/context';

interface Props {
  open: boolean;
  onClose: () => void;
  supplier: Supplier;
  includeUnit?: boolean;
}

function CreateMarkupConditionDialog({ supplier, open, onClose, includeUnit }: Props) {
  const t = useAppTFunction();

  const {
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useFormContext<CreateMarkupSchema>();
  const overGroup = watch('overGroup');
  const mainGroup = watch('mainGroup');
  const productGroup = watch('productGroup');
  const discountGroup = watch('discountGroup');
  const item = watch('item');
  const itemSearch = watch('itemSearch');
  const level = watch('level');
  const gtin = watch('gtin');

  const { productGroups, mgSupplierItems, discountGroups, isLoadingFetchItems, mgItemDto } =
    useLevelSelect({
      supplierId: supplier.mgSupplierId,
      selectedProductGroup: level === 'productGroup',
      selectedDiscountGroup: level === 'discountGroup',
      itemSearch,
      selectedMgItemNumber: includeUnit ? item?.mgItemNumber : undefined,
      onItemSearchSuccess: (results) => {
        if (!results.result.length) {
          setError('item', { message: t('calculus.error.itemSearch.noResults') });
        }
      },
      onItemSearchError: (err) => {
        console.error(err);
        setError('item', { message: t('calculus.error.itemSearch.generic') });
      },
    });

  const { baseAssortmentId } = useDepartmentAssortments();

  const {
    createSupplierLevelMarkupCondition,
    createProductGroupLevelMarkupCondition,
    createDiscountGroupLevelMarkupCondition,
    createMgItemLevelMarkupCondition,
  } = useMarkupActions({
    mgSupplierId: supplier.mgSupplierId,
  });

  useEffect(() => {
    clearErrors('item');
  }, [clearErrors, itemSearch]);

  const { data: activeMarkupConditionDto, remove: resetActiveMarkupCondition } =
    useFetchActiveMarkupCondition(
      {
        assortmentId: baseAssortmentId,
        mgSupplierId: supplier.mgSupplierId,
        groupNumber: productGroup?.groupNumber ?? mainGroup?.groupNumber ?? overGroup?.groupNumber,
        mgItemNumber: item?.mgItemNumber,
        gtinCode: gtin && gtin !== itemPackageLevelValue ? Number(gtin) : undefined,
      },
      { suspense: false, enabled: open }
    );

  useEffect(() => {
    resetActiveMarkupCondition();
  }, [level, resetActiveMarkupCondition]);

  const activeMarkupCondition = useMemo(
    () =>
      activeMarkupConditionDto?.active
        ? MarkupCondition.fromDto(activeMarkupConditionDto)
        : undefined,
    [activeMarkupConditionDto]
  );

  const supplierItemPackages = useMemo(() => {
    if (!mgItemDto) return undefined;
    const mgItem = MgItem.fromDto(mgItemDto);

    const supplierItem = mgItem.supplierItems.find(
      ({ mgSupplierId }) => mgSupplierId === supplier.mgSupplierId
    );

    return supplierItem?.getSalesPackagesOrdered().filter(({ type }) => type !== 'PRIMARY');
  }, [mgItemDto, supplier.mgSupplierId]);

  const onActivate = useCallback(
    ({ level, fromDate, markupName, salesFactor, gtin }: CreateMarkupSchema) => {
      if (level === 'supplier')
        createSupplierLevelMarkupCondition({
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: Number(salesFactor),
        });
      else if (level === 'productGroup')
        createProductGroupLevelMarkupCondition({
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: Number(salesFactor),
          groupNumber:
            productGroup?.groupNumber ?? mainGroup?.groupNumber ?? overGroup?.groupNumber,
        });
      else if (level === 'discountGroup')
        createDiscountGroupLevelMarkupCondition({
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: Number(salesFactor),
          groupCode: discountGroup.code,
        });
      else if (level === 'item' && item?.mgItemNumber)
        createMgItemLevelMarkupCondition({
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: Number(salesFactor),
          mgItemNumber: item.mgItemNumber,
          gtin: gtin && gtin !== itemPackageLevelValue ? Number(gtin) : undefined,
        });

      reset();
      onClose();
    },
    [
      createSupplierLevelMarkupCondition,
      createProductGroupLevelMarkupCondition,
      productGroup?.groupNumber,
      mainGroup?.groupNumber,
      overGroup?.groupNumber,
      discountGroup?.code,
      item?.mgItemNumber,
      createDiscountGroupLevelMarkupCondition,
      createMgItemLevelMarkupCondition,
      reset,
      onClose,
    ]
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>{t('markup.create.newMarkup')}</DialogTitle>

      <DialogContent>
        <CreateMarkupForm
          productGroups={productGroups}
          discountGroups={discountGroups}
          activeCondition={activeMarkupCondition}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          supplierName={supplier.name}
          includeUnit={includeUnit}
          packages={supplierItemPackages}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', mt: 1 }}>
        <ContainedButton
          disabled={Object.keys(errors).length > 0}
          onClick={handleSubmit(onActivate)}
        >
          {t('markup.actions.activate')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(CreateMarkupConditionDialog);
