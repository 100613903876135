import { conditionDatesSchema, levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';

export const activateAgreementSchema = (t: CalculusTFunction) =>
  levelSelectSchema(t)
    .concat(conditionDatesSchema(t))
    .concat(
      yup.object({
        agreementId: yup.string().default('').required(),
        priceType: yup.string().default('').optional(),
      })
    );

export type ActivateAgreementSchema = yup.InferType<ReturnType<typeof activateAgreementSchema>>;
