import { alpha, Theme } from '@mui/material';
import themeV2 from '../dap';
import { TabsClass } from '../dap/classes';
import {
  dataGridBoldClass,
  dataGridCellHoverClass,
  dataGridCellNoFocusOutlineClass,
  dataGridHorizontalPaddingSmallClass,
  dataGridHorizontalPaddingXSClass,
  dataGridRowHoverClass,
  dataGridSelectedRowClass,
} from './styleConstants';

export const componentsStyles: Theme['components'] = {
  ...themeV2.components,
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        padding: theme.spacing(4),
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[4],
        position: 'relative',
        overflowY: 'unset',
        gap: theme.spacing(3),
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme: { typography } }) => ({
        ...typography.h4,
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: () => ({ padding: 0 }),
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme: { palette, spacing, typography } }) => ({
        borderRadius: 0,
        border: 0,

        '.MuiDataGrid-columnHeaderTitle': {
          ...typography.body1,
          fontWeight: 600,
        },

        '.MuiDataGrid-columnHeader': {
          ...typography.body1,
          fontWeight: 500,
          flexWrap: 'wrap',
          backgroundColor: palette.common.white,
        },

        // Scrollbar
        '.MuiDataGrid-scrollbarFiller': {
          backgroundColor: '#ffffff !important',
        },

        // Row
        '.MuiDataGrid-row': {
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
        },

        // Containers
        '--DataGrid-containerBackground': palette.common.white,

        '.MuiDataGrid-topContainer': {
          backgroundColor: '#ffffff !important',
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
        },

        [`&.${dataGridHorizontalPaddingXSClass}`]: {
          '& .MuiDataGrid-topContainer, & .MuiDataGrid-row': {
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
          },
        },
        [`&.${dataGridHorizontalPaddingSmallClass}`]: {
          '& .MuiDataGrid-topContainer, & .MuiDataGrid-row': {
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
          },
        },

        '.MuiDataGrid-footerContainer': {
          marginTop: 4,
          marginBottom: 2,
          justifyContent: 'center',
        },

        // Cell
        '.MuiDataGrid-cell': {
          ...typography.body2,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          color: palette.action.active,
          fontWeight: 400,
          whiteSpace: 'normal !important',

          [`&.${dataGridBoldClass}`]: {
            overflow: 'visible',
            fontWeight: 500,
            color: palette.text.primary,
          },
          [`&.${dataGridCellNoFocusOutlineClass}:focus`]: {
            outline: 'none',
          },

          ':focus-within': {
            outline: 'transparent',
          },
        },

        '.MuiDataGrid-cell--pinnedRight': {
          backgroundColor: `${palette.common.white} !important`,
          paddingRight: 0,
          paddingLeft: 0,
        },
        '.MuiDataGrid-filler--pinnedRight': {
          backgroundColor: `${palette.common.white} !important`,
        },
        '.MuiDataGrid-cell--pinnedLeft': {
          backgroundColor: `${palette.common.white} !important`,
          paddingRight: 0,
        },

        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
      }),

      row: ({ theme: { palette, shadows } }) => ({
        ':nth-of-type(odd)': {
          backgroundColor: palette.grey[50],
          '&:hover': { background: palette.background.secondary },
        },
        ':nth-of-type(even)': {
          backgroundColor: palette.common.white,
          '&:hover': { background: palette.background.secondary },
        },
        ':last-of-type': {
          marginBottom: '2px',
          borderBottom: `1px solid ${palette.divider}`,
        },
        [`&.${dataGridRowHoverClass}`]: {
          '&:hover': {
            background: palette.background.secondary,
            boxShadow: shadows[2],
            zIndex: 2,
          },
        },
        [`&.${dataGridSelectedRowClass}`]: {
          background: palette.background.secondary,
          boxShadow: shadows[2],
          zIndex: 2,
        },
      }),

      cell: () => ({
        [`&.${dataGridCellHoverClass}`]: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      }),
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
      },
    },
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderWidth: ownerState.size === 'large' ? 2 : 1.5,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius * 2,
        textTransform: 'none',
        strokeWidth: 2,
        fontWeight: 500,

        '&:hover': {
          borderWidth: ownerState.size === 'large' ? 2 : 1.5,
          borderStyle: 'solid',
          boxShadow: theme.shadows[1],
          transition: 'none', // Remove for smoother border transition
          borderColor: theme.palette.grey[900],
          color: theme.palette.common.white,
          background: theme.palette.grey[900],
        },
        '&:focus-visible': {
          boxShadow: theme.shadows[1],
          outline: `1px solid ${theme.palette.grey[900]}`,
          outlineOffset: 3,
        },
        '&:disabled': {
          borderWidth: ownerState.size === 'large' ? 2 : 1.5,
          borderStyle: 'solid',
          background: theme.palette.grey.A100,
          color: theme.palette.text.disabled,
          borderColor: theme.palette.grey.A100,
        },
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.typography.body2,
        fontWeight: 500,
        padding: '9px 16px',
      }),
      sizeLarge: ({ theme }) => ({
        ...theme.typography.body1,
        fontWeight: 500,
        padding: '10px 38px',
      }),
      containedPrimary: ({ theme }) => ({
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        transition: 'none', // Remove for smoother border transition
      }),
      containedSecondary: ({ theme }) => ({
        color: theme.palette.text.primary,
        background: theme.palette.gradient.secondary,
        borderColor: theme.palette.secondary.main,
        transition: 'none', // Remove for smoother border transition
      }),
      containedWarning: ({ theme }) => ({
        color: theme.palette.text.primary,
        background: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
      }),
      contained: {
        border: 'none',
        '&.MuiButton-sizeSmall': {
          padding: '10px 20px',
          ':hover, :disabled': {
            border: 0,
            padding: '10px 20px',
          },
        },
        '&.MuiButton-sizeLarge': {
          padding: '12px 40px',
          ':hover, :disabled': {
            border: 0,
            padding: '12px 40px',
          },
        },
      },
      outlined: {
        '&.MuiButton-sizeSmall': {
          padding: '8.5px 18.5px',
          ':hover, :disabled': {
            padding: '8.5px 18.5px',
          },
        },
      },
      outlinedPrimary: ({ theme }) => ({
        color: theme.palette.primary.main,
        background: `linear-gradient(${theme.palette.common.white}, ${theme.palette.common.white}) padding-box, ${theme.palette.primary.main} border-box`,
        transition: 'none', // Remove for smoother border transition,
      }),
      outlinedSecondary: ({ theme }) => ({
        color: theme.palette.text.secondary,
        background: `linear-gradient(${theme.palette.common.white}, ${theme.palette.common.white}) padding-box, ${theme.palette.gradient.secondary} border-box`,
        transition: 'none', // Remove for smoother border transition
      }),
      outlinedWarning: ({ theme }) => ({
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        borderColor: theme.palette.warning.main,
      }),
      text: () => ({
        borderColor: 'transparent',
        transition: 'none', // Remove for smoother border transition
      }),
    },
    variants: [
      {
        props: { color: 'grey', variant: 'outlined' },
        style: ({ theme }) => ({
          color: theme.palette.text.secondary,
          background: theme.palette.common.white,
          borderColor: theme.palette.grey.A100,
        }),
      },
      {
        props: { color: 'grey', variant: 'contained' },
        style: ({ theme }) => ({
          color: theme.palette.text.secondary,
          background: theme.palette.common.white,
          borderColor: theme.palette.common.white,
        }),
      },
    ],
  },

  MuiToggleButton: {
    defaultProps: {
      disableFocusRipple: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderWidth: ownerState.size === 'large' ? 2 : 1,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius * 3,
        textTransform: 'none',
        background: theme.palette.common.white,
        '&.Mui-selected': {
          borderColor: theme.palette.primary.main,
          background: theme.palette.common.white,
        },
        '&:hover, &.Mui-selected:hover': {
          borderWidth: ownerState.size === 'large' ? 2 : 1,
          borderStyle: 'solid',
          boxShadow: theme.shadows[1],
          transition: 'none', // Remove for smoother border transition
          borderColor: theme.palette.grey[900],
          color: theme.palette.common.white,
          background: theme.palette.grey[900],
        },
        '&:focus-visible': {
          boxShadow: theme.shadows[1],
        },
        '&:disabled': {
          borderWidth: ownerState.size === 'large' ? 2 : 1,
          borderStyle: 'solid',
          background: theme.palette.grey.A100,
          color: theme.palette.text.disabled,
          borderColor: theme.palette.grey.A100,
        },
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.typography.body2,
        fontWeight: 500,
        padding: '7.5px',
      }),
      sizeLarge: ({ theme }) => ({
        ...theme.typography.body1,
        fontWeight: 500,
        padding: '10px',
      }),

      selected: ({ theme }) => ({
        borderColor: theme.palette.primary.main,
        background: theme.palette.common.white,
      }),
    },
    // css state classes didnt work for primary, secondary, standard
    variants: [
      {
        props: { color: 'primary' },
        style: ({ theme: { palette } }) => ({
          color: palette.primary.main,
          '&.Mui-selected': {
            borderColor: palette.primary.main,
          },
        }),
      },
      {
        props: { color: 'secondary' },
        style: ({ theme: { palette } }) => ({
          color: palette.secondary.main,
          '&.Mui-selected': {
            borderColor: palette.secondary.main,
          },
        }),
      },
      {
        props: { color: 'warning' },
        style: ({ theme: { palette } }) => ({
          color: palette.warning.main,
          '&.Mui-selected': {
            borderColor: palette.warning.main,
          },
        }),
      },
      {
        props: { color: 'success' },
        style: ({ theme: { palette } }) => ({
          color: palette.success.main,
          '&.Mui-selected': {
            borderColor: palette.success.main,
          },
        }),
      },
    ],
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: ({ theme: { palette, typography, shape, spacing } }) => ({
        color: palette.text.primary,
        ...typography.body2,
        fontWeight: 600,
        borderRadius: shape.borderRadius,
        '&.MuiButtonBase-root': {
          '& > :first-of-type': {
            outline: `2px solid ${palette.divider}`,
            borderRadius: shape.borderRadius * 5,
            margin: spacing(1.5),
          },
        },
      }),
      page: ({ theme: { palette } }) => ({
        '&.Mui-selected': {
          background: palette.primary.main,
          color: palette.common.white,
        },
      }),
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: () => ({
        fontWeight: `${400} !important`,
      }),
      shrink: ({ ownerState }) => ({
        ...(ownerState.shrink && {
          fontSize: '16px !important',
        }),
      }),
    },
  },

  MuiSwitch: {
    styleOverrides: {
      root: ({ theme: { palette, shape, transitions } }) => ({
        width: 45,
        height: 25,
        padding: 0,

        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2.5,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(20px)',
            '& + .MuiSwitch-track': {
              backgroundColor: palette.primary.main,
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: palette.grey[100],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          color: palette.common.white,
          width: 20,
          height: 20,
        },
        '& .MuiSwitch-track': {
          borderRadius: shape.borderRadius * 4,
          backgroundColor: palette.grey[200],
          opacity: 1,
          transition: transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }),
    },
  },
  MuiMenu: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
    styleOverrides: {
      root: ({ theme: { spacing, palette } }) => ({
        '& .MuiPaper-root': {
          padding: spacing(1),
          border: `2px solid ${palette.background.default}`,
          borderRadius: spacing(2),
        },
        '& .MuiMenu-list': {
          padding: 0,
          '& .MuiListSubheader-root:first-of-type': {
            lineHeight: 1,
            marginBottom: spacing(2),
          },
          '& .MuiDivider-root': {
            marginTop: spacing(2),
            marginBottom: spacing(2),
            border: `1px solid ${palette.divider}`,
          },
        },
      }),
    },
  },

  MuiMenuItem: {
    defaultProps: {},
    styleOverrides: {
      root: ({ ownerState, theme: { spacing, palette, shape } }) => ({
        gap: spacing(3),
        justifyContent: 'space-between',
        padding: `${spacing(1.5)} ${spacing(2.5)}`,
        backgroundColor: palette.background.secondary,
        border: `1px solid ${palette.divider}`,
        ':hover': {
          backgroundColor: palette.divider,
        },
        ':focus': {
          backgroundColor: palette.divider,
        },
        ':mouseOver': {
          backgroundColor: palette.divider,
        },
        ':where(:not(.MuiMenuItem-root) + .MuiMenuItem-root), :first-of-type': {
          borderTopLeftRadius: shape.borderRadius * 3,
          borderTopRightRadius: shape.borderRadius * 3,
        },
        ':has(+ :not(.MuiMenuItem-root)), :last-of-type': {
          borderBottomLeftRadius: shape.borderRadius * 3,
          borderBottomRightRadius: shape.borderRadius * 3,
        },
        ...(ownerState.color === 'warning' && {
          backgroundColor: palette.warning.main,
          ':hover': {
            backgroundColor: palette.warning.dark,
          },
          ':focus': {
            backgroundColor: palette.warning.dark,
          },
        }),
        '& .MuiSvgIcon-root': {
          fill: palette.primary.main,
        },
      }),
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: ({ ownerState, theme: { typography, palette } }) => ({
        minWidth: 240,

        '& .MuiFormLabel-root': {
          ...(ownerState.size === 'medium' ? typography.body1 : typography.body2),
          fontWeight: 600,
        },
        '& .MuiInputBase-input': {
          height: '20px',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused': {
            background: palette.common.white,
          },
        },
        '& .MuiOutlinedInput-input': {
          '&:-webkit-autofill': {
            WebkitBoxShadow: ' 0 0 0 30px white inset !important;',
          },
        },
      }),
    },
  },

  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginRight: theme.spacing(1),
        '& .MuiTabs-indicator': {
          display: 'none',
        },

        [`&.${TabsClass.underlined}`]: {
          width: '100%',
          minHeight: 0,
          marginRight: 0,
          borderBottom: `1px solid ${theme.palette.grey.A100}`,

          '& .MuiTab-root': {
            minHeight: '10px',
            paddingTop: 0,
            paddingBottom: 12,
            borderRadius: 0,
          },

          '& .MuiTabs-flexContainer': {
            justifyContent: 'start',
            gap: theme.spacing(3),
          },

          '& .MuiTabs-indicator': {
            display: 'block',
            background: theme.palette.primary.main,
            height: '4px',
            borderRadius: theme.shape.borderRadius * 5,
          },

          '& .MuiTabScrollButton-horizontal': {
            height: 20,
            width: 20,
          },
        },
      }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: `1px solid ${theme.palette.grey.A100}`,
        backgroundColor: alpha(theme.palette.background.paper, 0.5),
        borderTopLeftRadius: theme.shape.borderRadius * 2,
        borderTopRightRadius: theme.shape.borderRadius * 2,
        textTransform: 'none',
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.text.secondary,
        padding: theme.spacing(1, 6),
        minHeight: theme.spacing(6),

        [`:not(.${TabsClass.underlined})`]: {
          backgroundColor: theme.palette.grey[50],
        },

        [`&.Mui-selected:not(.${TabsClass.underlined})`]: {
          boxShadow: '6px 8px 8px rgba(15, 15, 15, 0.2)',
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.secondary,
          borderBottom: 0,
          fontWeight: 600,
        },

        [`&.${TabsClass.underlined}`]: {
          border: 0,
          padding: 0,
          spacing: theme.spacing(4),

          '&.Mui-selected': {
            color: theme.palette.text.primary,
            fontWeight: 700,
          },
        },
      }),
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&::before': { height: 0 },
        '&.active': {
          '> .MuiAccordionSummary-root': {
            color: theme.palette.primary.main,
          },
        },
      }),
    },
    defaultProps: {
      disableGutters: true,
      TransitionProps: { unmountOnExit: true, mountOnEnter: true },
      elevation: 0,
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({ theme: { shape, spacing, palette } }) => ({
        padding: spacing(1),
        backgroundColor: palette.primary.A200,
        borderRadius: shape.borderRadius * 2,
        gap: spacing(1),
      }),
      grouped: ({ theme: { spacing, palette, shape, shadows } }) => ({
        color: palette.text.secondary,
        background: 'transparent',
        border: 'none !important',
        fontWeight: 600,

        '&.MuiToggleButtonGroup-grouped': {
          borderRadius: shape.borderRadius,
          '&:hover,:focus': {
            boxShadow: shadows[1],
            color: palette.common.white,
            background: palette.grey[900],
          },
        },
        '&.Mui-selected': {
          '&.MuiToggleButton-primary, &.MuiToggleButton-standard': {
            color: palette.common.white,
            background: palette.primary.main,
          },
          '&:hover': {
            boxShadow: shadows[1],
            color: palette.common.white,
            background: palette.grey[900],
          },
        },
        '&.MuiToggleButton-sizeSmall': {
          padding: `${spacing(0.5)} ${spacing(1)}`,
        },
        '&.MuiToggleButton-sizeMedium': {
          padding: `${spacing(1)} ${spacing(1.5)}`,
        },
      }),
      middleButton: ({ theme: { palette, spacing } }) => ({
        '&::after': {
          content: '""',
          display: 'block',
          width: '0.5px',
          backgroundColor: palette.grey[100],
          position: 'absolute',
          height: '24px',
          right: `-${spacing(0.5)}`,
          top: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        },
      }),
      firstButton: ({ theme: { palette, spacing } }) => ({
        '&::after': {
          content: '""',
          display: 'block',
          width: '0.5px',
          backgroundColor: palette.grey[100],
          position: 'absolute',
          height: '24px',
          right: `-${spacing(0.5)}`,
          top: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        },
      }),
    },
  },
};
