import { useFetchPriceList } from '@retail/purchase-agreements/data-access';
import { ItemAgreement, PriceListPageResult } from '@retail/purchase-agreements/types';
import { usePagination } from '@shared/hooks';

interface Props {
  initPageSize: number;
  productGroupId?: string;
  mgItemNumber?: number;
  current?: boolean;
  query?: string;
  agreementId: number;
}

export const usePriceList = ({
  productGroupId,
  mgItemNumber,
  current,
  agreementId,
  query,
}: Props) => {
  const { pageSize, page, setPageSize, setPage } = usePagination({ initPageSize: 400 });

  const { data: priceListWithPaginationDTO } = useFetchPriceList(
    {
      agreementId: agreementId,
      groupNumbers: productGroupId ? [productGroupId] : undefined,
      mgItemNumbers: mgItemNumber ? [mgItemNumber] : undefined,
      current,
      page,
      size: pageSize,
      query,
    },
    {
      suspense: true,
    }
  );

  const items = priceListWithPaginationDTO!.result.map(ItemAgreement.fromDto);

  return {
    result: {
      totalItems: priceListWithPaginationDTO?.totalCount,
      items: items,
      page,
      pageSize,
    } as PriceListPageResult,
    setPage,
    setPageSize,
  };
};
