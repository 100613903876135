import { useCallback, useMemo, useState } from 'react';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { MonitoringAssortmentContainer } from '@retail/monitoring-assortment/containers';
import {
  NotFoundError,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
  Breadcrumbs,
} from '@retail/components';
import { useSelectedOrgUnit, useUserBrands } from '@retail/app/stores/selected-context';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import { Chip, OutlinedButton, Paper } from '@shared/components';
import { AssortmentPicker, CentralAssortmentLogo } from '@retail/monitoring-assortment/components';
import { Box, Stack, useTheme } from '@mui/material';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { MgItemDialogContainer } from '@retail/products/containers';

export function MonitoringAssortmentPage() {
  const t = useAppTFunction();
  const { monitoringAssortments } = useDepartmentAssortments();

  const [itemToView, setItemToView] = useState<number | undefined>(undefined);

  const { palette } = useTheme();
  const navigate = useNavigate();

  const { selectedBrandLogos } = useUserBrands();
  const { selectedOrgUnitId } = useSelectedOrgUnit();
  const { assortmentId } = useParams<{ assortmentId: string }>();

  const selectedAssortment = useMemo(
    () => monitoringAssortments.find(({ id }) => id === Number(assortmentId)),
    [assortmentId, monitoringAssortments]
  );

  const otherAssortments = useMemo(
    () => monitoringAssortments.filter((assortment) => assortment.id !== Number(assortmentId)),
    [monitoringAssortments, assortmentId]
  );

  const selectAssortment = useCallback(
    (assortment: MonitoringAssortment | null) => {
      if (assortment) {
        navigate(
          mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath({
            assortmentId: assortment.id,
          })
        );
      }
    },
    [navigate]
  );

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <Paper
        paddingY="dense"
        sx={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" ml={1}>
          <Stack direction="row" alignItems="center">
            <Breadcrumbs
              items={[
                {
                  label: t('assortment.marketInsight'),
                  href: mgPrisRoutes.marketInsight.getFullLinkPath(),
                },
                { label: selectedAssortment?.title || assortmentId + '' },
              ]}
            />
            <Stack direction="row" gap={1.5} ml={1}>
              {selectedOrgUnitId !== selectedAssortment?.owner?.id && selectedAssortment && (
                <CentralAssortmentLogo selectedBrandLogo={selectedBrandLogos.circularLogo} />
              )}
              <Chip
                label={t('assortment.items', { count: selectedAssortment?.size })}
                backgroundColor={palette.secondary[400]}
                sx={{ cursor: 'inherit' }}
              />
            </Stack>
          </Stack>

          {selectedAssortment && (
            <Stack gap={1.5} flexDirection="row" alignItems="center">
              {!!otherAssortments.length && (
                <AssortmentPicker
                  assortments={otherAssortments}
                  selectedAssortment={null}
                  selectAssortment={selectAssortment}
                  width={260}
                  alternativeLabel={t('assortment.changeAssortment')}
                />
              )}

              <OutlinedButton
                component={Link}
                to={mgPrisRoutes.marketInsight.competitors.fullRoutePath}
                sx={{ whiteSpace: 'nowrap' }}
                size="small"
              >
                {t('assortment.competitors.heading')}
              </OutlinedButton>
            </Stack>
          )}
        </Box>

        <SuspenseWithSentryErrorBoundary>
          {selectedAssortment ? (
            <MonitoringAssortmentContainer
              selectedAssortment={selectedAssortment}
              redirectOnDelete={mgPrisRoutes.marketInsight.assortments.getFullLinkPath()}
              assortmentQuery="department-assortments"
              setItemToView={setItemToView}
            />
          ) : (
            <NotFoundError
              title={t('assortment.mgAssortment.notFound.title')}
              subtitle={t('assortment.mgAssortment.notFound.subtitle', { assortmentId })}
              buttonText={t('assortment.mgAssortment.notFound.goBack')}
              navigateTo={mgPrisRoutes.marketInsight.fullRoutePath}
            />
          )}
        </SuspenseWithSentryErrorBoundary>
        {itemToView && (
          <MgItemDialogContainer
            open={!!itemToView}
            itemNumber={itemToView}
            onClose={() => setItemToView(undefined)}
            showMonitorItem={false}
          />
        )}
      </Paper>
    </PageLayout>
  );
}
