import { useQueryClient } from '@tanstack/react-query';

export const useRefetchDraftDrawer = () => {
  const queryClient = useQueryClient();

  const invalidateAssortmentDraft = () => {
    queryClient.invalidateQueries({
      queryKey: ['assortmentItems'],
    });
    queryClient.invalidateQueries({ queryKey: ['mg-item'] });
  };

  const invalidateAgreements = () =>
    queryClient.invalidateQueries({ queryKey: ['purchase-condition'] });

  const invalidateFreight = () => queryClient.invalidateQueries({ queryKey: ['freights'] });
  const invalidateMarkup = () => queryClient.invalidateQueries({ queryKey: ['markups'] });

  const invalidateDraftDrawer = () => {
    invalidateAssortmentDraft();
    invalidateAgreements();
    invalidateFreight();
    invalidateMarkup();
  };

  return {
    invalidateDraftDrawer,
    invalidateAssortmentDraft,
    invalidateAgreements,
    invalidateFreight,
    invalidateMarkup,
  };
};
