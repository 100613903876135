import { useMutateCreateOrUpdateConditions } from '@retail/calculus/data-access';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useToast } from '@retail/hooks';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { ActivateCondition, DeactivateCondition } from '@retail/purchase-agreements/types';
import { getDate } from '@retail/utils';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  mgSupplierId: number;
}

export function usePurchaseActions({ mgSupplierId }: Props) {
  const t = useCalculusTFunction();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();

  const invalidateAgreements = () => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['purchase-agreements'] });
    queryClient.invalidateQueries({ queryKey: ['agreement-prices'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  };

  const { mutateAsync: mutatePostPurchaseChanges, isLoading: isLoadingPostChanges } =
    useMutateCreateOrUpdateConditions({
      assortmentId: draftAssortmentId,
      departmentId: departmentId,
    });

  const deactivatePurchaseCondition = (deactivateCondition: DeactivateCondition) => {
    mutatePostPurchaseChanges({
      body: {
        purchaseConditions: [
          {
            mgSupplierId,
            mgSupplierConditions: deactivateCondition.supplierLevel
              ? [
                  {
                    agreementId: null,
                    validFrom: getDate(),
                  },
                ]
              : null,
            productGroupConditions: deactivateCondition.groupNumber
              ? [
                  {
                    agreementId: null,
                    validFrom: getDate(),
                    groupNumber: deactivateCondition.groupNumber,
                  },
                ]
              : [],
            mgSupplierItemConditions: deactivateCondition.mgItemNumber
              ? [
                  {
                    agreementId: null,
                    validFrom: getDate(),
                    mgItemNumber: deactivateCondition.mgItemNumber,
                    priceType: null,
                  },
                ]
              : [],
          },
        ],
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionDeleteInDrawer'));
        invalidateAgreements();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('calculus.error.generic'));
      });
  };

  const activateOrChangeCondition = (activateCondition: ActivateCondition) => {
    mutatePostPurchaseChanges({
      body: {
        purchaseConditions: [
          {
            mgSupplierId,
            mgSupplierConditions: activateCondition.supplierLevel
              ? [
                  {
                    agreementId: activateCondition.agreementId,
                    validFrom: getDate(activateCondition.fromDate),
                  },
                ]
              : null,
            productGroupConditions: activateCondition.groupNumber
              ? [
                  {
                    agreementId: activateCondition.agreementId,
                    validFrom: getDate(activateCondition.fromDate),
                    groupNumber: activateCondition.groupNumber,
                  },
                ]
              : [],
            mgSupplierItemConditions: activateCondition.mgItemNumber
              ? [
                  {
                    agreementId: activateCondition.agreementId,
                    validFrom: getDate(activateCondition.fromDate),
                    mgItemNumber: activateCondition.mgItemNumber,
                    priceType: activateCondition.priceType ?? null,
                  },
                ]
              : [],
          },
        ],
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionCreateInDrawer'));
        invalidateAgreements();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('calculus.error.generic'));
      });
  };

  return {
    deactivatePurchaseCondition,
    activateOrChangeCondition,
    isLoadingPostChanges,
  };
}
