import { Menu, MenuItem } from '@mui/material';
import { Actions } from '@retail/calculus/types';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { MarkupCondition, MarkupTemplate } from '@retail/markup/types';

interface Props {
  open: boolean;
  id: string;
  anchorEl: HTMLElement | null;
  markupTemplateClicked?: MarkupTemplate;
  activeMarkupCondition: MarkupCondition;
  setAction: (action: Actions) => void;
  onClose: () => void;
}

export const MarkupTemplatesTableActions = ({
  id,
  open,
  anchorEl,
  markupTemplateClicked,
  activeMarkupCondition,
  onClose,
  setAction,
}: Props) => {
  const t = useMarkupTFunction();

  const isLocal = markupTemplateClicked?.source === 'MPE';
  const isCondition = activeMarkupCondition.markupTemplateId === markupTemplateClicked?.templateId;

  return (
    <Menu open={open} id={id} anchorEl={anchorEl} onClose={onClose}>
      {isCondition ? (
        <MenuItem
          onClick={() => {
            setAction('delete');
            onClose();
          }}
        >
          {t('markup.actions.deactivate')}
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            setAction('activate');
            onClose();
          }}
        >
          {t('markup.actions.activate')}
        </MenuItem>
      )}

      {isLocal && (
        <MenuItem
          onClick={() => {
            setAction('edit');
            onClose();
          }}
        >
          {t('markup.actions.edit')}
        </MenuItem>
      )}

      {isLocal && activeMarkupCondition.markupTemplateId !== markupTemplateClicked?.templateId && (
        <MenuItem
          onClick={() => {
            setAction('delete');
            onClose();
          }}
        >
          {t('markup.actions.delete')}
        </MenuItem>
      )}
    </Menu>
  );
};
