import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useMutateMPEFetch } from '@retail/app/hooks';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

interface PostProps extends QueryOptions {
  assortmentId: number;
}

export function useMutateUpdateItemsToAssortment({ assortmentId, ...queryOptions }: PostProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, ItemsDTO[]>({
    method: 'POST',
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.items.getFullLinkPath({ assortmentId }),
    }),
    token,
    ...queryOptions,
  });
}
