import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useAuthContext } from './AuthContext';
import { Brand } from '@retail/auth/types';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

export function useFetchMyBrands(queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useFetch<Brand[]>({
    method: 'GET',
    queryKey: ['my-brands', token],
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.brands.getFullLinkPath(),
      searchParams: { onlyMyBrands: true },
    }),
    token,
    ...queryOptions,
  });
}
