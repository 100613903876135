import { Stack } from '@mui/material';
import { StyledTextField } from './CalculusInfoRow';
import { ConfirmEditButtons } from '../ConfirmEditButtons';
import { HelperText } from '@shared/components';
import { useForm } from 'react-hook-form';
import { discountSchema, DiscountSchema } from '@retail/markup/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { FC } from 'react';
import { TooltipError } from '../TooltipError';

interface Props {
  defaultValue: number;
  onSubmit: (value: number) => void;
  onCancel: () => void;
  errorPlacement?: 'bottom' | 'tooltipBottom';
}

export const DiscountInput: FC<Props> = ({
  defaultValue,
  onSubmit,
  onCancel,
  errorPlacement = 'bottom',
}) => {
  const t = useCalculusTFunction();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<DiscountSchema>({
    defaultValues: { discount: defaultValue },
    resolver: yupResolver(discountSchema(t)),
  });

  const _onSubmit = handleSubmit(({ discount }) => {
    onSubmit(discount);
  });

  return (
    <Stack alignItems="flex-start">
      <Stack direction="row" alignItems="center">
        <TooltipError
          message={
            errors.discount?.message && errorPlacement === 'tooltipBottom'
              ? errors.discount.message
              : undefined
          }
        >
          <StyledTextField
            {...register('discount')}
            size="small"
            onClick={(e) => e.stopPropagation()}
            error={!!errors.discount?.message}
          />
        </TooltipError>
        <ConfirmEditButtons onSubmit={_onSubmit} onCancel={onCancel} />
      </Stack>
      {errors.discount?.message && errorPlacement === 'bottom' && (
        <HelperText id="discountInputError" status="error">
          {errors.discount?.message}
        </HelperText>
      )}
    </Stack>
  );
};
