import { SupplierItemAutocomplete } from '@retail/components';
import { AutocompleteInput, SelectInputField } from '@shared/components';
import { LevelSelectSchema } from './levelSelectSchema';
import { useController, useFormContext } from 'react-hook-form';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { SelectChangeEvent, Stack } from '@mui/material';
import { MgSupplierItemSearchResult, OverGroup } from '@retail/products/types';
import { DiscountGroup } from '@retail/calculus/types';

interface Props {
  productGroups: OverGroup[];
  discountGroups?: DiscountGroup[];
  mgSupplierItems?: MgSupplierItemSearchResult[];
  isLoadingFetchItems: boolean;
  handleLevelSelectChange: (value: SelectChangeEvent<unknown>) => void;
}

export function LevelSelectForm({
  productGroups,
  mgSupplierItems,
  discountGroups,
  isLoadingFetchItems,
  handleLevelSelectChange,
}: Props) {
  const t = useCalculusTFunction();
  const { control, watch } = useFormContext<LevelSelectSchema>();

  const levelController = useController({ name: 'level', control });
  const overGroupController = useController({ name: 'overGroup', control });
  const mainGroupController = useController({ name: 'mainGroup', control });
  const productGroupController = useController({ name: 'productGroup', control });
  const discountGroupController = useController({ name: 'discountGroup', control });
  const itemController = useController({ name: 'item', control });
  const itemSearchController = useController({ name: 'itemSearch', control });

  const levelValue = watch('level');
  const overGroupValue = watch('overGroup');
  const mainGroupValue = watch('mainGroup');

  const menuItems = [
    { value: 'supplier', name: t('calculus.level.supplier') },
    { value: 'productGroup', name: t('calculus.level.productGroup') },
    { value: 'discountGroup', name: t('calculus.level.discountGroup') },
    { value: 'item', name: t('calculus.level.item.label') },
  ];

  const levelMenuItems = discountGroups
    ? menuItems
    : menuItems.filter((item) => item.value !== 'discountGroup');

  return (
    <Stack gap={1}>
      <SelectInputField
        {...levelController.field}
        error={levelController.fieldState.error?.message}
        size="small"
        width={400}
        label={t('calculus.level.level')}
        placeholder={t('calculus.actions.chooseLevel')}
        menuItems={levelMenuItems}
        onChange={handleLevelSelectChange}
        required
      />

      {levelValue === 'productGroup' && (
        <Stack flexDirection="row" gap={1}>
          <AutocompleteInput
            {...overGroupController.field}
            error={overGroupController.fieldState.error?.message}
            required
            width={260}
            size="small"
            label={t('calculus.level.overGroup')}
            placeholder={t('calculus.actions.chooseOverGroup')}
            showPopupIcon
            options={productGroups}
            getOptionLabel={(option) => option.getLabel()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, value) => overGroupController.field.onChange(value)}
          />
          {!!overGroupValue && (
            <AutocompleteInput
              {...mainGroupController.field}
              width={260}
              size="small"
              label={t('calculus.level.mainGroup')}
              placeholder={t('calculus.actions.chooseMainGroup')}
              showPopupIcon
              options={
                productGroups.find(
                  (overGroup) => overGroup.groupNumber === overGroupValue.groupNumber
                )?.subGroups ?? []
              }
              getOptionLabel={(option) => option.getLabel()}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => mainGroupController.field.onChange(value)}
            />
          )}
          {!!mainGroupValue && (
            <AutocompleteInput
              {...productGroupController.field}
              width={260}
              size="small"
              label={t('calculus.level.productGroup')}
              placeholder={t('calculus.actions.chooseProductGroup')}
              showPopupIcon
              options={
                productGroups
                  .flatMap((overGroup) => overGroup.subGroups)
                  .find((mainGroup) => mainGroup.groupNumber === mainGroupValue.groupNumber)
                  ?.subGroups ?? []
              }
              getOptionLabel={(option) => option.getLabel()}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => productGroupController.field.onChange(value)}
            />
          )}
        </Stack>
      )}

      {levelValue === 'discountGroup' && (
        <AutocompleteInput
          {...discountGroupController.field}
          error={discountGroupController.fieldState.error?.message}
          width={260}
          size="small"
          label={t('calculus.level.discountGroup')}
          placeholder={t('calculus.actions.chooseDiscountGroup')}
          showPopupIcon
          options={discountGroups ?? []}
          getOptionLabel={(option) => `${option.code} ${option.name}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, value) => discountGroupController.field.onChange(value)}
        />
      )}

      {levelValue === 'item' && (
        <SupplierItemAutocomplete
          error={itemController.fieldState.error?.message}
          label={t('calculus.level.item.label')}
          placeholder={t('calculus.level.item.placeholder')}
          size="small"
          sx={{ width: 400 }}
          searchResults={mgSupplierItems || []}
          isLoading={isLoadingFetchItems}
          selectOption={(e) => itemController.field.onChange(e)}
          inputField={itemSearchController.field}
          required
        />
      )}
    </Stack>
  );
}
