import { FC } from 'react';

import { MgItemInfo } from '@retail/my-assortment/components';
import { useDisclosure } from '@shared/hooks';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { AssortmentItem } from '@retail/my-assortment/types';
import { ProductCardPaper } from '@retail/my-assortment/containers';
import { MgItemDialogContainer } from '../../MgItem';
import { ProductCardContainer } from './ProductCardContainer';

interface Props {
  assortmentItem: AssortmentItem;
  closeProductCard: () => void;
}

export const ProductCardSuspenseContainer: FC<Props> = ({ assortmentItem, closeProductCard }) => {
  const { isOpen: openFullProductCard, onToggle: toggleProductCard } = useDisclosure();

  return (
    <ProductCardPaper>
      <MgItemInfo
        primaryText={assortmentItem.primaryText}
        secondaryText={assortmentItem.secondaryText}
        closeProductCard={closeProductCard}
        openProductCardModal={toggleProductCard}
      />
      <SuspenseWithSentryErrorBoundary>
        <ProductCardContainer assortmentItem={assortmentItem} />
      </SuspenseWithSentryErrorBoundary>
      <MgItemDialogContainer
        open={openFullProductCard}
        onClose={toggleProductCard}
        itemNumber={assortmentItem.mgItemNumber}
        showAddItem={false}
      />
    </ProductCardPaper>
  );
};
