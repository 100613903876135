import { Retailer } from './Retailer';
import { StoreDTO } from '../dto';

export interface RetailerInfo {
  id: number;
  name: string;
  urlName: string;
}

export interface Coordinates {
  lat: number;
  long: number;
}

export type CompetitorType = 'retailer' | 'store';

export abstract class Competitor {
  readonly id: number;
  readonly name: string;
  readonly baseLogoUrl: string;
  readonly coordinates?: Coordinates;
  protected readonly _urlName: string;

  protected constructor(
    id: number,
    name: string,
    urlName: string,
    baseLogoUrl: string,
    coordinates?: Coordinates
  ) {
    this.id = id;
    this.name = name;
    this.baseLogoUrl = baseLogoUrl;
    this._urlName = urlName;
    this.coordinates = coordinates;
  }

  abstract key(): string;

  abstract type(): CompetitorType;

  abstract fullName(): string;

  abstract retailer(): RetailerInfo;

  urlName: () => string = () => {
    return this._urlName;
  };

  logoUrl = (): string => {
    return Retailer.getLogoUrl(this._urlName, this.baseLogoUrl);
  };

  circularLogoUrl = (): string => {
    return Retailer.getCircularLogoUrl(this._urlName, this.baseLogoUrl);
  };
}

export class RetailerCompetitor extends Competitor {
  constructor(id: number, name: string, urlName: string, baseLogoUrl: string) {
    super(id, name, urlName, baseLogoUrl);
  }

  override key = (): string => {
    return `${this.id}`;
  };

  override type = (): CompetitorType => {
    return 'retailer';
  };

  override retailer = (): RetailerInfo => {
    return { id: this.id, name: this.name, urlName: this._urlName };
  };

  override fullName = (): string => {
    return this.name;
  };
}

export class StoreCompetitor extends Competitor {
  private readonly retailerInfo: RetailerInfo;

  constructor(
    id: number,
    name: string,
    retailerInfo: RetailerInfo,
    baseLogoUrl: string,
    coordinates?: Coordinates
  ) {
    super(id, name, retailerInfo.urlName, baseLogoUrl, coordinates);
    this.retailerInfo = retailerInfo;
  }

  override key = (): string => {
    return `${this.retailerInfo.id}-${this.id}`;
  };

  override type = (): CompetitorType => {
    return 'store';
  };

  override retailer = (): RetailerInfo => {
    return this.retailerInfo;
  };

  override fullName = (): string => {
    return `${this.retailerInfo.name} ${this.name}`;
  };

  static fromStoreDto = (
    { id, name, retailerName, retailerId, retailerUrlName, latitude, longitude }: StoreDTO,
    baseLogoUrl: string
  ): StoreCompetitor =>
    new StoreCompetitor(
      id,
      name,
      { id: retailerId, name: retailerName, urlName: retailerUrlName },
      baseLogoUrl,
      latitude && longitude
        ? {
            lat: latitude,
            long: longitude,
          }
        : undefined
    );
}
