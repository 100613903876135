import { useAppTFunction } from '@retail/app/i18n';
import { TextButton } from '@retail/components';
import { useExportAssortment } from '@retail/my-assortment/data-access';
import { ExcelIcon } from '@shared/custom-icons';
import { downloadResponseFile } from '@shared/fetch-utils';
import { useToast } from '@retail/hooks';
import { useCallback } from 'react';

interface Props {
  noItems: boolean;
  baseAssortmentId: number;
  selectedSupplier?: number;
  selectedProductGroup?: string;
  query: string;
}

export const ExportAssortment = ({
  noItems,
  baseAssortmentId,
  selectedSupplier,
  selectedProductGroup,
  query,
}: Props) => {
  const t = useAppTFunction();
  const toast = useToast();

  const { mutateAsync: mutateExportAssortmentToExcel, isLoading: isLoadingExcel } =
    useExportAssortment();

  const exportAssortmentToExcel = useCallback(async () => {
    return mutateExportAssortmentToExcel({
      assortmentId: baseAssortmentId,
      includeOwner: true,
      supplierIds: selectedSupplier ? [selectedSupplier] : undefined,
      groupNumbers: selectedProductGroup ? [selectedProductGroup] : undefined,
      query,
    }).then((response) => {
      downloadResponseFile({ filenameFallback: 'my-assortment.xlsx' })(response);
      toast.success(t('export.messages.fileDownloaded'));
    });
  }, [
    baseAssortmentId,
    mutateExportAssortmentToExcel,
    query,
    selectedProductGroup,
    selectedSupplier,
    t,
    toast,
  ]);

  return (
    <TextButton
      disabled={noItems}
      loading={isLoadingExcel}
      onClick={exportAssortmentToExcel}
      startIcon={<ExcelIcon />}
    >
      {t('common.exportToExcel')}
    </TextButton>
  );
};
