import {
  QueryOptions,
  RequestMethods,
  useFetch,
  useMutateFetch,
  UseMutateFetchProps,
} from '@shared/fetch-utils';
import { QueryKey } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useAuth0 } from '@auth0/auth0-react';
import { handleTokenRefresh } from './handleTokenRefresh';

export interface MPEFetchError {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
}

export interface UseMPEFetchOptions {
  queryKey: QueryKey;
  url: string;
  method: RequestMethods;
  body?: any;
  token?: string;
}

const orgUnitHeader = 'X-Org-Unit-Id';

interface OrgUnitHeaderProps {
  orgUnitId: number;
}

export function getOrgUnitHeader({ orgUnitId }: OrgUnitHeaderProps): HeadersInit | undefined {
  return { [orgUnitHeader]: `${orgUnitId}` };
}

export function useSelectedOrgUnitHeader() {
  const { selectedOrgUnitId } = useSelectedOrgUnit();
  return useMemo(
    () =>
      getOrgUnitHeader({
        orgUnitId: selectedOrgUnitId,
      }),
    [selectedOrgUnitId]
  );
}

export function useMPEFetch<TData, TError = MPEFetchError>(
  options: UseMPEFetchOptions & QueryOptions
) {
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();
  const { getAccessTokenSilently } = useAuth0();

  const optionsWithHeader = useMemo(
    () => ({
      ...options,
      headers: selectedOrgUnitHeader,
    }),
    [options, selectedOrgUnitHeader]
  );
  return useFetch<TData, TError>({
    ...optionsWithHeader,
    onRejected: async (error, retryRequest) => {
      if (error.status === 401) {
        try {
          const newToken = await handleTokenRefresh(getAccessTokenSilently);
          return await retryRequest({
            ...options,
            token: newToken,
          });
        } catch (refreshError) {
          console.error('Failed to refresh token', refreshError);
          throw refreshError;
        }
      }
      throw error;
    },
  });
}

export function useMutateMPEFetch<ResultT, BodyT = unknown, URLVariables = any>(
  options: UseMutateFetchProps<ResultT, BodyT, URLVariables>
) {
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();
  const { getAccessTokenSilently } = useAuth0();

  return useMutateFetch<ResultT, BodyT, URLVariables>({
    headers: selectedOrgUnitHeader,
    onRejected: async (error, retryRequest) => {
      if (error.status === 401) {
        try {
          const newToken = await handleTokenRefresh(getAccessTokenSilently);
          return await retryRequest({
            ...options,
            token: newToken,
          });
        } catch (refreshError) {
          console.error('Failed to refresh token', refreshError);
          throw refreshError;
        }
      }
      throw error;
    },
    ...options,
  });
}
