import { FC, MouseEventHandler, useCallback, useEffect, useMemo } from 'react';
import { SpreadsheetInput, withDialogSuspenseErrorBoundary } from '@retail/components';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import {
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { convertToCSVString, downloadBlobAsFile } from '@retail/my-assortment/utils';
import { useToast } from '@retail/hooks';
import { useImportAgreementPricesStore } from '@retail/purchase-agreements/stores';
import { usePostPurchaseAgreements } from '@retail/suppliers/data-access';
import { Supplier } from '@retail/suppliers/types';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  open: boolean;
  onClose: () => void;
  supplier: Supplier;
}

const exampleFileContent = [
  {
    'MgItem-number': '100000000',
    'Purchase-price': '100.50',
    'From-date': '2024-05-25',
    'To-date': '2025-05-25',
    'GTIN': '12345678910111',
  },
];

const handleDownloadTemplate: MouseEventHandler<HTMLAnchorElement> = (e) => {
  e.nativeEvent.stopImmediatePropagation();
  e.nativeEvent.stopPropagation();
  e.stopPropagation();

  try {
    const cvsString = convertToCSVString(exampleFileContent, ';');
    downloadBlobAsFile(new Blob([cvsString], { type: 'text/csv' }), 'agreement-prices.csv');
  } catch (error) {
    console.error('Error downloading the template:', error);
  }
};

const AgreementsUploadDialog: FC<Props> = ({ open, onClose, supplier }) => {
  const toast = useToast();
  const t = useAppTFunction();
  const { importedItemPrices, rejectedRows, importedFile, importSpreadsheet, clearState } =
    useImportAgreementPricesStore((state) => state);

  const { mutateAsync: postPurchaseAgreementPrices, isLoading } = usePostPurchaseAgreements();

  useEffect(() => clearState, [clearState]);

  const onSubmitPurchaseAgreementPrices = useCallback(() => {
    postPurchaseAgreementPrices({
      body: importedItemPrices!,
      urlVariables: supplier.mgSupplierId,
    })
      .then(() => {
        toast.success(t('purchaseAgreements.agreement.prices.upload.success.import'));
        onClose();
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('purchaseAgreements.agreement.prices.upload.error.api') + e.payload?.message);
      });
  }, [importedItemPrices, onClose, postPurchaseAgreementPrices, supplier.mgSupplierId, t, toast]);

  const onRemoveFile: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      clearState();
    },
    [clearState]
  );

  const onRejectFile = useCallback(
    () =>
      toast.error(t('purchaseAgreements.agreement.prices.upload.error.fileType'), {
        autoClose: false,
      }),
    [t, toast]
  );

  const importedAllRowsSuccessfully = useMemo(
    () => !!importedItemPrices?.length && !rejectedRows?.length,
    [importedItemPrices?.length, rejectedRows?.length]
  );

  return (
    <Dialog open={open} onClose={onClose} fullScreen sx={{ padding: 4 }}>
      <DialogTitle>
        {t('purchaseAgreements.agreement.prices.upload.titleSupplier', {
          supplierName: supplier.name,
        })}
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2} alignItems="center" mt={8}>
          <SpreadsheetInput
            onUpload={importSpreadsheet}
            showUploadIcon
            onRejectFiles={onRejectFile}
            sx={{ p: 4, maxWidth: '1200px', width: '100%' }}
          >
            <Typography pt={1}>{t('purchaseAgreements.agreement.prices.upload.title')}</Typography>
            <Typography pb={2} variant="body2">
              {importedFile
                ? importedFile.name
                : t('purchaseAgreements.agreement.prices.upload.acceptedFormats')}
            </Typography>

            <Stack flexDirection="row" gap={2}>
              <ContainedButton size="small">
                {importedFile
                  ? t('purchaseAgreements.agreement.prices.upload.changeFile')
                  : t('purchaseAgreements.agreement.prices.upload.upload')}
              </ContainedButton>

              {importedFile && (
                <ContainedButton color="warning" onClick={onRemoveFile} size="small">
                  {t('purchaseAgreements.agreement.prices.upload.removeFile')}
                </ContainedButton>
              )}
            </Stack>
            <Link
              mt={1}
              onClick={handleDownloadTemplate}
              variant="body2"
              whiteSpace="nowrap"
              sx={{
                cursor: 'pointer',
              }}
            >
              {t('purchaseAgreements.agreement.prices.upload.downloadTemplate')}
            </Link>
          </SpreadsheetInput>
          {!!importedItemPrices?.length && !rejectedRows?.length && (
            <Alert severity="success">
              {t('purchaseAgreements.agreement.prices.upload.success.fileContent', {
                count: importedItemPrices.length,
              })}
            </Alert>
          )}
          {!!rejectedRows?.length && (
            <Alert severity="error">
              {t('purchaseAgreements.agreement.prices.upload.error.fileContent', {
                count: rejectedRows.length,
                rowNumbers: rejectedRows.map(({ index }) => index + 1).join(', '),
              })}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <TextButton variant="text" size="small" onClick={onClose} loading={isLoading}>
          {t('common.cancel')}
        </TextButton>
        <ContainedButton
          onClick={onSubmitPurchaseAgreementPrices}
          size="small"
          disabled={!importedAllRowsSuccessfully}
          loading={isLoading}
        >
          {t('purchaseAgreements.agreement.prices.upload.importAction')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogSuspenseErrorBoundary(AgreementsUploadDialog);
