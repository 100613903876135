import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { useMutateMPEFetch } from '@retail/app/hooks';
import { mpeApi } from '@retail/mgpris/config';
import { buildUrl } from '@shared/utils';

export interface PostParams extends QueryOptions {
  assortmentId: number;
  departmentId: string;
}

export const useDeleteCondition = ({ assortmentId, departmentId, ...queryOptions }: PostParams) => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, number[]>({
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.deleteConditions.getFullLinkPath({ assortmentId }),
      searchParams: { departmentId },
    }),
    method: 'DELETE',
    token,
    ...queryOptions,
  });
};
