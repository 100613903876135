import { Box, Button, styled, Tooltip } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgSupplierItem } from '@retail/products/types';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  suppliers: Partial<MgSupplierItem>[];
  selectedSupplier: Partial<MgSupplierItem> | null;
  setSelectedSupplier: Dispatch<SetStateAction<Partial<MgSupplierItem> | null>>;
}

export const SupplierButtonGroup = ({
  suppliers,
  selectedSupplier,
  setSelectedSupplier,
}: Props) => {
  const t = useProductsTFunction();

  return (
    <Box display="flex">
      <Tooltip title={t('products.supplier.allSuppliers')}>
        <SupplierButton
          isActive={!selectedSupplier}
          onClick={() => setSelectedSupplier(null)}
          isSupplierButton
        >
          <EllipsisBox>{t('products.supplier.allSuppliers')}</EllipsisBox>
        </SupplierButton>
      </Tooltip>

      {suppliers.map((supplier) => {
        const shortenedSupplierName =
          supplier?.mgSupplierName && supplier.mgSupplierName.length > 15
            ? `${supplier.mgSupplierName.slice(0, 15)}...`
            : supplier.mgSupplierName;

        return (
          <Tooltip
            key={`${supplier.mgSupplierId}_${supplier.mgSupplierItemId}`}
            title={supplier.mgSupplierName}
          >
            <SupplierButton
              sx={{ marginLeft: 1 }}
              isActive={
                selectedSupplier?.mgSupplierId === supplier.mgSupplierId &&
                selectedSupplier?.mgSupplierItemId === supplier.mgSupplierItemId
              }
              onClick={() => setSelectedSupplier(supplier)}
            >
              <EllipsisBox>{shortenedSupplierName}</EllipsisBox>
            </SupplierButton>
          </Tooltip>
        );
      })}
    </Box>
  );
};

const SupplierButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isSupplierButton',
})<{ isActive?: boolean; isSupplierButton?: boolean }>(
  ({ isActive, isSupplierButton, theme: { shape, palette } }) => ({
    background: isActive ? palette.grey[800] : palette.common.white,
    border: `2px solid ${palette.grey[isActive ? 800 : 100]}`,
    borderRadius: shape.borderRadius * 6,
    color: isActive ? palette.common.white : palette.grey[700],
    maxWidth: isSupplierButton ? 250 : 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:hover': {
      borderWidth: '2px',
    },
  })
);

const EllipsisBox = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
