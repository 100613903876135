import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { PriceListDialog } from '@retail/purchase-agreements/components';
import { usePriceList } from '@retail/purchase-agreements/context';
import { useDisclosure } from '@shared/hooks';
import { useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  agreementId: number;
  productGroupId?: string;
  mgItemNumber?: number;
}

function PriceListDialogContainer({
  open,
  onClose,
  agreementId,
  productGroupId,
  mgItemNumber,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const { isOpen: includeFuturePrices, onToggle: toggleIncludeFuturePrices } = useDisclosure(false);

  const { result, setPage, setPageSize } = usePriceList({
    agreementId,
    productGroupId,
    mgItemNumber,
    current: !includeFuturePrices,
    query: searchQuery,
    initPageSize: 400,
  });

  return (
    <PriceListDialog
      result={result}
      open={open}
      includeFuturePrices={includeFuturePrices}
      toggleIncludeFuturePrices={toggleIncludeFuturePrices}
      setSearchQuery={setSearchQuery}
      onClose={onClose}
      updatePage={setPage}
      updatePageSize={setPageSize}
    />
  );
}

export default withDialogSuspenseErrorBoundary(PriceListDialogContainer);
