import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useMutateMPEFetch } from '@retail/app/hooks';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

interface PurchaseAgreementPriceDTO {
  mgItemNumber: number;
  price: number;
  validFrom: string;
  validTo?: string;
  gtinCode?: number;
}

export function usePostPurchaseAgreements() {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<undefined, PurchaseAgreementPriceDTO[], number>({
    token,
    method: 'POST',
    url: (supplierId) =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.suppliers.supplier.agreementPrices.getFullLinkPath({
          supplierId: supplierId!,
        }),
      }),
  });
}
