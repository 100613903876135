import { Stack, useTheme } from '@mui/material';
import { SalesPriceView } from './SalesPriceView';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ContainedButton, TextButton } from '@shared/components';
import { SalesCoverageView } from './SalesCoverageView';
import { EditableMarkupField, SalesPriceEditState } from '../store';
import { SalesPriceSummaryWrapper } from './SalesPriceSummaryWrapper';

interface Props {
  currentSalesCoverage?: number;
  newSalesCoverage?: {
    value: number;
    isApproximate: boolean;
  };
  currentSalesPrice?: number;
  packageUnit: string;
  newSalesPrice?: {
    value: number;
    isApproximate: boolean;
  };
  salesPriceEditState: SalesPriceEditState;
  editingField: EditableMarkupField | undefined;
  setEditingField: (field: EditableMarkupField) => void;
  closeEditingField: () => void;
  onChangeSalesCoverage: (value: number) => void;
  includeVat: boolean;
  resetMarkupValues: () => void;
  submitMarkupValues: () => void;
  onChangeSalesPrice: (salesPrice: number) => void;
}

export function SalesPriceSummary({
  currentSalesCoverage,
  newSalesCoverage,
  currentSalesPrice,
  newSalesPrice,
  salesPriceEditState,
  editingField,
  setEditingField,
  closeEditingField,
  onChangeSalesCoverage,
  packageUnit,
  includeVat,
  resetMarkupValues,
  submitMarkupValues,
  onChangeSalesPrice,
}: Props) {
  const t = useMyAssortmentTFunction();
  const { palette } = useTheme();

  const hasChanges = !!newSalesCoverage;

  return (
    <SalesPriceSummaryWrapper>
      <SalesPriceView
        currentSalesPrice={currentSalesPrice}
        newSalesPrice={newSalesPrice}
        salesPriceEditState={salesPriceEditState}
        isEditing={editingField === 'price'}
        cancelEditMode={closeEditingField}
        openEditMode={() => setEditingField('price')}
        onChangeSalesPrice={onChangeSalesPrice}
        packageUnit={packageUnit}
        includeVat={includeVat}
      />

      <SalesCoverageView
        currentSalesCoverage={currentSalesCoverage}
        newSalesCoverage={newSalesCoverage}
        isEditing={editingField === 'coverage'}
        openEditMode={() => setEditingField('coverage')}
        cancelEditMode={closeEditingField}
        onChangeSalesCoverage={onChangeSalesCoverage}
      />

      {hasChanges && (
        <>
          <Stack height={2} bgcolor={palette.divider} my={1.5} />
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="baseline">
            <Stack flexDirection="row" gap={1} flexWrap="nowrap">
              <TextButton onClick={resetMarkupValues}>{t('myAssortment.cancel')}</TextButton>
              <ContainedButton
                disabled={!!editingField}
                onClick={submitMarkupValues}
                color="secondary"
                size="small"
              >
                {t('myAssortment.createMarkup')}
              </ContainedButton>
            </Stack>
          </Stack>
        </>
      )}
    </SalesPriceSummaryWrapper>
  );
}
