import { SelectItemAgreementTable } from '@retail/purchase-agreements/components';
import { useFetchItemAgreements } from '@retail/purchase-agreements/data-access';
import { ItemAgreement } from '@retail/purchase-agreements/types';
import { useCallback, useState } from 'react';

interface Props {
  mgItemNumber: number;
  assortmentId: number;
  supplierId: number;
  selectAgreement: (agreement?: ItemAgreement) => void;
}

export function SelectItemAgreements({ mgItemNumber, assortmentId, supplierId, selectAgreement }: Props) {
  const { data: itemAgreementsPageDto } = useFetchItemAgreements({
    supplierId,
    assortmentId,
    mgItemId: mgItemNumber,
  });

  const itemAgreementDtos = itemAgreementsPageDto?.result ?? [];
  const itemAgreements = itemAgreementDtos.map(ItemAgreement.fromDto);

  const [checkedAgreement, setCheckedAgreement] = useState<ItemAgreement | undefined>(undefined);

  const handleCheckAgreement = useCallback(
    (agreement: ItemAgreement) => {
      setCheckedAgreement(agreement);
      selectAgreement(agreement);
    },
    [selectAgreement]
  );

  return (
    <SelectItemAgreementTable
      itemAgreements={itemAgreements}
      selectedAgreement={checkedAgreement}
      selectAgreement={handleCheckAgreement}
    />
  );
}
