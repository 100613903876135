import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { Department } from '@retail/auth/types';
import {
  CreateMarkupTemplateDTO,
  MarkupTemplate,
  PatchMarkupTemplateDTO,
} from '@retail/markup/types';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { useMutateMPEFetch } from '@retail/app/hooks';
import { mpeApi } from '@retail/mgpris/config';


export function useMutateCreateMarkupTemplate(
  departmentId: Department['departmentId'],
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, CreateMarkupTemplateDTO>({
    method: 'POST',
    url: () =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.markupTemplates.getFullLinkPath(),
        searchParams: { departmentId },
      }),
    token,
    ...queryOptions,
  });
}


export function useMutatePatchMarkupTemplate(
  markupTemplateId: MarkupTemplate['templateId'] | string,
  departmentId: Department['departmentId'],
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, PatchMarkupTemplateDTO>({
    method: 'PATCH',
    url: () =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.markupTemplates.template.getFullLinkPath({ templateId: markupTemplateId }),
        searchParams: { departmentId },
      }),
    token,
    ...queryOptions,
  });
}
