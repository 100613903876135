import { Stack, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { CalculusAreas } from '@retail/calculus/types';
import { Chip } from '@shared/components';
import React, { useMemo } from 'react';

interface Props {
  calculusArea: CalculusAreas;
  width?: number;
  height?: number;
}

export const ConditionTypeChip = ({ calculusArea, width = 144, height = 36 }: Props) => {
  const t = useAppTFunction();
  const { palette } = useTheme();

  const chipLabel = useMemo(() => {
    switch (calculusArea) {
      case CalculusAreas.PURCHASE:
        return t('app.routes.agreements');

      case CalculusAreas.FREIGHT:
        return t('app.routes.freight');

      default:
        return t('app.routes.markup');
    }
  }, [calculusArea, t]);

  const chipColor = useMemo(() => {
    switch (calculusArea) {
      case CalculusAreas.PURCHASE:
        return '#DDF5FC';

      case CalculusAreas.FREIGHT:
        return '#C0B6F44D';

      default:
        return palette.success[100];
    }
  }, [calculusArea, palette.success]);

  return (
    <Chip
      sx={{ width: width, height: height }}
      backgroundColor={chipColor}
      label={<Stack alignItems="center">{chipLabel}</Stack>}
    />
  );
};
