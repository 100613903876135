import * as yup from 'yup';
import { AppTFunction } from '@retail/app/i18n';

export const exportSchema = (tApp: AppTFunction) =>
  yup
    .object({
      allArticles: yup.boolean().default(false).required(),
      onlyChanges: yup
        .boolean()
        .default(false)
        .when('allArticles', {
          is: false,
          then: yup.boolean().required(tApp('export.errors.dateOrCheckboxRequired')),
          otherwise: yup.boolean(),
        }),
      fromDate: yup.date().when('allArticles', {
        is: false,
        then: yup
          .date()
          .typeError(tApp('export.errors.dateOrCheckboxRequired'))
          .required(tApp('export.errors.dateOrCheckboxRequired')),
        otherwise: yup.date().default(undefined).nullable(),
      }),
    })
    .required();

export type ExportSchema = yup.InferType<ReturnType<typeof exportSchema>>;
