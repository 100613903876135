import { MgPackageClassCode, MgPackageType } from '../MgItem';
import { PackageDTO } from '../../dto';
import { Unit } from './Unit';

export class Package {
  calculatedCount: number;
  gtinCode?: string;
  packageType: MgPackageType;
  unit: Unit;
  isBasePriceUnit: boolean;
  classCode: MgPackageClassCode;
  isPriceComparisonUnit: boolean;

  constructor(dto: PackageDTO) {
    this.calculatedCount = dto.calculatedCount;
    this.gtinCode = dto.gtinCode;
    this.packageType = dto.packageType;
    this.unit = Unit.fromDto(dto.unit);
    this.isBasePriceUnit = dto.isBasePriceUnit;
    this.classCode = dto.classCode;
    this.isPriceComparisonUnit = dto.isPriceComparisonUnit;
  }

  static fromDto(dto: PackageDTO): Package {
    return new Package(dto);
  }
}
