import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useFetchMe } from '@retail/auth/data-access';

type Props = {
  children: JSX.Element;
};

export const SuperUserRouteGuard = ({ children }: Props) => {
  const { data: user } = useFetchMe({ refetchOnWindowFocus: false });

  const isSuperUser = useMemo(() => !!user?.isSuperUser, [user]);

  return isSuperUser ? children : <Navigate to={mgPrisRoutes.root.fullRoutePath} replace />;
};
