import appNo from './no/app.json';
import commonNo from './no/common.json';
import adminNo from './no/admin.json';
import appSe from './se/app.json';
import commonSe from './se/common.json';
import adminSe from './se/admin.json';
import { validationLanguageResourcesResources } from '@retail/form/i18n';
import { productsI18nResources } from '@retail/products/i18n';
import { purchaseAgreementsI18nResources } from '@retail/purchase-agreements/i18n';
import { assortmentI18nResources } from '@retail/monitoring-assortment/i18n';
import { freightI18nResources } from '@retail/freight/i18n';
import { markupI18nResources } from '@retail/markup/i18n';
import { myAssortmentI18nResources } from '@retail/my-assortment/i18n';
import { suppliersI18nResources } from '@retail/suppliers/i18n';
import { calculusI18nResources } from '@retail/calculus/i18n';
import { exportI18nResources } from '@retail/export/i18n';
import { mgPdmI18nResources } from '@retail/mgpdm/i18n';
import { fixedPriceI18nResources } from '@retail/fixed-price/i18n';

const defaultResources = {
  app: appNo,
  common: commonNo,
  admin: adminNo,
  ...productsI18nResources.no,
  ...assortmentI18nResources.no,
  ...validationLanguageResourcesResources.no,
  ...purchaseAgreementsI18nResources.no,
  ...freightI18nResources.no,
  ...markupI18nResources.no,
  ...fixedPriceI18nResources.no,
  ...myAssortmentI18nResources.no,
  ...suppliersI18nResources.no,
  ...calculusI18nResources.no,
  ...exportI18nResources.no,
  ...mgPdmI18nResources.no,
};

export type AppResources = typeof defaultResources;

export type AppLanguageResources = {
  no: AppResources;
  se: AppResources;
};

export const appLanguageResources: AppLanguageResources = {
  no: defaultResources,
  se: {
    products: productsI18nResources.se.products,
    assortment: assortmentI18nResources.se.assortment,
    app: appSe,
    common: commonSe,
    admin: adminSe,
    validation: validationLanguageResourcesResources.se.validation,
    purchaseAgreements: purchaseAgreementsI18nResources.se.purchaseAgreements,
    freight: freightI18nResources.se.freight,
    markup: markupI18nResources.se.markup,
    fixedPrice: fixedPriceI18nResources.se.fixedPrice,
    calculus: calculusI18nResources.se.calculus,
    myAssortment: myAssortmentI18nResources.se.myAssortment,
    suppliers: suppliersI18nResources.se.suppliers,
    export: exportI18nResources.se.export,
    mgPdm: mgPdmI18nResources.se.mgPdm,
  },
};
