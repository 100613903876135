import { RecommendedPackagePriceDTO, RecommendedPriceDTO } from '../dto';

export class RecommendedPrice {
  mgItemNumber?: number;
  priceSegment?: string;
  incMva?: boolean;
  pricesConservative?: RecommendedPackagePriceDTO[];
  pricesAggressive?: RecommendedPackagePriceDTO[];
  pricesModerate?: RecommendedPackagePriceDTO[];

  constructor({
    mgItemNumber,
    priceSegment,
    incMva,
    pricesConservative,
    pricesAggressive,
    pricesModerate,
  }: RecommendedPriceDTO) {
    this.mgItemNumber = mgItemNumber;
    this.priceSegment = priceSegment;
    this.incMva = incMva;
    this.pricesConservative = pricesConservative;
    this.pricesAggressive = pricesAggressive;
    this.pricesModerate = pricesModerate;
  }
}
