import { useFetchProductGroupsInAssortment } from '@retail/my-assortment/data-access';
import { OverGroup } from '@retail/products/types';
import { productGroupSearchParamKey } from '@retail/products/utils';
import { useSelectedSearchParamState } from '@shared/hooks';
import { useState } from 'react';

interface Props {
  assortmentId: number;
  selectedSupplierId?: number;
  selectedDiscountGroupId?: number;
}

export const useSelectProductGroupsInAssortment = ({
  assortmentId,
  selectedSupplierId,
  selectedDiscountGroupId,
}: Props) => {
  const { data: productGroupsDto = [] } = useFetchProductGroupsInAssortment({
    assortmentId,
    supplierIds: selectedSupplierId ? [selectedSupplierId] : undefined,
    discountGroupIds: selectedDiscountGroupId ? [selectedDiscountGroupId] : undefined,
    suspense: true,
  });
  const productGroups = productGroupsDto.map(OverGroup.fromDto);

  const flattenedProductGroups = OverGroup.flatten(productGroups);

  const [selectedProductGroupNumber, selectProductGroupNumber] = useState<string | null>(null);

  const [selectedProductGroup, selectProductGroup, selectProductGroupByNr] =
    useSelectedSearchParamState({
      options: flattenedProductGroups,
      getOptionId: ({ groupNumber }) => groupNumber,
      searchParamKey: productGroupSearchParamKey,
    });

  return {
    productGroups,
    selectedProductGroupNumber,
    selectProductGroupNumber,
    selectedProductGroup,
    selectProductGroup,
    selectProductGroupByNr,
  };
};
