import { FC } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { Stack, Typography } from '@mui/material';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { useAppTFunction } from '@retail/app/i18n';
import { CheckIcon } from '@shared/custom-icons';
import { AgreementsTableActions } from './AgreementsTableActions';

interface Props {
  agreements: PurchaseAgreement[];
  setStandardAgreement?: (purchaseAgreement: PurchaseAgreement | null) => void;
}

type GridColAgreement = GridColDef<PurchaseAgreement>;

const baseColumnProps: Partial<GridColAgreement> = {
  minWidth: 120,
  flex: 1,
  sortable: false,
};

export const PurchaseAgreementsTablePrev: FC<Props> = ({ agreements, setStandardAgreement }) => {
  const t = usePurchaseAgreementsTFunction();
  const appT = useAppTFunction();
  const columns: GridColAgreement[] = [
    {
      ...baseColumnProps,
      field: 'name',
      headerName: t('purchaseAgreements.agreement.columns.agreementText'),
    },
    {
      ...baseColumnProps,
      field: 'source',
      headerName: t('purchaseAgreements.agreement.columns.source'),
    },
    {
      ...baseColumnProps,
      field: 'sourceAgreementId',
      headerName: t('purchaseAgreements.agreement.columns.sourceAgreementId'),
    },
    {
      ...baseColumnProps,
      field: 'id',
      headerName: t('purchaseAgreements.agreement.columns.ourId'),
    },
    {
      ...baseColumnProps,
      field: 'standardAgreement',
      headerName: t('purchaseAgreements.agreement.columns.standardAgreement'),
      minWidth: 150,
      renderCell: ({ row }) =>
        row.standardAgreement ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{appT('common.yes')}</Typography>
            <CheckIcon />
          </Stack>
        ) : (
          appT('common.no')
        ),
    },
  ];
  if (setStandardAgreement)
    columns.push({
      ...baseColumnProps,
      field: 'actions',
      headerName: '',
      width: 60,
      renderCell: ({ row }) =>
        row.standardAgreement ? null : (
          <AgreementsTableActions onSetAsStandardAgreement={() => setStandardAgreement(row)} />
        ),
    });

  return (
    <DataGridPremium
      columns={columns}
      rows={agreements}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
