import { Stack, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { HierarchyLevels, PackageType } from '@retail/calculus/types';
import { Chip } from '@shared/components';
import React, { useMemo } from 'react';

interface Props {
  bindingType?: HierarchyLevels;
  isAgreement?: boolean;
  labelDetails?: string | React.ReactNode;
  active: boolean;
  packageType?: PackageType;
  width?: number;
  height?: number;
}

export const LevelChip = ({
  bindingType,
  isAgreement,
  labelDetails,
  active,
  packageType,
  width = 144,
  height = 36,
}: Props) => {
  const t = useAppTFunction();
  const { palette } = useTheme();

  const label = useMemo(() => {
    switch (bindingType) {
      case HierarchyLevels.supplier:
        return t(`markup.levels.supplier`);
      case HierarchyLevels.overGroup:
        return t(`markup.levels.overGroup`);
      case HierarchyLevels.mainGroup:
        return t(`markup.levels.mainGroup`);
      case HierarchyLevels.productGroup:
        return t(`markup.levels.productGroup`);
      case HierarchyLevels.discountGroup:
        return t(`markup.levels.discountGroup`);

      case HierarchyLevels.item:
        switch (packageType) {
          case 'SECONDARY':
            return t('markup.levels.secondary');
          case 'TERTIARY':
            return t('markup.levels.tertiary');
          default:
            return t('markup.levels.item');
        }

      default:
        return isAgreement ? t('purchaseAgreements.availableAgreement') : '';
    }
  }, [bindingType, packageType, isAgreement, t]);

  const chipColor = useMemo(() => {
    if (active) {
      switch (bindingType) {
        case HierarchyLevels.supplier:
          return '#C0B6F4';
        case HierarchyLevels.overGroup:
          return '#E9E1A0';
        case HierarchyLevels.mainGroup:
          return palette.info.main;
        case HierarchyLevels.productGroup:
          return palette.warning.light;
        case HierarchyLevels.discountGroup:
          return palette.warning[100];
        case HierarchyLevels.item:
          switch (packageType) {
            case 'SECONDARY':
              return '#FFD1DC';
            case 'TERTIARY':
              return '#FF8678';
            default:
              return palette.success[100];
          }
        default:
          return palette.divider;
      }
    } else {
      return palette.divider;
    }
  }, [
    active,
    bindingType,
    packageType,
    palette.divider,
    palette.info.main,
    palette.success,
    palette.warning,
  ]);

  return (
    <Chip
      sx={{ width: width, height: height }}
      backgroundColor={chipColor}
      label={
        <Stack alignItems="center">
          {label}
          {labelDetails}
        </Stack>
      }
    />
  );
};
