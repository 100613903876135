import { useProductsTFunction } from '@retail/products/i18n';
import { Table, TableContainer, TableHead, TableRow } from '@mui/material';
import { MgItem, MgSupplierItem } from '@retail/products/types';
import { EnvironmentDocumentationTableBody } from './EnvironmentDocumentationTableBody';
import { DocumentationTableCell } from '../styles';

interface Props {
  mgItem: MgItem;
}

export const EnvironmentDocumentationTable = ({ mgItem }: Props) => {
  const t = useProductsTFunction();

  const suppliers: Partial<MgSupplierItem>[] = mgItem.getSuppliers();

  return (
    <TableContainer
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[100]}`,
        borderBottom: 'none',
        width: 'fit-content',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <DocumentationTableCell
              hasBorderLeft={false}
              sx={{ paddingLeft: (theme) => theme.spacing(6) }}
            >
              {t('products.mgItem.environmentDocumentation.title')}
            </DocumentationTableCell>

            {suppliers.map((supplier, index) => (
              <DocumentationTableCell key={`${supplier.id}-row-${index}`} align="center">
                {supplier.mgSupplierName}
              </DocumentationTableCell>
            ))}
          </TableRow>
        </TableHead>

        <EnvironmentDocumentationTableBody suppliers={mgItem.supplierItems} />
      </Table>
    </TableContainer>
  );
};
