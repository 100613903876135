import { PageLayout } from '@retail/components';
import { Paper } from '@shared/components';

export function ItemPage() {
  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        sx={{
          display: 'flex',
          height: '100%',
        }}
      ></Paper>
    </PageLayout>
  );
}
