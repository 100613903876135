import { DialogContent } from '@mui/material';
import { MgItem } from '@retail/products/types';
import { Dialog } from '@shared/components';
import { ErrorFallback, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { MgItemContainer } from './MgItemContainer';

interface Props {
  mgItem: MgItem;
  isOpen: boolean;
  showAddItem?: boolean;
  showMonitorItem?: boolean;
  onClose: () => void;
}

export function MgItemDialog({ mgItem, isOpen, onClose, showAddItem, showMonitorItem }: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl">
      <DialogContent>
        <SuspenseWithSentryErrorBoundary errorFallback={(props) => <ErrorFallback {...props} />}>
          <MgItemContainer
            mgItem={mgItem}
            hiddenBreadcrumbs
            showAddItem={showAddItem}
            showMonitorItem={showMonitorItem}
          />
        </SuspenseWithSentryErrorBoundary>
      </DialogContent>
    </Dialog>
  );
}
