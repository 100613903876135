import { Routes, RoutesDef } from './routeTypes';

const dynamicPathSegmentRegex = /(?<=\/:)[^/]+(?=\/|$)/g;

/**
 *
 * @param input - must conform to RoutesDef, but should be typed 'as const' to correctly infer the output types
 * @param parentPath
 */
export function transformRoutes<TInputRoutes extends RoutesDef, TBasePath extends string>(
  input: TInputRoutes,
  parentPath: TBasePath,
): Routes<TInputRoutes, TBasePath> {
  // @ts-ignore
  const output: Routes<TInputRoutes, TBasePath> = {};

  for (const routeKey in input) {
    const route = input[routeKey];

    const outputPath = parentPath + route.path;
    const dynamicPathSegements = outputPath.match(dynamicPathSegmentRegex);
    // @ts-ignore
    output[routeKey] = {
      relativeRoutePath: route.path,
      fullRoutePath: outputPath,
      getFullLinkPath: dynamicPathSegements
        ? (routeParams) =>
            dynamicPathSegements?.reduce(
              // @ts-ignore
              (tmpPath, param) => tmpPath.replace(`:${param}`, routeParams[param]),
              outputPath
            )
        : () => outputPath,
    };

    if (route.sub) {
      output[routeKey] = { ...output[routeKey], ...transformRoutes(route.sub, outputPath) };
    }
  }

  return output;
}
