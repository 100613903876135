import { Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgItemPackages, MgPackageType } from '@retail/products/types';
import {
  desiredPackagingOrder,
  getClassCodeLabel,
  getPackageTypeLabel,
  sortMgItemPackages,
} from '@retail/products/utils';
import { Chip } from '@shared/components';

interface Props {
  isSuperUser: boolean;
  packages: MgItemPackages;
  selectedPackage: string;
  setSelectedPackage: (selectedPackage: MgPackageType) => void;
}

export const MgPackagesButtonGroup = ({
  isSuperUser,
  packages,
  selectedPackage,
  setSelectedPackage,
}: Props) => {
  const { palette, shape, spacing } = useTheme();
  const t = useProductsTFunction();

  // @ts-ignore
  const sortedPackages: [MgPackageType, MgItemPackages[]][] = Object.entries(packages).sort(sortMgItemPackages);
  const emptyPackages = desiredPackagingOrder.filter((pkg) => !Object.keys(packages).includes(pkg));

  return (
    <Box
      display="flex"
      sx={{
        border: `1px solid ${palette.grey[100]}`,
        borderRadius: shape.borderRadius * 2,
        padding: spacing(0.5),
        width: 'fit-content',
      }}
    >
      {Object.values(sortedPackages).map(([key], index) => {
        const isSelected = key === selectedPackage;
        if (key === 'ADDITIONAL_GTIN' && !isSuperUser) return null;
        return (
          <Tooltip key={key} title={getPackageTypeLabel(t, key)}>
            <Chip
              label={<Typography fontWeight={600}>{getClassCodeLabel(key)}</Typography>}
              textColor={isSelected ? palette.common.white : undefined}
              backgroundColor={isSelected ? palette.grey[800] : 'none'}
              onClick={() => setSelectedPackage(key as MgPackageType)}
              sx={{ marginRight: index < Object.entries(packages).length ? spacing(0.25) : 0 }}
              rounded
            />
          </Tooltip>
        );
      })}

      {emptyPackages.map((packaging) => (
        <Tooltip key={packaging} title={getPackageTypeLabel(t, packaging)}>
          <div>
            <Chip
              disabled
              label={<Typography fontWeight={500}>{getClassCodeLabel(packaging)}</Typography>}
              rounded
            />
          </div>
        </Tooltip>
      ))}
    </Box>
  );
};
