import { IconButton, Stack, Tooltip } from '@mui/material';
import { useFreightTFunction } from '@retail/freight/i18n';
import { DeactivateIcon, EditButtonIcon, TrashIcon } from '@shared/custom-icons';

interface Props {
  editCondition: () => void;
  deactivateCondition?: () => void;
  deleteCondition?: () => void;
}

export const FreightTableActions = ({
  editCondition,
  deactivateCondition,
  deleteCondition,
}: Props) => {
  const t = useFreightTFunction();
  return (
    <Stack flexDirection="row" alignItems="center">
      {deactivateCondition && (
        <Tooltip title={t('freight.actions.deactivate')}>
          <IconButton onClick={deactivateCondition}>
            <DeactivateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {deleteCondition && (
        <Tooltip title={t('freight.actions.delete')}>
          <IconButton onClick={deleteCondition}>
            <TrashIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t('freight.actions.edit')}>
        <IconButton onClick={editCondition}>
          <EditButtonIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
