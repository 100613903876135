import { Pagination, Stack } from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';

import { useCallback } from 'react';
import { SelectPageSize } from '../Select';

interface Props {
  isDialog?: boolean;
  customRowsPerPageOptions?: number[];
}

export function DataGridPagination({ isDialog, customRowsPerPageOptions }: Props) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const standardRowsPerPageOptions = [2000, 4000, 8000, 10000];

  const rowsPerPageOptions = customRowsPerPageOptions ?? standardRowsPerPageOptions;

  const dialogRowsPerPageOptions = [200, 400, 600];

  const updatePageSize = useCallback(
    (newPageSize: number) => {
      apiRef.current.setPageSize(Number(newPageSize));
    },
    [apiRef]
  );

  return (
    <Stack flexDirection="row" alignItems="center">
      <SelectPageSize
        currentPageSize={pageSize}
        updatePageSize={updatePageSize}
        pageSizeOptions={isDialog ? dialogRowsPerPageOptions : rowsPerPageOptions}
      />
      <Pagination
        page={page + 1}
        count={pageCount}
        onChange={(_, newPage) => {
          apiRef.current.setPage(newPage - 1);
        }}
      />
    </Stack>
  );
}
