import { Remove } from '@mui/icons-material';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { AddIcon } from '@shared/custom-icons';
import { ProductGroupsTreeView } from '../ProductGroupsTree';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { OverGroup, ProductGroup } from '@retail/products/types';

interface Props {
  productGroups: OverGroup[];
  selectedProductGroup: ProductGroup | null;
  showProductGroups: boolean;
  toggleShowProductGroups: () => void;
  selectProductGroup: (productGroup: string | null) => void;
  getCount?: (productGroup: ProductGroup) => number | undefined;
}

export function ProductGroupFilter({
  productGroups,
  selectedProductGroup,
  showProductGroups,
  toggleShowProductGroups,
  selectProductGroup,
  getCount,
}: Props) {
  const t = useMyAssortmentTFunction();
  const { palette } = useTheme();

  return (
    <Stack>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2" color="text.secondary">
          {t('myAssortment.filters.productGroup')}
        </Typography>
        <IconButton onClick={toggleShowProductGroups}>
          {showProductGroups ? <Remove /> : <AddIcon stroke={palette.text.secondary} />}
        </IconButton>
      </Stack>
      {showProductGroups && (
        <ProductGroupsTreeView
          productGroups={productGroups}
          selectedProductGroup={selectedProductGroup}
          selectProductGroup={selectProductGroup}
          getCount={getCount}
        />
      )}
    </Stack>
  );
}
