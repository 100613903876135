import { useAppTFunction } from '@retail/app/i18n';
import { ToggleGropOption, ToggleGroup } from '@retail/components';
import { GroupOption } from '@retail/products/types';
import { useMemo } from 'react';

interface Props {
  label: string;
  selectedOption: GroupOption;
  onChangeOption: (option: GroupOption) => void;
  justifyContent?: string;
}

export const FilterSearchToggleGroup = ({
  label,
  selectedOption,
  onChangeOption,
  justifyContent,
}: Props) => {
  const t = useAppTFunction();
  const groupOptions: ToggleGropOption<GroupOption>[] = useMemo(
    () => [
      {
        label: t('suppliers.actions.hasStandardAgreement.unspecified'),
        value: 'UNSPECIFIED',
      },
      {
        label: t('suppliers.actions.hasStandardAgreement.yes'),
        value: 'YES',
      },
      {
        label: t('suppliers.actions.hasStandardAgreement.no'),
        value: 'NO',
      },
    ],
    [t]
  );

  return (
    <ToggleGroup
      size="small"
      label={label}
      options={groupOptions}
      value={selectedOption}
      onChange={onChangeOption}
      justifyContent={justifyContent}
    />
  );
};
