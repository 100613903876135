import { Stack } from '@mui/material';
import { StyledTextField } from './CalculusInfoRow';
import { ConfirmEditButtons } from '../ConfirmEditButtons';
import { HelperText } from '@shared/components';
import { useForm } from 'react-hook-form';
import { salesFactorSchema, SalesFactorSchema } from '@retail/markup/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { FC } from 'react';

interface Props {
  defaultValue: number;
  onSubmit: (value: number) => void;
  onCancel: () => void;
}

export const SalesFactorInput: FC<Props> = ({ defaultValue, onSubmit, onCancel }) => {
  const t = useCalculusTFunction();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<SalesFactorSchema>({
    defaultValues: { salesFactor: defaultValue },
    resolver: yupResolver(salesFactorSchema(t)),
  });

  const _onSubmit = handleSubmit(({ salesFactor }) => {
    onSubmit(salesFactor);
  });

  return (
    <Stack alignItems="flex-start">
      <Stack direction="row" alignItems="center">
        <StyledTextField
          {...register('salesFactor')}
          size="small"
          onClick={(e) => e.stopPropagation()}
          error={!!errors.salesFactor?.message}
        />
        <ConfirmEditButtons onSubmit={_onSubmit} onCancel={onCancel} />
      </Stack>
      {errors.salesFactor?.message && (
        <HelperText id="salesFactorInputError" status="error">
          {errors.salesFactor?.message}
        </HelperText>
      )}
    </Stack>
  );
};
