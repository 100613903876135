import { ChevronLeft } from '@mui/icons-material';
import { useAppTFunction } from '@retail/app/i18n';
import TextButton from './TextButton';

interface Props {
  showFilters: boolean;
  toggleFilters: () => void;
}

export function ToggleFiltersButton({ showFilters, toggleFilters }: Props) {
  const t = useAppTFunction();
  return (
    <TextButton
      onClick={toggleFilters}
      endIcon={
        <ChevronLeft
          sx={{
            transform: showFilters ? 'rotate(90deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      }
    >
      {showFilters ? t('products.search.hideFilters') : t('products.search.showFilters')}
    </TextButton>
  );
}
