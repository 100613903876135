import { TotalFileDownloadSupplier } from './TotalFileDownloadSupplier';
import { TotalFileDownloadDTO } from '../dtos';

export interface TotalFileDownloadProps {
  lastDownload?: string;
  totalStockItems?: number;
  totalNonStockItems?: number;
  totalItems?: number;
  suppliers?: TotalFileDownloadSupplier[];
}

export class TotalFileDownload {
  lastDownload?: string;
  totalStockItems?: number;
  totalNonStockItems?: number;
  totalItems?: number;
  suppliers?: TotalFileDownloadSupplier[];

  constructor({
    lastDownload,
    totalStockItems,
    totalNonStockItems,
    totalItems,
    suppliers,
  }: TotalFileDownloadProps) {
    this.lastDownload = lastDownload;
    this.totalStockItems = totalStockItems;
    this.totalNonStockItems = totalNonStockItems;
    this.totalItems = totalItems;
    this.suppliers = suppliers;
  }

  static fromDto(dto: TotalFileDownloadDTO): TotalFileDownload {
    return new TotalFileDownload({
      lastDownload: dto.lastDownload,
      totalStockItems: dto.totalStockItems,
      totalNonStockItems: dto.totalNonStockItems,
      totalItems: dto.totalItems,
      suppliers: dto.suppliers?.map((supplier) => TotalFileDownloadSupplier.fromDto(supplier)),
    });
  }
}
