import React, { FC } from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { FileUpload, ReadMore } from '@mui/icons-material';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  onShowAgreementsClick: () => void;
  onUploadAgreementsClick?: () => void;
}

export const SuppliersTableActions: FC<Props> = ({
  onShowAgreementsClick,
  onUploadAgreementsClick,
}) => {
  const t = usePurchaseAgreementsTFunction();

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={t('purchaseAgreements.supplier.viewAgreements')}>
        <IconButton onClick={onShowAgreementsClick}>
          <ReadMore />
        </IconButton>
      </Tooltip>
      {onUploadAgreementsClick && (
        <Tooltip title={t('purchaseAgreements.agreement.prices.upload.title')}>
          <IconButton onClick={onUploadAgreementsClick}>
            <FileUpload />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
