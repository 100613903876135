import { Typography } from '@mui/material';
import { Paper } from '@shared/components';
import { ItemInfo } from './ItemInfo';

interface Props {
  primaryText: string;
  secondaryText?: string;
  mgItemNumber: number;
  finfoNumber: number;
}

export function ItemInfoPaper({ primaryText, secondaryText, mgItemNumber, finfoNumber }: Props) {
  return (
    <Paper
      sx={{
        p: 1,
        border: ({ palette }) => `1px solid ${palette.primary.main}`,
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle2" align="center">
        {primaryText}
      </Typography>
      <Typography variant="body2" pb={1} align="center">
        {`${secondaryText}`}
      </Typography>

      <ItemInfo direction="column" mgItemNr={mgItemNumber} finfoNumber={finfoNumber} />
    </Paper>
  );
}
