import { conditionDatesSchema, levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';

export const percentFreightUnit = '%';

export const freightConditionsSchema = (t: CalculusTFunction) =>
  yup.object({
    freightFactor: yup
      .number()
      .typeError(t('calculus.error.mustBeNumberBetween1And2'))
      .transform((_, value: string | null) => {
        if (value) {
          return Number((value + '').replace(',', '.'));
        }
        return null;
      })
      .max(1, t('calculus.error.mustBeNumberBetween1And2'))
      .max(2, t('calculus.error.mustBeNumberBetween1And2'))
      .positive(t('calculus.error.mustBeNumberBetween1And2'))
      .nullable()
      .default(null),
    percentAddon: yup
      .number()
      .typeError(t('calculus.error.mustBeNumberBetween'))
      .transform((_, value: string | null) => {
        if (value) {
          return Number((value + '').replace(',', '.'));
        }
        return null;
      })
      .max(0, t('calculus.error.mustBeNumberBetween'))
      .max(100, t('calculus.error.mustBeNumberBetween'))
      .positive(t('calculus.error.mustBeNumberBetween'))
      .nullable()
      .default(null)
      .required(t('calculus.error.requiredField')),
  });

export const createFreightConditionSchema = (t: CalculusTFunction) =>
  levelSelectSchema(t).concat(conditionDatesSchema(t)).concat(freightConditionsSchema(t));

export const editFreightConditionSchema = (t: CalculusTFunction) =>
  conditionDatesSchema(t).concat(freightConditionsSchema(t));

export type CreateFreightConditionSchema = yup.InferType<
  ReturnType<typeof createFreightConditionSchema>
>;
export type EditFreightConditionSchema = yup.InferType<
  ReturnType<typeof editFreightConditionSchema>
>;
