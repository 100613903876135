import { Package } from './Package';
import { CurrencyPrice } from './CurrencyPrice';

interface PackagePriceProps {
  price: CurrencyPrice;
  supplierItemPackage: Package;
}

export class PackagePrice {
  price: CurrencyPrice;
  supplierItemPackage: Package;

  constructor(props: PackagePriceProps) {
    this.price = props.price;
    this.supplierItemPackage = props.supplierItemPackage;
  }

  static fromDto = (dto: PackagePriceProps): PackagePrice => {
    return new PackagePrice({
      price: dto.price,
      supplierItemPackage: Package.fromDto(dto.supplierItemPackage),
    });
  };
}
