import { List, ListItem, Stack, Typography } from '@mui/material';
import { PriceError } from '@retail/calculus/types';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

interface ErrorProps {
  errors: PriceError[];
}

export const ItemErrors = ({ errors }: ErrorProps) => {
  const t = useMyAssortmentTFunction();
  return (
    <Stack alignItems="flex-start">
      <Typography variant="body2"> {t('myAssortment.status.noPriceBecause')}: </Typography>
      <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }} disablePadding>
        {errors.map((error) => {
          if (error === PriceError.MISSING_AGREEMENT_PRICE) {
            return (
              <ListItem key={error} sx={{ display: 'list-item' }}>
                {t('myAssortment.status.noPurhcasePrice')}
              </ListItem>
            );
          } else if (error === PriceError.MISSING_STANDARD_AGREEMENT) {
            return (
              <ListItem key={error} sx={{ display: 'list-item' }}>
                {t('myAssortment.status.noStandardAgreement')}
              </ListItem>
            );
          } else {
            return (
              <ListItem key={error} sx={{ display: 'list-item' }}>
                {t('myAssortment.status.noMarkup')}
              </ListItem>
            );
          }
        })}
      </List>
    </Stack>
  );
};
