import { Typography, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Chip } from '@shared/components';
import { TrashIcon } from '@shared/custom-icons';

interface Props {
  resetFilters: () => void;
}

export const RemoveAllFiltersChip = ({ resetFilters }: Props) => {
  const t = useAppTFunction();
  const { palette } = useTheme();
  return (
    <Chip
      rounded
      label={
        <Typography color="text.secondary" variant="body2">
          {t('myAssortment.filters.clearFilters')}
        </Typography>
      }
      backgroundColor={palette.grey[50]}
      onDelete={resetFilters}
      onClick={resetFilters}
      deleteIcon={<TrashIcon />}
      sx={{ border: `2px solid ${palette.divider}` }}
    />
  );
};
