import React, { FC } from 'react';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { StarOutline } from '@mui/icons-material';

interface Props {
  onSetAsStandardAgreement: () => void;
}

export const AgreementsTableActions: FC<Props> = ({ onSetAsStandardAgreement }) => {
  const t = usePurchaseAgreementsTFunction();

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={t('purchaseAgreements.supplier.standardAgreement.set')}>
        <IconButton onClick={onSetAsStandardAgreement}>
          <StarOutline />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
