import { AssortmentDTO, AssortmentTypeDTO, Source } from '../dto';

export interface AssortmentProps {
  id: number;
  title: string;
  source: Source;
  updatedAt: string;
  createdAt: string;
  type: AssortmentTypeDTO;
  readOnly: boolean;
  parent?: number;
  size: number;
}

export class Assortment {
  id: number;
  title: string;
  source: Source;
  updatedAt: string;
  createdAt: string;
  type: AssortmentTypeDTO;
  readOnly: boolean;
  parent?: number;
  size: number;

  constructor({
    id,
    title,
    source,
    updatedAt,
    createdAt,
    type,
    readOnly,
    parent,
    size,
  }: AssortmentProps) {
    this.id = id;
    this.title = title;
    this.source = source;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.type = type;
    this.readOnly = readOnly;
    this.parent = parent;
    this.size = size;
  }

  static fromDTO = (dto: AssortmentDTO): Assortment =>
    new Assortment({
      id: dto.id,
      title: dto.title,
      source: dto.source,
      updatedAt: dto.updatedAt,
      createdAt: dto.createdAt,
      type: dto.type,
      readOnly: dto.readOnly,
      parent: dto.parent,
      size: dto.size,
    });
}
