import { Stack } from '@mui/material';
import { DiscountGroup } from '@retail/calculus/types';
import { FilterChip, RemoveAllFiltersChip } from '@retail/components';
import { ProductGroup } from '@retail/products/types';
import { Supplier } from '@retail/suppliers/types';
import { useCallback } from 'react';

interface Props {
  selectedSupplier: Supplier | null;
  selectedDiscountGroup: DiscountGroup | null;
  selectedProductGroup: ProductGroup | null;
  searchQuery?: string;
  selectSupplierById: (id: number | null) => void;
  selectDiscountGroupById: (id: number | null) => void;
  selectProductGroupByNr: (nr: string | null) => void;
  setSearchQuery: (query: string) => void;
}

export function MyAssortmentSearchFilterChips({
  selectedSupplier,
  selectedDiscountGroup,
  selectedProductGroup,
  searchQuery,
  selectSupplierById,
  selectDiscountGroupById,
  selectProductGroupByNr,
  setSearchQuery,
}: Props) {
  const resetFilters = useCallback(() => {
    selectSupplierById(null);
    selectDiscountGroupById(null);
    selectProductGroupByNr(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack flexDirection="row" gap={1} alignItems="center" pb={1}>
      {selectedSupplier && (
        <FilterChip
          id={selectedSupplier.mgSupplierId}
          name={selectedSupplier.name}
          onDelete={() => selectSupplierById(null)}
        />
      )}
      {selectedDiscountGroup && (
        <FilterChip
          id={selectedDiscountGroup.id}
          name={selectedDiscountGroup.name ?? ''}
          onDelete={() => selectDiscountGroupById(null)}
        />
      )}

      {selectedProductGroup && (
        <FilterChip
          id={selectedProductGroup.groupNumber}
          name={selectedProductGroup.getLabel()}
          onDelete={() => selectProductGroupByNr(null)}
        />
      )}
      {searchQuery && (
        <FilterChip id={searchQuery} name={searchQuery} onDelete={() => setSearchQuery('')} />
      )}
      {(selectedSupplier || selectedDiscountGroup || selectedProductGroup || !!searchQuery) && (
        <RemoveAllFiltersChip resetFilters={resetFilters} />
      )}
    </Stack>
  );
}
