import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ActiveTagPaper } from '@retail/components';
import { PurchaseCondition } from '@retail/products/types';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { CalculusInfoRow } from './CalculusInfoRow';
import { InfinityIcon } from '@shared/custom-icons';
import { useMemo } from 'react';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  purchaseCondition?: PurchaseCondition;
  purchasePrice?: number;
  unit?: string;
}

export function PurchaseAccordion({ purchaseCondition, unit, purchasePrice }: Props) {
  const t = useAppTFunction();
  const { i18n } = useTranslation();

  const purchasePriceFormatted = useMemo(
    () => (purchasePrice ? formatPrice(purchasePrice, i18n.language) : undefined),
    [i18n.language, purchasePrice]
  );

  return (
    <Accordion>
      <AccordionSummary>
        <CalculusInfoRow
          label={t('myAssortment.columns.purchasePrice')}
          chipLabel={purchasePriceFormatted ?? '-'}
          unit={unit}
        />
      </AccordionSummary>
      <AccordionDetails>
        <ActiveTagPaper>
          {purchaseCondition ? (
            <Grid container gap={2} flexDirection="column">
              <Stack gap={4} flexDirection="row" fontSize={14}>
                <Stack gap={3} flexDirection="row" fontSize={14}>
                  <Grid item fontWeight={600}>
                    {t('purchaseAgreements.agreement.columns.agreementText')}
                  </Grid>
                  <Grid item>{purchaseCondition.agreement.agreementName}</Grid>
                </Stack>
                <Stack gap={3} flexDirection="row" fontSize={14}>
                  <Grid item fontWeight={600}>
                    {t('purchaseAgreements.agreement.columns.agreementId')}
                  </Grid>
                  <Grid item>{purchaseCondition.agreement.agreementId}</Grid>
                </Stack>
              </Stack>
              <Stack gap={4} flexDirection="row" fontSize={14}>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('purchaseAgreements.agreement.columns.fromDate')}
                  </Grid>
                  <Grid item>{dayjs(purchaseCondition.condition.validFrom).format('ll')}</Grid>
                </Stack>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('purchaseAgreements.agreement.columns.toDate')}
                  </Grid>
                  <Grid item>
                    {purchaseCondition.condition.validTo ? (
                      dayjs(purchaseCondition.condition.validTo).format('ll')
                    ) : (
                      <InfinityIcon />
                    )}
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Typography>{t('myAssortment.productCard.noActivePurchaseCondition')}</Typography>
          )}
        </ActiveTagPaper>
      </AccordionDetails>
    </Accordion>
  );
}
