import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExcelIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="11" height="16" viewBox="0 0 11 16">
    <path
      d="M6.41667 4.48438V0.5H0.6875C0.30651 0.5 0 0.813477 0 1.20312V14.7969C0 15.1865 0.30651 15.5 0.6875 15.5H10.3125C10.6935 15.5 11 15.1865 11 14.7969V5.1875H7.10417C6.72604 5.1875 6.41667 4.87109 6.41667 4.48438ZM8.13828 7.60449L6.41667 10.3438L8.13828 13.083C8.28438 13.3174 8.12109 13.625 7.84896 13.625H6.84922C6.72318 13.625 6.60573 13.5547 6.54557 13.4404C5.98411 12.3799 5.5 11.4277 5.5 11.4277C5.31667 11.8613 5.21354 12.0137 4.45156 13.4434C4.39141 13.5576 4.27682 13.6279 4.15078 13.6279H3.15104C2.87891 13.6279 2.71563 13.3203 2.86172 13.0859L4.58906 10.3467L2.86172 7.60742C2.71276 7.37305 2.87891 7.06543 3.15104 7.06543H4.14792C4.27396 7.06543 4.39141 7.13574 4.45156 7.25C5.19922 8.67969 5.02448 8.23438 5.5 9.25684C5.5 9.25684 5.67474 8.91406 6.54844 7.25C6.60859 7.13574 6.72604 7.06543 6.85208 7.06543H7.84896C8.12109 7.0625 8.28438 7.37012 8.13828 7.60449ZM11 4.07129V4.25H7.33333V0.5H7.50807C7.69141 0.5 7.86615 0.573242 7.99505 0.705078L10.7995 3.57617C10.9284 3.70801 11 3.88672 11 4.07129Z"
      fill="inherit"
    />
  </SvgIcon>
);
