import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import dayjs from 'dayjs';
import { CustomColumnMenu, StackedTextCell } from '@retail/components';
import { Box, Link, Stack, Typography } from '@mui/material';
import { CheckIcon, InfinityIcon, MGPrisIcon } from '@shared/custom-icons';
import { AssortmentType, HierarchyLevels, PurchaseCondition } from '@retail/calculus/types';
import { PurchaseConditionsTableActions } from './PurchaseConditionsTableActions';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { Supplier } from '@retail/suppliers/types';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';
import { LevelChip } from '@retail/calculus/components';

interface Props {
  conditions: PurchaseCondition[];
  supplier: Supplier;
  language: string;
  handleActivate: (agreement: PurchaseCondition) => void;
  handleDeactivate: (agreement: PurchaseCondition) => void;
  handleEdit: (agreement: PurchaseCondition) => void;
  handleDelete: (conditionId: number) => void;
  openPriceListModal: (agreement: number, productGroupId: string) => void;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 140,
  sortable: false,
  headerAlign: 'left',
  align: 'left',
};

export function ConditionsTable({
  conditions,
  supplier,
  language,
  handleActivate,
  handleDeactivate,
  handleEdit,
  handleDelete,
  openPriceListModal,
}: Props) {
  const t = usePurchaseAgreementsTFunction();

  const hasActiveSupplierAgreement = useMemo(
    () => conditions.some((condition) => condition.bindingType === HierarchyLevels.supplier),
    [conditions]
  );
  const { selectedLevel } = useLevelSearchParams();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('purchaseAgreements.agreement.columns.type'),
        width: 190,
        renderCell: ({ row }) => {
          return (
            <LevelChip
              bindingType={row.bindingType ?? (row.current ? HierarchyLevels.supplier : undefined)}
              isAgreement={!row.id}
              height={row.standardAgreement ? 45 : undefined}
              labelDetails={
                row.standardAgreement && (
                  <Typography variant="body3" justifyContent="center">
                    <Stack flexDirection="row" gap={0.5}>
                      <MGPrisIcon fontSize="small" />
                      {t('purchaseAgreements.agreement.columns.standardAgreement')}
                    </Stack>
                  </Typography>
                )
              }
              active={
                row.assortmentType === AssortmentType.BASE ||
                (row.standardAgreement &&
                  !hasActiveSupplierAgreement &&
                  selectedLevel === HierarchyLevels.supplier)
              }
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'name',
        headerName: t('purchaseAgreements.agreement.columns.levelOrItemName'),
        width: 260,
        renderCell: ({ row }) => {
          const name = row.productGroupName ?? row.primaryText ?? supplier.name;
          const id = row.productGroup ?? row.mgItemNumber ?? supplier.mgSupplierId;
          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'agreementId',
        headerName: t('purchaseAgreements.agreement.columns.agreementId'),
      },
      {
        ...baseColumnProps,
        field: 'agreementText',
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
        width: 180,
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('purchaseAgreements.agreement.columns.validFrom'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('purchaseAgreements.agreement.columns.validTo'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : <InfinityIcon />),
      },

      {
        ...baseColumnProps,
        field: 'standardAgreement',
        headerName: t('purchaseAgreements.agreement.columns.standardAgreement'),
        headerAlign: 'center',
        align: 'center',
        width: 170,
        renderCell: ({ value }) =>
          value ? (
            <Stack direction="row" spacing={1}>
              <CheckIcon />
            </Stack>
          ) : (
            t('purchaseAgreements.no')
          ),
      },
      {
        ...baseColumnProps,
        field: 'totalItems',
        headerName: t('purchaseAgreements.agreement.columns.priceList'),
        renderCell: ({ row }) => (
          <Link
            component="button"
            onClick={() => openPriceListModal(row.agreementId, row.productGroup)}
            fontWeight={600}
          >
            {t('purchaseAgreements.priceList.lookUpPrices')}
          </Link>
        ),
      },
      {
        ...baseColumnProps,
        field: 'channel',
        headerName: t('purchaseAgreements.agreement.columns.channel'),
      },
      {
        ...baseColumnProps,
        field: 'source',
        headerName: t('purchaseAgreements.agreement.columns.source'),
      },
      {
        ...baseColumnProps,
        field: 'agreementUpdatedAt',
        headerName: t('purchaseAgreements.agreement.columns.lastUpdated'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        width: 90,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <PurchaseConditionsTableActions
            handleActivate={
              (!row.id && (!row.standardAgreement || selectedLevel !== HierarchyLevels.supplier)) ||
              (row.standardAgreement && !row.current)
                ? () => handleActivate(row)
                : undefined
            }
            handleDeactivate={
              row.id && row.assortmentType !== AssortmentType.DRAFT
                ? () => handleDeactivate(row)
                : undefined
            }
            handleEdit={
              row.id || (row.standardAgreement && row.current) ? () => handleEdit(row) : undefined
            }
            handleDelete={
              row.assortmentType === AssortmentType.DRAFT ? () => handleDelete(row.id) : undefined
            }
          />
        ),
      },
    ],
    [
      t,
      hasActiveSupplierAgreement,
      selectedLevel,
      supplier.name,
      supplier.mgSupplierId,
      openPriceListModal,
      handleActivate,
      handleDeactivate,
      handleEdit,
      handleDelete,
    ]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          columns={columns}
          rows={conditions}
          getRowId={(row) => `${row.agreementId}${row.id}`}
          rowHeight={70}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_PURCHASE_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_PURCHASE_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          slots={{
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
          hideFooter
        />
      </Box>
    </Box>
  );
}
