import { transformRoutes } from '@shared/utils';

const routeDefs = {
  purchaseAgreements: {
    path: '/purchase-agreements',
    sub: {
      prices: { path: '/prices' },
      agreement: {
        path: '/:agreementId',
        sub: {
          prices: { path: '/prices' },
        },
      },
    },
  },
  assortments: {
    path: '/assortments',
    sub: {
      assortment: {
        path: '/:assortmentId',
        sub: {
          assortmentXlsx: { path: '/assortment.xlsx' },
          items: { path: '/items' },
          productGroups: { path: '/product-groups' },
          suppliers: { path: '/suppliers' },
          activateConditions: { path: '/activate-conditions' },
          affectedSupplierItems: { path: '/affected-supplier-items' },
          agreementPrices: { path: '/agreement-prices' },
          conditionHierarchy: { path: '/condition-hierarchy' },
          deleteConditions: { path: '/delete-conditions' },
          deleteItems: { path: '/delete-items' },
          purchaseConditions: { path: '/purchase-conditions' },
          purchaseCondition: { path: '/purchase-condition' },
          freightConditions: { path: '/freight-conditions' },
          freightCondition: { path: '/freight-condition' },
          markupConditions: { path: '/markup-conditions' },
          markupCondition: { path: '/markup-condition' },
          conditions: { path: '/conditions' },
          createConditions: { path: '/create-conditions' },
        },
      },
      validatePriorities: { path: '/validate-priorities' },
    },
  },
  brands: {
    path: '/brands',
    sub: {
      brand: {
        path: '/:brandId',
        sub: {
          assortments: { path: '/assortments' },
          members: { path: '/members' },
          myOrgUnits: { path: '/my-org-units' },
        },
      },
    },
  },
  departments: {
    path: '/departments',
    sub: {
      department: {
        path: '/:departmentId',
        sub: {
          assortments: { path: '/assortments' },
        },
      },
    },
  },
  members: {
    path: '/members',
    sub: {
      member: {
        path: '/:memberId',
        sub: {
          assortments: {
            path: '/assortments',
            sub: {
              assortment: {
                path: '/:assortmentId',
                sub: {
                  items: { path: '/items' },
                  activateItems: { path: '/activate-items' },
                  deleteItems: { path: '/delete-items' },
                },
              },
            },
          },
        },
      },
    },
  },
  mgItems: {
    path: '/mg-items',
    sub: {
      mgItemNumber: {
        path: '/:mgItemNumber',
        sub: {
          json: { path: '/json' },
          assortments: { path: '/assortments' },
        },
      },
      supplierItems: { path: '/supplier-items' },
    },
  },
  suppliers: {
    path: '/suppliers',
    sub: {
      supplier: {
        path: '/:supplierId',
        sub: {
          agreementPrices: { path: '/agreement-prices' },
          suppliersToMgpbo: { path: '/suppliers-to-mgpbo' },
          productGroups: { path: '/product-groups' },
          discountGroups: { path: '/discount-groups' },
          items: {
            path: '/items',
            sub: {
              item: {
                path: '/:mgItemNumber',
                sub: {
                  agreementPrices: { path: '/agreement-prices' },
                },
              },
            },
          },
        },
      },
    },
  },
  sync: {
    path: '/sync',
    sub: {
      syncAllMgitems: { path: '/sync-all-mgitems' },
      syncAllAssortments: { path: '/sync-all-assortments' },
      resyncAssortment: { path: '/resync-assortment/:assortment-id' },
      resyncAllAssortments: { path: '/resync-all-assortments' },
      pricesToMgpbo: { path: '/prices-to-mgpbo' },
      dateLimitedEntity: { path: '/date-limited-entity' },
      mgpboItem: { path: '/mgpbo-item/:mgItemNumber' },
    },
  },
  orgUnits: {
    path: '/org-units',
    sub: {
      orgUnit: {
        path: '/:orgUnitId',
        sub: {
          competitors: { path: '/competitors' },
        },
      },
    },
  },
  monitoringAssortments: {
    path: '/monitoring-assortments',
    sub: {
      assortment: {
        path: '/:assortmentId',
        sub: {
          items: { path: '/items' },
        },
      },
      validateItemCodes: { path: '/validate-item-codes' },
    },
  },
  markupTemplates: {
    path: '/markup-templates',
    sub: {
      template: {
        path: '/:templateId',
      },
    },
  },
  retailers: {
    path: '/retailers',
  },
  users: {
    path: '/users',
    sub: {
      me: { path: '/me' },
    },
  },
  productGroups: {
    path: '/product-groups',
  },
  discountGroups: {
    path: '/discount-groups',
  },
  export: {
    path: '/totalfil',
    sub: {
      calculatePrices: { path: '/calculate-all-prices' },
      loadAllItems: { path: '/initial-load-all-items' },
      summary: {
        path: '/summary/:assortmentId',
      },
      downloadZip: {
        path: '/download/:assortmentId',
      },
      downloadCSV: {
        path: '/download-csv/:assortmentId',
      },
    },
  },
} as const;

export const mpeApi = transformRoutes(routeDefs, '/api/v1');
