import { mgPrisRoutes } from '@retail/mgpris/config';
import { useFetchAllSuppliers } from '@retail/products/data-access';
import { Supplier } from '@retail/suppliers/types';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDepartmentAssortments } from '@retail/my-assortment/context';

interface Props {
  supplierId: number;
}

export const useSelectSupplier = ({ supplierId }: Props) => {
  const navigate = useNavigate();
  const { draftAssortmentId } = useDepartmentAssortments();

  const { data: supplierDtos = [] } = useFetchAllSuppliers({ assortmentId: draftAssortmentId });
  const suppliers = useMemo(() => supplierDtos.map(Supplier.fromMinimalDTO), [supplierDtos]);

  const selectedSupplier = useMemo(() => {
    return suppliers.find((supplier) => supplier.mgSupplierId === Number(supplierId));
  }, [supplierId, suppliers]);

  const navigateToSupplier = useCallback(
    (id: number) => navigate(mgPrisRoutes.calculus.supplier.getFullLinkPath({ supplierId: id })),
    [navigate]
  );

  return {
    suppliers,
    selectedSupplier,
    navigateToSupplier,
  };
};
