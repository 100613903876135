import { useFetchAllDiscountGroups } from '@retail/calculus/data-access';
import { DiscountGroup } from '@retail/calculus/types';
import { discountGroupSearchParamKey } from '@retail/products/utils';
import { Supplier } from '@retail/suppliers/types';
import { useMultipleSelectedSearchParamState, useSelectedSearchParamState } from '@shared/hooks';

interface Props {
  selectedSuppliers: Supplier[] | null;
  assortmentId?: number;
}

export const useSelectDiscountGroups = ({ selectedSuppliers, assortmentId }: Props) => {
  const { data: discountGroupDtos = [] } = useFetchAllDiscountGroups({
    supplierIds: selectedSuppliers?.map((supplier) => supplier.mgSupplierId),
    assortmentId,
    suspense: true,
    enabled: (selectedSuppliers && selectedSuppliers?.length > 0) ?? false,
  });

  const discountGroups = discountGroupDtos
    .map(DiscountGroup.fromDto)
    .sort((a, b) => `${a?.mgSupplierId} ${a?.code}`.localeCompare(`${b?.mgSupplierId} ${b?.code}`));

  const [selectedDiscountGroups, selectDiscountGroups] = useMultipleSelectedSearchParamState({
    options: discountGroups,
    getOptionId: ({ id }) => id,
    searchParamKey: discountGroupSearchParamKey,
  });

  const [selectedDiscountGroup, selectDiscountGroup, selectDiscountGroupById] =
    useSelectedSearchParamState({
      options: discountGroups,
      getOptionId: ({ id }) => id,
      searchParamKey: discountGroupSearchParamKey,
    });

  return {
    discountGroups,
    selectedDiscountGroups,
    selectDiscountGroups,
    selectedDiscountGroup,
    selectDiscountGroup,
    selectDiscountGroupById,
  };
};
