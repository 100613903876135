import { PurchaseConditionDTO } from '../../dto';
import { Condition } from './Condition';

interface AgreementMinimal {
  agreementId: number;
  agreementName?: string;
}

interface PurchaseConditionProps {
  condition: Condition;
  agreement: AgreementMinimal;
}

export class PurchaseCondition {
  condition: Condition;
  agreement: AgreementMinimal;

  constructor(props: PurchaseConditionProps) {
    this.condition = props.condition;
    this.agreement = props.agreement;
  }

  static fromDto = (dto: PurchaseConditionDTO): PurchaseCondition =>
    new PurchaseCondition({
      condition: Condition.fromDto(dto.condition),
      agreement: dto.agreement,
    });
}
