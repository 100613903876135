import { Stack, Typography } from '@mui/material';
interface Props {
  groupNumber: string;
  name: string | undefined;
  count?: number;
}

export function NodeLabel({ groupNumber, name, count }: Props) {
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
      {`${groupNumber} ${name ? name : ''}`}
      <Typography variant="body1">{count ? `(${count})` : ''}</Typography>
    </Stack>
  );
}
