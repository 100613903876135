import { SuppliersSearchParams, useFetchAllSuppliers } from '@retail/products/data-access';
import { discountGroupSearchParamKey, supplierSearchParamKey } from '@retail/products/utils';
import { Supplier } from '@retail/suppliers/types';
import { useMultipleSelectedSearchParamState, useSelectedSearchParamState } from '@shared/hooks';
import { useMemo, useState } from 'react';

export const useSelectSuppliers = (searchParams?: SuppliersSearchParams) => {
  const { data: suppliersDtos } = useFetchAllSuppliers(searchParams);
  const suppliers = useMemo(
    () => suppliersDtos?.map(Supplier.fromMinimalDTO) || [],
    [suppliersDtos]
  );

  const [selectedSuppliers, selectSuppliers] = useMultipleSelectedSearchParamState({
    options: suppliers,
    getOptionId: ({ mgSupplierId }) => mgSupplierId,
    searchParamKey: supplierSearchParamKey,
  });

  const [selectedSupplierId, selectSupplierId] = useState<number | null>(null);

  const [selectedSupplier, selectSupplier, selectSupplierById] = useSelectedSearchParamState({
    options: suppliers,
    getOptionId: ({ mgSupplierId }) => mgSupplierId,
    searchParamKey: supplierSearchParamKey,
    resetContiguousSearchParamKey: discountGroupSearchParamKey,
  });

  return {
    suppliers,
    selectedSuppliers,
    selectSuppliers,
    selectedSupplierId,
    selectSupplierId,
    selectedSupplier,
    selectSupplier,
    selectSupplierById,
  };
};
