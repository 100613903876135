import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  dataGridCellNoFocusOutlineClass,
  dataGridColorRowClass,
  dataGridHorizontalPaddingSmallClass,
  dataGridRowHoverClass,
  dataGridSelectedRowClass,
  dataGridWhiteRowClass,
} from '@shared/styles';
import { AssortmentItem, AssortmentTab } from '@retail/my-assortment/types';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { AddItemsButton } from '../AddItemsButton';
import { CustomColumnMenu, DataGridPagination } from '@retail/components';
import clsx from 'clsx';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import { useAssortmentColumns } from './useAssortmentColumns';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';

interface Props {
  items: AssortmentItem[];
  selectedItem: AssortmentItem | null;
  page: number;
  pageSize: number;
  totalItems: number;
  includeVat: boolean;
  selectedTab: AssortmentTab;
  itemsChecked: number[];
  language: string;
  getSupplierCalculusLink?: (supplierId: number) => string;
  toggleAddItemsDialog: () => void;
  viewItemProductCard: (itemId: number) => void;
  setPage: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
  setItemsChecked: Dispatch<SetStateAction<number[]>>;
  deleteItem: (itemId: number) => void;
  selectSupplierById: (supplierId: number | null) => void;
  selectProductGroupByNr: (productGroupNr: string | null) => void;
  selectDiscountGroupById: (discountGroupId: number | null) => void;
  showBracketPriceColumns?: boolean;
}

export const AssortmentItemsTable = ({
  items,
  selectedItem,
  page,
  pageSize,
  totalItems,
  includeVat,
  selectedTab,
  itemsChecked,
  language,
  getSupplierCalculusLink,
  toggleAddItemsDialog,
  viewItemProductCard,
  setPage,
  setPageSize,
  setItemsChecked,
  deleteItem,
  selectSupplierById,
  selectProductGroupByNr,
  selectDiscountGroupById,
  showBracketPriceColumns = false,
}: Props) => {
  const { t } = useMyAssortmentTranslation();
  const lastMgItemNumber = useRef<number | undefined>(items[0]?.mgItemNumber);
  const rowColor = useRef<string>(dataGridColorRowClass);

  const onRowsSelectionHandler = (selections: GridRowSelectionModel) => {
    setItemsChecked(selections as number[]);
  };

  const selectRowId = useCallback(
    (rowId: number) => viewItemProductCard(rowId),
    [viewItemProductCard]
  );

  const columnConfigs = useAssortmentColumns({
    includeVat,
    deleteItem,
    getSupplierCalculusLink,
    selectSupplier: selectSupplierById,
    selectProductGroup: selectProductGroupByNr,
    selectDiscountGroup: selectDiscountGroupById,
    selectRowId,
  });

  const columns = useMemo(
    () =>
      columnConfigs
        .filter(({ show }) => show({ selectedTab, showBracketPriceColumns }))
        .map(({ getColumn }) => getColumn()),
    [columnConfigs, selectedTab, showBracketPriceColumns]
  );

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<AssortmentItem>) => {
      if (row.mgItemNumber !== lastMgItemNumber.current) {
        rowColor.current =
          rowColor.current === dataGridWhiteRowClass
            ? dataGridColorRowClass
            : dataGridWhiteRowClass;
        lastMgItemNumber.current = row.mgItemNumber;
      }

      return clsx(rowColor.current, dataGridRowHoverClass, {
        [dataGridSelectedRowClass]: selectedItem?.id === row.id,
      });
    },
    [selectedItem?.id]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium<AssortmentItem>
          key={selectedTab}
          columns={columns}
          rows={items}
          getRowId={(row) => row.id}
          // @ts-ignore
          getRowClassName={getRowClassName}
          getCellClassName={() => clsx(dataGridCellNoFocusOutlineClass)}
          className={dataGridHorizontalPaddingSmallClass}
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          rowHeight={65}
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnReorder
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  selectedTab === AssortmentTab.MY_ASSORTMENT
                    ? DATA_GRID_ID.ASSORTMENT_ITEMS_TABLE
                    : DATA_GRID_ID.ASSORTMENT_PRICE_CHANGES_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          rowCount={totalItems}
          pagination={true}
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);

            saveHiddenDataGridColumnsToLocalStorage(
              selectedTab === AssortmentTab.MY_ASSORTMENT
                ? DATA_GRID_ID.ASSORTMENT_ITEMS_TABLE
                : DATA_GRID_ID.ASSORTMENT_PRICE_CHANGES_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          onPaginationModelChange={(model) => {
            if (model.page !== page) setPage(model.page);
            if (model.pageSize !== pageSize) setPageSize(model.pageSize);
          }}
          rowSelectionModel={itemsChecked}
          onRowSelectionModelChange={onRowsSelectionHandler}
          slots={{
            columnMenu: CustomColumnMenu,
            pagination: () => <DataGridPagination />,
            noRowsOverlay: () => (
              <Stack
                alignItems="center"
                justifyContent="center"
                gap={2}
                height="100%"
                sx={{ position: 'relative', zIndex: 1, pointerEvents: 'all' }}
                // https://github.com/mui/mui-x/issues/5725
              >
                <Typography variant="h3" color="text.secondary">
                  {t('myAssortment.noItems')}
                </Typography>

                <AddItemsButton onClick={toggleAddItemsDialog} />
              </Stack>
            ),
          }}
          slotProps={{ ...columnsManagementSlotProps }}
        />
      </Box>
    </Box>
  );
};
