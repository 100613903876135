import { TotalFileDownloadSupplierDTO } from '../dtos';

export interface TotalFileDownloadSupplierProps {
  mgSupplierId: number;
  mgSupplierName?: string;
  finfoSupplierId?: number;
  gln?: number;
  stockItems?: number;
  nonStockItems?: number;
}

export class TotalFileDownloadSupplier {
  mgSupplierId: number;
  mgSupplierName?: string;
  finfoSupplierId?: number;
  gln?: number;
  stockItems?: number;
  nonStockItems?: number;

  constructor({
    mgSupplierId,
    mgSupplierName,
    finfoSupplierId,
    gln,
    stockItems,
    nonStockItems,
  }: TotalFileDownloadSupplierProps) {
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.finfoSupplierId = finfoSupplierId;
    this.gln = gln;
    this.stockItems = stockItems;
    this.nonStockItems = nonStockItems;
  }

  static fromDto(dto: TotalFileDownloadSupplierDTO): TotalFileDownloadSupplier {
    return new TotalFileDownloadSupplier({
      mgSupplierId: dto.mgSupplierId,
      mgSupplierName: dto.mgSupplierName,
      finfoSupplierId: dto.finfoSupplierId,
      gln: dto.gln,
      stockItems: dto.stockItems,
      nonStockItems: dto.nonStockItems,
    });
  }
}
