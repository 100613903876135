import { ItemPriority, MpeMgSupplierItemDTO } from '../../dto';
import { MgSupplierItemPackage } from './MgSupplierItemPackage';
import { MgItemBk04Varegruppe } from './MgItemBk04Varegruppe';
import { MgItemEtim } from './MgItemEtim';
import { MgItemEnvironment } from './MgItemEnvironment';
import { MgItemMarketing } from './MgItemMarketing';
import { MgPackageType, salesPackagePriority, SalesPackageType } from './MgPackage';
import { DiscountGroup } from '@retail/calculus/types';
import { MgItemPackage, MgItemPackages } from './MgItemPackages';
import { MarkupCondition, PriceCalculation } from '../price';

export interface MgSupplierItemBase {
  finfoNumber?: number;
  primaryText: string;
  mainSupplier?: boolean;
}

interface MgSupplierItemSupplier {
  id: number;
  name: string;
}

interface ConstructorProps {
  id: string;
  mainSupplier: boolean;

  primaryText: string;
  secondaryText: string;
  bk04Varegruppe?: MgItemBk04Varegruppe;
  prevMgItemNumber?: number;
  registrationDate?: string;
  discontinuationDate?: string;
  discountGroup?: DiscountGroup;
  dangerousGoods?: string;
  fscPefcClaim?: string;

  articleType?: number;
  finfoNumber?: number;
  nobbNumber?: number;

  packages: MgSupplierItemPackage[];
  mgParticipant: MgSupplierItemSupplier;
  mgSupplierId: number;
  mgSupplierName: string;
  mgSupplierItemId?: number;
  supplierProductName?: string;
  replacedBySupplierItemId?: string;

  basePrice?: number;
  basePriceDate?: string;
  basePriceUnit: string;
  recommendedSalesPrice?: number;
  vatCode?: string;
  commodityCode?: string;

  etim?: MgItemEtim;
  environment?: MgItemEnvironment;
  marketing?: MgItemMarketing;

  supplierItemId?: number;
  supplierGln?: number;
  supplierOrgNumber?: number;
  finfoSupplierId?: number;

  originCountry?: string;

  webProductName?: string;
  textIngress?: string;
  textFull?: string;
  aiDescription?: string;

  currentSalesPrices: PriceCalculation;
  priority: ItemPriority;
}

export class MgSupplierItem implements MgSupplierItemBase {
  id: string;
  mainSupplier: boolean;

  primaryText: string;
  secondaryText: string;
  bk04Varegruppe?: MgItemBk04Varegruppe;
  mgParticipant: MgSupplierItemSupplier;
  packages: MgSupplierItemPackage[];
  prevMgItemNumber?: number;
  registrationDate?: string;
  discontinuationDate?: string;
  discountGroup?: DiscountGroup;
  dangerousGoods?: string;
  fscPefcClaim?: string;

  articleType?: number;
  finfoNumber?: number;
  nobbNumber?: number;

  mgSupplierId: number;
  mgSupplierName: string;
  mgSupplierItemId?: number;
  supplierProductName?: string;
  replacedBySupplierItemId?: string;

  basePrice?: number;
  basePriceDate?: string;
  basePriceUnit: string;
  recommendedSalesPrice?: number;
  vatCode?: string;
  commodityCode?: string;

  etim?: MgItemEtim;
  environment?: MgItemEnvironment;
  marketing?: MgItemMarketing;

  supplierItemId?: number;
  supplierGln?: number;
  supplierOrgNumber?: number;
  finfoSupplierId?: number;

  originCountry?: string;

  webProductName?: string;
  textIngress?: string;
  textFull?: string;
  aiDescription?: string;

  priority: ItemPriority;
  currentSalesPrices: PriceCalculation;

  constructor({
    id,
    mainSupplier,

    primaryText,
    secondaryText,
    bk04Varegruppe,
    mgParticipant,
    packages,
    prevMgItemNumber,
    registrationDate,
    discontinuationDate,
    discountGroup,
    dangerousGoods,
    fscPefcClaim,

    articleType,
    finfoNumber,
    nobbNumber,

    mgSupplierId,
    mgSupplierName,
    mgSupplierItemId,
    supplierProductName,
    replacedBySupplierItemId,

    basePrice,
    basePriceDate,
    basePriceUnit,
    recommendedSalesPrice,
    vatCode,
    commodityCode,

    etim,
    environment,
    marketing,

    supplierItemId,
    supplierGln,
    supplierOrgNumber,
    finfoSupplierId,

    originCountry,

    webProductName,
    textIngress,
    textFull,
    aiDescription,

    currentSalesPrices,
    priority,
  }: ConstructorProps) {
    this.id = id;
    this.mainSupplier = mainSupplier;

    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.bk04Varegruppe = bk04Varegruppe;
    this.prevMgItemNumber = prevMgItemNumber;
    this.registrationDate = registrationDate;
    this.discontinuationDate = discontinuationDate;
    this.discountGroup = discountGroup;
    this.dangerousGoods = dangerousGoods;
    this.fscPefcClaim = fscPefcClaim;

    this.articleType = articleType;
    this.finfoNumber = finfoNumber;
    this.nobbNumber = nobbNumber;

    this.packages = packages;
    this.mgParticipant = mgParticipant;
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.mgSupplierItemId = mgSupplierItemId;
    this.supplierProductName = supplierProductName;
    this.replacedBySupplierItemId = replacedBySupplierItemId;

    this.basePrice = basePrice;
    this.basePriceDate = basePriceDate;
    this.basePriceUnit = basePriceUnit;
    this.recommendedSalesPrice = recommendedSalesPrice;
    this.vatCode = vatCode;
    this.commodityCode = commodityCode;

    this.etim = etim;
    this.environment = environment;
    this.marketing = marketing;

    this.supplierItemId = supplierItemId;
    this.supplierGln = supplierGln;
    this.supplierOrgNumber = supplierOrgNumber;
    this.finfoSupplierId = finfoSupplierId;

    this.originCountry = originCountry;

    this.webProductName = webProductName;
    this.textIngress = textIngress;
    this.textFull = textFull;
    this.aiDescription = aiDescription;

    this.currentSalesPrices = currentSalesPrices;
    this.priority = priority;
  }

  getSalesPackagesOrdered = () =>
    this.packages
      .filter((p) => p.isSalesPackage())
      .sort(
        (p1, p2) =>
          salesPackagePriority[p1.type as SalesPackageType] -
          salesPackagePriority[p2.type as SalesPackageType]
      );

  static getPackages = (packages: MgSupplierItemPackage[]): MgItemPackages => {
    // @ts-ignore
    const uniquePackages: MgItemPackages = {};

    for (const pkg of packages) {
      const vilmaUnit = pkg.vilmaUnit;
      const packageType = pkg.type;

      if (packageType) {
        if (!uniquePackages[packageType]) uniquePackages[packageType] = [];

        const newPackage: MgItemPackage = {
          gtinCode: pkg.mgPackage?.gtinCode,
          unit: pkg.vilmaUnit.unit,
          description: vilmaUnit?.description,
          basePriceUnit: pkg.basePriceUnit,
          calculatedCount: pkg.calculatedCount,
          packageType: pkg.type,
          classCode: pkg.classCode,
        };

        // Check if the package already exists in the array
        const packageExists = uniquePackages[packageType].some(
          (existingPackage) => existingPackage.gtinCode === newPackage.gtinCode
        );

        if (!packageExists) uniquePackages[packageType].push(newPackage);
      }
    }

    return uniquePackages;
  };

  getMarkupConditionForPrimaryPackage = (): MarkupCondition | undefined => {
    return this.currentSalesPrices.getBracketPackagePrice('F-PAK')?.bracketMarkupPrice.markup;
  };

  getMarkupConditionForSecondaryPackage = (): MarkupCondition | undefined => {
    return (
      this.currentSalesPrices.getBracketPackagePrice('D-PAK')?.bracketMarkupPrice.markup ||
      this.getMarkupConditionForPrimaryPackage()
    );
  };

  getMarkupConditionForTertiaryPackage = (): MarkupCondition | undefined => {
    return (
      this.currentSalesPrices.getBracketPackagePrice('T-PAK')?.bracketMarkupPrice.markup ||
      this.getMarkupConditionForSecondaryPackage()
    );
  };

  getMarkupConditionForPackage = (packageType: MgPackageType): MarkupCondition | undefined => {
    if (packageType === 'SECONDARY') return this.getMarkupConditionForSecondaryPackage();
    if (packageType === 'TERTIARY') return this.getMarkupConditionForTertiaryPackage();
    return this.getMarkupConditionForPrimaryPackage();
  };

  static fromDto = (supplierItem: MpeMgSupplierItemDTO): MgSupplierItem => {
    return new MgSupplierItem({
      id: String(supplierItem.mgSupplierItemId),
      mainSupplier: supplierItem.mainSupplier,

      primaryText: supplierItem.primaryText || '',
      secondaryText: supplierItem.secondaryText || '',
      bk04Varegruppe: MgItemBk04Varegruppe.fromDto(supplierItem),
      prevMgItemNumber: supplierItem.prevMgItemNumber,
      registrationDate: supplierItem.registrationDate,
      discontinuationDate: supplierItem.discontinuationDate,
      discountGroup: supplierItem.discountGroup,
      dangerousGoods: supplierItem.dangerousGoods,
      fscPefcClaim: supplierItem.fscPefcClaim,

      articleType: supplierItem.articleType,
      finfoNumber: supplierItem.finfoNumber,
      nobbNumber: supplierItem.nobbNumber,
      mgParticipant: {
        id: supplierItem.mgSupplierId,
        name: supplierItem.mgSupplierName,
      },
      mgSupplierId: supplierItem.mgSupplierId,
      mgSupplierName: supplierItem.mgSupplierName,
      mgSupplierItemId: supplierItem.mgSupplierItemId,
      supplierProductName: supplierItem.supplierProductName,
      replacedBySupplierItemId: supplierItem.replacedBySupplierItemId,

      basePrice: supplierItem.basePrice,
      basePriceDate: supplierItem.basePriceDate,
      basePriceUnit: supplierItem.basePriceUnit,
      recommendedSalesPrice: supplierItem.recommendedSalesPrice,
      vatCode: supplierItem.vatCode,
      commodityCode: supplierItem.commodityCode,

      originCountry: supplierItem.originCountry,

      webProductName: supplierItem.webProductName,
      textIngress: supplierItem.textIngress,
      textFull: supplierItem.textFull,
      aiDescription: supplierItem.aiDescription,

      etim: supplierItem.etim ? MgItemEtim.fromDto(supplierItem.etim) : undefined,
      marketing: supplierItem.marketing
        ? MgItemMarketing.fromDto(supplierItem.marketing)
        : undefined,
      environment: supplierItem.environment
        ? MgItemEnvironment.fromDto(supplierItem.environment)
        : undefined,

      supplierItemId: supplierItem.supplierItemId,
      supplierGln: supplierItem.supplierGln,
      supplierOrgNumber: supplierItem.supplierOrgNumber,
      finfoSupplierId: supplierItem.finfoSupplierId,
      packages: supplierItem.mgSupplierItemPackages?.map(MgSupplierItemPackage.fromDto) || [],

      currentSalesPrices: PriceCalculation.fromDto(supplierItem.currentSalesPrices),
      priority: supplierItem.priority,
    });
  };
}
