import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useMPEFetch } from '@retail/app/hooks';
import { QueryOptions } from '@shared/fetch-utils';
import { OverGroupDTO } from '@retail/products/types';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

interface Props extends QueryOptions {
  assortmentId: number;
  supplierIds?: number[];
  discountGroupIds?: number[];
}

export function useFetchProductGroupsInAssortment({
  assortmentId,
  supplierIds,
  discountGroupIds,
  ...queryOptions
}: Props) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<OverGroupDTO[]>({
    method: 'GET',
    queryKey: ['assortmentProductGroups', assortmentId, supplierIds, discountGroupIds],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.productGroups.getFullLinkPath({ assortmentId }),
      searchParams: { supplierIds, discountGroupIds },
    }),
    ...queryOptions,
  });
}
