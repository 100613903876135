import { PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Paper } from '@shared/components';
import { WelcomeCards } from '@retail/monitoring-assortment/components';
import { pdmRoutes } from '@retail/mgpris/config';
import { MonitoringAssortmentsOverview } from '@retail/mgpdm/containers';

export function MonitoringAssortmentsPage() {
  const competitorsRoutePath = pdmRoutes.marketInsight.competitors.fullRoutePath;

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <WelcomeCards competitorsRoutePath={competitorsRoutePath} />
      <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 0 }}>
        <SuspenseWithSentryErrorBoundary>
          <MonitoringAssortmentsOverview />
        </SuspenseWithSentryErrorBoundary>
      </Paper>
    </PageLayout>
  );
}
