import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { DiscountGroup } from '@retail/calculus/types';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  discountGroups: DiscountGroup[];
  selectedDiscountGroup: number | null;
  selectDiscountGroup: (discountGroupId: number | null) => void;
}

export function SelectDiscountGroup({
  discountGroups,
  selectedDiscountGroup,
  selectDiscountGroup,
}: Props) {
  const t = useProductsTFunction();
  return (
    <Stack gap={0.5}>
      <Typography
        component="label"
        htmlFor="discountGroupSelect"
        variant="body2"
        color="text.secondary"
      >
        {t('products.search.discountGroup.label')}
      </Typography>
      <Autocomplete
        id="discountGroupSelect"
        size="small"
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={
          discountGroups.find((discountGroup) => discountGroup.id === selectedDiscountGroup) ?? null
        }
        onChange={(_, newValue) => selectDiscountGroup(newValue?.id ?? null)}
        options={discountGroups}
        sx={{ width: 320 }}
        getOptionLabel={({ name, code }) => `${code} ${name}`}
        renderOption={(props, option) => (
          <Stack
            {...props}
            component="li"
            key={option.id}
            borderBottom={({ palette }) => `1px solid ${palette.divider}`}
            flexDirection="row"
            gap={1}
          >
            <Typography>{option.code}</Typography> {option.name}
          </Stack>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('products.search.discountGroup.searchInDiscountGroup')}
          />
        )}
      />
    </Stack>
  );
}
