import { FreightConditionDTO } from '../dtos';
import { CalculusAreas } from './CalculusAreas';
import { Operation } from './Condition';
import { ConditionBase, ConditionBaseProps } from './ConditionBase';

interface FreightConditionProps extends ConditionBaseProps<FreightCondition> {
  freightFactor: number;
  percentAddon?: number;
}

export class FreightCondition extends ConditionBase<FreightCondition> {
  freightFactor: number;
  percentAddon?: number;

  constructor({ freightFactor, percentAddon, ...superProps }: FreightConditionProps) {
    super(superProps);
    this.freightFactor = freightFactor;
    this.percentAddon = percentAddon;
  }

  static fromDto = (dto: FreightConditionDTO): FreightCondition =>
    new FreightCondition({
      id: dto.id,
      assortmentId: dto.assortmentId,
      assortmentType: dto.assortmentType,
      mgSupplierId: dto.mgSupplierId,
      bindingType: dto.bindingType,
      levelProductGroup: dto.levelProductGroup,
      levelProductGroupName: dto.levelProductGroupName,
      productGroup: dto.productGroup,
      productGroupName: dto.productGroupName,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierName: dto.mgSupplierName,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: dto.validFrom ? new Date(dto.validFrom) : undefined,
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      active: dto.active,
      current: dto.current,
      freightFactor: dto.freightFactor,
      percentAddon: dto.percentAddon,
      inherited: dto.inherited,
      source: dto.source,
      futureConditions: dto.futureConditions?.map(FreightCondition.fromDto),
      subConditions: dto.subConditions?.map(FreightCondition.fromDto),
      replacesConditions: dto.replacesConditions?.map(FreightCondition.fromDto),
      newCondition: dto.newCondition && FreightCondition.fromDto(dto.newCondition),
      operation: dto.operation as Operation,
      discountGroup: dto.discountGroup,
      discountGroupName: dto.discountGroupName,
    });

  getType = () => CalculusAreas.FREIGHT;
}
