import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { PurchaseAgreementDTO } from '@retail/purchase-agreements/types';
import { buildUrl } from '@shared/utils';
import { useMPEFetch, useMutateMPEFetch } from '@retail/app/hooks';
import { mpeApi } from '@retail/mgpris/config';

export interface PurchaseAgreementParams extends QueryOptions {
  supplierId: number;
}

export function useFetchPurchaseAgreements({
  supplierId,
  ...queryOptions
}: PurchaseAgreementParams) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PurchaseAgreementDTO[]>({
    method: 'GET',
    queryKey: ['purchase-agreements', supplierId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.purchaseAgreements.getFullLinkPath(),
      searchParams: {
        mgParticipantAccountId: supplierId,
      },
      ...queryOptions,
    }),
  });
}

export function useSetStandardPurchaseAgreements() {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<PurchaseAgreementDTO[], never, number[]>({
    url: (agreementIds) =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.purchaseAgreements.getFullLinkPath(),
        searchParams: {
          agreementIds: (agreementIds as number[]).join(','),
        },
      }),
    method: 'PATCH',
    token,
  });
}
