import { useAssortmentActions, useBrandAssortments } from '@retail/mgpdm/context';
import { pdmRoutes } from '@retail/mgpris/config';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { MgItemDetails } from '@retail/products/containers';
import { MgItem } from '@retail/products/types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  mgItem: MgItem;
  hiddenBreadcrumbs?: boolean;
  showMonitorItem?: boolean;
  showAddItem?: boolean;
}

export function MgItemContainer({
  mgItem,
  showMonitorItem,
  hiddenBreadcrumbs,
  showAddItem,
}: Props) {
  const { baseAssortmentId, monitoringAssortments } = useBrandAssortments();

  const { updateAssortmentItems } = useAssortmentActions();

  const navigate = useNavigate();
  const navigateToAssortment = useCallback(
    (assortmentId: number, selectedAssortment: MonitoringAssortment | null) => {
      navigate(
        selectedAssortment
          ? pdmRoutes.marketInsight.assortments.assortment.getFullLinkPath({ assortmentId })
          : pdmRoutes.brandAssortment.getFullLinkPath()
      );
    },
    [navigate]
  );

  return (
    <MgItemDetails
      mgItem={mgItem}
      assortmentId={baseAssortmentId}
      monitoringAssortments={monitoringAssortments}
      showMonitorItem={showMonitorItem}
      hiddenBreadcrumbs={hiddenBreadcrumbs}
      showAddItem={showAddItem}
      addItemsToAssortment={updateAssortmentItems}
      navigateToAssortment={navigateToAssortment}
    />
  );
}
