import { MgSupplierItem } from './MgSupplierItem';
import { MgItemDTO, RecommendedPriceDTO } from '../../dto';
import { MgItemBase } from './MgItemBase';
import { MgPackage } from './MgPackage';

export class MgItem extends MgItemBase<MgSupplierItem> {
  constructor(
    id: number,
    supplierItems: MgSupplierItem[],
    mgPackages: MgPackage[],
    updatedAt: string,
    createdAt: string,
    recommendedPrice?: RecommendedPriceDTO,
    replacedByMgItemNumber?: number
  ) {
    super({
      id,
      supplierItems,
      mgPackages,
      updatedAt,
      createdAt,
      recommendedPrice,
      replacedByMgItemNumber,
    });
  }

  getSuppliers = (): Partial<MgSupplierItem>[] =>
    this.supplierItems.map((supplierItems) => ({
      mgSupplierId: supplierItems.mgSupplierId,
      mgSupplierName: supplierItems.mgSupplierName,
      mgSupplierItemId: supplierItems.mgSupplierItemId,
    })) ?? [];

  static fromDto = (mgItemDto: MgItemDTO): MgItem => {
    const supplierItems: MgSupplierItem[] = mgItemDto.mgSupplierItems?.map((supplierItem) =>
      MgSupplierItem.fromDto(supplierItem)
    );

    const mgPackages = mgItemDto.mgPackages.map(MgPackage.fromDto);

    return new MgItem(
      mgItemDto.mgItemNumber,
      supplierItems,
      mgPackages,
      mgItemDto.updatedAt,
      mgItemDto.createdAt,
      mgItemDto.recommendedPrice,
      mgItemDto.replacedByMgItemNumber
    );
  };
}
