import { useCallback, useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { AssortmentItem } from '@retail/my-assortment/types';
import { useAppTFunction } from '@retail/app/i18n';
import { getDataGridLocalization, MgPrisLocale } from '@retail/utils';
import { DraftAssortmentItemsTableContent } from './DraftAssortmentItemsTableContent';
import { CustomDetailPanelToggle } from '@retail/calculus/components';

interface Props {
  items: AssortmentItem[];
  isLoading: boolean;
  language: string;
  onDeleteDraftItem: (ids: number[]) => void;
  onSelectItems: (mgSupplierId: GridRowId, itemIds: number[]) => void;
}

export interface GroupedAssortmentItems {
  mgSupplierId: number;
  items: AssortmentItem[];
}

const baseColumnProps: Partial<GridColDef> = {
  width: 150,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  headerAlign: 'left',
  align: 'left',
};

export const DraftAssortmentItemsTable = ({
  items,
  isLoading,
  language,
  onDeleteDraftItem,
  onSelectItems,
}: Props) => {
  const t = useAppTFunction();
  const apiRef = useGridApiRef();

  const groupAssortmentItemsBySupplierId = (items: AssortmentItem[]): GroupedAssortmentItems[] => {
    const grouped = items.reduce((acc: { [key: number]: AssortmentItem[] }, item) => {
      if (!acc[item.mgSupplierId]) {
        acc[item.mgSupplierId] = [];
      }
      acc[item.mgSupplierId].push(item);
      return acc;
    }, {});

    return Object.keys(grouped).map((mgSupplierId) => ({
      mgSupplierId: Number(mgSupplierId),
      items: grouped[Number(mgSupplierId)],
    }));
  };

  const groupedAssortmentItems = useMemo(() => groupAssortmentItemsBySupplierId(items), [items]);

  const findSupplierName = useCallback(
    (mgSupplierId: number) => {
      const group = groupedAssortmentItems.find(
        (assortmentItemGroup) => assortmentItemGroup.mgSupplierId === mgSupplierId
      );

      return group?.items[0]?.mgSupplierName ?? mgSupplierId;
    },
    [groupedAssortmentItems]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'mgSupplierId',
        headerName: t('markup.supplier'),
        flex: 1,
        renderCell: ({ value }) => (
          <Box ml={6}>
            <Typography variant="body2" fontWeight={600}>
              {findSupplierName(value)}
            </Typography>
          </Box>
        ),
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        align: 'right',
        renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
      },
    ],
    [findSupplierName, t]
  );

  const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
    const selection = rowSelectionModel[0];
    apiRef.current?.toggleDetailPanel(selection);
  };

  const getDetailPanelContent = useCallback(
    (mgSupplierId: GridRowId) => {
      const assortmentItemsGroup = groupedAssortmentItems.find(
        (group) => group.mgSupplierId === mgSupplierId
      );

      return (
        <DraftAssortmentItemsTableContent
          mgSupplierId={mgSupplierId}
          items={assortmentItemsGroup?.items ?? []}
          isLoading={isLoading}
          language={language}
          onDeleteDraftItem={onDeleteDraftItem}
          onSelectItems={onSelectItems}
        />
      );
    },
    [groupedAssortmentItems, isLoading, language, onDeleteDraftItem, onSelectItems]
  );

  const getDetailPanelHeight = useCallback(
    (mgSupplierId: GridRowId) => {
      if (groupedAssortmentItems.length === 1) return 525;

      const assortmentItemsGroup = groupedAssortmentItems.find(
        (group) => group.mgSupplierId === mgSupplierId
      );

      return assortmentItemsGroup?.items && assortmentItemsGroup.items.length > 3 ? 350 : 200;
    },
    [groupedAssortmentItems]
  );

  useEffect(() => {
    if (groupedAssortmentItems.length === 1) {
      apiRef?.current?.setExpandedDetailPanels([groupedAssortmentItems[0].mgSupplierId]);
    }
  }, [apiRef, groupedAssortmentItems]);

  return (
    <Box height="100%">
      <DataGridPremium
        apiRef={apiRef}
        getRowId={(row) => row.mgSupplierId}
        columns={columns}
        rows={groupedAssortmentItems}
        rowHeight={50}
        columnHeaderHeight={0}
        getDetailPanelContent={({ id }) => getDetailPanelContent(id)}
        getDetailPanelHeight={({ id }) => getDetailPanelHeight(id)}
        hideFooter
        rowSelection
        localeText={getDataGridLocalization(language as MgPrisLocale)}
        onRowSelectionModelChange={(newRowSelectionModel) =>
          handleRowSelection(newRowSelectionModel)
        }
        slots={{
          noRowsOverlay: () => (
            <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
              <Typography variant="h3" color="text.secondary">
                {t('myAssortment.noItems')}
              </Typography>
            </Stack>
          ),
        }}
        sx={{
          '.MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  );
};
