import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

interface Props<TId extends string | number, TOption> {
  options: TOption[];
  getOptionId: (option: TOption) => TId;
  searchParamKey: string;
  initialValue?: TId[];
}

export function useMultipleSelectedSearchParamState<TId extends string | number, TOption = TId>({
  options,
  searchParamKey,
  getOptionId,
  initialValue,
}: Props<TId, TOption>): [
  TOption[] | null,
  (option: TOption[], adjustContiguousSearchParam?: { key: string; value: string[] }) => void
] {
  const [searchParams, setSearchParams] = useSearchParams(
    initialValue && { [searchParamKey]: initialValue.join(',') }
  );

  const selectedMultipleOptionsIds = searchParams.get(searchParamKey);

  const selectedOptions = useMemo(
    () =>
      selectedMultipleOptionsIds
        ? options.filter((option) =>
            selectedMultipleOptionsIds.split(',').includes(`${getOptionId(option)}`)
          )
        : null,
    [getOptionId, options, selectedMultipleOptionsIds]
  );
  const selectOptions = useCallback(
    (options: TOption[], adjustContiguousSearchParam?: { key: string; value: string[] }) =>
      setSearchParams((prevParams) => {
        if (options.length > 0) {
          prevParams.set(searchParamKey, options.map((option) => getOptionId(option)).join(','));
          if (adjustContiguousSearchParam) {
            adjustContiguousSearchParam.value.length > 0
              ? prevParams.set(
                  adjustContiguousSearchParam.key,
                  adjustContiguousSearchParam.value.join(',')
                )
              : prevParams.delete(adjustContiguousSearchParam.key);
          }
        } else {
          prevParams.delete(searchParamKey);
          adjustContiguousSearchParam && prevParams.delete(adjustContiguousSearchParam.key);
        }

        return prevParams;
      }),
    [searchParamKey, setSearchParams, getOptionId]
  );

  return [selectedOptions, selectOptions];
}
