import { Stack, Switch, Typography } from '@mui/material';

interface SwitchProps {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
  text: string;
  justifyContent?: string;
}

export const ToggleSwitch = ({
  toggle,
  setToggle,
  text,
  justifyContent = 'inherit',
}: SwitchProps) => {
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent={justifyContent}>
      <Typography component="label" htmlFor="switch" pr={1} variant="subtitle2" noWrap>
        {text}
      </Typography>
      <Switch id="switch" value={toggle} checked={toggle} onClick={() => setToggle(!toggle)} />
    </Stack>
  );
};
