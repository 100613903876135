import { PackagePrice } from './PackagePrice';
import { DatedPriceDTO } from '../../dto';

interface DatedPriceProps {
  validFrom: Date;
  validTo?: Date;
  packagePrices: PackagePrice[];
}

export class DatedPrice {
  validFrom: Date;
  validTo?: Date;
  packagePrices: PackagePrice[];

  constructor(props: DatedPriceProps) {
    this.validFrom = props.validFrom;
    this.validTo = props.validTo;
    this.packagePrices = props.packagePrices;
  }

  getPriceForUnit = (unit: string): PackagePrice | undefined =>
    this.packagePrices.find(({ supplierItemPackage }) => supplierItemPackage.unit.unit === unit);

  static fromDto = (dto: DatedPriceDTO): DatedPrice =>
    new DatedPrice({
      validFrom: new Date(dto.validFrom),
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      packagePrices: dto.packagePrices.map(PackagePrice.fromDto),
    });
}
