import { PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Paper } from '@shared/components';
import { AllMonitoringAssortmentsContainer } from '@retail/monitoring-assortment/containers';
import { WelcomeCards } from '@retail/monitoring-assortment/components';
import { mgPrisRoutes } from '@retail/mgpris/config';

export function MarketInsightPage() {
  const competitorsRoutePath = mgPrisRoutes.marketInsight.competitors.fullRoutePath;

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <WelcomeCards competitorsRoutePath={competitorsRoutePath} />
      <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 0 }}>
        <SuspenseWithSentryErrorBoundary>
          <AllMonitoringAssortmentsContainer />
        </SuspenseWithSentryErrorBoundary>
      </Paper>
    </PageLayout>
  );
}
