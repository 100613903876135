export enum PriceStatus {
  IN_ASSORTMENT = 'IN_ASSORTMENT',
  FOR_APPROVAL = 'FOR_APPROVAL',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  PENDING = 'PENDING',
}

export enum PriceError {
  MISSING_AGREEMENT_PRICE = 'MISSING_AGREEMENT_PRICE',
  MISSING_STANDARD_AGREEMENT = 'MISSING_STANDARD_AGREEMENT',
  MISSING_MARKUP_CONDITION = 'MISSING_MARKUP_CONDITION',
}
