import {
  CalculusAreas,
  FreightCondition,
  MarkupCondition,
  PurchaseCondition,
} from '@retail/calculus/types';

export const isPurchaseCondition = (
  condition: PurchaseCondition | FreightCondition | MarkupCondition
): condition is PurchaseCondition => {
  return condition.getType() === CalculusAreas.PURCHASE;
};

export const isFreightCondition = (
  condition: PurchaseCondition | FreightCondition | MarkupCondition
): condition is FreightCondition => {
  return condition.getType() === CalculusAreas.FREIGHT;
};

export const isMarkupCondition = (
  condition: PurchaseCondition | FreightCondition | MarkupCondition
): condition is MarkupCondition => {
  return condition.getType() === CalculusAreas.MARKUP;
};
