import { useProductsTFunction } from '@retail/products/i18n';
import { formatPrice } from '@retail/products/utils';
import { calculateVatPrice } from '@retail/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  price?: number;
  includeVat?: boolean;
}

export const FormattedPrice = ({ price, includeVat = false }: Props) => {
  const { i18n } = useTranslation();
  const t = useProductsTFunction();
  if (!price) return '-';

  return t('products.prices.price', {
    price: formatPrice(calculateVatPrice(price, includeVat), i18n.language),
  });
};
