import { MgPackage, MgPackageClassCode, MgPackageType } from './MgPackage';
import { VilmaUnit } from './VilmaUnit';

export enum PACKAGING_UNITS {
  STL = 'STL',
  KTA = 'KTA',
  EMPTY = '',
  FOUR = '4',
  ARK = 'ARK',
  ASK = 'ASK',
  BNT = 'BNT',
  BRK = 'BRK',
  CON = 'CON',
  DAG = 'DAG',
  DNK = 'DNK',
  PAC = 'PAC',
  FAT = 'FAT',
  FL = 'FL',
  FRP = 'FRP',
  HPL = 'HPL',
  HNK = 'HNK',
  HST = 'HST',
  KRT = 'KRT',
  KG = 'KG',
  M3 = 'M3',
  M2 = 'M2',
  L = 'L',
  LÅD = 'LÅD',
  M = 'M',
  ML = 'ML',
  MM = 'MM',
  MP = 'MP',
  ODE = 'ODE',
  PKT = 'PKT',
  PAL = 'PAL',
  PAR = 'PAR',
  PAT = 'PAT',
  PP = 'PP',
  PÅS = 'PÅS',
  RNG = 'RNG',
  RLE = 'RLE',
  RÖR = 'RÖR',
  SB = 'SB',
  SET = 'SET',
  SKI = 'SKI',
  SPL = 'SPL',
  ST = 'ST',
  SÄC = 'SÄC',
  TIM = 'TIM',
  TON = 'TON',
  TUB = 'TUB',
  TST = 'TST',
}

export type MgItemPackage = {
  gtinCode: MgPackage['gtinCode'];
  description: VilmaUnit['description'];
  unit: VilmaUnit['unit'];
  basePriceUnit: MgPackage['basePriceUnit'];
  calculatedCount: MgPackage['calculatedCount'];
  packageType: MgPackageType;
  classCode: MgPackageClassCode;
};

export type MgItemPackages = Record<MgPackageType, MgItemPackage[]>;
