import { TanstackQueryLoadingWrapper, ToggleFiltersButton } from '@retail/components';
import { useMgSupplierItemsSearch } from '@retail/products/data-access';
import { Dispatch, SetStateAction } from 'react';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { Stack, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { useDisclosure, usePagination } from '@shared/hooks';
import { MgSupplierItemSearchResults } from './MgSupplierItemSearchResults';
import { useSearchFilters } from '@retail/products/context';
import { SearchFilters } from '@retail/products/components';

interface Props {
  checkedItems: MgSupplierItemSearchResult[];
  includeAddButton?: boolean;
  clickableRow?: boolean;
  assortmentId?: number;
  setCheckedItems: Dispatch<SetStateAction<MgSupplierItemSearchResult[]>>;
}

export function MgSupplierItemSearchContainer({
  checkedItems,
  includeAddButton = false,
  clickableRow,
  assortmentId,
  setCheckedItems,
}: Props) {
  const t = useProductsTFunction();

  const { pageSize, page, setPageSize, setPage } = usePagination();

  const {
    searchFilters,
    searchFilters: {
      query,
      articleIds,
      withAgreementPrices,
      fromMainSupplier,
      inAssortment,
      selectedSuppliers,
      selectedProductGroups,
      selectedDiscountGroups,
    },
    setSearchFilters,
    filterOptions,
    filterOptions: { productGroups },
    resetFilters,
  } = useSearchFilters({ assortmentId });

  const { isOpen: showFilters, onToggle: toggleFilters } = useDisclosure(true);

  const loadingState = useMgSupplierItemsSearch(
    {
      size: pageSize,
      page: page,
      query,
      articleIds,
      assortmentId,
      withAgreementPrices,
      fromMainSupplier: fromMainSupplier === 'UNSPECIFIED' ? undefined : fromMainSupplier === 'YES',
      inAssortment: inAssortment === 'YES' ? true : inAssortment === 'NO' ? false : undefined,
      supplierIds: selectedSuppliers?.map(({ mgSupplierId }) => mgSupplierId),
      groupNumbers: selectedProductGroups?.map(({ groupNumber }) => groupNumber),
      discountGroupIds: selectedDiscountGroups?.map(({ id }) => id),
    },
    {
      enabled:
        !!selectedSuppliers ||
        !!selectedProductGroups ||
        (!!selectedSuppliers && !!selectedDiscountGroups) ||
        !!query ||
        articleIds.length > 0,
      suspense: false,
    }
  );

  return (
    <Stack flexDirection="row" flexGrow={1} justifyContent="space-between" overflow="auto">
      {!loadingState.isFetching && !loadingState.data && !loadingState.error && (
        <Stack flexGrow={1}>
          <Stack alignItems="flex-end" mr={2}>
            {!showFilters && (
              <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleFilters} />
            )}
          </Stack>
          <Stack textAlign="center" justifyContent="center" pb={24} flexGrow={1}>
            <Typography variant="h6" fontWeight="medium" color="text.secondary" alignSelf="center">
              {t('products.mgItem.search.infoText')}
            </Typography>
          </Stack>
        </Stack>
      )}

      <TanstackQueryLoadingWrapper loadingState={loadingState} showReloadButtonOnError>
        {(searchResults) => {
          const supplierItems = MgSupplierItemSearchResult.fromSearchResultDTOs(
            searchResults.result,
            productGroups
          );

          return (
            <MgSupplierItemSearchResults
              totalItems={searchResults.totalCount}
              supplierItems={supplierItems}
              checkedItems={checkedItems}
              page={page}
              pageSize={pageSize}
              includeAddButton={includeAddButton}
              clickableRow={clickableRow}
              showFilters={showFilters}
              searchFilters={searchFilters}
              setSearchFilters={setSearchFilters}
              resetFilters={resetFilters}
              toggleFilters={toggleFilters}
              setCheckedItems={setCheckedItems}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          );
        }}
      </TanstackQueryLoadingWrapper>

      {showFilters && (
        <SearchFilters
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          filterOptions={filterOptions}
          setPage={setPage}
          isFetching={loadingState.isFetching}
          showFilters={showFilters}
          toggleFilters={toggleFilters}
        />
      )}
    </Stack>
  );
}
