import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useMPEFetch } from '@retail/app/hooks';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';
import { MarkupConditionDTO } from '@retail/calculus/types';

interface SearchProps {
  assortmentId: number;
  mgSupplierId?: number;
  groupNumber?: string;
  mgItemNumber?: number;
  includeSubConditions?: boolean;
  gtinCode?: number;
}

export function useFetchActiveMarkupCondition(
  {
    assortmentId,
    mgSupplierId,
    groupNumber,
    mgItemNumber,
    includeSubConditions = true,
    gtinCode,
  }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<MarkupConditionDTO>({
    method: 'GET',
    queryKey: ['markup-condition', assortmentId, mgSupplierId, groupNumber, mgItemNumber, gtinCode],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.markupCondition.getFullLinkPath({ assortmentId }),
      searchParams: {
        mgSupplierId,
        groupNumber,
        mgItemNumber,
        includeSubConditions,
        gtinCode,
      },
    }),
    ...queryOptions,
  });
}
