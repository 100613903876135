import { Stack, Typography } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { ConfettiCircle } from '@retail/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
  navigateToItemSearch: () => void;
}

export function SuccessCreatingAssortment({ isOpen, closeDialog, navigateToItemSearch }: Props) {
  const t = useAssortmentTFunction();

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <Stack p={0.5} gap={2} alignItems="center">
        <ConfettiCircle />

        <Typography variant="h4" color="text.secondary">
          {t('assortment.admin.create.super')}
        </Typography>

        <Typography variant="h3" align="center">
          {t('assortment.admin.create.assortmentCreated')}
        </Typography>

        <Typography variant="body1" align="center" color="text.secondary">
          {t('assortment.admin.create.emptyAssortment')}
        </Typography>

        <Stack pt={2} flexDirection="row" gap={2}>
          <TextButton onClick={closeDialog}>{t('assortment.common.close')}</TextButton>

          <ContainedButton onClick={navigateToItemSearch}>
            {t('assortment.admin.create.goToProductSearch')}
          </ContainedButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
