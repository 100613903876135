export const gtinRegex = /^(?:\d{8}|\d{12,14})$/;

export const getIsRecognizedHeaderPredicate = (header: string) => (recHeading: string) =>
  header.toLowerCase().includes(recHeading);

export const recognizedMgItemNrHeadings = [
  'mg-item-nummer',
  'mg-item-number',
  'mg-item nummer',
  'mg-item number',
  'mgitem-nummer',
  'mgitem-number',
  'mgitem nummer',
  'mgitem number',
  'mgitem-nr',
  'mgitem nr',
  'mgitemnr',
];

export const recognizedPurchasePriceHeadings = [
  'purchaseprice',
  'purchase-price',
  'purchase price',
];

export const recognizedValidFromHeadings = ['fromdate', 'from-date', 'from date'];

export const recognizedValidToHeadings = ['todate', 'to-date', 'to date'];

export const recognizedGtinHeadings = ['gtin', 'gtinnumber', 'gtin-number', 'gtin number'];
