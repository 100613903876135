import { atom, useRecoilState } from 'recoil';

export enum DraftDrawerTabs {
  ASSORTMENT = 'ASSORTMENT',
  CONDITIONS = 'CONDITIONS',
}

export const hasDraftDrawerValuesAtom = atom<boolean>({
  key: 'hasDraftDrawerValuesAtom',
  default: false,
});

export const useHasDraftDrawerValues = () => useRecoilState(hasDraftDrawerValuesAtom);

export const currentDraftDrawerTab = atom<DraftDrawerTabs>({
  key: 'currentDraftDrawerTab',
  default: DraftDrawerTabs.ASSORTMENT,
});

export const useCurrentDraftDrawerTab = () => useRecoilState(currentDraftDrawerTab);
