import { forwardRef, PropsWithChildren } from 'react';
import { ButtonProps, useTheme } from '@mui/material';
import { LoadingButton } from '@shared/components';

function TextButtonBase(
  {
    children,
    fontSize = '14px',
    size = 'small',
    sx,
    ...rest
  }: PropsWithChildren<
    ButtonProps & {
      loading?: boolean;
      fontSize?: string;
    }
  >,
  ref: ButtonProps['ref']
) {
  const { palette } = useTheme();
  return (
    <LoadingButton
      {...rest}
      variant="text"
      ref={ref}
      size={size}
      color="inherit"
      sx={{
        whiteSpace: 'nowrap',
        border: 0,
        p: 0,
        ':disabled': {
          bgcolor: 'inherit',
          border: 0,
        },
        '&:hover': {
          border: 0,
          color: palette.primary.main,
          bgcolor: 'transparent',
          boxShadow: 'none',
        },
        ...sx,
      }}
    >
      {children}
    </LoadingButton>
  );
}
const TextButton = forwardRef(TextButtonBase);
export default TextButton;
