import { Stack } from '@mui/material';
import {
  AssortmentItemsTable,
  DeleteItemsDialog,
  deletionFromDateSchema,
} from '@retail/my-assortment/components';
import { Paper, UniversalFormProvider } from '@shared/components';
import { AssortmentItemsTableToolbar } from './AssortmentItemsTableToolbar';
import {
  AssortmentItem,
  AssortmentTab,
  FilterOptions,
  FilterSelection,
  ItemsDTO,
  SetFilterOptions,
} from '@retail/my-assortment/types';
import { useCallback, useState } from 'react';
import { Pagination, useSelectedSearchParamState } from '@shared/hooks';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import {
  useAssortmentItemsActions,
  useDepartmentAssortments,
  useIncludeVatMyAssortment,
} from '@retail/my-assortment/context';
import { useLanguageSelector } from '@retail/app/hooks';
import { ProductCardSuspenseContainer } from '../ProductCard/ProductCardSuspenseContainer';
import { useUserBrands } from '@retail/app/stores/selected-context';
import { mgPrisRoutes } from '@retail/mgpris/config';

interface Props {
  assortmentItems: AssortmentItem[];
  searchFilters: FilterOptions;
  setSearchFilters: SetFilterOptions;
  filterOptions: FilterSelection;
  totalItems: number;
  pagination: Pagination;
  selectedTab: AssortmentTab;
  showFilters: boolean;
  toggleFilters: () => void;
  toggleAddItemsDialog: () => void;
}

export function AssortmentItems({
  assortmentItems,
  searchFilters,
  setSearchFilters,
  totalItems,
  pagination,
  selectedTab,
  showFilters,
  toggleFilters,
  toggleAddItemsDialog,
}: Props) {
  const t = useAssortmentTFunction();
  const { language } = useLanguageSelector();
  const { includeVat } = useIncludeVatMyAssortment();
  const { selectedBrand } = useUserBrands();
  const { baseAssortmentId } = useDepartmentAssortments();

  const { deleteItemsByDate, updateItemsIsLoading } = useAssortmentItemsActions();

  const getSupplierCalculusLink = (supplierId: number) =>
    mgPrisRoutes.calculus.supplier.getFullLinkPath({ supplierId });

  const [selectedItem, setSelectedItem, setSelectedItemId] = useSelectedSearchParamState({
    options: assortmentItems,
    searchParamKey: 'item',
    getOptionId: ({ id }) => id,
  });

  const viewItem = useCallback(
    (itemId: number) => {
      if (selectedItem?.id === itemId) {
        setSelectedItemId(null);
        !showFilters && toggleFilters();
      } else {
        setSelectedItemId(itemId);
        showFilters && toggleFilters();
      }
    },
    [selectedItem?.id, setSelectedItemId, showFilters, toggleFilters]
  );
  const [itemsChecked, setItemsChecked] = useState<number[]>([]);
  const [itemsForDeletion, setItemsForDeletion] = useState<number[]>([]);

  const confirmDeleteCheckedItemsFromDate = useCallback(
    (date: string) => {
      if (itemsForDeletion.length) {
        const itemsWithUpdatedNullPriority: ItemsDTO[] = itemsForDeletion.map((checkedItemId) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const assortmentItem = assortmentItems.find(
            (assortmentItem) => checkedItemId === assortmentItem.id
          )!;
          return {
            mgSupplierId: assortmentItem.mgSupplierId,
            mgItemNumber: assortmentItem.mgItemNumber,
            priority: null,
            validFrom: date,
          };
        });
        deleteItemsByDate(itemsWithUpdatedNullPriority);
        setItemsForDeletion([]);
        setItemsChecked([]);
      }
    },
    [assortmentItems, deleteItemsByDate, itemsForDeletion]
  );

  const setCheckedItemsForDeletion = useCallback(
    () => setItemsForDeletion(itemsChecked),
    [itemsChecked]
  );
  const setItemForDeletion = useCallback((itemId: number) => {
    setItemsForDeletion([itemId]);
  }, []);
  const onCloseDeleteItemsDialog = useCallback(() => setItemsForDeletion([]), []);

  return (
    <Paper sx={{ display: 'flex', flexGrow: 1, p: 0, overflow: 'auto' }}>
      <Stack flexGrow={1}>
        <AssortmentItemsTableToolbar
          {...searchFilters}
          totalItems={totalItems}
          noItemsChecked={!itemsChecked.length}
          baseAssortmentId={baseAssortmentId}
          showFilters={showFilters}
          openFilters={toggleFilters}
          setSearchFilters={setSearchFilters}
          deleteCheckedItems={setCheckedItemsForDeletion}
          deletionIsLoading={updateItemsIsLoading}
        />

        <Stack flexDirection="row" flexGrow={1}>
          <AssortmentItemsTable
            {...pagination}
            {...setSearchFilters}
            items={assortmentItems}
            totalItems={totalItems}
            selectedItem={selectedItem}
            itemsChecked={itemsChecked}
            selectedTab={selectedTab}
            language={language}
            includeVat={includeVat}
            getSupplierCalculusLink={getSupplierCalculusLink}
            viewItemProductCard={viewItem}
            toggleAddItemsDialog={toggleAddItemsDialog}
            setItemsChecked={setItemsChecked}
            deleteItem={setItemForDeletion}
            showBracketPriceColumns={selectedBrand.wholesaler}
          />
          {selectedItem && (
            <ProductCardSuspenseContainer
              item={{
                primaryText: selectedItem.primaryText,
                secondaryText: selectedItem.secondaryText,
                mgItemNumber: selectedItem.mgItemNumber,
                mgSupplierId: selectedItem.mgSupplierId,
              }}
              closeProductCard={() => {
                setSelectedItem(null);
                !showFilters && toggleFilters();
              }}
            />
          )}
        </Stack>
      </Stack>

      {itemsForDeletion.length > 0 && (
        <UniversalFormProvider
          schema={deletionFromDateSchema(t)}
          defaultValues={deletionFromDateSchema(t).getDefault()}
        >
          <DeleteItemsDialog
            isOpen={true}
            numberOfItems={itemsForDeletion.length}
            isDeleting={updateItemsIsLoading}
            onClose={onCloseDeleteItemsDialog}
            onDelete={confirmDeleteCheckedItemsFromDate}
          />
        </UniversalFormProvider>
      )}
    </Paper>
  );
}
