import { Box, Radio } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { LevelChip } from '@retail/calculus/components';
import { formatPrice } from '@retail/products/utils';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { ItemAgreement } from '@retail/purchase-agreements/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  itemAgreements: ItemAgreement[];
  selectedAgreement?: ItemAgreement;
  selectAgreement: (agreement: ItemAgreement) => void;
}

const baseColumnProps: Partial<GridColDef<ItemAgreement>> = {
  width: 148,
  sortable: false,
};

export function SelectItemAgreementTable({
  itemAgreements,
  selectedAgreement,
  selectAgreement,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  const { i18n } = useTranslation();

  const columns: GridColDef<ItemAgreement>[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'action',
        width: 60,
        headerName: '',
        renderCell: ({ row }) => {
          return (
            <Radio
              checked={
                row.agreementId === selectedAgreement?.agreementId ||
                (!selectedAgreement && !!row.conditionId)
              }
              onChange={() => selectAgreement(row)}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'agreementText',
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
      },
      {
        ...baseColumnProps,
        field: 'agreementId',
        headerName: t('purchaseAgreements.agreement.columns.agreementId'),
      },
      {
        ...baseColumnProps,
        field: 'priceUnit',
        width: 100,
        headerName: t('purchaseAgreements.agreement.columns.basePriceUnit'),
        renderCell: ({ row }) => row.gtinPriceUnit ?? row.priceUnit,
      },
      {
        ...baseColumnProps,
        field: 'price',
        headerName: t('purchaseAgreements.agreement.columns.price'),
        renderCell: ({ row }) =>
          t('purchaseAgreements.pricePerUnit', {
            price: formatPrice(row.price, i18n.language),
            unit: row.priceUnit,
          }),
      },
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('purchaseAgreements.agreement.columns.activationLevel'),
        renderCell: ({ row }) => {
          return row.conditionLevel ? (
            <LevelChip bindingType={row.conditionLevel} active={true} />
          ) : null;
        },
      },
    ],
    [i18n.language, selectAgreement, selectedAgreement, t]
  );

  return (
    <DataGridPremium
      getRowId={(row) => `${row.agreementId}${row.priceType}${row.gtinPriceUnit}`}
      rows={itemAgreements}
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
    />
  );
}
