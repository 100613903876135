import { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { dataGridBoldClass, dataGridCellNoFocusOutlineClass } from '@shared/styles';
import { useSuppliersTFunction } from '@retail/suppliers/i18n';
import { Supplier } from '@retail/suppliers/types';
import clsx from 'clsx';
import { CheckIcon } from '@shared/custom-icons';
import { useAppTFunction } from '@retail/app/i18n';
import { SuppliersTableActions } from './SuppliersTableActions';
import { getTogglableDataGridColumns } from '@shared/utils';
import { CustomColumnMenu } from '@retail/components';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';

type Props = {
  suppliers: Supplier[];
  onSupplierSelect: (supplier: Supplier) => void;
  onShowUploadDialog?: (supplier: Supplier) => void;
  language: string;
};

type GridColSupplier = GridColDef<Supplier>;

const baseColumnProps: Partial<GridColSupplier> = {
  width: 170,
  sortable: false,
  align: 'left',
  headerAlign: 'left',
  headerName: '',
};

export const SuppliersTable = ({
  language,
  suppliers,
  onSupplierSelect,
  onShowUploadDialog,
}: Props) => {
  const t = useSuppliersTFunction();
  const appT = useAppTFunction();

  const columns: GridColSupplier[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'name',
        cellClassName: () => dataGridBoldClass,
        headerName: t('suppliers.columns.participantAccountName'),
        headerAlign: 'left',
        align: 'left',
        width: 265,
      },
      {
        ...baseColumnProps,
        field: 'mgSupplierId',
        headerAlign: 'left',
        align: 'left',
        headerName: t('suppliers.columns.mgParticipantAccountId'),
      },
      {
        ...baseColumnProps,
        field: 'finfoSupplierId',
        headerAlign: 'left',
        align: 'left',
        headerName: t('suppliers.columns.finfoSupplierId'),
      },
      {
        ...baseColumnProps,
        field: 'gln',
        headerAlign: 'left',
        align: 'left',
        headerName: t('suppliers.columns.gln'),
      },
      {
        ...baseColumnProps,
        field: 'purchaseAgreementCount',
        headerName: t('suppliers.columns.purchaseAgreementCount'),
        headerAlign: 'center',
        align: 'center',
        width: 160,
      },
      {
        ...baseColumnProps,
        width: 172,
        field: 'hasStandardAgreement',
        headerName: t('suppliers.columns.hasStandardAgreement'),
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) =>
          row.hasStandardAgreement ? (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <CheckIcon />
            </Stack>
          ) : (
            appT('common.no')
          ),
      },
      {
        field: 'actions',
        headerName: '',
        minWidth: 40,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <SuppliersTableActions
            onShowAgreementsClick={() => onSupplierSelect(row)}
            onUploadAgreementsClick={onShowUploadDialog ? () => onShowUploadDialog(row) : undefined}
          />
        ),
      },
    ],
    [appT, onShowUploadDialog, onSupplierSelect, t]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium<Supplier>
          getRowId={(row) => row.mgSupplierId}
          columns={columns}
          rows={suppliers}
          rowHeight={65}
          getCellClassName={() => clsx(dataGridCellNoFocusOutlineClass)}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          hideFooter
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);

            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.SUPPLIERS_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.SUPPLIERS_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          slots={{
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
};
