import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ToggleSwitch } from '@retail/components';

interface Props {
  toggleVat: boolean;
  handleToggleVat: () => void;
}

export const ExcludeVatSwitch = ({ toggleVat, handleToggleVat }: Props) => {
  const t = useMyAssortmentTFunction();

  return (
    <ToggleSwitch
      toggle={toggleVat}
      setToggle={handleToggleVat}
      text={t('myAssortment.actions.includeVat')}
    />
  );
};
