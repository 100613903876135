import { Stack, Typography } from '@mui/material';
import { DeleteItemsButton, MyAssortmentSearchFilterChips } from '@retail/my-assortment/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ExportAssortment } from '../DepartementContext';
import { ToggleFiltersButton } from '@retail/components';
import { Supplier } from '@retail/suppliers/types';
import { ProductGroup } from '@retail/products/types';
import { DiscountGroup } from '@retail/calculus/types';
import { SetFilterOptions } from '@retail/my-assortment/types';

interface Props {
  noItemsChecked: boolean;
  totalItems: number;
  baseAssortmentId: number;
  selectedSupplier: Supplier | null;
  selectedProductGroup: ProductGroup | null;
  selectedDiscountGroup: DiscountGroup | null;
  searchQuery?: string;
  deletionIsLoading: boolean;
  showFilters: boolean;
  deleteCheckedItems: () => void;
  openFilters: () => void;
  setSearchFilters: SetFilterOptions;
}

export const AssortmentItemsTableToolbar = ({
  noItemsChecked,
  totalItems,
  baseAssortmentId,
  selectedSupplier,
  selectedProductGroup,
  selectedDiscountGroup,
  searchQuery,
  deletionIsLoading,
  showFilters,
  deleteCheckedItems,
  openFilters,
  setSearchFilters,
}: Props) => {
  const t = useMyAssortmentTFunction();

  return (
    <Stack px={3} pt={2} pb={0} gap={1}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography>
          <b>{totalItems}</b>{' '}
          {totalItems === 1
            ? t('myAssortment.itemsInListSingular')
            : t('myAssortment.itemsInListPlural')}
        </Typography>

        <Stack flexDirection="row" justifyContent="space-between" gap={2}>
          <ExportAssortment
            noItems={totalItems === 0}
            baseAssortmentId={baseAssortmentId}
            selectedSupplier={selectedSupplier?.mgSupplierId}
            selectedProductGroup={selectedProductGroup?.groupNumber}
            query={searchQuery ?? ''}
          />
          <DeleteItemsButton
            noItemsChecked={noItemsChecked}
            deleteCheckedItems={deleteCheckedItems}
            deletionIsLoading={deletionIsLoading}
          />
          {!showFilters && (
            <ToggleFiltersButton showFilters={showFilters} toggleFilters={openFilters} />
          )}
        </Stack>
      </Stack>
      <MyAssortmentSearchFilterChips
        {...setSearchFilters}
        selectedSupplier={selectedSupplier}
        selectedDiscountGroup={selectedDiscountGroup}
        selectedProductGroup={selectedProductGroup}
        searchQuery={searchQuery}
      />
    </Stack>
  );
};
