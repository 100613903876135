import { useMemo } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { useFreightTFunction } from '@retail/freight/i18n';
import { AssortmentType, FreightCondition } from '@retail/calculus/types';
import { dataGridBoldClass } from '@shared/styles';
import { CalculusAreas } from '@retail/calculus/types';
import { Supplier } from '@retail/suppliers/types';
import { CustomColumnMenu, StackedTextCell } from '@retail/components';
import { FreightTableActions } from '@retail/freight/components';
import { ItemsAffected } from '@retail/calculus/containers';
import { LevelChip } from '@retail/calculus/components';
import { Box } from '@mui/material';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';
import { InfinityIcon } from '@shared/custom-icons';

interface Props {
  supplier: Supplier;
  language: string;
  freightConditions: FreightCondition[];
  editCondition: (condition: FreightCondition) => void;
  deactivateCondition: (condition: FreightCondition) => void;
  deleteCondition: (conditionId?: number) => void;
  setConditionForAffectedItemsView: (condition: FreightCondition) => void;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 140,
  sortable: false,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
};

export const FreightConditionsTable = ({
  supplier,
  language,
  freightConditions,
  editCondition,
  deactivateCondition,
  deleteCondition,
  setConditionForAffectedItemsView,
}: Props) => {
  const t = useFreightTFunction();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('freight.columns.level'),
        width: 190,
        cellClassName: () => dataGridBoldClass,
        renderCell: ({ row }) => {
          return (
            <LevelChip
              bindingType={row.bindingType}
              active={row.assortmentType === AssortmentType.BASE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'name',
        headerName: t('freight.columns.name'),
        width: 260,
        cellClassName: () => dataGridBoldClass,
        renderCell: ({ row }) => {
          const name =
            row.productGroupName ?? row.primaryText ?? row.discountGroupName ?? supplier.name;
          const id =
            row.productGroup ?? row.mgItemNumber ?? row.discountGroup ?? supplier.mgSupplierId;

          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('freight.columns.fromDate'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('freight.columns.toDate'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : <InfinityIcon />),
      },
      {
        ...baseColumnProps,
        field: 'freightSurchange',
        headerName: t('freight.columns.freightSurcharge'),
        renderCell: ({ value }) => value || '-',
      },
      {
        ...baseColumnProps,
        field: 'percentAddon',
        headerName: t('freight.form.percent'),
        renderCell: ({ value }) => (value !== 'undefined' ? value + '%' : '-'),
      },
      {
        ...baseColumnProps,
        field: 'itemsAffected',
        headerName: t('freight.columns.itemsAffected'),
        width: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) =>
          row.mgItemNumber ? (
            '-'
          ) : (
            <ItemsAffected
              mgSupplierId={supplier.mgSupplierId}
              productGroup={row.productGroup}
              discountGroupId={row.discountGroup}
              type={CalculusAreas.FREIGHT}
              openItemsAffectedModal={() => setConditionForAffectedItemsView(row)}
            />
          ),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        width: 90,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <FreightTableActions
            editCondition={() => editCondition(row)}
            deactivateCondition={
              row.assortmentType !== AssortmentType.DRAFT
                ? () => deactivateCondition(row)
                : undefined
            }
            deleteCondition={
              row.assortmentType === AssortmentType.DRAFT
                ? () => deleteCondition(row.id)
                : undefined
            }
          />
        ),
      },
    ],
    [
      t,
      supplier.name,
      supplier.mgSupplierId,
      setConditionForAffectedItemsView,
      editCondition,
      deactivateCondition,
      deleteCondition,
    ]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          getRowId={(row) => row.id}
          columns={columns}
          rows={freightConditions}
          rowHeight={70}
          disableRowSelectionOnClick
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_FREIGHT_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          disableColumnResize
          disableColumnReorder
          hideFooter
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_FREIGHT_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          slots={{
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
};
