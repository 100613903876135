import React from 'react';
import { CalculusInfoRow } from './CalculusInfoRow';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

interface Props {
  costPrice?: number;
  unit?: string;
}

export const CostPriceRow: React.FC<Props> = ({ costPrice, unit }) => {
  const t = useMyAssortmentTFunction();
  const { i18n } = useTranslation();

  return (
    <Stack px={2} py={1.5} flexDirection="row" alignItems="center" justifyContent="space-between">
      <CalculusInfoRow
        label={t('myAssortment.columns.costPriceInSalesUnit')}
        chipLabel={costPrice ? formatPrice(costPrice, i18n.language) : '-'}
        unit={unit}
      />
      <Stack width={({ spacing }) => spacing(3)} />
    </Stack>
  );
};
