import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Chip } from '@shared/components';

interface Props {
  id: number | string;
  name: string;
  onDelete: () => void;
}

export const FilterChip = ({ id, name, onDelete }: Props) => {
  return (
    <Chip
      key={id}
      rounded
      backgroundColor="#FCEED6"
      deleteIcon={<Close />}
      onDelete={onDelete}
      label={
        <Typography color="text.secondary" variant="body2">
          {name}
        </Typography>
      }
    />
  );
};
