import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { MgSupplierItemSearchField } from '@retail/products/components';
import { SelectProductGroup, SelectSupplier } from '../AssortmentTable';
import { Supplier } from '@retail/suppliers/types';
import { ProductGroup } from '@retail/products/types';
import { SideFilters } from '@retail/components';

interface Props {
  assortmentName: string;
  totalItems: number;
  searchTerm: string;
  disableFields: boolean;
  suppliers: Supplier[];
  productGroups: ProductGroup[];
  selectedSupplier: number | null;
  selectedProductGroup: string | null;
  showFilters: boolean;
  selectSupplier: (supplierId: number | null) => void;
  selectProductGroup: (productGroupNr: string | null) => void;
  setSearchTerm: (term: string) => void;
  setPage: (page: number) => void;
  closeFilters: () => void;
}

export function CentralAssortmentFilters({
  assortmentName,
  totalItems,
  searchTerm,
  disableFields,
  suppliers,
  productGroups,
  selectedSupplier,
  selectedProductGroup,
  showFilters,
  selectSupplier,
  selectProductGroup,
  setSearchTerm,
  setPage,
  closeFilters,
}: Props) {
  const t = useMyAssortmentTFunction();
  return (
    <SideFilters
      showFilters={showFilters}
      closeFilters={closeFilters}
      heading={assortmentName}
      subHeading={
        totalItems === 1
          ? t('myAssortment.centralAssortments.oneItem', { count: totalItems })
          : t('myAssortment.centralAssortments.nrOfItems', { count: totalItems })
      }
    >
      <SelectSupplier
        suppliers={suppliers}
        selectedSupplier={selectedSupplier}
        selectSupplier={selectSupplier}
      />
      <SelectProductGroup
        productGroups={productGroups}
        selectedProductGroup={selectedProductGroup}
        selectProductGroup={selectProductGroup}
      />
      <MgSupplierItemSearchField
        size="small"
        width={324}
        searchTerm={searchTerm}
        isDisabled={disableFields}
        setPage={setPage}
        onSubmitSearch={setSearchTerm}
      />
    </SideFilters>
  );
}
