import { Coordinates } from '@retail/retailer/types';
import { getDistance } from 'geolib';

export function calculateDistanceFromCompetitorInKm(
  competitorCoordinates: Coordinates,
  myCoordinates: Coordinates
): number {
  const distance = getDistance(
    { latitude: myCoordinates.lat, longitude: myCoordinates.long },
    { latitude: competitorCoordinates.lat, longitude: competitorCoordinates.long }
  );
  return distance * 0.001;
}