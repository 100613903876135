export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_PUT = 'PUT';
export const METHOD_DELETE = 'DELETE';
export const METHOD_PATCH = 'PATCH';
export type RequestMethods =
  | typeof METHOD_POST
  | typeof METHOD_GET
  | typeof METHOD_PUT
  | typeof METHOD_DELETE
  | typeof METHOD_PATCH;

export interface FetchError {
  status: number;
  text: string;
  payload: {
    error?: string;
    details?: any[];
    message?: string;
    path?: string;
    status?: number;
    timestamp?: string;
  };
}

export interface PPSFetchError {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
}

export interface RequestContext {
  key: string;
  value: string;
}

interface FetcherArgs {
  url: string;
  method?: RequestMethods;
  token?: string;
  body?: string;
  context?: RequestContext;
  options?: RequestInit;
  parsedAs?: ContentType;
  headers?: HeadersInit;
}

async function handleErrors(response: Response) {
  if (!response.ok) {
    let payload;
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      try {
        payload = await response.json();
      } catch (parseError) {
        const fetchError: FetchError = {
          status: response.status,
          text: response.statusText,
          payload,
        };
        return Promise.reject(fetchError);
      }
    } else {
      payload = await response.text();
      const fetchError: FetchError = {
        status: response.status,
        text: response.statusText,
        payload: {
          message: payload,
        },
      };
      return Promise.reject(fetchError);
    }
  }
  return response;
}

type ContentType = 'json' | 'text' | 'blob';

const defaultHeaders: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export async function fetcher<T>({
  url,
  method = METHOD_GET,
  token,
  body,
  context,
  headers = {},
  parsedAs = 'json',
}: FetcherArgs): Promise<T> {
  const options: RequestInit = {
    method,
    headers: { ...defaultHeaders, ...headers },
  };

  if (token) {
    options.headers = { ...options.headers, Authorization: `Bearer ${token}` };
  }

  if (context) {
    options.headers = { ...options.headers, [context.key]: context.value };
  }

  if (body) {
    options.body = body;
  }

  return fetch(url, options)
    .then(handleErrors)
    .then((response) => {
      if (response.status === 204) {
        return Promise.resolve();
      }

      const contentLength = response.headers.get('Content-Length');
      if (contentLength !== null && +contentLength === 0) {
        // return empty body if we cannot parse it to json.
        return Promise.resolve();
      }
      // Let others deal with response
      if (parsedAs !== 'json') {
        return response;
      }

      return response.json();
    })
    .catch((err) => {
      // err can be 4xx and 5xx errors
      // but also Failed to fetch, where user is offline and does not have internet.
      throw err;
    });
}
