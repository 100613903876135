import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { DraftTabs } from '@retail/my-assortment/types';
import { UnderlinedTabs } from '@shared/components';
import { useMemo } from 'react';

interface Props {
  selectedTab: DraftTabs;
  setSelectedTab: (tab: DraftTabs) => void;
  includeCentral?: boolean;
}

export const AddItemsTabs = ({ selectedTab, setSelectedTab, includeCentral = true }: Props) => {
  const t = useMyAssortmentTFunction();
  const tabs = useMemo(
    () => [
      { label: t('myAssortment.actions.addItems'), value: DraftTabs.ADD_ITEMS },
      { label: t('myAssortment.actions.importItemsToDraft'), value: DraftTabs.IMPORT },
      includeCentral
        ? {
            label: t('myAssortment.tabs.centralAssortments'),
            value: DraftTabs.CENTRAL_ASSORTMENTS,
          }
        : {},
    ],
    [t, includeCentral]
  );

  const selectTab = (_: any, newTab: DraftTabs) => setSelectedTab(newTab);

  return <UnderlinedTabs id="draft" tabs={tabs} currentTab={selectedTab} onChange={selectTab} />;
};
