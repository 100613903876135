import { ProductGroupDTO } from '../../dto';
import { GroupType } from '../GroupType';

export interface ProductGroupProps {
  id: number;
  type: GroupType;
  groupNumber: string;
  name: string;
  numberOfItems?: number;
}

export class ProductGroup {
  id: number;
  type: GroupType;
  groupNumber: string;
  name: string;
  numberOfItems?: number;

  constructor({ id, type, groupNumber, name, numberOfItems }: ProductGroupProps) {
    this.id = id;
    this.type = type;
    this.groupNumber = groupNumber;
    this.name = name;
    this.numberOfItems = numberOfItems;
  }

  getLabel = (): string => (this.name ? `${this.groupNumber} ${this.name}` : this.groupNumber);

  static fromDto = (dto: ProductGroupDTO): ProductGroup =>
    new ProductGroup({
      id: dto.id,
      type: dto.type,
      groupNumber: dto.groupNumber,
      name: dto.name,
      numberOfItems: dto.numberOfItems,
    });
}
