import { Supplier } from '@retail/suppliers/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useMemo, useState } from 'react';
import { createMarkupSchema, MarkupsTable } from '@retail/markup/components';
import { ConditionState, MarkupCondition } from '@retail/calculus/types';
import { Paper, UniversalFormProvider } from '@shared/components';
import { CreateMarkupConditionDialog } from './CreateMarkupCondition';
import { DeleteOrDeactivateMarkupDialog } from './DeleteOrDeactivateMarkupDialog';
import { EditMarkupDialog } from './MarkupTemplates/EditMarkupDialog';
import { useFetchConditions } from '@retail/calculus/data-access';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { useCalculusConditions } from '@retail/calculus/context';
import { DeleteDialog } from '@retail/components';
import { useUserBrands } from '@retail/app/stores/selected-context';
import { useLanguageSelector } from '@retail/app/hooks';
import { Stack } from '@mui/material';

interface Props {
  supplier: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  closeCreate: () => void;
}

export function DepartmentMarkupContainer({
  supplier,
  typesToShow,
  isCreateOpen,
  closeCreate,
}: Props) {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const { draftAssortmentId, baseAssortmentId } = useDepartmentAssortments();
  const { selectedProductGroupNumber, selectedMgItemId } = useLevelSearchParams();
  const { handleDeleteConditions } = useCalculusConditions();

  const [markupForDeactivation, setMarkupForDeactivation] = useState<MarkupCondition | undefined>(
    undefined
  );
  const [markupForEdit, setMarkupForEdit] = useState<MarkupCondition | undefined>(undefined);
  const [markupIdToDelete, setMarkupIdToDelete] = useState<number | undefined>(undefined);

  const handleDeactivate = (markup?: MarkupCondition) => setMarkupForDeactivation(markup);
  const handleEdit = (markup?: MarkupCondition) => setMarkupForEdit(markup);
  const handleDelete = (markupId?: number) => setMarkupIdToDelete(markupId);

  const { data: conditionDtos = [] } = useFetchConditions({
    assortmentId: typesToShow === ConditionState.ACTIVE ? baseAssortmentId : draftAssortmentId,
    conditionTypes: ['MARKUP'],
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: [supplier.mgSupplierId],
    groupNumbers: selectedProductGroupNumber ? [selectedProductGroupNumber] : [],
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : [],
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
  });
  const { selectedBrand } = useUserBrands();
  const includeUnit = useMemo(() => !!selectedBrand.wholesaler, [selectedBrand.wholesaler]);

  const markupConditionDtos = useMemo(
    () => (conditionDtos?.length > 0 ? conditionDtos[0].markupConditions : []),
    [conditionDtos]
  );
  const markupConditions = markupConditionDtos.map(MarkupCondition.fromDto);

  const existingMarkupConditions = markupConditions.filter(
    (markup) => markup.operation !== 'DELETE'
  );

  const markupSchema = useMemo(() => createMarkupSchema(t, includeUnit), [includeUnit, t]);

  return (
    <Stack flexGrow={1} overflow="auto" borderTop={({ palette }) => `1px solid ${palette.divider}`}>
      <MarkupsTable
        supplier={supplier}
        language={language}
        markups={existingMarkupConditions}
        handleDeactivate={handleDeactivate}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />

      {markupForDeactivation && (
        <DeleteOrDeactivateMarkupDialog
          isOpen
          mgSupplierId={supplier.mgSupplierId}
          activeMarkupCondition={markupForDeactivation}
          onClose={handleDeactivate}
        />
      )}

      <UniversalFormProvider schema={markupSchema} defaultValues={markupSchema.getDefault()}>
        <CreateMarkupConditionDialog
          open={isCreateOpen}
          onClose={closeCreate}
          supplier={supplier}
          includeUnit={includeUnit}
        />
      </UniversalFormProvider>

      {markupForEdit && (
        <EditMarkupDialog isOpen markupToEdit={markupForEdit} onClose={() => handleEdit()} />
      )}
      {markupIdToDelete && (
        <DeleteDialog
          isOpen
          dialogTitle={t('calculus.actions.deleteFromDraft')}
          message={t('calculus.actions.confirmRemoval')}
          onDelete={() => handleDeleteConditions([markupIdToDelete])}
          onClose={handleDelete}
        />
      )}
    </Stack>
  );
}
