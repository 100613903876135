import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { MgSupplierItemSearchResultDTO, PaginationResultDTO } from '@retail/products/types';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';
import { useMutateMPEFetch, useSelectedOrgUnitHeader } from '@retail/app/hooks';
import { mpeApi } from '@retail/mgpris/config';

interface SearchProps {
  query?: string;
  articleIds?: string[];
  supplierIds?: number[];
  groupNumbers?: string[];
  discountGroupIds?: number[];
  assortmentId?: number;
  withAgreementPrices?: boolean;
  fromMainSupplier?: boolean;
  includeOwner?: boolean;
  inAssortment?: boolean;
  page: number;
  size: number;
}

interface FetchProps extends SearchProps {
  baseUrl: string;
  token: string;
  headers?: HeadersInit;
}

export async function searchMgSupplierItems({
  baseUrl,
  groupNumbers,
  discountGroupIds,
  assortmentId,
  supplierIds,
  query,
  articleIds,
  withAgreementPrices = true,
  fromMainSupplier,
  includeOwner = true,
  inAssortment,
  page,
  size,
  token,
  headers,
}: FetchProps) {
  return fetcher<PaginationResultDTO<MgSupplierItemSearchResultDTO[]>>({
    url: buildUrl({
      base: baseUrl,
      route: mpeApi.mgItems.supplierItems.getFullLinkPath(),
      searchParams: {
        query,
        articleIds,
        groupNumbers,
        discountGroupIds,
        assortmentId,
        supplierIds,
        withAgreementPrices,
        fromMainSupplier,
        includeOwner,
        inAssortment,
        page,
        size,
      },
    }),
    token,
    headers,
  });
}

export function useMgSupplierItemsSearch(searchProps: SearchProps, options?: QueryOptions) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  const fetcher = useCallback(
    () =>
      searchMgSupplierItems({
        baseUrl: mpe.url,
        token,
        headers: selectedOrgUnitHeader,
        ...searchProps,
      }),
    [mpe.url, token, selectedOrgUnitHeader, searchProps]
  );
  return useQueryFetch({
    fetcher,
    queryKey: ['mg-supplier-item', 'search', searchProps],
    ...options,
  });
}

export function useMutateMgSupplierItemsSearch(options: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<PaginationResultDTO<MgSupplierItemSearchResultDTO[]>, void, SearchProps>(
    {
      url: (searchProps) =>
        buildUrl({
          base: mpe.url,
          route: mpeApi.mgItems.supplierItems.getFullLinkPath(),
          searchParams: { ...searchProps },
        }),
      token,
      method: 'GET',
      mutationKey: ['mg-supplier-item-search'],
      ...options,
    }
  );
}
