import { SxProps, Theme } from '@mui/material';
import { UseTreeItem2Status } from '@mui/x-tree-view';

export const childNodeClassName = 'childnode';

export const navTreeLabelStyling =
  (isChildNode: boolean): SxProps<Theme> =>
  (theme) => ({
    color: isChildNode ? theme.palette.text.secondary : undefined,
    fontSize: '14px',
    padding: theme.spacing(0.5, 0),
    fontWeight: 'medium',
  });

export const navTreeContentStyling = (depth: number, status: UseTreeItem2Status): SxProps<Theme> => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: depth > 2 ? 1 : depth > 1 ? 3 : depth > 0 ? 2 : 0,
  backgroundColor: (theme) => (status.selected ? theme.palette.common.white : undefined),
  fontWeight: status.selected ? 600 : undefined,

  '> *': {
    fontWeight: status.selected ? 600 : undefined,
    color: (theme) => (status.selected ? theme.palette.primary.main : undefined),
  },
});

export const navTreeGroupTransition: SxProps<Theme> = () => ({
  padding: 0,
});
