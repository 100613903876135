import { Close } from '@mui/icons-material';
import { IconButton, Link, Stack, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

export interface MgItemInfoProps {
  primaryText: string;
  secondaryText?: string;
  closeProductCard: () => void;
  openProductCardModal: () => void;
}

export const MgItemInfo = ({
  primaryText,
  secondaryText,
  closeProductCard,
  openProductCardModal,
}: MgItemInfoProps) => {
  const t = useMyAssortmentTFunction();
  return (
    <Stack p={2} flexDirection="row" justifyContent="space-between" alignItems="flex-start">
      <Stack>
        <Typography variant="subtitle1">{primaryText}</Typography>
        {secondaryText && <Typography variant="body2">{secondaryText}</Typography>}
      </Stack>
      <Stack alignItems="flex-end" flexWrap="nowrap">
        <IconButton sx={{ p: 0 }} onClick={closeProductCard}>
          <Close />
        </IconButton>
        <Link textAlign="end" component="button" onClick={openProductCardModal}>
          {t('myAssortment.actions.seeCompleteProductCard')}
        </Link>
      </Stack>
    </Stack>
  );
};
