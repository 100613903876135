import { Box, FormHelperText, FormLabel, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorOutlineRounded } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DeletionFromDateSchema } from './deletionFromDateSchema';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';

export function DeletionFromDateForm() {
  const t = useAssortmentTFunction();
  const { control } = useFormContext<DeletionFromDateSchema>();

  const fromDateController = useController({ name: 'fromDate', control });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display="flex" alignItems="center">
        <FormLabel error={!!fromDateController.fieldState.error} sx={{ width: 150 }} required>
          {t('assortment.deleteItems.form.validFrom')}
        </FormLabel>

        <Stack gap={1} overflow="hidden">
          <DatePicker
            {...fromDateController.field}
            minDate={new Date()}
            format="dd/MM/yyyy"
            slotProps={{
              textField: { sx: { width: 192 } },
            }}
          />

          {!!fromDateController.fieldState.error && (
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              <ErrorOutlineRounded color="warning" fontSize="small" />
              <FormHelperText sx={{ color: ({ palette }) => palette.warning.main }}>
                {fromDateController.fieldState.error.message}
              </FormHelperText>
            </Stack>
          )}
        </Stack>
      </Box>
    </LocalizationProvider>
  );
}
