import { useFetchBk04ProductGroups } from '@retail/products/data-access';
import { OverGroup } from '@retail/products/types';
import { productGroupSearchParamKey } from '@retail/products/utils';
import { useMultipleSelectedSearchParamState, useSelectedSearchParamState } from '@shared/hooks';

export const useSelectProductGroups = () => {
  const { data: bk04ProductGroupsDto = [] } = useFetchBk04ProductGroups({ suspense: true });
  const bk04ProductGroups = bk04ProductGroupsDto.map(OverGroup.fromDto);
  const flattenedProductGroups = OverGroup.flatten(bk04ProductGroups);

  const [selectedProductGroups, selectProductGroups] = useMultipleSelectedSearchParamState({
    options: flattenedProductGroups,
    getOptionId: ({ groupNumber }) => groupNumber,
    searchParamKey: productGroupSearchParamKey,
  });

  const [selectedProductGroup, selectProductGroup, selectProductGroupByNr] =
    useSelectedSearchParamState({
      options: flattenedProductGroups,
      getOptionId: ({ groupNumber }) => groupNumber,
      searchParamKey: productGroupSearchParamKey,
    });

  return {
    productGroups: flattenedProductGroups,
    selectedProductGroups,
    selectProductGroups,
    selectedProductGroup,
    selectProductGroup,
    selectProductGroupByNr,
  };
};
