export enum OrgUnitRoleDTO {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export interface UserBrandDTO {
  name: string;
  iamKey: string;
  role?: OrgUnitRoleDTO;
  orgUnitId: number;
}

export interface UserMemberDTO {
  dataownerId: string;
  memberId: string;
  name: string;
  role?: OrgUnitRoleDTO;
  orgUnitId: number;
}

export interface UserDepartmentDTO {
  dataownerId: string;
  locationId: string;
  memberId: string;
  departmentId: string;
  name: string;
  role?: OrgUnitRoleDTO;
  latitude?: number;
  longitude?: number;
  orgUnitId: number;
}

export interface UserOrgUnitsDTO {
  brand: UserBrandDTO | null;
  members: UserMemberDTO[];
  departments: UserDepartmentDTO[];
}
