import { ListItem, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import { Chip, OutlinedButton } from '@shared/components';
import { CentralAssortmentLogo } from '../CentralAssortmentLogo';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { Link } from 'react-router-dom';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';

interface Props {
  assortment: MonitoringAssortment;
  linkTo: (assortmentId: number) => string;
  selectedOrgUnitId: number;
  brandLogoUri: string;
}

export function AssortmentListItem({ assortment, linkTo, selectedOrgUnitId, brandLogoUri }: Props) {
  const { palette, shape, shadows } = useTheme();
  const t = useAssortmentTFunction();

  return (
    <ListItem key={assortment.id} disablePadding sx={{ display: 'list-item' }}>
      <Stack
        component={Link}
        to={linkTo(assortment.id)}
        flexDirection="row"
        alignItems="center"
        color={palette.common.black}
        px={3}
        py={1.5}
        sx={{
          textDecoration: 'none',
          '&:hover,:focus': {
            outline: `2px solid ${palette.primary.main}`,
            borderRadius: shape.borderRadius,
            boxShadow: shadows[6],
            '>button': {
              background: palette.primary.main,
              color: palette.common.white,
            },
          },
        }}
      >
        <ListItemText>
          <Stack display="flex" flexDirection="row" mb={0.5} gap={1.5} alignItems="center">
            <Typography variant="h6">{assortment.title}</Typography>
            {selectedOrgUnitId !== assortment.owner?.id && (
              <CentralAssortmentLogo selectedBrandLogo={brandLogoUri} />
            )}
          </Stack>
          <Stack flexDirection="row" gap={1}>
            <Typography variant="body1" color="text.secondary">
              {t('assortment.numItems')}
            </Typography>
            <Chip
              size="small"
              label={assortment.size}
              backgroundColor={palette.secondary[400]}
              sx={{ cursor: 'inherit' }}
            />
          </Stack>
        </ListItemText>
        <OutlinedButton size="small">
          {t('assortment.assortmentsOverview.seeAssortment')}
        </OutlinedButton>
      </Stack>
    </ListItem>
  );
}
