import { atom, useRecoilState } from 'recoil';
import { syncLocalStorageEffect } from '@retail/hooks';
import { useMemo } from 'react';

const IncludeVatMyAssortmentAtom = atom<boolean>({
  key: 'includeVatMyAssortment',
  default: false,
  effects: [syncLocalStorageEffect<boolean>('includeVAT')],
});

export const useIncludeVatMyAssortment = () => {
  const [includeVat, setIncludeVat] = useRecoilState(IncludeVatMyAssortmentAtom);
  const toggleIncludeVat = () => setIncludeVat((value) => !value);

  return useMemo(() => ({ includeVat, toggleIncludeVat }), [includeVat]);
};
