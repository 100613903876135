import { SvgIcon, SvgIconProps } from '@mui/material';

export const DecreaseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
      >
        <path
          d="M7.86603 10.5C7.48112 11.1667 6.51888 11.1667 6.13397 10.5L0.937822 1.5C0.552922 0.833332 1.03405 -1.22094e-06 1.80385 -1.15364e-06L12.1962 -2.4512e-07C12.966 -1.77822e-07 13.4471 0.833333 13.0622 1.5L7.86603 10.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
