import { PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useParams } from 'react-router-dom';
import { useFetchMgItem } from '@retail/products/data-access';
import { MgItem } from '@retail/products/types';
import { MgItemContainer, MgItemNotFound } from '@retail/products/containers';

export function MgItemPage() {
  const { itemNumber } = useParams<{ itemNumber: string }>();

  const { data: mgItemDto } = useFetchMgItem(
    {
      mgItemNumber: Number(itemNumber),
    },
    { suspense: true }
  );

  const mgItem = mgItemDto && MgItem.fromDto(mgItemDto);

  return (
    <PageLayout maxWidth="full" spacing={2}>
      {mgItem ? (
        <SuspenseWithSentryErrorBoundary>
          <MgItemContainer mgItem={mgItem} />
        </SuspenseWithSentryErrorBoundary>
      ) : (
        <MgItemNotFound mgItemNr={itemNumber} />
      )}
    </PageLayout>
  );
}
