import { Box, Typography } from '@mui/material';
import { useMgPdmTFunction } from '@retail/mgpdm/i18n';
import { forwardRef } from 'react';

export const MgPdmLogo = forwardRef((props, ref) => {
  const t = useMgPdmTFunction();

  return (
    <Box position="relative" height="100%" ref={ref} {...props}>
      <Box
        sx={{
          position: 'absolute',
          top: 16,
          left: 104,
          zIndex: 1,
        }}
      >
        <Typography variant="h3">{t('mgPdm.PDM')}</Typography>
      </Box>
      <Box
        sx={{
          width: '120px',
          height: '100%',
          backgroundColor: ({ palette }) => palette.secondary.dark,
          transform: 'skew(-43deg)',
          marginLeft: '5rem',
          marginRight: '5rem',
        }}
      ></Box>
    </Box>
  );
});
