import React, { forwardRef } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useController, useFormContext } from 'react-hook-form';
import { ExportSchema } from './exportSchema';
import { isValid } from 'date-fns';

interface Props {
  hasValidation?: boolean;
  onUpdateDateTimePicker?: (fromDate: Date | null) => Promise<void>;
}

export const ExportDateTimePicker = forwardRef<HTMLDivElement, Props>(
  ({ hasValidation = true, onUpdateDateTimePicker }: Props, ref) => {
    const { control } = useFormContext<ExportSchema>();

    const allArticlesController = useController({ name: 'allArticles', control });
    const fromDateController = useController({ name: 'fromDate', control });

    return (
      <DateTimePicker
        {...fromDateController.field}
        value={fromDateController.field.value ?? null}
        ampm={false}
        viewRenderers={{
          hours: null,
          minutes: null,
          seconds: null,
        }}
        disabled={hasValidation ? allArticlesController.field.value === true : false}
        maxDate={new Date()}
        ref={ref}
        onChange={async (value) => {
          if (isValid(value) && onUpdateDateTimePicker) await onUpdateDateTimePicker(value);
        }}
        slotProps={{
          field: {
            clearable: true,
          },
          textField: {
            size: 'small',
            error: hasValidation ? !!fromDateController.fieldState.error : false,
            helperText: hasValidation ? fromDateController.fieldState.error?.message : '',
            sx: {
              minWidth: '220px',
              maxWidth: '220px',
            },
          },
        }}
      />
    );
  }
);

ExportDateTimePicker.displayName = 'ExportDateTimePicker';
