import { PurchaseAgreementDTO } from '../dto';

export class PurchaseAgreement {
  id: number;
  name: string;
  standardAgreement: boolean;
  supplierId: number;
  mpeCreatedAt: Date;
  mpeUpdatedAt: Date;
  source: string;
  sourceAgreementId?: number;

  constructor(
    id: number,
    name: string,
    standardAgreement: boolean,
    supplierId: number,
    mpeCreatedAt: Date,
    mpeUpdatedAt: Date,
    source: string,
    sourceAgreementId?: number
  ) {
    this.id = id;
    this.name = name;
    this.standardAgreement = standardAgreement;
    this.supplierId = supplierId;
    this.mpeCreatedAt = mpeCreatedAt;
    this.mpeUpdatedAt = mpeUpdatedAt;
    this.source = source;
    this.sourceAgreementId = sourceAgreementId;
  }

  static fromDto = (dto: PurchaseAgreementDTO): PurchaseAgreement =>
    new PurchaseAgreement(
      dto.agreementId,
      dto.agreementText,
      dto.standardAgreement,
      dto.mgSupplierId,
      dto.mpeCreatedAt,
      dto.mpeUpdatedAt,
      dto.source,
      dto.sourceAgreementId
    );
}
