export enum HierarchyLevels {
  standard = 'STANDARD',
  supplier = 'MG_PARTICIPANT_ACCOUNT',
  overGroup = 'OVERGRUPPE',
  mainGroup = 'HOVEDGRUPPE',
  underGroup = 'UNDERGRUPPE',
  productGroup = 'VAREGRUPPE',
  discountGroup = 'DISCOUNT_GROUP',
  item = 'MG_SUPPLIER_ITEM',
}
