import { BodyLoader } from '@retail/components';
import {
  CreateConditionDialog,
  MarkupChange,
  markupMetaSchema,
  MarkupMetaSchema,
  PrioritizeSupplierDialog,
  ProductCard,
  SupplierTabs,
  useProductCardStore,
} from '@retail/my-assortment/components';
import { ItemsDTO, SupplierWithPriority } from '@retail/my-assortment/types';
import { MgItem, RecommendedPrice } from '@retail/products/types';
import { useDisclosure } from '@shared/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchMgItem } from '@retail/products/data-access';
import {
  useAssortmentItemsActions,
  useDepartmentAssortments,
  useIncludeVatMyAssortment,
} from '@retail/my-assortment/context';
import { useMarkupActions } from '@retail/markup/context';
import { UniversalFormProvider } from '@shared/components';
import { useCalculusTFunction } from '@retail/calculus/i18n';

export interface ProductCardContainerProps {
  mgItemNumber: number;
  mgSupplierId: number;
}

export function ProductCardContainer({ mgItemNumber, mgSupplierId }: ProductCardContainerProps) {
  const t = useCalculusTFunction();
  const { baseAssortmentId } = useDepartmentAssortments();
  const { updatePriority, updateItemsIsLoading } = useAssortmentItemsActions();
  const { includeVat } = useIncludeVatMyAssortment();
  const { resetMarkup, newMarkup, selectedPackageType } = useProductCardStore();

  const { data: mgItemDto } = useFetchMgItem(
    {
      mgItemNumber,
      assortmentId: baseAssortmentId,
      calculationPackages: ['PRIMARY'],
    },
    { suspense: true }
  );
  const mgItem = MgItem.fromDto(mgItemDto!);

  const { isOpen: isPrioritizeDialogOpen, onToggle: togglePrioritizeDialog } = useDisclosure();
  const {
    isOpen: createConditionDialogIsOpen,
    onOpen: openCreateConditionDialog,
    onClose: closeCreateConditionDialog,
  } = useDisclosure();

  const suppliers: SupplierWithPriority[] = useMemo(
    () =>
      mgItem.supplierItems.map((supplierItem) => {
        return {
          id: supplierItem.mgSupplierId,
          name: supplierItem.mgSupplierName,
          priority: supplierItem.priority,
        };
      }),
    [mgItem.supplierItems]
  );

  const [selectedSupplierId, selectSupplier] = useState(() => mgSupplierId);

  useEffect(() => {
    selectSupplier(mgSupplierId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mgSupplierId]);

  const selectedSupplierItem = mgItem.supplierItems.find(
    (supplierItem) => supplierItem.mgSupplierId === selectedSupplierId
  );

  const recommendedPrice = mgItem.recommendedPrice
    ? RecommendedPrice.fromDto(mgItem.recommendedPrice)
    : undefined;

  const {
    createMgItemLevelSalesPriceCondition,
    createMgItemLevelMarkupCondition,
    isLoadingPostChanges,
  } = useMarkupActions({
    mgSupplierId: selectedSupplierId,
  });

  const selectedSupplierItemPackage = useMemo(
    () => selectedSupplierItem?.packages.find(({ type }) => type === selectedPackageType),
    [selectedPackageType, selectedSupplierItem?.packages]
  );

  const createCondition = useCallback(
    (
      { fromDate, markupName }: MarkupMetaSchema,
      { salesPrice, editedField, salesFactor, salesPriceIncVat }: MarkupChange,
      unit: string
    ) => {
      if (editedField !== 'price')
        createMgItemLevelMarkupCondition({
          mgItemNumber: mgItem.id,
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: salesFactor,
        });
      else if (editedField === 'price' && salesPriceIncVat && salesPrice) {
        createMgItemLevelSalesPriceCondition({
          mgItemNumber: mgItem.id,
          validFrom: fromDate,
          templateName: markupName,
          salesPrice: includeVat ? salesPriceIncVat : salesPrice,
          incVat: includeVat,
          unit,
        });
      }

      resetMarkup();
      closeCreateConditionDialog();
    },

    [
      closeCreateConditionDialog,
      createMgItemLevelMarkupCondition,
      createMgItemLevelSalesPriceCondition,
      includeVat,
      mgItem.id,
      resetMarkup,
    ]
  );

  const handlePostPriorityChanges = useCallback(
    (suppliers: SupplierWithPriority[]) => {
      const supplierItems = suppliers.map((supplier) => {
        return {
          mgSupplierId: supplier.id,
          mgItemNumber: mgItem.id,
          priority: supplier.priority,
        } as ItemsDTO;
      });
      updatePriority(supplierItems);
    },
    [mgItem.id, updatePriority]
  );

  if (updateItemsIsLoading || !selectedSupplierItem || !selectedSupplierItemPackage) {
    return <BodyLoader />;
  }

  return (
    <>
      <SupplierTabs
        suppliers={suppliers}
        clickedSupplier={mgSupplierId}
        selectedSupplier={selectedSupplierId}
        selectSupplier={selectSupplier}
        openPrioritizeDialog={togglePrioritizeDialog}
      />
      <ProductCard
        key={selectedSupplierItem.id}
        selectedSupplierItem={selectedSupplierItem}
        selectedSupplierItemPackage={selectedSupplierItemPackage}
        recommendedPrice={recommendedPrice}
        includeVat={includeVat}
        submitMarkupValues={openCreateConditionDialog}
      />
      {newMarkup && (
        <UniversalFormProvider
          schema={markupMetaSchema(t)}
          defaultValues={
            {
              fromDate: new Date(),
              markupName: selectedSupplierItem.primaryText,
            } as MarkupMetaSchema
          }
        >
          <CreateConditionDialog
            itemName={selectedSupplierItem?.primaryText}
            open={createConditionDialogIsOpen}
            isLoadingCreate={isLoadingPostChanges}
            onClose={closeCreateConditionDialog}
            handleCreateCondition={(formData) =>
              createCondition(formData, newMarkup, selectedSupplierItemPackage.vilmaUnit.unit)
            }
          />
        </UniversalFormProvider>
      )}

      <PrioritizeSupplierDialog
        suppliers={suppliers}
        isOpen={isPrioritizeDialogOpen}
        onClose={togglePrioritizeDialog}
        handlePostPriorityChanges={handlePostPriorityChanges}
        isLoading={updateItemsIsLoading}
      />
    </>
  );
}
