import { useCallback, useEffect, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  APP_BAR_HEIGHT,
  Navigation,
  NavRoute,
  NavWithSubRoutes,
  Sidebar,
  SidebarHeader,
  SidebarUserMenu,
} from '@shared/components';
import { useSidebarDisclosure } from '@shared/stores';
import { pdmRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import {
  isSuperUserAtom,
  useFetchMe,
  useIsSuperUser,
  useAuthContext,
} from '@retail/auth/data-access';
import { MarketIcon, ShoppingCartIcon, StorageUnitIcon } from '@shared/custom-icons';
import { useSharedTranslation } from '@shared/i18n';
import { useSetRecoilState } from 'recoil';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import dayjs from 'dayjs';
import { APP_BAR_MGP_MARGIN_TOP } from './MgpAppSidebar';

type Route = NavRoute | NavWithSubRoutes;

export function PdmAppSidebar() {
  const t = useAppTFunction();
  const { palette } = useTheme();

  const { t: sharedT } = useSharedTranslation();
  const { version, buildTime } = useMgPrisConfig();

  const {
    isOpen: isSidebarOpen,
    onToggle: toggleSidebar,
    onOpen: openSidebar,
  } = useSidebarDisclosure();

  const { data: user } = useFetchMe({ refetchOnWindowFocus: false });
  const userName = useMemo(() => `${user?.givenName} ${user?.familyName}`, [user]);

  const isSuperUser = useIsSuperUser();
  const setIsSuperUser = useSetRecoilState(isSuperUserAtom);

  const { logout } = useAuthContext();
  const logoutDefault = useCallback(() => logout(), [logout]);

  const marketInsightRouteMatch = useMatch(`${pdmRoutes.marketInsight.fullRoutePath}/*`);
  const itemsRouteMatch = useMatch(`${pdmRoutes.items.fullRoutePath}/*`);
  const brandAssortmentRouteMatch = useMatch(`${pdmRoutes.brandAssortment.fullRoutePath}/*`);

  const navRoutes: Route[] = useMemo(() => {
    const routes: Route[] = [
      {
        title: t('mgPdm.brandAssorment'),
        to: pdmRoutes.brandAssortment.getFullLinkPath(),
        Icon: StorageUnitIcon,
        isActive: !!brandAssortmentRouteMatch,
      },
      {
        title: t('app.routes.products'),
        to: pdmRoutes.items.getFullLinkPath(),
        Icon: ShoppingCartIcon,
        isActive: !!itemsRouteMatch,
      },
      {
        title: t('app.routes.marketInsight'),
        to: pdmRoutes.marketInsight.getFullLinkPath(),
        Icon: MarketIcon,
        isActive: !!marketInsightRouteMatch,
      },
    ];

    return routes;
  }, [t, brandAssortmentRouteMatch, marketInsightRouteMatch, itemsRouteMatch]);

  useEffect(() => {
    setIsSuperUser(isSuperUser);
  }, [isSuperUser, setIsSuperUser]);

  const { buildDate, buildTimeFormatted } = useMemo(
    () => ({
      buildDate: buildTime && dayjs.unix(buildTime).format('l'),
      buildTimeFormatted: buildTime && dayjs.unix(buildTime).format('hh:mm:ss'),
    }),
    [buildTime]
  );

  const listButtonStyles = {
    color: palette.grey.A400,
    background: 'none',

    '&.Mui-selected': {
      color: palette.common.white,
      background: palette.secondary.main,
    },
  };

  return (
    <Sidebar
      isOpen={isSidebarOpen}
      toggleOpen={toggleSidebar}
      appBarHeight={APP_BAR_HEIGHT + APP_BAR_MGP_MARGIN_TOP}
      hasBorderTopRightRadius
    >
      <SidebarHeader title={sharedT('shared.navigation')} expandedView={isSidebarOpen} />
      <Navigation
        navRoutes={navRoutes}
        expandedView={isSidebarOpen}
        expandView={openSidebar}
        overridingStyles={listButtonStyles}
      />

      <Box marginTop="auto" display="flex" justifyContent="center" p={3}>
        <SidebarUserMenu userName={userName} logOut={logoutDefault} expandedView={isSidebarOpen} />
      </Box>
    </Sidebar>
  );
}
