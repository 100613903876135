import { Box, Typography } from '@mui/material';
import { ExportDateTimePicker } from './ExportDateTimePicker';
import { ContainedButton } from '@shared/components';
import { DownloadIcon } from '@sanity/icons';
import { useExportTFunction } from '@retail/export/i18n';

interface Props {
  toggleExportDialog: () => void;
  onUpdateDateTimePicker: (fromDate: Date | null) => Promise<void>;
}

export const ExportButtons = ({ onUpdateDateTimePicker, toggleExportDialog }: Props) => {
  const t = useExportTFunction();

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2" sx={{ mr: 1 }}>
        {t('export.fromDate')}
      </Typography>

      <ExportDateTimePicker onUpdateDateTimePicker={onUpdateDateTimePicker} hasValidation={false} />

      <Box ml={1}>
        <ContainedButton
          color="secondary"
          size="small"
          onClick={toggleExportDialog}
          startIcon={<DownloadIcon />}
        >
          {t('export.download')}
        </ContainedButton>
      </Box>
    </Box>
  );
};
