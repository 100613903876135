import React from 'react';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDelete: () => void;
  isLoading: boolean;
}

export const DeleteAssortmentDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  handleConfirmDelete,
  isLoading,
}) => {
  const t = useAssortmentTFunction();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t('assortment.admin.delete.title')}</DialogTitle>

      <DialogContent>
        <Typography variant="body1">{t('assortment.admin.delete.confirm.description')}</Typography>
      </DialogContent>

      <DialogActions>
        <TextButton size="small" onClick={onClose}>
          {t('assortment.common.cancel')}
        </TextButton>
        <ContainedButton
          size="small"
          color="warning"
          onClick={handleConfirmDelete}
          loading={isLoading}
        >
          {t('assortment.common.confirm')}{' '}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAssortmentDialog;
