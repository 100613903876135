import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { mgPrisEnvConfigs } from '@retail/mgpris/config';
import { FeatureFlag, mgPrisFeatures } from '@retail/mgpris/config-types';
import { Chip } from '@shared/components';
import { Tooltip, useTheme } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

const devFeatures = mgPrisEnvConfigs.dev.featureFlags;
const stageFeatures = mgPrisEnvConfigs.stage.featureFlags;
const prodFeatures = mgPrisEnvConfigs.prod.featureFlags;

const features = mgPrisFeatures.map((feature) => ({
  name: feature,
  envs: {
    dev: devFeatures.find(({ name }) => name === feature),
    stage: stageFeatures.find(({ name }) => name === feature),
    prod: prodFeatures.find(({ name }) => name === feature),
  },
}));

type RowType = (typeof features)[number];

const columns: GridColDef<RowType>[] = [
  {
    field: 'name',
    headerName: 'Feature',
    flex: 1,
    renderCell: ({ value }) => <Chip label={value} />,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    renderCell: ({ row }) => {
      switch (row.name) {
        case 'bracket-price-table':
          return 'Show bracket price table for wholesalers';
        case 'my-assortment:items:write':
          return 'Allows adding items to my assortment';
        case 'fixed-price':
          return 'Show fixed price options';
        default:
          return 'Missing description';
      }
    },
  },
  {
    field: 'dev',
    headerName: 'Dev',
    flex: 1,
    renderCell: ({ row }) => {
      const feature = row.envs.dev;
      return <FeatureChip feature={feature} />;
    },
  },
  {
    field: 'stage',
    headerName: 'Stage',
    flex: 1,
    renderCell: ({ row }) => {
      const feature = row.envs.stage;
      return <FeatureChip feature={feature} />;
    },
  },
  {
    field: 'prod',
    headerName: 'Prod',
    flex: 1,
    renderCell: ({ row }) => {
      const feature = row.envs.prod;
      return <FeatureChip feature={feature} />;
    },
  },
];

export const FeatureTogglesTable = () => {
  return (
    <DataGridPremium
      columns={columns}
      rows={features}
      getRowId={(row) => row.name}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
      sx={{ width: '1200px' }}
    />
  );
};

const FeatureChip = ({ feature }: { feature: FeatureFlag | undefined }) => {
  const { palette } = useTheme();
  if (!feature) return <Chip backgroundColor="#ffb9b0" label="Disabled" />;
  if (typeof feature.enabled === 'boolean') {
    return feature.enabled ? (
      <Chip backgroundColor={palette.success['100']} label="Enabled" />
    ) : (
      <Chip backgroundColor="#ffb9b0" label="Disabled" />
    );
  }
  return (
    <Tooltip title={feature.enabled.toString()}>
      <Chip label="Conditional" backgroundColor="#ffddb0" avatar={<HelpOutline />} />
    </Tooltip>
  );
};
