import { BodyLoader } from '@retail/components';
import {
  CreatedSalesFactor,
  CreatedSalesPrice,
  DatedPackageMarkups,
  PrioritizeSupplierDialog,
  SupplierTabs,
  useBracketPriceStore,
  WholesalerProductCard,
} from '@retail/my-assortment/components';
import { ItemsDTO, SupplierWithPriority } from '@retail/my-assortment/types';
import { MgItem } from '@retail/products/types';
import { useDisclosure } from '@shared/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchMgItem } from '@retail/products/data-access';
import {
  useAssortmentItemsActions,
  useDepartmentAssortments,
  useIncludeVatMyAssortment,
} from '@retail/my-assortment/context';
import { useMarkupActions } from '@retail/markup/context';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import {
  CreateMgItemLevelMarkupCondition,
  CreateMgItemLevelSalesPriceCondition,
} from '@retail/markup/types';
import { useFeatureContext } from '@retail/app/stores/selected-context';

export interface ProductCardContainerProps {
  mgItemNumber: number;
  mgSupplierId: number;
}

export function WholesalerProductCardContainer({
  mgSupplierId,
  mgItemNumber,
}: ProductCardContainerProps) {
  const { featureFlags } = useMgPrisConfig();
  const featureContext = useFeatureContext();
  const { baseAssortmentId } = useDepartmentAssortments();
  const { updatePriority, updateItemsIsLoading } = useAssortmentItemsActions();
  const { includeVat, toggleIncludeVat } = useIncludeVatMyAssortment();
  const { selectedPackageType } = useBracketPriceStore();

  const { data: mgItemDto } = useFetchMgItem(
    {
      mgItemNumber,
      assortmentId: baseAssortmentId,
      calculationPackages: ['PRIMARY', 'SECONDARY', 'TERTIARY'],
    },
    { suspense: true }
  );
  const mgItem = MgItem.fromDto(mgItemDto!);

  const { isOpen: isPrioritizeDialogOpen, onToggle: togglePrioritizeDialog } = useDisclosure();

  const suppliers: SupplierWithPriority[] = useMemo(
    () =>
      mgItem.supplierItems.map((supplierItem) => {
        return {
          id: supplierItem.mgSupplierId,
          name: supplierItem.mgSupplierName,
          priority: supplierItem.priority,
        };
      }),
    [mgItem.supplierItems]
  );

  const [selectedSupplierId, selectSupplier] = useState(() => mgSupplierId);

  useEffect(() => {
    selectSupplier(mgSupplierId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mgSupplierId]);

  const selectedSupplierItem = mgItem.supplierItems.find(
    (supplierItem) => supplierItem.mgSupplierId === selectedSupplierId
  );

  const { createMultipleMgItemLevelConditions, isLoadingPostChanges } = useMarkupActions({
    mgSupplierId: selectedSupplierId,
  });

  const selectedSupplierItemPackage = useMemo(
    () => selectedSupplierItem?.packages.find(({ type }) => type === selectedPackageType),
    [selectedPackageType, selectedSupplierItem?.packages]
  );

  const saveBracketPrices = useCallback(
    ({ packageMarkups, validFrom }: DatedPackageMarkups) => {
      const priceConditions: CreateMgItemLevelSalesPriceCondition[] = [];
      const factorConditions: CreateMgItemLevelMarkupCondition[] = [];

      Object.entries(packageMarkups).forEach(([packageClassCode, packageMarkup]) => {
        const templateName = `${packageMarkup?.gtin} (${packageClassCode})`;
        if ((packageMarkup as CreatedSalesPrice)?.salesPriceIncVat) {
          priceConditions.push({
            mgItemNumber: mgItem.id,
            validFrom,
            salesPrice: includeVat
              ? (packageMarkup as CreatedSalesPrice).salesPriceIncVat
              : (packageMarkup as CreatedSalesPrice).salesPriceWithoutVat,
            incVat: includeVat,
            gtin: Number(packageMarkup.gtin),
            templateName,
          });
        } else if ((packageMarkup as CreatedSalesFactor)?.salesFactor) {
          factorConditions.push({
            mgItemNumber: mgItem.id,
            validFrom,
            salesFactor: (packageMarkup as CreatedSalesFactor).salesFactor,
            gtin: Number(packageMarkup.gtin),
            templateName,
          });
        }
      });

      return createMultipleMgItemLevelConditions([...priceConditions, ...factorConditions]);
    },

    [createMultipleMgItemLevelConditions, includeVat, mgItem.id]
  );

  const handlePostPriorityChanges = useCallback(
    (suppliers: SupplierWithPriority[]) => {
      const supplierItems = suppliers.map((supplier) => {
        return {
          mgSupplierId: supplier.id,
          mgItemNumber: mgItem.id,
          priority: supplier.priority,
        } as ItemsDTO;
      });
      updatePriority(supplierItems);
    },
    [mgItem.id, updatePriority]
  );

  if (updateItemsIsLoading || !selectedSupplierItem || !selectedSupplierItemPackage) {
    return <BodyLoader />;
  }

  return (
    <>
      <SupplierTabs
        suppliers={suppliers}
        clickedSupplier={mgSupplierId}
        selectedSupplier={selectedSupplierId}
        selectSupplier={selectSupplier}
        openPrioritizeDialog={togglePrioritizeDialog}
      />
      <WholesalerProductCard
        key={selectedSupplierItem.id}
        selectedSupplierItem={selectedSupplierItem}
        selectedSupplierItemPackage={selectedSupplierItemPackage}
        includeVat={includeVat}
        onToggleIncludeVat={toggleIncludeVat}
        saveBracketPrices={saveBracketPrices}
        isSavingBracketPrices={isLoadingPostChanges}
        features={featureFlags}
        featureContext={featureContext}
      />

      <PrioritizeSupplierDialog
        suppliers={suppliers}
        isOpen={isPrioritizeDialogOpen}
        onClose={togglePrioritizeDialog}
        handlePostPriorityChanges={handlePostPriorityChanges}
        isLoading={updateItemsIsLoading}
      />
    </>
  );
}
