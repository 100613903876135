import { useAppTFunction } from '@retail/app/i18n';
import { useToast } from '@retail/hooks';
import { useMutateUpdateItemsToAssortment } from '@retail/mgpdm/data-access';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useBrandAssortments } from './useBrandAssortments';

export const useAssortmentActions = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const t = useAppTFunction();

  const { baseAssortmentId } = useBrandAssortments();

  const { mutateAsync: postItems, isLoading: loadingPostItems } = useMutateUpdateItemsToAssortment({
    assortmentId: baseAssortmentId,
  });

  const updateAssortmentItems = useCallback(
    (selectedItems: ItemsDTO[], submitActions?: () => void) =>
      postItems({ body: selectedItems })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
          queryClient.invalidateQueries({ queryKey: ['mg-item'] });
          toast.success(
            selectedItems.length === 1
              ? t('products.mgItem.itemsAddedSingular', { count: selectedItems.length })
              : t('products.mgItem.itemsAddedPlural', { count: selectedItems.length })
          );
          submitActions && submitActions();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('products.errors.generic'));
        }),
    [postItems, queryClient, t, toast]
  );

  return {
    updateAssortmentItems,
    updateItemsIsLoading: loadingPostItems,
  };
};
