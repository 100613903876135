import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { NewAssortmentForm } from './newAssortmentForm';
import { TextInput } from '@retail/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';

type Props = Pick<UseFormReturn<NewAssortmentForm>, 'control'>;

const NewAssortmentFields: React.FC<Props> = ({ control }) => {
  const t = useAssortmentTFunction();

  return (
    <TextInput
      control={control}
      name="title"
      label={t('assortment.admin.create.form.title.label')}
      fullWidth
      margin="normal"
      required
    />
  );
};

export default NewAssortmentFields;
