import { Grid, Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { Paper } from '@shared/components';
import {
  useFetchCompetitors,
  useFetchRetailers,
  usePostCompetitors,
} from '@retail/retailer/data-access';
import { Retailer } from '@retail/retailer/types';
import { useToast } from '@retail/hooks';
import { useCallback, useMemo } from 'react';
import { PageLayout, Breadcrumbs } from '@retail/components';
import { ChosenCompetitors, EditCompetitors } from '@retail/retailer/containers';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

interface Props {
  marketInsightRoutePath: string;
}

export function CompetitorsPage({ marketInsightRoutePath }: Props) {
  const t = useAppTFunction();
  const toast = useToast();
  const { selectedOrgUnitId } = useSelectedOrgUnit();
  const { mutateAsync: postAsync, isLoading } = usePostCompetitors(selectedOrgUnitId);
  const { data: competitorDtos = [], refetch } = useFetchCompetitors(selectedOrgUnitId);
  const { retailerLogoBaseUrl } = useMgPrisConfig();
  const { data: retailerDtos = [] } = useFetchRetailers();

  const retailers = useMemo(
    () =>
      retailerDtos
        .filter(({ name, urlName, stores }) => name && urlName && stores?.length)
        .map((dto) => Retailer.fromDto(dto, competitorDtos, retailerLogoBaseUrl)),
    [competitorDtos, retailerDtos, retailerLogoBaseUrl]
  );

  const competitorIds = useMemo(() => competitorDtos.map(({ id }) => id) || [], [competitorDtos]);

  const submitCompetitors = useCallback(
    (storeIds: number[]) => {
      postAsync({ body: storeIds })
        .then(() => {
          toast.success(t('admin.retailers.save.success'));
          refetch();
        })
        .catch(() => toast.error(t('admin.retailers.save.error')));
    },
    [postAsync, refetch, t, toast]
  );

  const addCompetitors = useCallback(
    (storeIds: number[]) => {
      submitCompetitors([...competitorIds, ...storeIds]);
    },
    [competitorIds, submitCompetitors]
  );

  const removeCompetitor = useCallback(
    (storeId: number) => {
      submitCompetitors(competitorIds.filter((competitorId) => competitorId !== storeId));
    },
    [competitorIds, submitCompetitors]
  );

  return (
    <PageLayout maxWidth="full" spacing={2} fixedHeight>
      <Paper
        paddingY="dense"
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3 }}
      >
        <Breadcrumbs
          items={[
            {
              label: t('app.routes.marketInsight'),
              href: marketInsightRoutePath,
            },
            { label: t('assortment.competitors.heading') },
          ]}
        />

        <Stack component={Grid} flexGrow={1} flexDirection="row" overflow="hidden" container>
          <Stack component={Grid} item xs={6} height="100%">
            <ChosenCompetitors
              retailers={retailers}
              removeCompetitor={removeCompetitor}
              nrOfCompetitors={competitorIds.length}
            />
          </Stack>

          <Stack component={Grid} item xs={6} pl={3} height="100%">
            <EditCompetitors
              retailers={retailers}
              addCompetitors={addCompetitors}
              loading={isLoading}
            />
          </Stack>
        </Stack>
      </Paper>
    </PageLayout>
  );
}
