import { AssortmentPriorityResponseDTO } from '../dto';
import { ItemPriority } from '@retail/products/types';

export interface AssortmentPriorityProps {
  mgSupplierId: number;
  mgItemNumber: number;
  priority?: ItemPriority | null;
  validFrom?: string;
}

export interface AssortmentPriorityResponseProps {
  invalidDates: AssortmentPriorityProps[];
  invalidItemNumbers: AssortmentPriorityProps[];
  invalidSupplierIds: AssortmentPriorityProps[];
  invalidPriorities: AssortmentPriorityProps[];
}

export class AssortmentUploadValidation {
  invalidDates: AssortmentPriorityProps[];
  invalidItemNumbers: AssortmentPriorityProps[];
  invalidSupplierIds: AssortmentPriorityProps[];
  invalidPriorities: AssortmentPriorityProps[];

  constructor({
    invalidDates,
    invalidItemNumbers,
    invalidSupplierIds,
    invalidPriorities,
  }: AssortmentPriorityResponseProps) {
    this.invalidDates = invalidDates;
    this.invalidItemNumbers = invalidItemNumbers;
    this.invalidSupplierIds = invalidSupplierIds;
    this.invalidPriorities = invalidPriorities;
  }

  static fromDTO = (dto: AssortmentPriorityResponseDTO): AssortmentUploadValidation =>
    new AssortmentUploadValidation({
      invalidDates: dto.invalidDates,
      invalidPriorities: dto.invalidPriorities,
      invalidSupplierIds: dto.invalidSupplierIds,
      invalidItemNumbers: dto.invalidItemNumbers,
    });
}
