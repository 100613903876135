import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { TextButton } from '@retail/components';
import { DeleteIcon } from '@shared/custom-icons';

interface Props {
  noItemsChecked: boolean;
  deletionIsLoading: boolean;
  deleteCheckedItems: () => void;
}

export const DeleteItemsButton = ({
  noItemsChecked,
  deleteCheckedItems,
  deletionIsLoading,
}: Props) => {
  const t = useMyAssortmentTFunction();

  return (
    <TextButton
      disabled={noItemsChecked}
      onClick={deleteCheckedItems}
      startIcon={<DeleteIcon />}
      loading={deletionIsLoading}
    >
      {t('myAssortment.actions.deleteItems')}
    </TextButton>
  );
};
