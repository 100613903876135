import { useAppTFunction } from '@retail/app/i18n';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { Breadcrumbs as SharedBreadcrumbs, BreadcrumbsProps } from '@shared/components';

interface Props extends BreadcrumbsProps {
  marginBottom?: number;
}

export function Breadcrumbs({ items }: Props) {
  const t = useAppTFunction();
  return (
    <SharedBreadcrumbs
      fontSize="sm"
      items={[{ label: t('app.appName'), href: mgPrisRoutes.root.getFullLinkPath() }, ...items]}
    />
  );
}
