import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ItemPriority } from '@retail/products/types';
import { SupplierWithPriority } from '@retail/my-assortment/types';
import { ContainedButton, Dialog, Paper, TextButton } from '@shared/components';
import { useCallback, useEffect, useState } from 'react';
import { UnderPrioritizedSuppliers } from './UnderPrioritizedSuppliers';
import { UnprioritizedSuppliers } from './UnprioritizedSuppliers';

interface Props {
  suppliers: SupplierWithPriority[];
  isOpen: boolean;
  onClose: () => void;
  handlePostPriorityChanges: (suppliers: SupplierWithPriority[]) => void;
  isLoading: boolean;
}

export function PrioritizeSupplierDialog({
  suppliers,
  isOpen,
  onClose,
  handlePostPriorityChanges,
  isLoading,
}: Props) {
  const t = useMyAssortmentTFunction();

  const [newSupplierPrio, setNewSupplierPrio] = useState<SupplierWithPriority[]>(suppliers);

  useEffect(() => setNewSupplierPrio(suppliers), [suppliers]);

  const mainPrioSupplier = newSupplierPrio.find(
    (supplier) => supplier.priority === ItemPriority.TEN
  );
  const underPrioSuppliers = newSupplierPrio.filter(
    (supplier) => supplier.priority === ItemPriority.ZERO
  );
  const unprioSuppliers = newSupplierPrio.filter((supplier) => !supplier.priority);

  const resetSuppliers = useCallback(() => {
    setNewSupplierPrio(suppliers);
    onClose();
  }, [suppliers, onClose]);

  const setMainSupplier = useCallback((supplier: SupplierWithPriority) => {
    setNewSupplierPrio((prev) =>
      prev.map((prevSupplier) => {
        if (prevSupplier.id === supplier.id) {
          return { id: prevSupplier.id, name: prevSupplier.name, priority: ItemPriority.TEN };
        } else if (prevSupplier.priority === ItemPriority.TEN) {
          return { id: prevSupplier.id, name: prevSupplier.name, priority: ItemPriority.ZERO };
        }
        return prevSupplier;
      })
    );
  }, []);

  const setUnderPrioSupplier = useCallback((supplier: SupplierWithPriority) => {
    setNewSupplierPrio((prev) =>
      prev.map((prevSupplier) => {
        if (prevSupplier.id === supplier.id) {
          return { id: prevSupplier.id, name: prevSupplier.name, priority: ItemPriority.ZERO };
        }
        return prevSupplier;
      })
    );
  }, []);

  const setUnprioSupplier = useCallback((supplier: SupplierWithPriority) => {
    setNewSupplierPrio((prev) =>
      prev.map((prevSupplier) => {
        if (prevSupplier.id === supplier.id) {
          return { id: prevSupplier.id, name: prevSupplier.name, priority: undefined };
        }
        return prevSupplier;
      })
    );
  }, []);

  const postChanges = useCallback(() => {
    const suppliersWithChanges = newSupplierPrio.filter((newSupplier) => {
      const prevSupplierPrio =
        suppliers.find((supplier) => supplier.id === newSupplier.id)?.priority ?? undefined;
      return prevSupplierPrio !== newSupplier.priority;
    });

    handlePostPriorityChanges(suppliersWithChanges);
    onClose();
  }, [newSupplierPrio, handlePostPriorityChanges, onClose, suppliers]);

  return (
    <Dialog open={isOpen} onClose={resetSuppliers} maxWidth="sm" fullWidth>
      <DialogTitle>{t('myAssortment.actions.reprioritizeSuppliers')}</DialogTitle>

      <DialogContent>
        <Paper variant="outlined" sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
          <Stack bgcolor="background.secondary" p={2}>
            <Typography variant="body1">{t('myAssortment.priority.mainPrioSupplier')}</Typography>
          </Stack>
          <Stack p={2.5}>
            {mainPrioSupplier ? (
              <Typography variant="body2">{mainPrioSupplier?.name}</Typography>
            ) : (
              <Typography textAlign="center" variant="body2" fontStyle="italic">
                {t('myAssortment.priority.noMainPrio')}
              </Typography>
            )}
          </Stack>

          <Stack bgcolor="background.secondary" p={2}>
            <Typography variant="body1">{t('myAssortment.priority.underPrioSupplier')}</Typography>
          </Stack>

          <UnderPrioritizedSuppliers
            underprioritizedSuppliers={underPrioSuppliers}
            setMainSupplier={setMainSupplier}
            setNoPrioSupplier={setUnprioSupplier}
          />

          <Stack bgcolor="background.secondary" p={2}>
            <Typography variant="body1">{t('myAssortment.priority.unprioritized')}</Typography>
          </Stack>
          <UnprioritizedSuppliers
            unprioritizedSuppliers={unprioSuppliers}
            setUnderPrioSupplier={setUnderPrioSupplier}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <TextButton size="small" onClick={resetSuppliers}>
          {t('myAssortment.cancel')}
        </TextButton>
        <ContainedButton onClick={postChanges} loading={isLoading} size="small">
          {t('myAssortment.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
