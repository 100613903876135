import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';
import { useCallback } from 'react';
import { useMutatePatchMarkupTemplate } from '@retail/markup/data-access';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { MarkupCondition } from '@retail/calculus/types';
import { EditMarkupForm } from './EditMarkupForm';
import { PatchMarkupSchema, patchMarkupSchema } from './patchMarkupSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDate } from '@retail/utils';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  markupToEdit: MarkupCondition;
}

export function EditMarkupDialog({ markupToEdit, isOpen, onClose }: Props) {
  const t = useCalculusTFunction();

  const queryClient = useQueryClient();
  const { selectedDepartment } = useSelectedOrgUnit();

  const formMethods = useForm<PatchMarkupSchema>({
    defaultValues: {
      markupName: markupToEdit.markupTemplateName,
      toDate: markupToEdit.validTo ? new Date(markupToEdit?.validTo) : null,
    },
    resolver: yupResolver(patchMarkupSchema(t)),
  });

  const { mutateAsync: patchMarkupTemplate } = useMutatePatchMarkupTemplate(
    markupToEdit?.markupTemplateId ?? '',
    selectedDepartment!.departmentId
  );

  const onEdit = useCallback(
    (data: PatchMarkupSchema) => {
      patchMarkupTemplate({
        body: {
          templateId: markupToEdit?.markupTemplateId ?? 0,
          name: data.markupName,
          validTo: getDate(data.toDate),
        },
      })
        .catch((err) => {
          console.error(err);
          toast.error(t('calculus.error.generic'));
        })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
          queryClient.invalidateQueries({ queryKey: ['markup-templates'] });
          onClose();
        });
    },
    [patchMarkupTemplate, markupToEdit?.markupTemplateId, t, onClose, queryClient]
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>
        {t('calculus.actions.editEntry', { name: markupToEdit?.markupTemplateName })}
      </DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <EditMarkupForm />
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', alignSelf: 'center', mt: 1 }}>
        <ContainedButton
          disabled={Object.keys(formMethods?.formState?.errors).length > 0}
          sx={{ alignSelf: 'center' }}
          onClick={formMethods.handleSubmit(onEdit)}
        >
          {t('calculus.actions.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
