import { transformRoutes } from '@shared/utils';

const routeDefs = {
  root: {
    path: '/',
  },
  brandAssortment: {
    path: '/brandAssortment',
  },
  marketInsight: {
    path: '/marketinsight',
    sub: {
      assortments: {
        path: '/assortments',
        sub: {
          assortment: {
            path: '/:assortmentId',
          },
        },
      },
      competitors: {
        path: '/competitors',
        sub: {
          edit: {
            path: '/edit',
          },
        },
      },
    },
  },
  items: {
    path: '/items',
    sub: {
      item: {
        path: '/:itemNumber',
      },
    },
  },
  calculus: {
    path: '/calculus',
    sub: {
      supplier: {
        path: '/:supplierId',
      },
    },
  },
  superusers: {
    path: '/superusers',
  },
  users: {
    path: '/users',
  },
  suppliers: {
    path: '/suppliers',
  },
} as const;

export const pdmRoutes = transformRoutes(routeDefs, '/pdm');
