import { Stack } from '@mui/material';
import { AddButton, CalculusActionPortal } from '@retail/components';
import { Supplier } from '@retail/suppliers/types';
import { UniversalFormProvider } from '@shared/components';
import { activateAgreementSchema } from '@retail/purchase-agreements/components';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useAgreements } from '@retail/purchase-agreements/context';
import { ConditionState } from '@retail/calculus/types';
import { ActivateAgreementDialog } from './ActivateAgreement';
import { ConditionsContainer } from './PurchaseConditions';

interface Props {
  typesToShow: ConditionState;
  assortmentId: number;
  selectedSupplier: Supplier;
  isCreateOpen: boolean;
  toggleCreateDialog: () => void;
}

export function PurchaseContainer({
  typesToShow,
  assortmentId,
  selectedSupplier,
  isCreateOpen,
  toggleCreateDialog,
}: Props) {
  const t = useCalculusTFunction();

  const { agreements } = useAgreements({ supplierId: selectedSupplier.mgSupplierId });

  return (
    <Stack flexGrow={1}>
      <CalculusActionPortal>
        <AddButton onClick={toggleCreateDialog}>{t('calculus.newPurchaseCondition')}</AddButton>
      </CalculusActionPortal>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        {isCreateOpen && (
          <UniversalFormProvider
            schema={activateAgreementSchema(t)}
            defaultValues={activateAgreementSchema(t).getDefault()}
          >
            <ActivateAgreementDialog
              agreements={agreements}
              assortmentId={assortmentId}
              open={true}
              onClose={toggleCreateDialog}
              supplierId={selectedSupplier.mgSupplierId}
            />
          </UniversalFormProvider>
        )}
      </Stack>

      <ConditionsContainer
        supplier={selectedSupplier}
        typesToShow={typesToShow}
        agreements={agreements}
      />
    </Stack>
  );
}
