import { MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { CreateMarkupSchema } from './createMarkupSchema';
import {
  MgSupplierItemPackage,
  MgSupplierItemSearchResult,
  OverGroup,
} from '@retail/products/types';
import { DiscountGroup, MarkupCondition } from '@retail/calculus/types';
import { useEffect, useMemo } from 'react';
import { CreateMarkup } from './CreateMarkup';
import { LevelSelectForm } from '@retail/calculus/components';
import { SelectInputField } from '@shared/components';
import { getClassCodeLabel } from '@retail/products/utils';
import { useMarkupTFunction } from '@retail/markup/i18n';

export const itemPackageLevelValue = 'ITEM';

interface Props {
  productGroups: OverGroup[];
  activeCondition?: MarkupCondition;
  mgSupplierItems: MgSupplierItemSearchResult[] | undefined;
  discountGroups: DiscountGroup[];
  isLoadingFetchItems: boolean;
  supplierName: string;
  includeUnit?: boolean;
  packages?: MgSupplierItemPackage[];
}

export function CreateMarkupForm({
  productGroups,
  activeCondition,
  mgSupplierItems,
  discountGroups,
  isLoadingFetchItems,
  supplierName,
  packages,
  includeUnit,
}: Props) {
  const t = useMarkupTFunction();
  const { control, resetField, watch } = useFormContext<CreateMarkupSchema>();

  const levelController = useController({ name: 'level', control });
  const markupNameController = useController({ name: 'markupName', control });
  const gtinController = useController({ name: 'gtin' });

  const levelValue = watch('level');
  const overGroupValue = watch('overGroup');
  const mainGroupValue = watch('mainGroup');
  const productGroupValue = watch('productGroup');
  const discountGroupValue = watch('discountGroup');
  const itemValue = watch('item');
  const itemSearchValue = watch('itemSearch');
  const gtinValue = watch('gtin');

  const productGroupName = useMemo(
    () => productGroupValue?.name ?? mainGroupValue?.name ?? overGroupValue?.name,
    [mainGroupValue, overGroupValue, productGroupValue]
  );

  const discountGroupName = useMemo(() => discountGroupValue?.name, [discountGroupValue?.name]);

  const handleLevelSelectChange = (value: SelectChangeEvent<unknown>) => {
    resetField('item');
    resetField('overGroup');
    resetField('mainGroup');
    resetField('productGroup');
    resetField('discountGroup');
    resetField('markupName');
    resetField('salesCoverage');
    resetField('salesFactor');
    resetField('fromDate');
    resetField('toDate');
    resetField('gtin');

    levelController.field.onChange(value);
  };

  useEffect(() => {
    if (!itemSearchValue) {
      resetField('gtin');
    }
  }, [itemSearchValue, resetField]);

  useEffect(() => {
    if (levelValue === 'supplier') {
      markupNameController.field.onChange(supplierName);
    } else if (levelValue === 'productGroup' && !!productGroupName) {
      markupNameController.field.onChange(productGroupName);
    } else if (levelValue === 'discountGroup' && !!discountGroupName) {
      markupNameController.field.onChange(discountGroupValue?.name);
    } else if (levelValue === 'item' && !!itemValue) {
      if (gtinValue && gtinValue !== itemPackageLevelValue) {
        const selectedPackage = packages?.find((packageItem) => packageItem.gtinCode === gtinValue);
        const classCodeLabel = selectedPackage
          ? ` (${getClassCodeLabel(selectedPackage.type)})`
          : '';
        markupNameController.field.onChange(`${gtinValue}${classCodeLabel}`);
      } else {
        markupNameController.field.onChange(itemValue.primaryText);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelValue, productGroupName, itemValue, gtinValue, discountGroupName]);

  const packageOptions = useMemo(() => {
    if (!packages) return undefined;

    const itemLevelOption = { value: itemPackageLevelValue, name: t('markup.create.mgItemLevel') };

    return [
      itemLevelOption,
      ...packages.map(({ type, gtinCode, vilmaUnit }) => ({
        value: gtinCode,
        name: getClassCodeLabel(type || ''),
        description: vilmaUnit.unit,
      })),
    ];
  }, [packages, t]);

  return (
    <form>
      <Stack pb={1} gap={1}>
        <LevelSelectForm
          productGroups={productGroups}
          discountGroups={discountGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          handleLevelSelectChange={handleLevelSelectChange}
        />
        {packageOptions && (
          <SelectInputField
            menuItems={packageOptions}
            label={t('markup.create.packageLevel.title')}
            {...gtinController.field}
            size="small"
            required
            error={gtinController.fieldState.error?.message}
            placeholder={t('markup.create.packageLevel.placeholder')}
            renderOption={({ value: gtin, name, description: packageUnit }) => {
              if (gtin === itemPackageLevelValue) {
                return (
                  <MenuItem value={gtin} key={gtin}>
                    {name}
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    rowGap: 0,
                  }}
                  value={gtin}
                  key={gtin}
                >
                  <Typography variant="subtitle2">{name}</Typography>
                  <Typography variant="body3">{`${packageUnit} - ${gtin}`}</Typography>
                </MenuItem>
              );
            }}
          />
        )}
        <Stack pt={1}>
          {(levelValue === 'supplier' ||
            overGroupValue ||
            discountGroupValue ||
            (includeUnit ? gtinValue : itemValue)) && (
            <CreateMarkup markupCondition={activeCondition} />
          )}
        </Stack>
      </Stack>
    </form>
  );
}
