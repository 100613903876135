import { Store } from './Store';
import { RetailerDTO, StoreDTO } from '../dto';

export class Retailer {
  name: string;
  id: number;
  stores: Store[];
  urlName: string;
  baseUrl: string;

  constructor(name: string, id: number, stores: Store[], urlName: string, baseUrl: string) {
    this.name = name;
    this.id = id;
    this.stores = stores;
    this.urlName = urlName;
    this.baseUrl = baseUrl;
  }

  static getLogoUrl = (urlName: string, baseLogoUrl: string): string => {
    return `${baseLogoUrl}/${urlName}_logo.webp`;
  };

  logoUrl = (): string => Retailer.getLogoUrl(this.urlName, this.baseUrl);

  static getCircularLogoUrl = (urlName: string, baseLogoUrl: string): string => {
    return `${baseLogoUrl}/${urlName}_circle_64_clr.png`;
  };

  circularLogoUrl = (): string => Retailer.getCircularLogoUrl(this.urlName, this.baseUrl);

  static fromDto = (
    dto: RetailerDTO,
    competitorStoreDtos: StoreDTO[],
    retailerLogoBaseUrl: string
  ): Retailer =>
    new Retailer(
      dto.name,
      dto.id,
      dto.stores?.map((storeDto) => Store.fromStoreDto(storeDto, competitorStoreDtos)) || [],
      dto.urlName,
      retailerLogoBaseUrl
    );
}
