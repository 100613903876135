import { MouseEventHandler, useCallback } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { ContainedButton } from '@shared/components';

import { ImportStateValidation } from './ImportStateValidation';
import { useRecoilState } from 'recoil';
import {
  assortmentFileAtom,
  downloadTemplateCsv,
  useResetImportedAssortmentState,
  useSpreadsheetState,
} from '@retail/my-assortment/utils';
import { SpreadsheetInput } from '@retail/components';
import { SpreadSheetRow } from '@retail/monitoring-assortment/types';
import { useToast } from '@retail/hooks';

const handleDownloadTemplate: MouseEventHandler<HTMLAnchorElement> = (e) => {
  e.nativeEvent.stopImmediatePropagation();
  e.stopPropagation();
  const csvData = 'MgItem-nummer;MgSupplier-id;Prioritized';

  try {
    downloadTemplateCsv(csvData, 'itemsTemplate.csv');
  } catch (error) {
    console.error('Error downloading the template:', error);
  }
};

interface Props {
  isLarge?: boolean;
}

export const UploadAssortmentContainer = ({ isLarge }: Props) => {
  const { t } = useMyAssortmentTranslation();
  const toast = useToast();

  const [spreadsheet, setSpreadsheet] = useSpreadsheetState();
  const [acceptedFile, setAcceptedFile] = useRecoilState(assortmentFileAtom);
  const resetImportedAssortmentStates = useResetImportedAssortmentState();

  const onUpload = useCallback(
    (rows: SpreadSheetRow[], file: File) => {
      resetImportedAssortmentStates();
      const [headerRow, ...dataRows] = rows;
      setAcceptedFile(file);
      setSpreadsheet({ headerRow, dataRows });
    },
    [resetImportedAssortmentStates, setAcceptedFile, setSpreadsheet]
  );

  const onRemoveFile: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      resetImportedAssortmentStates();
    },
    [resetImportedAssortmentStates]
  );

  const onRejectFile = useCallback(
    () => toast.error(t('myAssortment.uploadAssortment.error.fileType')),
    [t, toast]
  );

  return (
    <Stack gap={2} alignItems="center">
      <SpreadsheetInput
        onUpload={onUpload}
        onRejectFiles={onRejectFile}
        showUploadIcon
        sx={isLarge ? { width: 500, height: 350 } : undefined}
      >
        <Typography pt={1}>{t('myAssortment.uploadAssortment.title')}</Typography>
        <Typography pb={2} variant="body2">
          {acceptedFile ? acceptedFile.name : t('myAssortment.uploadAssortment.acceptedFormats')}
        </Typography>

        <Stack flexDirection="row" gap={2}>
          <ContainedButton size="small">
            {spreadsheet
              ? t('myAssortment.uploadAssortment.change')
              : t('myAssortment.uploadAssortment.upload')}
          </ContainedButton>

          {spreadsheet && (
            <ContainedButton color="warning" onClick={onRemoveFile} size="small">
              {t('myAssortment.uploadAssortment.removeFile')}
            </ContainedButton>
          )}
        </Stack>
        <Link
          mt={1}
          onClick={handleDownloadTemplate}
          variant="body2"
          whiteSpace="nowrap"
          sx={{ cursor: 'pointer' }}
        >
          {t('myAssortment.uploadAssortment.downloadTemplate')}
        </Link>
      </SpreadsheetInput>

      {spreadsheet && <ImportStateValidation dataRowsLength={spreadsheet.dataRows.length} />}
    </Stack>
  );
};
