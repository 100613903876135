import { useMutateValidatePriorities } from '@retail/my-assortment/data-access';
import { AssortmentUploadValidation } from '@retail/my-assortment/types';
import {
  assortmentSpreadsheetValidatedAtom,
  assortmentSpreadsheetValidationAtom,
  getImportedItems,
  useImportedAssortmentState,
  useResetImportedAssortmentState,
} from '@retail/my-assortment/utils';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

interface Props {
  assortmentId: number;
}

export const useAssortmentImport = ({ assortmentId }: Props) => {
  const importState = useImportedAssortmentState();
  const importedItems = getImportedItems(importState);

  const resetImportedAssortmentStates = useResetImportedAssortmentState();

  const [validationState, setValidationState] = useRecoilState(assortmentSpreadsheetValidationAtom);
  const [isValidated, setIsValidated] = useRecoilState(assortmentSpreadsheetValidatedAtom);

  const { mutateAsync: validateItemsAsync, isLoading: loadingValidateItems } =
    useMutateValidatePriorities({ assortmentId });

  const onValidateItems = useCallback(async () => {
    validateItemsAsync({ body: importedItems })
      .catch((err) => console.error(err))
      .then((response) => {
        const res = response as AssortmentUploadValidation;
        setValidationState(res);
      });
  }, [importedItems, setValidationState, validateItemsAsync]);

  useEffect(() => {
    if (importState.status !== 'none' && importedItems.length > 0 && !isValidated) {
      onValidateItems();
      setIsValidated(true);
    }
  }, [importState.status, importedItems.length, isValidated, onValidateItems, setIsValidated]);

  return {
    importedItems,
    validationState,
    importState,
    resetImportedAssortmentStates,
    loadingValidateItems,
  };
};
