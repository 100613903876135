import { PriceStatus, SalesPriceDTO } from '../dtos';

export interface SalesPriceProps {
  priceDate: Date;
  purchasePrice: number;
  freightPercentAddon?: number;
  freightFactor?: number;
  freightFixedAddOn?: number;
  markupCostFactor?: number;
  markupSalesFactor?: number;
  costPrice: number;
  salesPrice: number;
  salesPriceIncVat: number;
  salesPriceCoverage: number;
  status: PriceStatus;
  unit: string;
  basePriceUnit: boolean;
  primaryUnit: boolean;
  purchasePriceAdjustment: boolean;
}

export class SalesPrice {
  priceDate: Date;
  purchasePrice: number;
  freightPercentAddon?: number;
  freightFactor?: number;
  freightFixedAddOn?: number;
  markupCostFactor?: number;
  markupSalesFactor?: number;
  costPrice: number;
  salesPrice: number;
  salesPriceIncVat: number;
  salesPriceCoverage: number;
  status: PriceStatus;
  unit: string;
  basePriceUnit: boolean;
  primaryUnit: boolean;
  purchasePriceAdjustment: boolean;

  constructor({
    priceDate,
    purchasePrice,
    freightPercentAddon,
    freightFactor,
    freightFixedAddOn,
    markupCostFactor,
    markupSalesFactor,
    costPrice,
    salesPrice,
    salesPriceIncVat,
    salesPriceCoverage,
    status,
    unit,
    basePriceUnit,
    primaryUnit,
    purchasePriceAdjustment,
  }: SalesPriceProps) {
    this.priceDate = priceDate;
    this.purchasePrice = purchasePrice;
    this.freightPercentAddon = freightPercentAddon;
    this.freightFactor = freightFactor;
    this.freightFixedAddOn = freightFixedAddOn;
    this.markupCostFactor = markupCostFactor;
    this.markupSalesFactor = markupSalesFactor;
    this.costPrice = costPrice;
    this.salesPrice = salesPrice;
    this.salesPriceIncVat = salesPriceIncVat;
    this.salesPriceCoverage = salesPriceCoverage;
    this.status = status;
    this.unit = unit;
    this.basePriceUnit = basePriceUnit;
    this.primaryUnit = primaryUnit;
    this.purchasePriceAdjustment = purchasePriceAdjustment;
  }

  static fromDto = (dto: SalesPriceDTO) =>
    new SalesPrice({
      priceDate: new Date(dto.priceDate),
      purchasePrice: dto.purchasePrice,
      freightPercentAddon: dto.freightPercentAddon,
      freightFactor: dto.freightFactor,
      freightFixedAddOn: dto.freightFixedAddOn,
      markupCostFactor: dto.markupCostFactor,
      markupSalesFactor: dto.markupSalesFactor,
      costPrice: dto.costPrice,
      salesPrice: dto.salesPrice,
      salesPriceIncVat: dto.salesPriceIncVat,
      salesPriceCoverage: dto.salesPriceCoverage,
      status: dto.status,
      unit: dto.unit,
      basePriceUnit: dto.basePriceUnit,
      primaryUnit: dto.primaryUnit,
      purchasePriceAdjustment: dto.purchasePriceAdjustment,
    });

  static getCurrentPriceForUnit = (prices: SalesPrice[], unit: string): SalesPrice | undefined =>
    prices.find((price) => price.unit === unit);
}

export interface SalesPricesByDate {
  priceDate: Date;
  prices: SalesPrice[];
}
