import { Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { DialogLayout, InfoChip, ToggleSwitch } from '@retail/components';
import { MgSupplierItemConditionsPageResult } from '@retail/calculus/types';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { AffectedItemsTable } from './AffectedItemsTable';

interface Props {
  open: boolean;
  items: MgSupplierItemConditionsPageResult;
  onlyItemsInAssortment: boolean;
  onClose: () => void;
  toggleOnlyItemsInAssortment: () => void;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function AffectedItemsDialog({
  open,
  items,
  onlyItemsInAssortment,
  onClose,
  toggleOnlyItemsInAssortment,
  updatePage,
  updatePageSize,
}: Props) {
  const t = useCalculusTFunction();
  return (
    <DialogLayout
      open={open}
      onClose={onClose}
      width="lg"
      fullHeight
      header={t('calculus.itemsAffected.priceList')}
    >
      <Stack height="100%">
        <Stack flexDirection="row" py={2} px={4} justifyContent="space-between">
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Typography variant="subtitle1">
              {t('calculus.itemsAffected.affectedItems')}:
            </Typography>
            <InfoChip label={items.totalItems} />
          </Stack>
          <ToggleSwitch
            toggle={onlyItemsInAssortment}
            setToggle={toggleOnlyItemsInAssortment}
            text={t('calculus.itemsAffected.onlyInAssortment')}
          />
        </Stack>

        <AffectedItemsTable {...items} updatePage={updatePage} updatePageSize={updatePageSize} />
      </Stack>
    </DialogLayout>
  );
}
