import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { CalculusAreas, SupplierItemConditionsDTO } from '@retail/calculus/types';
import { PaginationResultDTO } from '@retail/products/types';
import { useMPEFetch } from '@retail/app/hooks';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

interface ApiProps extends QueryOptions {
  assortmentId: number;
  mgSupplierId: number;
  conditionType: CalculusAreas;
  groupNumber?: string;
  discountGroupId?: string;
  agreementId?: number;
  baseAssortment?: boolean;
  page: number;
  size: number;
}

export function useFetchAffectedItems({
  assortmentId,
  mgSupplierId,
  conditionType,
  groupNumber,
  discountGroupId,
  agreementId,
  baseAssortment = true,
  page,
  size,
  ...queryOptions
}: ApiProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PaginationResultDTO<SupplierItemConditionsDTO[]>>({
    method: 'GET',
    queryKey: [
      'affected-items',
      mgSupplierId,
      conditionType,
      groupNumber,
      discountGroupId,
      agreementId,
      baseAssortment,
      page,
      size,
    ],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.affectedSupplierItems.getFullLinkPath({ assortmentId }),
      searchParams: {
        mgSupplierId,
        groupNumber,
        discountGroupId,
        baseAssortment,
        agreementId,
        conditionType,
        page,
        size,
      },
    }),
    ...queryOptions,
  });
}
