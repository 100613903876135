import {
  bolistCircularLogoUri,
  bolistIconImgUri,
  mestergruppenIconUri,
  xlbyggCircularLogoUri,
  xlByggIconImgUri,
} from '@assets/images';

export interface Logos {
  circularLogo: string;
  logo: string;
}

export const brandLogo = (brandId: string): Logos => {
  switch (brandId) {
    case 'bolist':
      return { circularLogo: bolistCircularLogoUri, logo: bolistIconImgUri };
    case 'xlbygg':
    case 'xlbyggsverige':
      return { circularLogo: xlbyggCircularLogoUri, logo: xlByggIconImgUri };
    case 'mestergruppenvareforsyning':
      return { circularLogo: mestergruppenIconUri, logo: mestergruppenIconUri };
    default:
      return { circularLogo: '', logo: '' };
  }
};
