import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { Menu, MenuButton } from '@retail/components';
import { MenuItem } from '@mui/material';

interface Props {
  openImportModal: () => void;
  openCreateManuallyModal: () => void;
}

export const CreateAssortmentMenu: React.FC<Props> = ({ openImportModal, openCreateManuallyModal }) => {
  const t = useAssortmentTFunction();

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton color="secondary" size="small">
            {t('assortment.admin.create.options.title')}
          </MenuButton>
          <MenuItem
            onClick={() => {
              openImportModal();
              onClose();
            }}
          >
            {t('assortment.admin.create.options.fromFile')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              openCreateManuallyModal();
              onClose();
            }}
          >
            {t('assortment.admin.create.options.manual')}
          </MenuItem>
        </>
      )}
    </Menu>
  );
};
