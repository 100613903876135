import { Stack } from '@mui/material';
import { GridColumnMenuColumnsItem, GridColumnMenuProps } from '@mui/x-data-grid';

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
  const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu,
  };

  return (
    <Stack>
      <GridColumnMenuColumnsItem {...itemProps} />
    </Stack>
  );
};
