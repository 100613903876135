import { MgSupplierItemRaw, MgSupplierItem } from '@retail/products/types';
import { useProductsTFunction } from '@retail/products/i18n';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { DiffFile } from 'diff2html/lib/types';
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import { CopyToClipboardButton } from '@retail/components';

interface Props {
  selectedSupplierItem: MgSupplierItemRaw | undefined;
  comparisonSupplier: MgSupplierItemRaw | null;
  supplierItems: MgSupplierItemRaw[];
  setComparisonSupplier: Dispatch<SetStateAction<MgSupplierItemRaw | null>>;
  getMgItemSupplier: (
    supplierId: MgSupplierItemRaw['mgSupplierId'],
    mgSupplierItemId: MgSupplierItemRaw['mgSupplierItemId']
  ) => MgSupplierItem | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Diff = require('diff');

export const Comparison = ({
  selectedSupplierItem,
  supplierItems,
  comparisonSupplier,
  setComparisonSupplier,
  getMgItemSupplier,
}: Props) => {
  const t = useProductsTFunction();

  const [diff, setDiff] = useState<string | DiffFile[]>([]);

  const updateDiff = useCallback(() => {
    const selectedSupplierItemJson = JSON.stringify(selectedSupplierItem, null, 2);

    const comparisonSupplierJson = comparisonSupplier
      ? JSON.stringify(comparisonSupplier, null, 2)
      : '{}';

    const newDiff = Diff.createTwoFilesPatch(
      selectedSupplierItem
        ? getMgItemSupplier(
            selectedSupplierItem.mgSupplierId,
            selectedSupplierItem.mgSupplierItemId
          )?.mgSupplierName
        : '',
      comparisonSupplier
        ? getMgItemSupplier(comparisonSupplier.mgSupplierId, comparisonSupplier.mgSupplierItemId)
            ?.mgSupplierName
        : '',
      selectedSupplierItemJson,
      comparisonSupplierJson
    );

    setDiff(newDiff);
  }, [selectedSupplierItem, comparisonSupplier, getMgItemSupplier]);

  const outputHtml = Diff2Html.html(diff, {
    outputFormat: 'line-by-line',
    drawFileList: false,
    matching: 'lines',
  });

  useEffect(() => {
    updateDiff();
  }, [selectedSupplierItem, comparisonSupplier, updateDiff]);

  return (
    <Stack>
      <Box display="flex" justifyContent="space-between">
        <Autocomplete
          size="small"
          disablePortal
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t('products.mgItem.json.compare')} ${t(
                'products.supplier.name'
              ).toLowerCase()}`}
            />
          )}
          noOptionsText={t('products.mgItem.json.noSuppliers')}
          options={supplierItems}
          value={comparisonSupplier}
          onChange={(_, val) => setComparisonSupplier(val)}
          getOptionLabel={({ mgSupplierId, mgSupplierItemId }) =>
            getMgItemSupplier(mgSupplierId, mgSupplierItemId)?.mgSupplierName ??
            String(mgSupplierId) ??
            '-'
          }
          renderOption={(props, { mgSupplierId, mgSupplierItemId }) => (
            <Box component="li" {...props} key={mgSupplierId}>
              {getMgItemSupplier(mgSupplierId, mgSupplierItemId)?.mgSupplierName}
            </Box>
          )}
          isOptionEqualToValue={(option, value) =>
            option.mgSupplierItemId === value.mgSupplierItemId
          }
          sx={{
            width: 200,
          }}
        />

        <CopyToClipboardButton
          value={JSON.stringify(selectedSupplierItem)}
          disabled={!!comparisonSupplier}
        />
      </Box>

      <Box
        dangerouslySetInnerHTML={{ __html: outputHtml }}
        sx={{ mt: 2, maxWidth: 1700, '.d2h-tag': { display: 'none' }, '> tbody': { fontSize: 12 } }}
      />

      {selectedSupplierItem?.mgSupplierId === comparisonSupplier?.mgSupplierId &&
        selectedSupplierItem?.mgSupplierItemId === comparisonSupplier?.mgSupplierItemId && (
          <Box display="flex" width={1700} justifyContent="center" alignItems="center" height={200}>
            <Typography variant="h4" sx={{ color: 'text.secondary' }}>
              {t('products.mgItem.json.identicalSuppliers')}
            </Typography>
          </Box>
        )}
    </Stack>
  );
};
