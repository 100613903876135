import { Stack, Typography } from '@mui/material';
import {
  AddItemsMenu,
  MgSupplierItemsTableWithCheckbox,
  SearchFilterChips,
} from '@retail/products/components';
import {
  FilterOptions,
  MgSupplierItemSearchResult,
  SetFilterOptions,
} from '@retail/products/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Pagination as PaginationType } from '@shared/hooks';
import { useProductsTFunction } from '@retail/products/i18n';
import { useLanguageSelector } from '@retail/app/hooks';
import { ToggleFiltersButton } from '@retail/components';
import { useAssortmentActions } from '@retail/mgpdm/context';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useNavigate } from 'react-router-dom';
import { pdmRoutes } from '@retail/mgpris/config';

interface Props {
  totalItems: number;
  supplierItems: MgSupplierItemSearchResult[];
  checkedItems: MgSupplierItemSearchResult[];
  clickableRow?: boolean;
  showFilters: boolean;
  includeAddButton: boolean;
  searchFilters: FilterOptions;
  setSearchFilters: SetFilterOptions;
  toggleShowFilters: () => void;
  resetFilters: () => void;
  setCheckedItems: Dispatch<SetStateAction<MgSupplierItemSearchResult[]>>;
}

export function MgSupplierItemSearchResults({
  totalItems,
  supplierItems,
  checkedItems,
  pageSize,
  page,
  clickableRow,
  showFilters,
  includeAddButton,
  searchFilters,
  setSearchFilters,
  resetFilters,
  toggleShowFilters,
  setCheckedItems,
  setPage,
  setPageSize,
}: Props & PaginationType) {
  const t = useProductsTFunction();
  const { language } = useLanguageSelector();

  const navigate = useNavigate();
  const navigateToItem = useCallback(
    (mgItemNumber: string) =>
      navigate(pdmRoutes.items.item.getFullLinkPath({ itemNumber: mgItemNumber })),
    [navigate]
  );

  const { updateAssortmentItems, updateItemsIsLoading } = useAssortmentActions();

  const onAddItemsToAssortment = useCallback(() => {
    const items = checkedItems.map((item) => {
      return {
        mgSupplierId: item.mgParticipantAccountId,
        mgItemNumber: item.mgItemNumber,
        priority: 'ZERO',
      } as ItemsDTO;
    });

    updateAssortmentItems(items).then(() => setCheckedItems([]));
  }, [checkedItems, setCheckedItems, updateAssortmentItems]);

  return (
    <Stack flexGrow={1}>
      <Stack flexDirection="row" justifyContent="end" alignItems="center" p={2} pt={0} gap={1}>
        <Typography>{t('products.search.result', { count: totalItems })}</Typography>
        <Typography flex={1} textAlign="center">
          {t('products.mgItem.selectedItems', {
            selected: checkedItems.length,
            total: supplierItems.length,
          })}
        </Typography>
        {includeAddButton && (
          <AddItemsMenu
            onAddItemsToAssortment={onAddItemsToAssortment}
            onAddItemsToMonitoringAssortment={() => null}
            disabled={checkedItems.length === 0}
            isLoading={updateItemsIsLoading}
          />
        )}

        {!showFilters && (
          <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleShowFilters} />
        )}
      </Stack>
      <SearchFilterChips
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        resetFilters={resetFilters}
      />
      <MgSupplierItemsTableWithCheckbox
        items={supplierItems}
        totalItems={totalItems}
        clickableRow={clickableRow}
        language={language}
        selection={{ selectedItems: checkedItems, selectItem: setCheckedItems }}
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        setPageSize={setPageSize}
        onRowClick={navigateToItem}
      />
    </Stack>
  );
}
