import { Unit } from './Unit';
import { FreightConditionDTO } from '../../dto';
import { Condition } from './Condition';

interface FreightProps {
  condition: Condition;
  freightFactor?: number;
  percentAddon?: number;
  fixedAddOn?: number;
  fixedAddOnUnit?: Unit;
}

export class FreightCondition {
  condition: Condition;
  freightFactor?: number;
  percentAddon?: number;
  fixedAddOn?: number;
  fixedAddOnUnit?: Unit;

  constructor(props: FreightProps) {
    this.condition = props.condition;
    this.freightFactor = props.freightFactor;
    this.percentAddon = props.percentAddon;
    this.fixedAddOn = props.fixedAddOn;
    this.fixedAddOnUnit = props.fixedAddOnUnit;
  }

  static fromDto = (dto: FreightConditionDTO): FreightCondition =>
    new FreightCondition({
      condition: Condition.fromDto(dto.condition),
      freightFactor: dto.freightFactor,
      percentAddon: dto.percentAddon,
      fixedAddOn: dto.fixedAddOn,
      fixedAddOnUnit: dto.fixedAddOnUnit,
    });
}
