import { FC, ReactElement } from 'react';
import { Tooltip } from '@mui/material';
import { HelperText } from '@shared/components';

export const TooltipError: FC<{ message?: string; children: ReactElement }> = ({
  message,
  children,
}) =>
  message ? (
    <Tooltip
      open
      title={
        <HelperText id="discountInputError" status="error">
          {message}
        </HelperText>
      }
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
