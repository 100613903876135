import { Tab, TabProps, Tabs, TabsOwnProps, TabsProps } from '@mui/material';
import { a11yProps } from '@shared/utils';

/* Use the same id as TabPanel */
interface Props {
  id: string;
  tabs: Array<Pick<TabProps, 'label' | 'value' | 'icon'>>;
  currentTab: string | number;
  onChange: TabsProps['onChange'];
  variant?: TabsOwnProps['variant'];
}

export default function ChipTabs({ id, tabs, currentTab, variant, onChange }: Props) {
  return (
    <Tabs value={currentTab} onChange={onChange} variant={variant}>
      {tabs.map(({ label, value, icon }, index) => (
        <Tab
          key={`${id}-${value || index}`}
          label={label}
          value={value || index}
          icon={icon}
          {...a11yProps(id, value || index)}
        />
      ))}
    </Tabs>
  );
}
