import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { MgPackagesButtonGroup } from '@retail/products/components';
import { MgItemPackages, MgPackageType } from '@retail/products/types';
import { Chip } from '@shared/components';
import { ArmScaleIcon } from '@shared/custom-icons';

interface Props {
  packages: MgItemPackages;
  selectedPackage: MgPackageType;
  basePriceUnit?: string;
  setSelectedPackage: (selectedPackage: MgPackageType) => void;
}

export const Packages = ({
  packages,
  selectedPackage,
  basePriceUnit,
  setSelectedPackage,
}: Props) => {
  const { palette } = useTheme();
  const t = useMyAssortmentTFunction();
  const selectedMgPackage = packages[selectedPackage] && packages[selectedPackage][0];

  return (
    <Stack p={2} gap={1}>
      <Stack alignContent="center" flexDirection="row" alignItems="center" gap={2} flexWrap="wrap">
        <Typography fontWeight={500}>{t('myAssortment.productCard.priceUnit')}</Typography>
        <Chip
          rounded
          backgroundColor="inherit"
          sx={{ border: ({ palette }) => `2px solid ${palette.divider}` }}
          label={basePriceUnit ?? '-'}
        />
      </Stack>
      <Stack
        alignContent="center"
        flexDirection="row"
        alignItems="center"
        gap={2}
        flexWrap="wrap"
        pb={1}
      >
        <Typography fontWeight={500}>{t('myAssortment.productCard.packages')}</Typography>
        <MgPackagesButtonGroup
          isSuperUser={true}
          packages={packages}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />
      </Stack>
      <Stack
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography>{t('myAssortment.packages.unit')}:</Typography>
          <Chip
            rounded
            backgroundColor={palette.info[400]}
            label={<Typography fontWeight={500}>{selectedMgPackage?.description}</Typography>}
          />
          {!selectedMgPackage?.gtinCode && <ArmScaleIcon fontSize="small" />}
        </Stack>

        {selectedMgPackage?.gtinCode && (
          <Box display="flex" alignItems="center">
            {t('myAssortment.packages.gtin')}:{' '}
            <Typography component={Box} ml={1}>
              {selectedMgPackage.gtinCode}
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
