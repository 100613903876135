import { PageLayout } from '@retail/components';
import { Alert, Box, Paper, Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { FeatureTogglesTable } from './FeatureTogglesTable';

export function FeatureTogglesPage() {
  const t = useAppTFunction();
  return (
    <PageLayout maxWidth="full" fixedHeight>
      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          p: 2,
        }}
      >
        <Stack spacing={2} alignItems="flex-start">
          <Typography variant="h3" component="h1">
            {t('app.routes.features')}
          </Typography>
          <Alert severity="warning">This page is only visible to super users</Alert>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <FeatureTogglesTable />
          </Box>
        </Stack>
      </Paper>
    </PageLayout>
  );
}
