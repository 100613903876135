import { SelectInputField } from '@shared/components';
import { CreateItemLevelFreightConditionSchema } from './itemLevelFreightConditionSchema';
import { useController, useFormContext } from 'react-hook-form';
import { MgItem } from '@retail/products/types';
import { useFreightTFunction } from '@retail/freight/i18n';
import { Stack } from '@mui/material';
import { FreightConditionFormBase } from './FreightConditionFormBase';

interface Props {
  selectedItem: MgItem;
}

export function ItemLevelFreightForm({ selectedItem }: Props) {
  const t = useFreightTFunction();

  const { control } = useFormContext<CreateItemLevelFreightConditionSchema>();
  const freightTypeController = useController({ name: 'freightType', control });

  const freightOptions = [
    { value: 'percent', name: t('freight.form.percentOrFactor') },
    //TODO: add when backend is ready
    /*
    {
      value: 'primary',
      name: t('freight.form.addonPerPrimary', { primaryUnit: '{primærenhet}' }),
    },
    { value: 'ton', name: t('freight.form.addonPerTon') },
    { value: 'squareMeter', name: t('freight.form.addonPerM3') },*/
  ];

  return (
    <Stack flexDirection="column" gap={2}>
      <SelectInputField
        {...freightTypeController.field}
        value={freightTypeController.field.value ?? ''}
        error={freightTypeController.fieldState.error?.message}
        size="small"
        width={400}
        label={t('freight.form.freightType')}
        placeholder={t('freight.actions.chooseFreightType')}
        menuItems={freightOptions}
        required
      />
      {freightTypeController.field.value === 'percent' && <FreightConditionFormBase />}
    </Stack>
  );
}
