import { Box, Stack } from '@mui/material';
import { MgItem, MgItemRaw, MgSupplierItemRaw } from '@retail/products/types';
import { Paper } from '@shared/components';
import { useState } from 'react';
import { JsonViewer } from '@textea/json-viewer';
import { useFetchRawMgItem } from '@retail/products/data-access';
import { Comparison, SupplierTabSelect } from '@retail/products/components';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  mgItem: MgItem;
}

export const JSONContainer = ({ mgItem }: Props) => {
  const t = useProductsTFunction();

  const { data: jsonDTO } = useFetchRawMgItem({
    mgItemNumber: mgItem.id,
  });
  const mgItemJSON = jsonDTO && MgItemRaw.fromDto(jsonDTO);

  const [selectedSupplierItem, setSelectedSupplierItem] = useState<MgSupplierItemRaw | undefined>(
    mgItemJSON?.getPrimarySupplierItem()
  );
  const [comparisonSupplier, setComparisonSupplier] = useState<MgSupplierItemRaw | null>(null);

  const unselectedSupplierItems =
    mgItemJSON?.mgSupplierItems?.filter(
      (supplierItem: MgSupplierItemRaw) =>
        supplierItem.mgSupplierId !== selectedSupplierItem?.mgSupplierId
    ) ?? [];

  const getMgItemSupplier = (
    supplierId: MgSupplierItemRaw['mgSupplierId'],
    mgSupplierItemId: MgSupplierItemRaw['mgSupplierItemId']
  ) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mgItem.supplierItems.find(
      (s) => s.mgSupplierId === supplierId && s.mgSupplierItemId === mgSupplierItemId
    )!;

  if (!selectedSupplierItem) {
    return <Paper paddingY="dense">{t('products.mgItem.json.noSuppliersInfo')}</Paper>;
  }

  return (
    <Stack position="relative">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        pb={2}
      >
        <SupplierTabSelect
          suppliers={mgItemJSON?.mgSupplierItems ?? []}
          selectedSupplier={selectedSupplierItem as MgSupplierItemRaw}
          selectSupplier={setSelectedSupplierItem}
          getSupplierName={(supplier) =>
            getMgItemSupplier(supplier.mgSupplierId, supplier.mgSupplierItemId)?.mgSupplierName
          }
          getSupplierId={(supplier) => `${supplier?.mgSupplierId}_${supplier?.registrationDate}`}
        />
      </Box>

      <Comparison
        getMgItemSupplier={getMgItemSupplier}
        comparisonSupplier={comparisonSupplier}
        setComparisonSupplier={setComparisonSupplier}
        selectedSupplierItem={selectedSupplierItem}
        supplierItems={unselectedSupplierItems}
      />

      <Box
        sx={{
          display: comparisonSupplier ? 'none' : 'block',
          border: (theme) => `1px solid ${theme.palette.grey[50]}`,
          padding: (theme) => theme.spacing(2),
        }}
      >
        <JsonViewer value={selectedSupplierItem} style={{ fontSize: 12 }} />
      </Box>
    </Stack>
  );
};
