import { MarkupConditionDTO } from '../../dto';
import { Condition } from './Condition';

interface MarkupConditionProps {
  condition: Condition;
  gtinCode?: number;
  costFactor: number;
  salesFactor: number;
  salesPriceCoverage: number;
  templateName?: string;
}

export class MarkupCondition {
  condition: Condition;
  gtinCode?: number;
  costFactor: number;
  salesFactor: number;
  salesPriceCoverage: number;
  templateName?: string;

  constructor(props: MarkupConditionProps) {
    this.condition = props.condition;
    this.gtinCode = props.gtinCode;
    this.costFactor = props.costFactor;
    this.salesFactor = props.salesFactor;
    this.salesPriceCoverage = props.salesPriceCoverage;
    this.templateName = props.templateName;
  }

  static fromDto(dto: MarkupConditionDTO): MarkupCondition {
    return new MarkupCondition({
      condition: Condition.fromDto(dto.condition),
      gtinCode: dto.gtinCode,
      costFactor: dto.costFactor,
      salesFactor: dto.salesFactor,
      salesPriceCoverage: dto.salesPriceCoverage,
      templateName: dto.templateName,
    });
  }
}
