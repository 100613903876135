import { ChevronRight } from '@mui/icons-material';
import { Grid, Typography, useTheme } from '@mui/material';
import { landingPageBackground, marketInsight3 } from '@assets/images';
import { useAppTranslation } from '@retail/app/i18n';
import { useAuthContext } from '@retail/auth/data-access';
import { PageLayout } from '@retail/components';
import { ContainedButton } from '@shared/components';

export const LandingPage = () => {
  const { login } = useAuthContext();
  const { t } = useAppTranslation();
  const { palette } = useTheme();

  return (
    <PageLayout maxWidth="full" sx={{ backgroundColor: `${palette.background.paper} !important` }}>
      <Grid
        container
        justifyContent={{ xs: 'end', lg: 'space-around' }}
        direction={{ xs: 'column', lg: 'row' }}
        minWidth={{ xs: '250px', lg: '500px' }}
        textAlign={{ xs: 'center', lg: 'left' }}
        flexGrow={1}
      >
        <Grid item>
          <img
            src={landingPageBackground}
            style={{ position: 'absolute', bottom: 0, width: '100%', left: 0, right: 0 }}
            alt=""
          />
        </Grid>
        <Grid
          item
          lg={5.3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          pb={{ lg: 22 }}
          gap={4}
          alignItems={{ xs: 'center', lg: 'start' }}
        >
          <Typography variant="h1" sx={{ '& span': { color: palette.primary.main } }}>
            {t('app.welcomeTitle')} <span>{t('app.appName')}</span>
          </Typography>

          <Typography variant="body1" color="text.secondary">
            {t('app.welcomeDesc')}
          </Typography>

          <ContainedButton onClick={() => login()} endIcon={<ChevronRight />}>
            {t('app.actions.logIn')}
          </ContainedButton>
        </Grid>

        <Grid item lg="auto" display="flex" justifyContent="center" alignItems="end">
          <img src={marketInsight3} style={{ maxWidth: '636px' }} alt="" />
        </Grid>
      </Grid>
    </PageLayout>
  );
};
