import { useProductsTFunction } from '@retail/products/i18n';
import { Stack, Typography } from '@mui/material';

interface Props {
  liProps: React.HTMLAttributes<HTMLLIElement>;
  mgItemNumber: number;
  primaryText: string;
  secondaryText?: string;
  finfoNumber: number;
}

export function SearchResultItem({
  mgItemNumber,
  primaryText,
  secondaryText,
  finfoNumber,
  liProps,
}: Props) {
  const t = useProductsTFunction();

  return (
    <Stack component={'li'} {...liProps}>
      <Typography variant="subtitle2">{primaryText}</Typography>
      <Typography variant="body2">{secondaryText}</Typography>
      <Stack flexDirection="row" gap={2} pt={1}>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography variant="subtitle2">{t('products.mgItem.number.short')}: </Typography>
          {mgItemNumber}
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography variant="subtitle2">
            {t('products.mgItem.supplierItem.finfoNumber.short')}:
          </Typography>
          {finfoNumber}
        </Stack>
      </Stack>
    </Stack>
  );
}
