import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';

export const salesFactorSchema = (t: CalculusTFunction) =>
  yup.object({
    salesFactor: yup
      .number()
      .transform((_, value: string | null) => {
        if (value) {
          return Number((value + '').replace(',', '.'));
        }
        return null;
      })
      .min(0.001, t('calculus.error.mustBeNumberBetweenZeroAndTen'))
      .max(10, t('calculus.error.mustBeNumberBetweenZeroAndTen'))
      .positive(t('calculus.error.mustBeNumberBetweenZeroAndTen'))
      .required(t('calculus.error.requiredField')),
  });

export const salesCoverageSchema = (t: CalculusTFunction) =>
  yup.object({
    salesCoverage: yup
      .number()
      .typeError(t('calculus.error.mustBeNumber'))
      .transform((_, value: string | null) => {
        if (value) {
          return Number((value + '').replace(',', '.'));
        }
        return null;
      })
      .min(-99.99, t('calculus.error.mustBePercentage'))
      .max(90, t('calculus.error.mustBePercentage'))
      .nullable()
      .required(t('calculus.error.requiredField')),
  });

export const salesPriceSchema = (t: CalculusTFunction) =>
  yup.object({
    salesPrice: yup
      .number()
      .typeError(t('calculus.error.mustBeNumber'))
      .transform((_, value: string | null) => {
        if (value) {
          return Number((value + '').replace(',', '.'));
        }
        return null;
      })
      .positive(t('calculus.error.mustBeNumber'))
      .test('max-two-decimals', t('calculus.error.mustHaveMaxTwoDecimals'), (value) => {
        if (value === undefined || value === null) return false;
        const stringValue = value.toString();
        const decimalPart = stringValue.split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      })
      .required(t('calculus.error.requiredField')),
  });

export const discountSchema = (t: CalculusTFunction) =>
  yup.object({
    discount: yup
      .number()
      .transform((_, value: string | null) => {
        if (value) {
          return Number((value + '').replace(',', '.'));
        }
        return null;
      })
      .min(0, t('calculus.error.mustBeNumberBetweenZeroAndAHundred'))
      .max(99.999, t('calculus.error.mustBeNumberBetweenZeroAndAHundred'))
      .required(t('calculus.error.requiredField')),
  });

export type SalesFactorSchema = yup.InferType<ReturnType<typeof salesFactorSchema>>;
export type SalesCoverageSchema = yup.InferType<ReturnType<typeof salesCoverageSchema>>;
export type SalesPriceSchema = yup.InferType<ReturnType<typeof salesPriceSchema>>;
export type DiscountSchema = yup.InferType<ReturnType<typeof discountSchema>>;
