import { useNavigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useFetchMyBrands } from '@retail/auth/data-access';
import { Brand } from '@retail/auth/types';
import { brandLogo, Logos } from '@retail/utils';

export const selectedBrandKey = 'selectedBrandKey';

const setInitialBrand = (brands: Brand[]) => {
  if (!brands.length)
    throw new Error('Expected at least one brand. Should not reach this point without a brand.');

  const storedBrandKey = localStorage.getItem(selectedBrandKey);
  const selectedBrand = brands.find(({ brandId }) => brandId === storedBrandKey);

  if (selectedBrand) return selectedBrand;

  const firstBrand = brands[0];
  localStorage.setItem(selectedBrandKey, firstBrand.brandId);

  return firstBrand;
};

type BrandsState = {
  availableBrands: Brand[];
  selectedBrand: Brand;
  selectedBrandLogos: Logos;
  switchBrand: (brand: Brand) => void;
};

const context = createContext<BrandsState>({} as BrandsState);

const BrandsProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate();

  const { data: availableBrands = [] } = useFetchMyBrands();

  const [selectedBrand, setSelectedBrand] = useState<Brand>(() => setInitialBrand(availableBrands));

  const switchBrand = useCallback(
    (brand: Brand) => {
      if (brand.brandId !== selectedBrand.brandId) {
        localStorage.setItem(selectedBrandKey, brand.brandId);

        setSelectedBrand(brand);
        navigate(mgPrisRoutes.root.fullRoutePath);
        window.location.reload();
      }
    },
    [navigate, selectedBrand.brandId]
  );

  return (
    <context.Provider
      value={{
        availableBrands,
        selectedBrand,
        selectedBrandLogos: brandLogo(selectedBrand.brandId),
        switchBrand,
      }}
    >
      {props.children}
    </context.Provider>
  );
};

const useUserBrands = () => {
  return useContext(context);
};

export { BrandsProvider, useUserBrands };
