import {
  QueryOptions,
  RequestMethods,
  useFetch,
  useMutateFetch,
  UseMutateFetchProps,
} from '@shared/fetch-utils';
import { QueryKey } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

export interface MPEFetchError {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
}

export interface UseMPEFetchOptions {
  queryKey: QueryKey;
  url: string;
  method: RequestMethods;
  body?: any;
  token?: string;
}

const orgUnitHeader = 'X-Org-Unit-Id';

interface OrgUnitHeaderProps {
  orgUnitId: number;
}

export function getOrgUnitHeader({ orgUnitId }: OrgUnitHeaderProps): HeadersInit | undefined {
  return { [orgUnitHeader]: `${orgUnitId}` };
}

export function useSelectedOrgUnitHeader() {
  const { selectedOrgUnitId } = useSelectedOrgUnit();
  return useMemo(
    () =>
      getOrgUnitHeader({
        orgUnitId: selectedOrgUnitId,
      }),
    [selectedOrgUnitId]
  );
}

export function useMPEFetch<TData, TError = MPEFetchError>(
  options: UseMPEFetchOptions & QueryOptions
) {
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  const optionsWithHeader = useMemo(
    () => ({
      ...options,
      headers: selectedOrgUnitHeader,
    }),
    [options, selectedOrgUnitHeader]
  );
  return useFetch<TData, TError>(optionsWithHeader);
}

export function useMutateMPEFetch<ResultT, BodyT = unknown, URLVariables = any>(
  options: UseMutateFetchProps<ResultT, BodyT, URLVariables>
) {
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();
  return useMutateFetch<ResultT, BodyT, URLVariables>({
    headers: selectedOrgUnitHeader,
    ...options,
  });
}
