import { Clear, Search } from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  Popper,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { forwardRef, useId, useMemo } from 'react';
import { SystemStyleObject } from '@mui/system';
import { SearchResultItem } from './SearchResultItem';

interface Props {
  size?: 'small' | 'medium';
  sx?: SystemStyleObject<Theme>;
  searchResults: MgSupplierItemSearchResult[];
  isLoading: boolean;
  error?: string;
  inputField: {
    name: string;
    value: string;
    onBlur: () => void;
    onChange: (event: any) => void;
  };
  selectOption: (option: MgSupplierItemSearchResult | null) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

export const SupplierItemAutocomplete = forwardRef(
  (
    {
      sx,
      searchResults,
      inputField,
      isLoading,
      error,
      selectOption,
      label,
      placeholder,
      required,
      size,
    }: Props,
    ref
  ) => {
    const t = useProductsTFunction();
    const inputId = useId();
    const formLabelId = useId();

    const PopperComponent = useMemo(() => {
      if (searchResults.length) {
        return Popper;
      }
      return NullComponent;
    }, [searchResults.length]);

    return (
      <FormControl required={required}>
        <Autocomplete
          size={size}
          sx={{ ...autoCompleteSx, ...sx }}
          renderInput={(params) => {
            const clearValue = () => {
              // @ts-ignore
              params.inputProps.onChange({ target: { value: '' } });
              inputField.onChange('');
              selectOption(null);
            };
            return (
              <>
                <FormLabel htmlFor={inputId} id={formLabelId} sx={{ fontWeight: 600 }}>
                  {label}
                </FormLabel>
                <TextField
                  {...params}
                  {...inputField}
                  id={inputId}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      paddingRight: '9px !important',
                    },
                    endAdornment: inputField.value ? (
                      <InputAdornment position="end">
                        {isLoading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          <Tooltip title={t('products.mgItem.search.reset')}>
                            <IconButton onClick={clearValue}>
                              <Clear />
                            </IconButton>
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ) : undefined,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className="search-icon" />
                      </InputAdornment>
                    ),
                  }}
                  error={!!error}
                  placeholder={placeholder}
                  fullWidth
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
              </>
            );
          }}
          options={searchResults}
          // @ts-ignore
          renderOption={({ key, ...props }, option) => (
            <SearchResultItem
              liProps={{
                ...props,
                onClick: (event) => {
                  props.onClick && props.onClick(event);
                  selectOption(option);
                },
              }}
              key={option.mgItemNumber}
              {...option}
            />
          )}
          clearOnBlur={false}
          filterOptions={(option) => option} // must be set, see https://mui.com/material-ui/react-autocomplete/#search-as-you-type
          getOptionLabel={({ primaryText, mgItemNumber }) => primaryText || `${mgItemNumber}`}
          PopperComponent={PopperComponent}
          ListboxProps={{
            sx: {
              maxHeight: '25rem',
              '& li': {
                borderTop: ({ palette }) => `1px solid ${palette.divider}`,
                '&:last-child': {
                  borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
                },
              },
            },
          }}
          noOptionsText={t('products.mgItem.search.noResults')}
        />
      </FormControl>
    );
  }
);

function NullComponent() {
  return null;
}

const autoCompleteSx: SystemStyleObject<Theme> = {
  '&.Mui-focused .search-icon': {
    color: ({ palette }) => palette.primary.main,
  },
  '& .Mui-error .search-icon': {
    color: ({ palette }) => palette.error.main,
  },
  '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
    paddingY: '4px',
  },
};
