import { StoreDTO } from '../dto';
import { Coordinates } from './Competitor';

interface ConstructorProps {
  id: number;
  name: string;
  isSelected: boolean;
  city?: string;
  coordinates?: Coordinates;
}

export class Store {
  id: number;
  name: string;
  isSelected: boolean;
  city?: string;
  coordinates?: Coordinates;

  constructor({ id, name, isSelected, city, coordinates }: ConstructorProps) {
    this.id = id;
    this.name = name;
    this.isSelected = isSelected;
    this.city = city;
    this.coordinates = coordinates;
  }

  static fromStoreDto = (
    { id, name, city, latitude, longitude }: StoreDTO,
    competitors: StoreDTO[]
  ): Store =>
    new Store({
      id,
      name,
      isSelected: competitors.some((competitor) => competitor.id === id),
      city,
      coordinates:
        latitude && longitude
          ? {
              lat: latitude,
              long: longitude,
            }
          : undefined,
    });
}
