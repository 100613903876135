import { MgItem } from '@retail/products/types';
import { Paper } from '@shared/components';

interface Props {
  mgItem: MgItem;
}

// TODO: Comment in code and adjust to MPE API when needed
export const MgItemCompetitorPrices = ({ mgItem }: Props) => {
  // const { selectedOrgUnitId } = useSelectedOrgUnit();
  // const competitors = useSelectedCompetitors(selectedOrgUnitId);
  //
  // const mpeMgItemLoadingState = useFetchMgItem(
  //   {
  //     mgItemNumber: mgItem.id,
  //     includeByggmaterialpriserPrices: true,
  //     competitorStoreIds: competitors.map((competitor) => competitor.id),
  //   },
  //   { suspense: false }
  // );

  return (
    <Paper sx={{ p: 0 }}>
      {/*<TanstackQueryLoadingWrapper*/}
      {/*  loadingState={mpeMgItemLoadingState}*/}
      {/*  LoadingComponent={BodyLoader}*/}
      {/*  ErrorComponent={DefaultPpsErrorFallback}*/}
      {/*>*/}
      {/*  {(mgItemDto) => {*/}
      {/*    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
      {/*    const ppsMgItem = MgItemCompetitorsWithPrices.fromDto(mgItemDto!);*/}

      {/*    return (*/}
      {/*      <CompetitorViewForMgItem*/}
      {/*        competitors={ppsMgItem.competitors}*/}
      {/*        contextCompetitors={competitors}*/}
      {/*      />*/}
      {/*    );*/}
      {/*  }}*/}
      {/*</TanstackQueryLoadingWrapper>*/}
    </Paper>
  );
};
