import { Box, IconButton } from '@mui/material';
import { GridExpandMoreIcon, GridRenderCellParams, useGridSelector } from '@mui/x-data-grid';
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useAppTFunction } from '@retail/app/i18n';
import { isValidElement } from 'react';

export const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'value'>) => {
  const t = useAppTFunction();

  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <Box display="flex" justifyContent="flex-end" flexGrow={1}>
      <IconButton
        disabled={!hasDetail}
        aria-label={isExpanded ? t('calculus.close') : t('calculus.open')}
      >
        <GridExpandMoreIcon
          sx={(theme) => ({
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          })}
          fontSize="inherit"
        />
      </IconButton>
    </Box>
  );
};
