import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  suppliers: Supplier[];
  selectedSupplier: number | null;
  selectSupplier: (supplierId: number | null) => void;
}

export function SelectSupplier({ suppliers, selectedSupplier, selectSupplier }: Props) {
  const t = useProductsTFunction();
  return (
    <Stack gap={0.5}>
      <Typography component="label" htmlFor="supplierSelect" variant="body2" color="text.secondary">
        {t('products.supplier.name')}
      </Typography>
      <Autocomplete
        id="supplierSelect"
        options={suppliers}
        size="small"
        isOptionEqualToValue={(option, value) => option.mgSupplierId === value.mgSupplierId}
        value={suppliers.find((supplier) => supplier.mgSupplierId === selectedSupplier) ?? null}
        onChange={(_, newValue) => {
          selectSupplier(newValue?.mgSupplierId ?? null);
        }}
        getOptionLabel={({ name, mgSupplierId }) => `${name} ${mgSupplierId}`}
        renderOption={(props, option) => (
          <Stack
            {...props}
            component="li"
            key={option.mgSupplierId}
            borderBottom={({ palette }) => `1px solid ${palette.divider}`}
          >
            <Typography>{option.name}</Typography> {option.mgSupplierId}
          </Stack>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder={t('products.supplier.searchForSuppliers')} />
        )}
      />
    </Stack>
  );
}
