import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { ExportResources } from '../types/ExportResources';

export const useExportTranslation = () => useMessagesTranslation<ExportResources>();

export type ExportMessageKey = MessagePaths<ExportResources>;

export const useExportTFunction = () => useExportTranslation().t;

export type ExportTFunction = ReturnType<typeof useExportTFunction>;
