import { ButtonProps, useTheme } from '@mui/material';
import { LoadingButton } from '@shared/components';
import { forwardRef, PropsWithChildren } from 'react';
interface Props {
  state: 'selected' | 'regular';
}
function TabButtonBase(
  { children, state, ...rest }: PropsWithChildren<ButtonProps & Props>,
  ref: ButtonProps['ref']
) {
  const { palette } = useTheme();
  return (
    <LoadingButton
      {...rest}
      ref={ref}
      variant="text"
      sx={{
        textDecoration: 'none',
        fontWeight: state === 'selected' ? 'bold' : 'none',
        color: state === 'selected' ? undefined : palette.text.secondary,
        border: 0,
        p: 0,
        '&:hover': {
          fontWeight: 'bold',
          border: 0,
          color: state === 'selected' ? palette.primary.main : palette.text.secondary,
          bgcolor: palette.background.paper,
          boxShadow: 'none',
        },
        ':selected': {
          color: palette.primary.main,
        },
      }}
    >
      {children}
    </LoadingButton>
  );
}

const TabButton = forwardRef(TabButtonBase);
export default TabButton;
