import { Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useScrollWithShadow } from '@retail/hooks';
import { RetailerWithCompetitorsList } from '@retail/retailer/components';
import { Retailer } from '@retail/retailer/types';
import { Paper } from '@shared/components';

interface Props {
  retailers: Retailer[];
  removeCompetitor: (storeId: number) => void;
  nrOfCompetitors: number;
}

export function ChosenCompetitors({ retailers, removeCompetitor, nrOfCompetitors }: Props) {
  const t = useAppTFunction();
  const { selectedDepartment } = useSelectedOrgUnit();
  const myCoordinates =
    selectedDepartment && selectedDepartment.latitude && selectedDepartment.longitude
      ? { lat: selectedDepartment.latitude, long: selectedDepartment.longitude }
      : undefined;
  const { onScrollHandler, boxShadow, overflowRef } = useScrollWithShadow([removeCompetitor]);

  return (
    <Paper
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}
    >
      <Typography variant="h4" component="h2" pb={1}>
        {t('assortment.competitors.chosenCompetitors')}
      </Typography>
      <Stack overflow="auto" ref={overflowRef} onScroll={onScrollHandler} sx={{ boxShadow }}>
        {nrOfCompetitors > 0 ? (
          retailers.map((retailer) => {
            return (
              <RetailerWithCompetitorsList
                key={retailer.id}
                retailer={retailer}
                myCoordinates={myCoordinates}
                removeCompetitor={removeCompetitor}
              />
            );
          })
        ) : (
          <Typography>{t('assortment.competitors.noCompetitors')}</Typography>
        )}
      </Stack>
    </Paper>
  );
}
