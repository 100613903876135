import { forwardRef, ReactNode } from 'react';
import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Provider,
  TreeItem2Root,
  useTreeItem2,
} from '@mui/x-tree-view';
import { TreeNode } from './NavigationTreeView';
import { navTreeContentStyling, navTreeGroupTransition, navTreeLabelStyling } from './styles';

interface Props extends TreeNode {
  depth: number;
  children?: ReactNode;
}

const CustomTreeItem = forwardRef(function CustomTreeItem(
  { label, onSelect, id, depth, children }: Props,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId: id, children, label, rootRef: ref });

  const isChildNode = depth > 0;

  return (
    <TreeItem2Provider itemId={id}>
      <TreeItem2Root {...getRootProps()}>
        <TreeItem2Content status={status} sx={navTreeContentStyling(depth, status)}>
          <TreeItem2IconContainer
            {...getIconContainerProps()}
            onClick={(event) => getContentProps().onClick(event)}
          >
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>

          <TreeItem2Label
            {...getLabelProps()}
            sx={navTreeLabelStyling(isChildNode)}
            onClick={() => {
              onSelect && onSelect();
            }}
          />
        </TreeItem2Content>

        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} sx={navTreeGroupTransition} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export function NavigationTreeItem({
  label,
  childNodes = [],
  onClick,
  onSelect,
  id,
  depth,
}: Props) {
  return (
    <CustomTreeItem id={id} label={label} depth={depth} onClick={onClick} onSelect={onSelect}>
      {childNodes.map((node) => (
        <NavigationTreeItem key={node.id} depth={depth + 1} {...node} />
      ))}
    </CustomTreeItem>
  );
}
