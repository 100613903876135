import { Stack, Typography } from '@mui/material';
import { Paper } from '@shared/components';
import { sideFiltersWidth } from '@shared/styles';
import { ToggleFiltersButton } from '../Buttons';
import { PropsWithChildren } from 'react';

interface SideFilterProps extends PropsWithChildren {
  heading: string;
  subHeading?: string;
  showFilters: boolean;
  closeFilters: () => void;
}

export function SideFilters({
  heading,
  subHeading,
  showFilters,
  closeFilters,
  children,
}: SideFilterProps) {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 0,
        borderLeft: ({ palette }) => `1px solid ${palette.divider}`,
        px: 3,
        py: 2,
        width: sideFiltersWidth,
      }}
    >
      <Stack gap={2} flexGrow={1}>
        <Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontWeight={600}>
              {heading}
            </Typography>

            <ToggleFiltersButton showFilters={showFilters} toggleFilters={closeFilters} />
          </Stack>
          <Typography variant="body2" fontWeight={600}>
            {subHeading}
          </Typography>
        </Stack>
        <Stack gap={3}>{children}</Stack>
      </Stack>
    </Paper>
  );
}
