import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useFetchDepartmentAssortments } from '@retail/my-assortment/data-access';
import { Assortment, AssortmentTypeDTO } from '@retail/my-assortment/types';
import { useMemo } from 'react';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';

export const useDepartmentAssortments = () => {
  const { selectedDepartment } = useSelectedOrgUnit();
  const departmentId = selectedDepartment!.departmentId;

  const { data: assortmentDtos = [] } = useFetchDepartmentAssortments({ departmentId });

  const assortments = useMemo(
    () => assortmentDtos.map((dto) => new Assortment(dto)),
    [assortmentDtos]
  );

  const baseAssortment = useMemo(
    () => assortments.find((assortment) => assortment.type === AssortmentTypeDTO.BASE)!,
    [assortments]
  );
  const draftAssortment = useMemo(
    () => assortments.find((assortment) => assortment.type === AssortmentTypeDTO.DRAFT)!,
    [assortments]
  );
  const monitoringAssortments = useMemo(
    () =>
      assortmentDtos
        .filter(({ type }) => type === AssortmentTypeDTO.MONITORING)
        .map((dto) => new MonitoringAssortment(dto)),
    [assortmentDtos]
  );

  return {
    draftAssortment,
    baseAssortment,
    departmentId,
    draftAssortmentId: draftAssortment.id,
    baseAssortmentId: baseAssortment.id,
    monitoringAssortments,
  };
};
