import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { ConditionDatesForm, conditionDatesSchema } from '@retail/calculus/components';
import { ContainedButton, Dialog, TextButton, TextInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';
import { useAppTFunction } from '@retail/app/i18n';

export const markupMetaSchema = (t: CalculusTFunction) =>
  conditionDatesSchema(t).concat(
    yup.object({
      markupName: yup.string().required(t('calculus.error.requiredField')),
    })
  );

export type MarkupMetaSchema = yup.InferType<ReturnType<typeof markupMetaSchema>>;

interface Props {
  itemName: string;
  open: boolean;
  isLoadingCreate: boolean;
  onClose: () => void;
  handleCreateCondition: (formaData: MarkupMetaSchema) => void;
}

export function CreateConditionDialog({
  itemName,
  open,
  isLoadingCreate,
  onClose,
  handleCreateCondition,
}: Props) {
  const t = useAppTFunction();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useFormContext<MarkupMetaSchema>();

  const field = useController<MarkupMetaSchema>({ name: 'markupName', control });

  const onSubmit = handleSubmit(handleCreateCondition);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('myAssortment.createMarkupFor', { name: itemName })}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <TextInputField
            {...field.field}
            required
            fullWidth
            labelWidth={142}
            labelDirection="row"
            margin="none"
            label={t('myAssortment.productCard.markupName')}
            size="small"
            error={errors.markupName?.message}
          />
          <ConditionDatesForm excludeToDate labelAlignment="row" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" gap={1} flexWrap="nowrap">
          <TextButton onClick={onClose}>{t('myAssortment.cancel')}</TextButton>
          <ContainedButton
            disabled={isLoadingCreate}
            onClick={onSubmit}
            color="secondary"
            size="small"
          >
            {t('myAssortment.createMarkup')}
          </ContainedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
