import { useDisclosure } from '@shared/hooks';
import { useCallback, useMemo, useState } from 'react';
import { useSelectProductGroups } from './useSelectProductGroups';
import { useSelectSuppliers } from './useSelectSuppliers';
import { useSelectDiscountGroups } from './useSelectDiscountGroups';
import { Supplier } from '@retail/suppliers/types';
import { discountGroupSearchParamKey } from '@retail/products/utils';
import { GroupOption } from '@retail/products/types';
import { useSelectArticleIds } from './useSelectArticleIds';
import { useSelectSearchQuery } from './useSelectSearchQuery';

interface Props {
  assortmentId?: number;
}

export const useSearchFilters = ({ assortmentId }: Props) => {
  const { isOpen: withAgreementPrices, onToggle: toggleWithAgreementPrices } = useDisclosure(true);

  const [fromMainSupplier, toggleFromMainSupplier] = useState<GroupOption>('UNSPECIFIED');
  const [inAssortment, toggleInAssortment] = useState<GroupOption>('UNSPECIFIED');

  const { suppliers, selectedSuppliers, selectSuppliers } = useSelectSuppliers({ assortmentId });
  const { productGroups, selectedProductGroups, selectProductGroups } = useSelectProductGroups();
  const { discountGroups, selectedDiscountGroups, selectDiscountGroups } = useSelectDiscountGroups({
    selectedSuppliers,
  });
  const { articleIds, setArticleIds, removeArticleId, resetArticleIds } = useSelectArticleIds();
  const { query, setQuery } = useSelectSearchQuery();

  const handleSelectSupplier = useCallback(
    (suppliers: Supplier[], supplierIdToDelete?: number) => {
      const discountGroups = (selectedDiscountGroups ?? []).filter(
        (discountGroup) => discountGroup.mgSupplierId !== supplierIdToDelete
      );
      selectSuppliers(suppliers, {
        key: discountGroupSearchParamKey,
        value: discountGroups.map(({ id }) => id + ''),
      });
    },
    [selectSuppliers, selectedDiscountGroups]
  );

  const resetFilters = useCallback(() => {
    resetArticleIds();
    setQuery('');
    selectProductGroups([]);
    selectDiscountGroups([]);
    selectSuppliers([]);
    toggleFromMainSupplier('UNSPECIFIED');
    toggleInAssortment('UNSPECIFIED');
    if (!withAgreementPrices) {
      toggleWithAgreementPrices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      searchFilters: {
        withAgreementPrices,
        fromMainSupplier,
        inAssortment,
        selectedSuppliers,
        selectedProductGroups,
        selectedDiscountGroups,
        query,
        articleIds,
      },
      setSearchFilters: {
        toggleWithAgreementPrices,
        toggleFromMainSupplier,
        toggleInAssortment,
        selectSupplier: handleSelectSupplier,
        selectProductGroups,
        selectDiscountGroups,
        setQuery,
        setArticleIds,
        removeArticleId,
      },
      filterOptions: {
        suppliers,
        productGroups,
        discountGroups,
      },
      resetFilters,
    }),
    [
      withAgreementPrices,
      fromMainSupplier,
      inAssortment,
      selectedSuppliers,
      selectedProductGroups,
      selectedDiscountGroups,
      query,
      articleIds,
      toggleWithAgreementPrices,
      handleSelectSupplier,
      selectProductGroups,
      selectDiscountGroups,
      setQuery,
      setArticleIds,
      removeArticleId,
      suppliers,
      productGroups,
      discountGroups,
      resetFilters,
    ]
  );
};
