import { FieldValueView } from '../FieldValueView';
import { EditFieldButton } from '../EditFieldButton';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  currentSalesCoverage?: number;
  newSalesCoverage?: {
    value: number;
    isApproximate: boolean;
  };
  openEditMode: () => void;
  editable?: boolean;
  maximumFractionDigits?: number;
}

export const SalesCoverageValueView: React.FC<Props> = ({
  currentSalesCoverage,
  newSalesCoverage,
  openEditMode,
  editable = true,
  maximumFractionDigits = 6,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <Stack direction="row" alignItems="center">
      <FieldValueView
        oldValue={
          currentSalesCoverage
            ? `${currentSalesCoverage.toLocaleString(language, { maximumFractionDigits })}%`
            : '-'
        }
        newValue={
          newSalesCoverage
            ? `${newSalesCoverage.value.toLocaleString(language, {
                maximumFractionDigits,
              })}%`
            : '-'
        }
        hasNewValue={!!newSalesCoverage && currentSalesCoverage !== newSalesCoverage?.value}
        approximatelyEqual={newSalesCoverage?.isApproximate}
      />
      {editable && <EditFieldButton onEdit={openEditMode} />}
    </Stack>
  );
};
