import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { MgItemDTO, MgItemRawDTO, MgPackageType } from '@retail/products/types';
import { buildUrl } from '@shared/utils';
import { useMPEFetch, useSelectedOrgUnitHeader } from '@retail/app/hooks';
import { AssortmentDTO } from '@retail/my-assortment/types';
import { mpeApi } from '@retail/mgpris/config';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';

interface FetchProps {
  baseUrl: string;
  mgItemNumber: number;
  assortmentId?: number;
  calculationPackages?: MgPackageType[];
  token: string;
  headers?: HeadersInit;
}

export async function fetchMgItem({
  baseUrl,
  mgItemNumber,
  assortmentId,
  calculationPackages,
  token,
  headers,
}: FetchProps) {
  return fetcher<MgItemDTO>({
    url: buildUrl({
      base: baseUrl,
      route: mpeApi.mgItems.mgItemNumber.getFullLinkPath({ mgItemNumber }),
      searchParams: { assortmentId, calculationPackages },
    }),
    token,
    headers,
  });
}

interface Props {
  mgItemNumber: number;
  assortmentId?: number;
  calculationPackages?: MgPackageType[];
}

export function useFetchMgItem(
  { mgItemNumber, assortmentId, calculationPackages }: Props,
  options?: QueryOptions
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  const fetcher = useCallback(
    () =>
      fetchMgItem({
        baseUrl: mpe.url,
        mgItemNumber,
        assortmentId,
        calculationPackages,
        token,
        headers: selectedOrgUnitHeader,
      }),
    [mpe.url, mgItemNumber, assortmentId, calculationPackages, token, selectedOrgUnitHeader]
  );

  return useQueryFetch({
    fetcher,
    queryKey: ['mg-item', mgItemNumber, calculationPackages, assortmentId, selectedOrgUnitHeader],
    ...options,
  });
}

interface FetchAssortmentsProps {
  mgItemNumber: number;
  orgUnitId: number;
}

export function useFetchAssortmentsForMgItem(
  { mgItemNumber, orgUnitId }: FetchAssortmentsProps,
  options?: QueryOptions
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<AssortmentDTO[]>({
    method: 'GET',
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.mgItems.mgItemNumber.assortments.getFullLinkPath({ mgItemNumber }),
      searchParams: { orgUnitId },
    }),
    token,
    queryKey: ['assortments-for-mg-item', mgItemNumber],
    ...options,
  });
}

export function useFetchRawMgItem(
  { mgItemNumber }: Pick<FetchAssortmentsProps, 'mgItemNumber'>,
  options?: QueryOptions
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<MgItemRawDTO>({
    method: 'GET',
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.mgItems.mgItemNumber.json.getFullLinkPath({ mgItemNumber }),
    }),
    token,
    queryKey: ['json-for-mg-item', mgItemNumber],
    ...options,
  });
}
