import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import NewAssortmentFields from '../NewAssortmentFields';
import { ImportAssortmentSpreadsheet } from './ImportAssortmentSpreadsheet';
import {
  IdentifiableColumn,
  importStepAtom,
  useImportedAssortmentState,
  useSpreadsheetState,
} from './assortmentImportState';
import { NewAssortmentForm, newAssortmentSchema } from '../newAssortmentForm';
import { ItemCodes } from '@retail/monitoring-assortment/types';
import { ContainedButton, Dialog, OutlinedButton, Stepper } from '@shared/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { useCallback, useState } from 'react';
import { ValidateSpreadsheet } from '../ValidateSpreadsheet';

export interface NewAssortment {
  title: string;
  itemCodes: ItemCodes;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (newAssortment: NewAssortment) => void;
  getInvalidItemCodes?: (itemCodes: ItemCodes) => Promise<ItemCodes>;
  columnsToRetrieve: Set<IdentifiableColumn>;
  isLoading?: boolean;
}

export const ImportAssortmentDialog: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  getInvalidItemCodes,
  columnsToRetrieve,
  isLoading = false,
}) => {
  const [activeStep, setActiveStep] = useRecoilState(importStepAtom);
  const [, setSpreadsheet] = useSpreadsheetState();
  const { handleSubmit, control } = useForm<NewAssortmentForm>({
    resolver: yupResolver(newAssortmentSchema),
    shouldUnregister: false,
  });
  const t = useAssortmentTFunction();

  const assortmentImportState = useImportedAssortmentState({ columnsToRetrieve });
  const [invalidItemCodes, setInvalidItemCodes] = useState<undefined | ItemCodes>();

  const onSubmitForm = handleSubmit(({ title }) => {
    if (assortmentImportState.status === 'success') {
      onSubmit({
        title,
        itemCodes: {
          finfoNumbers: assortmentImportState.finfoNumbers,
          gtinCodes: assortmentImportState.gtinNumbers,
          mgItemNumbers: assortmentImportState.mgItemNumbers,
        },
      });
    }
    setSpreadsheet(undefined);
    setActiveStep(0);
  });

  const doValidation = useCallback(() => {
    assortmentImportState.status === 'success' &&
      getInvalidItemCodes &&
      getInvalidItemCodes({
        finfoNumbers: assortmentImportState.finfoNumbers || [],
        gtinCodes: assortmentImportState.gtinNumbers || [],
        mgItemNumbers: assortmentImportState.mgItemNumbers || [],
      }).then(setInvalidItemCodes);
  }, [assortmentImportState, getInvalidItemCodes]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setSpreadsheet(undefined);
        setActiveStep(0);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" flexDirection="column" flex="1" alignItems="stretch" gap={3}>
          <Typography variant="h5">{t('assortment.admin.create.title')}</Typography>
          <Stepper
            activeStep={activeStep}
            steps={[
              t('assortment.admin.create.fromFile.import.title'),
              t('assortment.admin.create.fromFile.import.validate'),
              t('assortment.admin.create.fromFile.nameAndType.name'),
            ]}
          />
        </Box>
      </DialogTitle>
      {activeStep === 0 && (
        <>
          <DialogContent>
            <ImportAssortmentSpreadsheet columnsToRetrieve={columnsToRetrieve} />
          </DialogContent>

          <DialogActions>
            <ContainedButton
              size="small"
              onClick={() => {
                setActiveStep(1);
                doValidation();
              }}
              disabled={assortmentImportState.status !== 'success'}
            >
              {t('assortment.admin.create.next')}
            </ContainedButton>
          </DialogActions>
        </>
      )}
      {activeStep === 1 && (
        <>
          <DialogContent>
            <ValidateSpreadsheet
              importState={assortmentImportState}
              invalidItemCodes={invalidItemCodes}
            />
          </DialogContent>
          <DialogActions>
            <OutlinedButton size="small" onClick={() => setActiveStep(0)} disabled={isLoading}>
              {t('assortment.admin.create.previous')}
            </OutlinedButton>
            <ContainedButton size="small" onClick={() => setActiveStep(2)}>
              {t('assortment.admin.create.next')}
            </ContainedButton>
          </DialogActions>
        </>
      )}
      {activeStep === 2 && (
        <>
          <DialogContent>
            <NewAssortmentFields control={control} />
          </DialogContent>
          <DialogActions>
            <OutlinedButton size="small" onClick={() => setActiveStep(1)} disabled={isLoading}>
              {t('assortment.admin.create.previous')}
            </OutlinedButton>
            <ContainedButton size="small" onClick={onSubmitForm} loading={isLoading}>
              {t('assortment.admin.create.importCTA')}
            </ContainedButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
