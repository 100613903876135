import React, { FC, useCallback, useMemo } from 'react';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import {
  useFetchPurchaseAgreements,
  useSetStandardPurchaseAgreements,
} from '@retail/purchase-agreements/data-access';
import { Supplier } from '@retail/suppliers/types';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { PurchaseAgreementsTablePrev } from '@retail/purchase-agreements/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { useIsSuperUser } from '@retail/auth/data-access';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@retail/hooks';

interface Props {
  supplier: Supplier;
  open: boolean;
  onClose: () => void;
}

const SupplierAgreementsDialogContainer: FC<Props> = ({ supplier, open, onClose }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const t = usePurchaseAgreementsTFunction();
  const { data: agreementDtos = [] } = useFetchPurchaseAgreements({
    supplierId: supplier.mgSupplierId,
  });
  const isSuperUser = useIsSuperUser();
  const { mutateAsync: mutateStandardPurchaseAgreement, isLoading } =
    useSetStandardPurchaseAgreements();
  const agreements = useMemo(
    () =>
      agreementDtos.map(PurchaseAgreement.fromDto).sort((a1, a2) => a1.name.localeCompare(a2.name)),
    [agreementDtos]
  );
  const [agreementToSet, setAgreementToSet] = React.useState<PurchaseAgreement | null>(null);

  const onSubmitStandardPurchaseAgreement = useCallback(
    (agreementId: number) =>
      mutateStandardPurchaseAgreement({
        urlVariables: [agreementId],
      })
        .then((agreements) => {
          setAgreementToSet(null);
          queryClient.invalidateQueries(['purchase-agreements', supplier.mgSupplierId]);
          queryClient.invalidateQueries(['suppliers']);
          toast.success(
            t('purchaseAgreements.supplier.standardAgreement.success', {
              agreement: agreements[0].agreementText,
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(t('purchaseAgreements.supplier.standardAgreement.error.generic'));
        }),
    [mutateStandardPurchaseAgreement, queryClient, supplier.mgSupplierId, t, toast]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t('purchaseAgreements.agreement.agreementsFor', { supplier: supplier.name })}
      </DialogTitle>

      <DialogContent>
        <PurchaseAgreementsTablePrev
          agreements={agreements}
          setStandardAgreement={isSuperUser ? setAgreementToSet : undefined}
        />
      </DialogContent>
      {agreementToSet && (
        <DialogActions>
          <Typography variant="body1" color="textSecondary">
            {t('purchaseAgreements.supplier.standardAgreement.confirmation.description', {
              agreement: agreementToSet.name,
            })}
          </Typography>
          <TextButton onClick={() => setAgreementToSet(null)} loading={isLoading}>
            {t('purchaseAgreements.supplier.standardAgreement.confirmation.cancel')}
          </TextButton>
          <ContainedButton
            onClick={() => onSubmitStandardPurchaseAgreement(agreementToSet.id)}
            loading={isLoading}
          >
            {t('purchaseAgreements.supplier.standardAgreement.confirmation.confirm')}
          </ContainedButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withDialogSuspenseErrorBoundary(SupplierAgreementsDialogContainer);
