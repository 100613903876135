import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { fetchFile, QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import {
  Assortment,
  AssortmentDTO,
  AssortmentPriorityResponseDTO,
  ItemsDTO,
} from '@retail/my-assortment/types';
import { useMPEFetch, useMutateMPEFetch, useSelectedOrgUnitHeader } from '@retail/app/hooks';
import { useMutation } from '@tanstack/react-query';
import { mpeApi } from '@retail/mgpris/config';

interface BrandProps extends QueryOptions {
  brandId: string;
}

interface MemberProps extends QueryOptions {
  memberId: string;
}

interface DepartmentProps extends QueryOptions {
  departmentId: string;
}

export function useFetchBrandAssortments({ brandId, ...queryOptions }: BrandProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<Assortment[]>({
    method: 'GET',
    queryKey: ['brand-assortments'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.brands.brand.assortments.getFullLinkPath({ brandId }),
    }),
    ...queryOptions,
  });
}

export function useFetchMemberAssortments({ memberId, ...queryOptions }: MemberProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<Assortment[]>({
    method: 'GET',
    queryKey: ['member-assortments'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.members.member.assortments.getFullLinkPath({ memberId }),
    }),
    ...queryOptions,
  });
}

export function useFetchDepartmentAssortments({ departmentId, ...queryOptions }: DepartmentProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<AssortmentDTO[]>({
    method: 'GET',
    queryKey: ['department-assortments'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.departments.department.assortments.getFullLinkPath({ departmentId }),
    }),
    ...queryOptions,
  });
}

export function useMutateValidatePriorities({ ...queryOptions }) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<AssortmentPriorityResponseDTO, ItemsDTO[]>({
    method: 'POST',
    url: () =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.assortments.validatePriorities.getFullLinkPath(),
      }),
    token,
    ...queryOptions,
  });
}

export interface FetchAssortmentProps extends QueryOptions {
  assortmentId: number;
  includeOwner?: boolean;
  supplierIds?: number[];
  groupNumbers?: string[];
  query?: string;
}

export function useExportAssortment() {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  return useMutation(
    ({
      assortmentId,
      includeOwner,
      supplierIds,
      groupNumbers,
      query,
      ...queryOptions
    }: FetchAssortmentProps) =>
      fetchFile({
        url: buildUrl({
          base: mpe.url,
          route: mpeApi.assortments.assortment.assortmentXlsx.getFullLinkPath({ assortmentId }),
          searchParams: {
            includeOwner,
            supplierIds,
            groupNumbers,
            query,
          },
        }),
        token,
        method: 'GET',
        headers: { Accept: 'application/octet-stream', ...selectedOrgUnitHeader },
        ...queryOptions,
      })
  );
}
