import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

interface Props<TId extends string | number, TOption> {
  options: TOption[];
  getOptionId: (option: TOption) => TId;
  searchParamKey: string;
  resetContiguousSearchParamKey?: string;
  initialValue?: TId;
}

export function useSelectedSearchParamState<TId extends string | number, TOption = TId>({
  options,
  searchParamKey,
  resetContiguousSearchParamKey,
  getOptionId,
  initialValue,
}: Props<TId, TOption>): [
  TOption | null,
  (option: TOption | null) => void,
  (optionId: TId | null) => void
] {
  const [searchParams, setSearchParams] = useSearchParams(
    initialValue && { [searchParamKey]: `${initialValue}` }
  );
  const selectedOptionId = searchParams.get(searchParamKey);
  const selectedOption = useMemo(
    () => options.find((option) => `${getOptionId(option)}` === selectedOptionId) || null,
    [getOptionId, options, selectedOptionId]
  );
  const selectOption = useCallback(
    (
      option: TOption | null,
      addContiguousSearchParamKey?: { searchParam: string; value: string }
    ) =>
      setSearchParams((prevParams) => {
        if (option) {
          prevParams.set(searchParamKey, `${getOptionId(option)}`);
          addContiguousSearchParamKey &&
            prevParams.set(
              addContiguousSearchParamKey.searchParam,
              addContiguousSearchParamKey.value
            );
        } else {
          prevParams.delete(searchParamKey);
        }
        resetContiguousSearchParamKey && prevParams.delete(resetContiguousSearchParamKey);
        return prevParams;
      }),
    [getOptionId, resetContiguousSearchParamKey, searchParamKey, setSearchParams]
  );
  const selectOptionById = useCallback(
    (
      optionId: TId | null,
      addContiguousSearchParamKey?: { searchParam: string; value: string }
    ) => {
      setSearchParams((prevParams) => {
        if (optionId) {
          prevParams.set(searchParamKey, `${optionId}`);
          addContiguousSearchParamKey &&
            prevParams.set(
              addContiguousSearchParamKey.searchParam,
              addContiguousSearchParamKey.value
            );
        } else {
          prevParams.delete(searchParamKey);
        }
        resetContiguousSearchParamKey && prevParams.delete(resetContiguousSearchParamKey);
        return prevParams;
      });
    },
    [resetContiguousSearchParamKey, searchParamKey, setSearchParams]
  );

  return [selectedOption, selectOption, selectOptionById];
}
