import { Box, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';
import { CustomColumnMenu, DataGridPagination } from '@retail/components';
import { useAppTFunction } from '@retail/app/i18n';
import { useMemo } from 'react';
import { TotalFileDownloadSupplier } from '@retail/export/types';

interface Props {
  language: string;
  suppliers: TotalFileDownloadSupplier[];
}

const baseColumnProps: Partial<GridColDef> = {
  width: 170,
  headerName: '',
  align: 'left',
  headerAlign: 'left',
  sortable: false,
};

export const ExportTable = ({ language, suppliers }: Props) => {
  const t = useAppTFunction();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'mgSupplierName',
        width: 265,
        headerName: t('suppliers.columns.participantAccountName'),
      },
      {
        ...baseColumnProps,
        field: 'mgSupplierId',
        headerName: t('suppliers.columns.mgParticipantAccountId'),
      },
      {
        ...baseColumnProps,
        field: 'finfoSupplierId',
        headerName: t('suppliers.columns.finfoSupplierId'),
      },
      {
        ...baseColumnProps,
        field: 'gln',
        headerName: t('suppliers.columns.gln'),
      },
      {
        ...baseColumnProps,
        width: 210,
        field: 'stockItems',
        headerAlign: 'center',
        align: 'center',
        headerName: t('export.stockItems'),
      },
      {
        ...baseColumnProps,
        width: 210,
        field: 'nonStockItems',
        headerAlign: 'center',
        align: 'center',
        headerName: t('export.nonStockItems'),
      },
    ],
    [t]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium<TotalFileDownloadSupplier>
          columns={columns}
          rows={suppliers}
          getRowId={(row) => row.mgSupplierId}
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          rowHeight={65}
          disableRowSelectionOnClick
          disableColumnReorder
          hideFooter
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.EXPORT_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);

            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.EXPORT_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          slots={{
            columnMenu: CustomColumnMenu,
            pagination: () => <DataGridPagination />,
            noRowsOverlay: () => (
              <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
                <Typography variant="h3" color={(theme) => theme.palette.grey[100]}>
                  {t('export.messages.noSuppliers')}
                </Typography>
              </Stack>
            ),
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
};
