import { Stack, Typography } from '@mui/material';
import { useUserBrands } from '@retail/app/stores/selected-context';
import { useFetchBrandAssortments } from '@retail/my-assortment/data-access';
import { CentralAssortmentItems } from './CentralAssortmentItems';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ItemWithPriority } from '@retail/my-assortment/types';

interface Props {
  checkedAssortmentItems: ItemWithPriority[];
  setCheckedAssortmentItems: (items: ItemWithPriority[]) => void;
}

export function CentralAssortmentOverview({
  checkedAssortmentItems,
  setCheckedAssortmentItems,
}: Props) {
  const t = useMyAssortmentTFunction();
  const { selectedBrand } = useUserBrands();

  const { data: brandAssortments = [] } = useFetchBrandAssortments({
    brandId: selectedBrand.brandId,
  });

  const baseAssortment = brandAssortments.find((assortment) => assortment.type === 'BASE');
  return (
    <Stack flexGrow={1}>
      {baseAssortment ? (
        <CentralAssortmentItems
          assortmentId={baseAssortment.id}
          assortmentName={baseAssortment.title}
          checkedAssortmentItems={checkedAssortmentItems}
          setCheckedAssortmentItems={setCheckedAssortmentItems}
        />
      ) : (
        <Stack display="flex" pt={12} alignItems="center">
          <Typography variant="h6">
            {t('myAssortment.centralAssortments.noCentralAssortments')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
