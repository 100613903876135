import { useState } from 'react';
import { useSelectedCompetitors } from '@retail/retailer/data-access';
import { Stack } from '@mui/material';
import {
  AddOrReplaceMgItemsFromFileDialog,
  DeleteAssortmentDialog,
  EditAssortmentMenu,
  EditAssortmentTitleDialog,
} from '@retail/monitoring-assortment/components';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@shared/hooks';
import {
  useMonitoringAssortmentActions,
  useMonitoringAssortmentItems,
} from '@retail/monitoring-assortment/context';
import { useToast } from '@retail/hooks';
import { LoadingBackdrop, ToggleSwitch } from '@retail/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { MgAssortmentItemsView } from '@retail/products/components';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  selectedAssortment: MonitoringAssortment;
  redirectOnDelete: string;
  assortmentQuery: string;
  setItemToView: (item?: number) => void;
}

export function MonitoringAssortmentContainer({
  selectedAssortment,
  redirectOnDelete,
  assortmentQuery,
  setItemToView,
}: Props) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { selectedOrgUnitId } = useSelectedOrgUnit();
  const competitors = useSelectedCompetitors(selectedOrgUnitId);

  const {
    result: { assortmentItems, pageSize, page, totalItems },
    setPageSize,
    setPage,
    refetch,
    isFetching,
  } = useMonitoringAssortmentItems({ assortmentId: selectedAssortment.id, competitors });

  const {
    isLoading,
    handleDeleteMgAssortment,
    handlePatchTitleOrItemsOfMgAssortment,
    handleAddItemsToMgAssortment,
    handleValidateItemsOnImportedAssortment,
  } = useMonitoringAssortmentActions();

  const t = useAssortmentTFunction();
  const navigate = useNavigate();

  const [toggleVat, setToggleVat] = useState(false);
  const [toggleSimpleView, setToggleSimpleView] = useState(false);

  const { isOpen: isDeleteModalOpen, onToggle: toggleDeleteModal } = useDisclosure();
  const { isOpen: isEditNameModalOpen, onToggle: toggleEditNameModal } = useDisclosure();
  const { isOpen: isAddItemsModalOpen, onToggle: toggleAddItemsModal } = useDisclosure();

  const onDeleteAssortment = () => {
    handleDeleteMgAssortment(selectedAssortment.id)
      .then(() => {
        toast.success(t('assortment.admin.delete.success'));
        navigate(redirectOnDelete);
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
        toggleDeleteModal();
      });
  };

  const onAddItems = (mgItemNumbers: number[]) => {
    handleAddItemsToMgAssortment({
      assortmentId: selectedAssortment.id,
      mgItemNumbers: mgItemNumbers,
    })
      .then(() => {
        toast.success(t('assortment.admin.update.success', { name: selectedAssortment.title }));
        refetch();
        toggleAddItemsModal();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
        toggleAddItemsModal();
      });
  };

  const onUpdateTitle = (title: string) => {
    handlePatchTitleOrItemsOfMgAssortment({
      assortmentId: selectedAssortment.id,
      title,
    })
      .then(() => {
        toggleEditNameModal();
        refetch();
        queryClient.invalidateQueries({ queryKey: [assortmentQuery] });
        toast.success(t('assortment.admin.update.assortmentSucess'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
      });
  };

  // const onExportAssortment = useCallback(
  //   () =>
  //     exportAssortmentToExcel(selectedAssortment.id)
  //       .then(() => {
  //         toast.success(t('assortment.export.success'));
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         toast.error(t('assortment.export.error'));
  //       }),
  //   [selectedAssortment.id, exportAssortmentToExcel, t, toast]
  // );

  return (
    <Stack spacing={1} flexGrow={1}>
      <LoadingBackdrop isLoading={isFetching} />

      <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
        <Stack direction="row" gap={2} pl={1}>
          <ToggleSwitch
            toggle={toggleVat}
            setToggle={setToggleVat}
            text={t('assortment.vat.excluded')}
          />
          <ToggleSwitch
            toggle={toggleSimpleView}
            setToggle={setToggleSimpleView}
            text={t('assortment.assortmentTable.simpleView')}
          />
        </Stack>

        <EditAssortmentMenu
          onAddFromFileClick={toggleAddItemsModal}
          onEditNameClick={toggleEditNameModal}
          onDeleteClick={toggleDeleteModal}
        />
      </Stack>

      <MgAssortmentItemsView
        updatePageSize={setPageSize}
        updatePage={setPage}
        totalItems={totalItems}
        pageSize={pageSize}
        page={page}
        items={assortmentItems}
        excludeVat={toggleVat}
        toggleSimpleView={toggleSimpleView}
        competitors={competitors}
        setItemToView={setItemToView}
      />

      <DeleteAssortmentDialog
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        handleConfirmDelete={onDeleteAssortment}
        isLoading={isLoading}
      />
      <EditAssortmentTitleDialog
        isOpen={isEditNameModalOpen}
        onClose={toggleEditNameModal}
        existingTitle={selectedAssortment.title}
        handleConfirmTitle={onUpdateTitle}
        isLoading={isLoading}
      />
      <AddOrReplaceMgItemsFromFileDialog
        isOpen={isAddItemsModalOpen}
        getInvalidItemCodes={handleValidateItemsOnImportedAssortment}
        onClose={toggleAddItemsModal}
        isLoading={isLoading}
        onSubmitAddOrReplace={onAddItems}
      />
    </Stack>
  );
}
