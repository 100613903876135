import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { StoreDTO } from '@retail/retailer/types';
import { useMPEFetch, useMutateMPEFetch } from '@retail/app/hooks';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

export function useFetchCompetitors(orgUnitId: number, queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<StoreDTO[]>({
    method: 'GET',
    queryKey: ['orgUnitCompetitors', orgUnitId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.orgUnits.orgUnit.competitors.getFullLinkPath({ orgUnitId }),
    }),
    ...queryOptions,
  });
}

export function usePostCompetitors(orgUnitId: number, queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<StoreDTO, number[]>({
    token,
    method: 'POST',
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.orgUnits.orgUnit.competitors.getFullLinkPath({ orgUnitId }),
    }),
    ...queryOptions,
  });
}
