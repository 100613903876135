import { Outlet, Route, Routes } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { AppLayout, AuthContainerPage, OrgUnitAccessChecker } from '@retail/app/pages';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import {
  BrandsProvider,
  MyOrgUnitsProvider,
  SelectedOrgUnitProvider,
} from '@retail/app/stores/selected-context';
import * as Sentry from '@sentry/react';
import { mgpRoutes, mgPdmRoutes, BaseRoute } from './Routes';

const routesForMgp = mgpRoutes();
const routesForPdm = mgPdmRoutes();


const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes: React.FC = () => {
  return (
    <SentryRoutes>
      <Route
        path={mgPrisRoutes.root.fullRoutePath}
        element={
          <AuthContainerPage>
            <SuspenseWithSentryErrorBoundary>
              <BrandsProvider>
                <MyOrgUnitsProvider>
                  <OrgUnitAccessChecker>
                    <SelectedOrgUnitProvider>
                      <AppLayout>
                        <Outlet />
                      </AppLayout>
                    </SelectedOrgUnitProvider>
                  </OrgUnitAccessChecker>
                </MyOrgUnitsProvider>
              </BrandsProvider>
            </SuspenseWithSentryErrorBoundary>
          </AuthContainerPage>
        }
      >
        {/* All following routes are only shown to authorized users, due to AuthContainerPage */}
        <Route path="" element={<BaseRoute />} />
        {routesForMgp}
        {routesForPdm}
      </Route>
    </SentryRoutes>
  );
};
