import { nbNO, svSE } from '@mui/material/locale';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { nbNO as dataGridNbNO, svSE as dataGridSvSE } from '@mui/x-data-grid/locales';
import { getTogglableDataGridColumns } from '@shared/utils';

export type MgPrisLocale = 'no' | 'se';

export const getMuiLocalization = (language: MgPrisLocale) => {
  return language === 'no' ? nbNO : svSE;
};

export const getDataGridLocalization = (language: MgPrisLocale) => {
  return language === 'se'
    ? dataGridSvSE.components.MuiDataGrid.defaultProps.localeText
    : dataGridNbNO.components.MuiDataGrid.defaultProps.localeText;
};

export enum DATA_GRID_ID {
  ASSORTMENT_ITEMS_TABLE = 'DATA_GRID_ASSORTMENT_ITEMS',
  ASSORTMENT_PRICE_CHANGES_TABLE = 'DATA_GRID_ASSORTMENT_PRICE_CHANGES',

  SUPPLIERS_TABLE = 'DATA_GRID_SUPPLIERS',
  MG_SUPPLIER_ITEMS_TABLE = 'DATA_GRID_MG_SUPPLIER_ITEMS',

  CALCULUS_ITEMS_TABLE = 'DATA_GRID_CALCULUS_ITEMS',
  CALCULUS_PURCHASE_TABLE = 'DATA_GRID_CALCULUS_PURCHASE',
  CALCULUS_FREIGHT_TABLE = 'DATA_GRID_CALCULUS_FREIGHT',
  CALCULUS_MARKUP_TABLE = 'DATA_GRID_CALCULUS_MARKUP',

  DRAFT_DRAWER = 'DATA_GRID_DRAFT_DRAWER',
  DRAFT_DRAWER_ITEMS = 'DATA_GRID_DRAFT_DRAWER_ITEMS',
  DRAFT_DRAWER_PURCHASE = 'DATA_GRID_DRAFT_DRAWER_PURCHASE',
  DRAFT_DRAWER_FREIGHT = 'DATA_GRID_DRAFT_DRAWER_FREIGHT',
  DRAFT_DRAWER_MARKUP = 'DATA_GRID_DRAFT_DRAWER_MARKUP',

  EXPORT_TABLE = 'EXPORT',
}

/**
 * Remember values for slotProps, slots, onColumnVisibilityModelChange and initialState.
 * Check for instance AssortmentItemsTable.tsx
 * */
export const saveHiddenDataGridColumnsToLocalStorage = (
  dataGridId: DATA_GRID_ID,
  toggableColumns: string[],
  columnVisibilityModel: GridColumnVisibilityModel
) => {
  const filteredColumns = toggableColumns.filter(
    (column) => columnVisibilityModel[column] === false
  );

  localStorage.setItem(dataGridId, JSON.stringify(filteredColumns));
};

export const getInitialHiddenDataGridColumnsFromLocalStorage = (
  dataGridId: DATA_GRID_ID,
  toggableColumns: string[]
): GridColumnVisibilityModel => {
  const columns = localStorage.getItem(dataGridId);

  if (!columns) {
    const initialModel = toggableColumns.reduce<GridColumnVisibilityModel>((acc, column) => {
      acc[column] = true;
      return acc;
    }, {});

    saveHiddenDataGridColumnsToLocalStorage(dataGridId, toggableColumns, initialModel);

    return initialModel;
  }

  try {
    const parsedColumns: string[] = JSON.parse(columns);

    if (parsedColumns?.length === toggableColumns.length) {
      return toggableColumns.reduce<GridColumnVisibilityModel>((acc, column) => {
        acc[column] = true;
        return acc;
      }, {});
    }

    if (Array.isArray(parsedColumns)) {
      return toggableColumns.reduce<GridColumnVisibilityModel>((acc, column) => {
        acc[column] = !parsedColumns.includes(column);
        return acc;
      }, {});
    }
  } catch (error) {
    console.error('Error parsing localStorage columns:', error);
  }

  // Default: all columns visible
  return toggableColumns.reduce<GridColumnVisibilityModel>((acc, column) => {
    acc[column] = true;
    return acc;
  }, {});
};

export const columnsManagementSlotProps = {
  columnsManagement: {
    getTogglableColumns: getTogglableDataGridColumns,
    disableShowHideToggle: true,
  },
};
