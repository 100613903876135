import { useUserBrands } from './BrandContext';
import { FeatureContext } from '@retail/mgpris/config-types';
import { useMemo } from 'react';

export const useFeatureContext = (): FeatureContext => {
  const { selectedBrand } = useUserBrands();

  return useMemo(
    () => ({ locationType: selectedBrand.wholesaler ? 'wholesale' : 'retail' }),
    [selectedBrand]
  );
};
