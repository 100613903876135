import { useCallback, useMemo } from 'react';
import { NavigationTreeView, TreeNode } from '@shared/components';
import { NodeLabel } from './NodeLabel';
import { OverGroup, ProductGroup } from '@retail/products/types';

interface Props {
  productGroups: OverGroup[];
  selectedProductGroup: ProductGroup | null;
  getCount?: (productGroup: ProductGroup) => number | undefined;
  selectProductGroup: (productGroup: string | null) => void;
}

export const ProductGroupsTreeView = ({
  productGroups,
  selectedProductGroup,
  getCount,
  selectProductGroup,
}: Props) => {
  const shouldDefaultExpand = useCallback(
    (productGroupId: string) =>
      !!selectedProductGroup &&
      ((selectedProductGroup.groupNumber.length > 3 &&
        selectedProductGroup.groupNumber.slice(0, 3) === productGroupId) ||
        (selectedProductGroup.groupNumber.length > 2 &&
          selectedProductGroup.groupNumber.slice(0, 2) === productGroupId)),
    [selectedProductGroup]
  );

  const treeNodes: TreeNode[] = useMemo(() => {
    const overGroupsNodes = productGroups.map((productGroup) => ({
      id: productGroup.groupNumber,
      label: <NodeLabel {...productGroup} count={getCount && getCount(productGroup)} />,
      onSelect: () =>
        selectProductGroup(
          productGroup.groupNumber === selectedProductGroup?.groupNumber
            ? null
            : productGroup.groupNumber
        ),

      defaultExpanded: shouldDefaultExpand(productGroup.groupNumber),

      childNodes: productGroup.subGroups?.map((productGroup) => ({
        id: productGroup.groupNumber,
        label: <NodeLabel {...productGroup} count={getCount && getCount(productGroup)} />,
        onSelect: () =>
          selectProductGroup(
            productGroup.groupNumber === selectedProductGroup?.groupNumber
              ? null
              : productGroup.groupNumber
          ),
        defaultExpanded: shouldDefaultExpand(productGroup.groupNumber),

        childNodes: productGroup.subGroups?.map((productGroup) => ({
          id: productGroup.groupNumber,
          label: <NodeLabel {...productGroup} count={getCount && getCount(productGroup)} />,
          onSelect: () => {
            selectProductGroup(
              productGroup.groupNumber === selectedProductGroup?.groupNumber
                ? null
                : productGroup.groupNumber
            );
          },
          defaultExpanded: shouldDefaultExpand(productGroup.groupNumber),
        })),
      })),
    }));

    return overGroupsNodes;
  }, [productGroups, getCount, shouldDefaultExpand, selectProductGroup, selectedProductGroup]);

  return (
    <NavigationTreeView
      nodes={treeNodes}
      selectedNodeId={selectedProductGroup?.groupNumber ?? null}
    />
  );
};
