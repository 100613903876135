import { Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { ProductGroup, SupplierWithProductGroups } from '@retail/calculus/types';
import { ProductGroup as BaseProductGroup, OverGroup } from '@retail/products/types';
import { ProductGroupFilter, SideFilters, ToggleSwitch } from '@retail/components';
import { MgSupplierItemSearchField } from '@retail/products/components';
import { useDisclosure } from '@shared/hooks';
import { useCallback, useMemo } from 'react';

interface Props {
  supplierWithProductGroups: SupplierWithProductGroups;
  selectedProductGroup: ProductGroup | null;
  inAssortment: boolean;
  searchQuery: string;
  showFilters: boolean;
  selectProductGroupId: (option: string | null) => void;
  setInAssortment: (inAssortment: boolean) => void;
  setSearchQuery: (query: string) => void;
  closeFilters: () => void;
}

export function ItemPricesFilters({
  supplierWithProductGroups,
  selectedProductGroup,
  inAssortment,
  searchQuery,
  showFilters,
  selectProductGroupId,
  setInAssortment,
  setSearchQuery,
  closeFilters,
}: Props) {
  const t = useAppTFunction();

  const { isOpen: showProductGroups, onToggle: toggleShowProductGroups } = useDisclosure(true);

  const getProductGroupCount = useCallback(
    (productGroup: BaseProductGroup) => productGroup.numberOfItems,
    []
  );

  const baseProductGroups = useMemo(
    () => supplierWithProductGroups.productGroups.map(OverGroup.fromDto),
    [supplierWithProductGroups.productGroups]
  );

  const numberOfItems = useMemo(
    () =>
      supplierWithProductGroups.numberOfItems === 1
        ? t('calculus.oneItem', { count: supplierWithProductGroups.numberOfItems })
        : t('calculus.nrOfItems', { count: supplierWithProductGroups.numberOfItems }),
    [supplierWithProductGroups.numberOfItems, t]
  );

  return (
    <SideFilters
      heading={supplierWithProductGroups.mgSupplierName}
      subHeading={numberOfItems}
      showFilters={showFilters}
      closeFilters={closeFilters}
    >
      <ToggleSwitch
        text={t('purchaseAgreements.priceList.inAssortment')}
        justifyContent="space-between"
        toggle={inAssortment}
        setToggle={() => setInAssortment(!inAssortment)}
      />
      <Stack gap={0.5}>
        <Typography variant="body2" color="text.secondary">
          {t('myAssortment.filters.itemSearch')}
        </Typography>
        <MgSupplierItemSearchField
          isDisabled={false}
          searchTerm={searchQuery}
          onSubmitSearch={setSearchQuery}
          size="small"
          width={310}
          includeLabel={false}
        />
      </Stack>
      <ProductGroupFilter
        productGroups={baseProductGroups}
        selectedProductGroup={selectedProductGroup as BaseProductGroup | null}
        showProductGroups={showProductGroups}
        toggleShowProductGroups={toggleShowProductGroups}
        selectProductGroup={selectProductGroupId}
        getCount={getProductGroupCount}
      />
    </SideFilters>
  );
}
