import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { PaginationResultDTO } from '@retail/products/types';
import { ItemAgreementDTO } from '@retail/purchase-agreements/types';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { useMPEFetch } from '@retail/app/hooks';
import { mpeApi } from '@retail/mgpris/config';

interface ApiProps {
  supplierId: number;
  assortmentId: number;
  mgItemId: number;
  current?: boolean;
}

export function useFetchItemAgreements(
  { supplierId, assortmentId, mgItemId, current = true }: ApiProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PaginationResultDTO<ItemAgreementDTO[]>>({
    method: 'GET',
    queryKey: ['item-agreements', supplierId, mgItemId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.suppliers.supplier.items.item.agreementPrices.getFullLinkPath({
        supplierId,
        mgItemNumber: mgItemId,
      }),
      searchParams: {
        assortmentId,
        current,
      },
    }),
    ...queryOptions,
  });
}
