import { Breakpoint, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Dialog, Paper } from '@shared/components';
import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  header: string;
  subheader?: string | React.ReactNode;
  open: boolean;
  width?: Breakpoint;
  fullHeight?: boolean;
  onClose: () => void;
}

export function DialogLayout({
  children,
  header,
  onClose,
  open,
  width,
  fullHeight = false,
  subheader,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={width}
      sx={{
        '& .MuiPaper-root': {
          height: fullHeight ? '100%' : 'fit-content',
        },
      }}
    >
      <Stack component={DialogTitle} gap={2}>
        {header}
        {subheader && <Stack>{subheader}</Stack>}
      </Stack>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Paper variant="outlined" sx={{ p: 0, bgcolor: ({ palette }) => palette.grey[50] }}>
          {children}
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
