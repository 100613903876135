import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { useFetchBrandAssortments } from '@retail/my-assortment/data-access';
import { AssortmentTypeDTO } from '@retail/my-assortment/types';
import { useMemo } from 'react';

export const useBrandAssortments = () => {
  const { selectedBrandContext } = useSelectedOrgUnit();
  const brandId = selectedBrandContext!.brandId;

  const { data: assortments = [] } = useFetchBrandAssortments({ brandId });

  const baseAssortment = useMemo(
    () => assortments.find((assortment) => assortment.type === AssortmentTypeDTO.BASE)!,
    [assortments]
  );
  const draftAssortment = useMemo(
    () => assortments.find((assortment) => assortment.type === AssortmentTypeDTO.DRAFT)!,
    [assortments]
  );

  const monitoringAssortments = useMemo(
    () =>
      assortments
        .filter(({ type }) => type === AssortmentTypeDTO.MONITORING)
        .map((dto) => new MonitoringAssortment(dto)),
    [assortments]
  );

  return {
    brandId,
    draftAssortment,
    baseAssortment,
    monitoringAssortments,
    draftAssortmentId: draftAssortment.id,
    baseAssortmentId: baseAssortment.id,
  };
};
