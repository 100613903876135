import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ActiveTagPaper } from '@retail/components';
import { useAppTFunction } from '@retail/app/i18n';
import { MarkupCondition } from '@retail/products/types';
import { InfinityIcon } from '@shared/custom-icons';

interface Props {
  markupCondition?: MarkupCondition;
  accordionSummary: JSX.Element;
}

export function MarkupAccordion({ markupCondition, accordionSummary }: Props) {
  const t = useAppTFunction();

  return (
    <Accordion>
      <AccordionSummary>{accordionSummary}</AccordionSummary>
      <AccordionDetails>
        <ActiveTagPaper>
          {markupCondition ? (
            <Grid container gap={2} flexDirection="column">
              <Stack gap={3} flexDirection="row" fontSize={14}>
                <Grid item fontWeight={600}>
                  {t('markup.markupName')}
                </Grid>
                <Grid item>{markupCondition.templateName || t('markup.defaultMarkup')}</Grid>
              </Stack>
              <Stack gap={4} flexDirection="row" fontSize={14}>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('markup.fromDate')}
                  </Grid>
                  <Grid item>{dayjs(markupCondition.condition.validFrom).format('ll')}</Grid>
                </Stack>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('markup.toDate')}
                  </Grid>
                  <Grid item>
                    {markupCondition.condition.validTo ? (
                      dayjs(markupCondition.condition.validTo).format('ll')
                    ) : (
                      <InfinityIcon />
                    )}
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Typography>{t('myAssortment.productCard.noActiveMarkupCondition')}</Typography>
          )}
        </ActiveTagPaper>
      </AccordionDetails>
    </Accordion>
  );
}
