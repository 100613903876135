import { SvgIcon, SvgIconProps } from '@mui/material';

export default function InfinityIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.6 6.62C21.58 6.62 24 9 24 12C24 14.96 21.58 17.37 18.6 17.37C17.15 17.37 15.8 16.81 14.78 15.8L12 13.34L9.17 15.85C8.2 16.82 6.84 17.38 5.4 17.38C2.42 17.38 0 14.96 0 12C0 9.04 2.42 6.62 5.4 6.62C6.84 6.62 8.2 7.18 9.22 8.2L12 10.66L14.83 8.14999C15.8 7.17999 17.16 6.62 18.6 6.62ZM7.8 14.39L10.5 12L7.84 9.64999C7.16 8.96999 6.31 8.62 5.4 8.62C3.53 8.62 2 10.13 2 12C2 13.87 3.53 15.38 5.4 15.38C6.31 15.38 7.16 15.03 7.8 14.39ZM16.2 9.60999L13.5 12L16.16 14.35C16.84 15.03 17.7 15.38 18.6 15.38C20.47 15.38 22 13.87 22 12C22 10.13 20.47 8.62 18.6 8.62C17.69 8.62 16.84 8.97 16.2 9.60999Z"
          fill="#3E3F5B"
        />
      </svg>
    </SvgIcon>
  );
}
