import { MgSalesPackageClassCode } from '@retail/products/types';
import React, { useMemo } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '@retail/products/utils';
import { SalesPriceInput } from '../SalesPriceSummary/SalesPriceInput';
import { FieldValueView } from '../FieldValueView';
import { useAppTFunction } from '@retail/app/i18n';
import { EditFieldButton } from '../EditFieldButton';
import { DiscountInput } from '../Calculus';
import { StackedTextCell } from '@retail/components';
import { SalesCoverageInput } from '../SalesPriceSummary/SalesCoverageInput';
import { SalesCoverageValueView } from '../SalesPriceSummary/SalesCoverageValueView';
import { dataGridHorizontalPaddingXSClass } from '@shared/styles';
import { BracketPriceEditState, EditableBracketMarkupField } from './store';

interface NewValue {
  value: number;
  isApproximate: boolean;
}

type MarkupState = {
  currentValue?: number;
  newValue?: NewValue;
  onChange?: (value: number) => void;
  editState: BracketPriceEditState;
};

export interface BracketPackagePrice {
  packageClassCode: MgSalesPackageClassCode;
  unit: string;
  id: number | string;
  consistsOfCount: number;
  salesPrice: MarkupState;
  discount?: MarkupState;
  salesPriceCoverage: Omit<MarkupState, 'editState'>;
  editingField?: EditableBracketMarkupField;
  setEditingField: (field: EditableBracketMarkupField | undefined) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export interface BracketPriceTableProps {
  packagePrices: BracketPackagePrice[];
  baseUnit: string;
  editable?: boolean;
}

export const BracketPriceTable: React.FC<BracketPriceTableProps> = ({
  packagePrices,
  baseUnit,
  editable = false,
}) => {
  const t = useAppTFunction();
  const {
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<BracketPackagePrice>[] = useMemo(
    () => [
      {
        field: 'package',
        headerName: t('products.mgItem.supplierItem.package'),
        flex: 0.7,
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.packageClassCode} bottomLine={row.unit} />
        ),
      },
      {
        field: 'consistsOfCount',
        headerName: t('products.mgItem.supplierItem.consistsOf'),
        flex: 0.8,
        renderCell: ({ value }) => `${value} ${baseUnit}`,
      },
      {
        field: 'salesPrice',
        headerName: t('products.mgItem.supplierItem.salesPriceInFPack'),
        flex: 1,
        renderCell: ({
          row: {
            salesPrice: { currentValue, newValue, onChange, editState },
            editingField,
            setEditingField,
          },
        }) => {
          if (editingField === 'price' && editable) {
            return (
              <SalesPriceInput
                defaultValue={newValue?.value || currentValue || 0}
                onSubmit={onChange || noop}
                onCancel={() => setEditingField(undefined)}
                errorPlacement="tooltipBottom"
              />
            );
          }

          return (
            <Stack direction="row" alignItems="center">
              <FieldValueView
                oldValue={t('myAssortment.recommendedPrices.value', {
                  value: currentValue ? formatPrice(currentValue, language) : '-',
                })}
                newValue={t('myAssortment.recommendedPrices.value', {
                  value: newValue?.value ? formatPrice(newValue.value, language) : '-',
                })}
                hasNewValue={!!newValue && newValue.value !== currentValue}
                approximatelyEqual={newValue?.isApproximate}
              />
              {onChange && editable && (
                <EditFieldButton
                  onEdit={() => setEditingField('price')}
                  disabled={editState !== 'editable'}
                  title={
                    editState !== 'editable'
                      ? t(`myAssortment.productCard.salesPrice.error.${editState}`)
                      : undefined
                  }
                />
              )}
            </Stack>
          );
        },
      },
      {
        field: 'salesPriceCoverage',
        headerName: t('myAssortment.columns.salesCoverage'),
        flex: 0.9,
        renderCell: ({
          row: {
            salesPriceCoverage: { onChange, newValue, currentValue },
            editingField,
            setEditingField,
          },
        }) => {
          if (editingField === 'coverage' && editable) {
            return (
              <SalesCoverageInput
                defaultValue={newValue?.value || currentValue || 0}
                onSubmit={onChange || noop}
                onCancel={() => setEditingField(undefined)}
              />
            );
          }
          return (
            <SalesCoverageValueView
              openEditMode={() => setEditingField('coverage')}
              newSalesCoverage={newValue}
              currentSalesCoverage={currentValue}
              maximumFractionDigits={2}
              editable={editable}
            />
          );
        },
      },
      {
        field: 'discount',
        headerName: t('myAssortment.productCard.discount'),
        flex: 0.9,
        renderCell: ({ row: { packageClassCode, discount, editingField, setEditingField } }) => {
          if (packageClassCode === 'F-PAK' || !discount) return '-';

          const { currentValue, newValue, editState, onChange } = discount;
          if (editingField === 'discount' && editable) {
            return (
              <DiscountInput
                defaultValue={newValue?.value || currentValue || 0}
                onSubmit={onChange || noop}
                onCancel={() => setEditingField(undefined)}
                errorPlacement="tooltipBottom"
              />
            );
          }

          return (
            <Stack flexDirection="row" alignItems="center">
              <FieldValueView
                oldValue={
                  currentValue !== undefined && currentValue !== null
                    ? `${currentValue.toLocaleString(language, { maximumFractionDigits: 2 })}%`
                    : '-'
                }
                newValue={
                  newValue?.value
                    ? `${newValue.value.toLocaleString(language, { maximumFractionDigits: 2 })}%`
                    : '-'
                }
                hasNewValue={!!newValue && newValue.value !== currentValue}
                approximatelyEqual={newValue?.isApproximate}
              />
              {onChange && editable && (
                <EditFieldButton
                  onEdit={() => setEditingField('discount')}
                  disabled={editState !== 'editable'}
                  title={
                    editState !== 'editable'
                      ? t(`myAssortment.productCard.salesPrice.error.${editState}`)
                      : undefined
                  }
                />
              )}
            </Stack>
          );
        },
      },
    ],
    [baseUnit, editable, language, t]
  );

  return (
    <Stack>
      <DataGridPremium
        columns={columns}
        rows={packagePrices}
        rowHeight={80}
        disableColumnResize
        disableColumnMenu
        disableColumnSorting
        disableRowSelectionOnClick
        hideFooter
        disableVirtualization
        scrollbarSize={0}
        className={dataGridHorizontalPaddingXSClass}
      />
    </Stack>
  );
};
