import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { Supplier } from '@retail/suppliers/types';
import { ProductGroup } from '@retail/products/types';
import { FilterChip, RemoveAllFiltersChip } from '@retail/components';

interface Props {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedSupplier?: Supplier;
  setSelectedSupplier?: (supplierId: number | null) => void;
  selectedProductGroup: ProductGroup | null;
  selectProductGroupId: (productGroup: string | null) => void;
}

export function SearchFilterChips({
  searchQuery,
  setSearchQuery,
  selectedSupplier,
  setSelectedSupplier,
  selectedProductGroup,
  selectProductGroupId,
}: Props) {
  const resetFilters = useCallback(() => {
    setSearchQuery('');
    selectProductGroupId(null);
    setSelectedSupplier && setSelectedSupplier(null);
  }, [setSearchQuery, selectProductGroupId, setSelectedSupplier]);

  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      {searchQuery && (
        <FilterChip id={searchQuery} name={searchQuery} onDelete={() => setSearchQuery('')} />
      )}
      {selectedProductGroup && (
        <FilterChip
          id={selectedProductGroup.groupNumber}
          name={`${selectedProductGroup.groupNumber} ${selectedProductGroup.name}`}
          onDelete={() => selectProductGroupId(null)}
        />
      )}
      {selectedSupplier && setSelectedSupplier && (
        <FilterChip
          id={selectedSupplier.mgSupplierId}
          name={selectedSupplier.name}
          onDelete={() => setSelectedSupplier(null)}
        />
      )}

      {(searchQuery || selectedProductGroup || selectedSupplier) && (
        <RemoveAllFiltersChip resetFilters={resetFilters} />
      )}
    </Stack>
  );
}
