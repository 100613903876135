import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          d="M15 7.5V22.5M22.5 15L7.5 15"
          strokeWidth="2"
          strokeLinejoin="round"
          strokeLinecap="round"
          stroke={props.color}
        />
      </svg>
    </SvgIcon>
  );
};
