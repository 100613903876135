import { useFormContext } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';
import { useCallback, useEffect } from 'react';
import { usePurchaseActions } from '@retail/purchase-agreements/context';
import { useAppTFunction } from '@retail/app/i18n';
import { PriceType } from '@retail/calculus/types';
import { ActivateAgreementSchema } from '@retail/purchase-agreements/components';
import { useLevelSelect } from '@retail/calculus/context';
import { ActivateAgreementForm } from './ActivateAgreementForm';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { withDialogSuspenseErrorBoundary } from '@retail/components';

interface Props {
  open: boolean;
  onClose: () => void;
  agreements: PurchaseAgreement[];
  supplierId: number;
}

function ActivateAgreementDialog({ open, agreements, onClose, supplierId }: Props) {
  const t = useAppTFunction();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    clearErrors,
  } = useFormContext<ActivateAgreementSchema>();

  const level = watch('level');
  const itemSearch = watch('itemSearch');

  const { productGroups, mgSupplierItems, isLoadingFetchItems } = useLevelSelect({
    supplierId,
    selectedProductGroup: level === 'productGroup',
    selectedDiscountGroup: level === 'discountGroup',
    itemSearch,
    onItemSearchSuccess: (results) => {
      if (!results.result.length) {
        setError('item', { message: t('calculus.error.itemSearch.noResults') });
      }
    },
    onItemSearchError: (err) => {
      console.error(err);
      setError('item', { message: t('calculus.error.itemSearch.generic') });
    },
  });

  useEffect(() => {
    clearErrors('item');
  }, [clearErrors, itemSearch]);

  const { activateOrChangeCondition, isLoadingPostChanges } = usePurchaseActions({
    mgSupplierId: supplierId,
  });

  const submitAgreements = useCallback(
    (data: ActivateAgreementSchema) => {
      activateOrChangeCondition({
        agreementId: Number(data.agreementId),
        groupNumber:
          data.productGroup?.groupNumber ??
          data.mainGroup?.groupNumber ??
          data.overGroup?.groupNumber,
        mgItemNumber: data.item?.mgItemNumber,
        supplierLevel: data.level === 'supplier',
        fromDate: data.fromDate,
        priceType: data.priceType as PriceType,
      });

      reset();
      onClose();
    },
    [activateOrChangeCondition, reset, onClose]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>
        {t('purchaseAgreements.agreement.activateAgreement')}
      </DialogTitle>

      <DialogContent>
        <ActivateAgreementForm
          productGroups={productGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          agreements={agreements}
          supplierId={supplierId}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', mt: 1 }}>
        <ContainedButton
          disabled={Object.keys(errors).length > 0}
          loading={isLoadingPostChanges}
          onClick={handleSubmit(submitAgreements)}
        >
          {t('purchaseAgreements.agreement.actions.setToApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(ActivateAgreementDialog);
