import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { Stack } from '@mui/material';
import { Supplier } from '@retail/suppliers/types';
import { DepartmentMarkupContainer } from './DepartmentMarkupContainer';
import { ConditionState } from '@retail/calculus/types';

interface Props {
  selectedSupplier: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  closeCreate: () => void;
}

export function MarkupContainer({
  selectedSupplier,
  typesToShow,
  isCreateOpen,
  closeCreate,
}: Props) {
  const { selectedDepartment } = useSelectedOrgUnit();

  return (
    <Stack flexGrow={1}>
      {selectedDepartment ? (
        <SuspenseWithSentryErrorBoundary>
          <DepartmentMarkupContainer
            supplier={selectedSupplier}
            typesToShow={typesToShow}
            isCreateOpen={isCreateOpen}
            closeCreate={closeCreate}
          />
        </SuspenseWithSentryErrorBoundary>
      ) : (
        <div>brandContext</div>
      )}
    </Stack>
  );
}
