import { formatPrice } from '@retail/products/utils';
import { StackedTextCell } from '@retail/components';
import { Box } from '@mui/material';
import { ItemStatus } from './ItemStatus/ItemStatus';
import dayjs from 'dayjs';
import { FC } from 'react';
import { MgPackageClassCode, PriceCalculation } from '@retail/products/types';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { useTranslation } from 'react-i18next';
import { PricePerUnit } from '@retail/products/components';

interface Props {
  priceCalculation: PriceCalculation | undefined;
  includeVat: boolean;
  bracketClassCode: MgPackageClassCode;
  centerAlign?: boolean;
}

export const BracketPriceCell: FC<Props> = ({
  priceCalculation,
  includeVat,
  bracketClassCode,
  centerAlign = true,
}) => {
  const { t } = useMyAssortmentTranslation();
  const { i18n } = useTranslation();

  const bracketPackagePrice = priceCalculation?.getBracketPackagePrice(bracketClassCode);

  const bracketSalesPriceInFpak = bracketPackagePrice?.bracketMarkupPrice.packageSalesPrices?.find(
    ({ supplierItemPackage }) => supplierItemPackage.classCode === 'F-PAK'
  );

  const price = bracketSalesPriceInFpak
    ? formatPrice(
        includeVat
          ? bracketSalesPriceInFpak?.price.priceWithVat
          : bracketSalesPriceInFpak?.price.priceWithoutVat,
        i18n.language
      )
    : '-';

  const unitDescription = bracketSalesPriceInFpak?.supplierItemPackage.unit.description;

  const pricePerUnitText = bracketSalesPriceInFpak ? (
    <PricePerUnit
      price={price}
      unit={bracketSalesPriceInFpak.supplierItemPackage.unit.unit}
      description={unitDescription}
    />
  ) : (
    '-'
  );

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      {bracketPackagePrice && bracketSalesPriceInFpak && (
        <ItemStatus status={bracketPackagePrice.bracketMarkupPrice.status} />
      )}
      <StackedTextCell
        topLine={pricePerUnitText}
        nullableBottomLine
        bottomLine={
          bracketPackagePrice &&
          bracketSalesPriceInFpak &&
          dayjs(bracketPackagePrice.validFrom).format('ll')
        }
      />
    </Box>
  );
};
