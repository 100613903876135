export enum CalculusAreas {
  PURCHASE = 'PURCHASE',
  FREIGHT = 'FREIGHT',
  MARKUP = 'MARKUP',
}

export enum CalculusTabOptions {
  PURCHASE = 'PURCHASE',
  FREIGHT = 'FREIGHT',
  MARKUP = 'MARKUP',
  ITEMS = 'ITEMS',
}

export enum CalculusDraftTabOptions {
  CONDITIONS = 'CONDITIONS',
  ITEMS = 'ITEMS',
}
