import {
  FreightCondition,
  MarkupCondition,
  Operation,
  PurchaseCondition,
} from '@retail/calculus/types';
import { StackedTableCellWithChangeInValue } from '@retail/components';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { isPurchaseCondition, isFreightCondition, isMarkupCondition } from '@retail/calculus/utils';
import { calculateSalesCoverage } from '@retail/utils';
import { ConditionRow } from './DraftDrawerTableContent';

interface Props {
  condition: ConditionRow;
}

export const ChangeType = ({ condition }: Props) => {
  const t = useMarkupTFunction();

  const oldCondition = condition.getPreviousCondition();
  const newCondition = condition;

  const getPurchaseText = (condition: PurchaseCondition) =>
    `${condition.agreementText} (${condition.agreementId})`;

  const getFreightText = (condition: FreightCondition) =>
    condition.percentAddon
      ? `${condition.percentAddon}%`
      : `oldCondition.percentAddon ?? oldCondition?.freightFactor`;

  const getMarkupText = (condition: MarkupCondition) =>
    `${condition.salesFactor} (${calculateSalesCoverage(condition.salesFactor)} ${t(
      'markup.salesCoverage'
    )})`;

  const prevValue = !oldCondition
    ? '-'
    : isPurchaseCondition(oldCondition)
    ? getPurchaseText(oldCondition)
    : isFreightCondition(oldCondition)
    ? getFreightText(oldCondition)
    : isMarkupCondition(oldCondition)
    ? getMarkupText(oldCondition)
    : '';

  const newValue = !newCondition
    ? '-'
    : isPurchaseCondition(newCondition)
    ? getPurchaseText(newCondition)
    : isFreightCondition(newCondition)
    ? getFreightText(newCondition)
    : isMarkupCondition(newCondition)
    ? getMarkupText(newCondition)
    : '';

  return (
    <StackedTableCellWithChangeInValue
      prevValue={prevValue}
      newValue={newValue}
      lineThrough={condition.operation === Operation.DELETE}
      centered={false}
    />
  );
};
