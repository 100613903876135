import { Typography } from '@mui/material';
import React from 'react';

const NoPrice: React.FC = () => {
  return (
    <Typography fontWeight={600} fontSize="inherit">
      {'-'}
    </Typography>
  );
};

export default NoPrice;
