import {
  BrandAssortmentPage,
  CalculusForSupplierPage,
  CompetitorsPage,
  ExportPage,
  FeatureTogglesPage,
  ItemPage,
  MarketInsightPage,
  MgItemPage,
  MgSupplierItemSearchPage,
  MonitoringAssortmentPage,
  MonitoringAssortmentsPage,
  MyAssortmentPage,
  NotFoundPage,
  PdmMonitoringAssortmentPage,
  SupplierItemSearchPage,
  SuppliersPage,
} from '@retail/app/pages';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { mgPrisRoutes, pdmRoutes } from '@retail/mgpris/config';
import { Navigate, Route } from 'react-router-dom';
import { SuperUserRouteGuard } from './SuperUserRouteGuard';

export const BaseRoute = () => {
  const { selectedBrandContext } = useSelectedOrgUnit();

  return selectedBrandContext ? (
    <Navigate to={pdmRoutes.brandAssortment.fullRoutePath} replace />
  ) : (
    <Navigate to={mgPrisRoutes.myAssortment.fullRoutePath} replace />
  );
};

export const mgpRoutes = () => (
  <>
    <Route
      path={mgPrisRoutes.marketInsight.assortments.fullRoutePath}
      element={<Navigate to={mgPrisRoutes.marketInsight.fullRoutePath} replace />}
    />

    <Route
      path={mgPrisRoutes.marketInsight.assortments.assortment.fullRoutePath}
      element={<MonitoringAssortmentPage />}
    />

    <Route path={mgPrisRoutes.items.fullRoutePath} element={<MgSupplierItemSearchPage />} />

    <Route path={mgPrisRoutes.items.item.fullRoutePath} element={<MgItemPage />} />

    <Route path={mgPrisRoutes.myAssortment.fullRoutePath} element={<MyAssortmentPage />} />
    <Route path={mgPrisRoutes.calculus.fullRoutePath} element={<CalculusForSupplierPage />} />
    <Route
      path={mgPrisRoutes.calculus.supplier.fullRoutePath}
      element={<CalculusForSupplierPage />}
    />

    <Route path={mgPrisRoutes.suppliers.fullRoutePath} element={<SuppliersPage />} />

    <Route path={mgPrisRoutes.marketInsight.fullRoutePath} element={<MarketInsightPage />} />
    <Route
      path={mgPrisRoutes.marketInsight.competitors.fullRoutePath}
      element={
        <CompetitorsPage marketInsightRoutePath={mgPrisRoutes.marketInsight.getFullLinkPath()} />
      }
    />
    <Route path={mgPrisRoutes.export.fullRoutePath} element={<ExportPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </>
);

export const mgPdmRoutes = () => (
  <>
    <Route path={pdmRoutes.brandAssortment.fullRoutePath} element={<BrandAssortmentPage />} />

    <Route
      path={pdmRoutes.marketInsight.assortments.fullRoutePath}
      element={<Navigate to={pdmRoutes.marketInsight.fullRoutePath} replace />}
    />

    <Route path={pdmRoutes.marketInsight.fullRoutePath} element={<MonitoringAssortmentsPage />} />
    <Route
      path={pdmRoutes.marketInsight.assortments.assortment.fullRoutePath}
      element={<PdmMonitoringAssortmentPage />}
    />
    <Route
      path={pdmRoutes.marketInsight.competitors.fullRoutePath}
      element={
        <CompetitorsPage marketInsightRoutePath={pdmRoutes.marketInsight.getFullLinkPath()} />
      }
    />

    <Route path={pdmRoutes.items.fullRoutePath} element={<SupplierItemSearchPage />} />

    <Route path={pdmRoutes.items.item.fullRoutePath} element={<ItemPage />} />

    <Route path={pdmRoutes.calculus.fullRoutePath} element={<CalculusForSupplierPage />} />
    <Route
      path={mgPrisRoutes.calculus.supplier.fullRoutePath}
      element={<CalculusForSupplierPage />}
    />

    <Route path={mgPrisRoutes.suppliers.fullRoutePath} element={<SuppliersPage />} />

    <Route
      path={mgPrisRoutes.superuser.features.fullRoutePath}
      element={
        <SuperUserRouteGuard>
          <FeatureTogglesPage />
        </SuperUserRouteGuard>
      }
    />

    <Route path="*" element={<NotFoundPage />} />
  </>
);
