import { Typography } from '@mui/material';
import { StackedTextCell } from '@retail/components';

interface Props {
  oldValue: string | number;
  newValue: string | number;
  hasNewValue: boolean;
  approximatelyEqual?: boolean;
}

export function FieldValueView({ oldValue, newValue, hasNewValue, approximatelyEqual }: Props) {
  return hasNewValue ? (
    <StackedTextCell
      centerAlign
      gap="small"
      topLine={
        <Typography variant="body2">{`${approximatelyEqual ? '≈ ' : ''}${newValue}`}</Typography>
      }
      bottomLine={
        <Typography color="text.secondary" variant="body2" sx={{ textDecoration: 'line-through' }}>
          {oldValue}
        </Typography>
      }
    />
  ) : (
    <Typography variant="body2">{oldValue}</Typography>
  );
}
