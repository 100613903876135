import { IconButton, Stack, Tooltip } from '@mui/material';
import { DeactivateIcon, EditButtonIcon, TrashIcon } from '@shared/custom-icons';
import { useCalculusTFunction } from '@retail/calculus/i18n';

interface Props {
  handleDeactivate?: () => void;
  handleEdit: () => void;
  handleDelete?: () => void;
}

export const MarkupTableActions = ({ handleDeactivate, handleEdit, handleDelete }: Props) => {
  const t = useCalculusTFunction();

  return (
    <Stack flexDirection="row" alignItems="center">
      {handleDeactivate && (
        <Tooltip title={t('calculus.deactivate')}>
          <IconButton onClick={handleDeactivate}>
            <DeactivateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {handleDelete && (
        <Tooltip title={t('calculus.delete')}>
          <IconButton onClick={handleDelete}>
            <TrashIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t('calculus.edit')}>
        <IconButton onClick={handleEdit}>
          <EditButtonIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
