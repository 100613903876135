import { Stack, Typography } from '@mui/material';
import {
  AddItemsMenu,
  MgSupplierItemsTableWithCheckbox,
  SearchFilterChips,
} from '@retail/products/components';
import {
  FilterOptions,
  MgSupplierItemSearchResult,
  SetFilterOptions,
} from '@retail/products/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Pagination as PaginationType, useDisclosure } from '@shared/hooks';
import { useProductsTFunction } from '@retail/products/i18n';
import { useMutateAddItemsToDraft } from '@retail/my-assortment/data-access';
import { useMemberAssortments } from '@retail/my-assortment/context';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useToast } from '@retail/hooks';
import { useRefetchDraftDrawer } from '@retail/context';
import MonitorMgSupplierItemsDialog from './MonitorMgSupplierItemsDialog';
import { useLanguageSelector } from '@retail/app/hooks';
import { Feature, ToggleFiltersButton } from '@retail/components';
import { useNavigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useFeatureContext } from '@retail/app/stores/selected-context';
import { ContainedButton } from '@shared/components';
import { Add } from '@mui/icons-material';

interface Props {
  totalItems: number;
  supplierItems: MgSupplierItemSearchResult[];
  checkedItems: MgSupplierItemSearchResult[];
  includeAddButton: boolean;
  clickableRow?: boolean;
  showFilters: boolean;
  resetFilters: () => void;
  toggleFilters: () => void;
  setCheckedItems: Dispatch<SetStateAction<MgSupplierItemSearchResult[]>>;
  searchFilters: FilterOptions;
  setSearchFilters: SetFilterOptions;
}

export function MgSupplierItemSearchResults({
  totalItems,
  supplierItems,
  checkedItems,
  pageSize,
  page,
  includeAddButton,
  clickableRow,
  showFilters,
  searchFilters,
  setSearchFilters,
  resetFilters,
  toggleFilters,
  setCheckedItems,
  setPage,
  setPageSize,
}: Props & PaginationType) {
  const t = useProductsTFunction();
  const { language } = useLanguageSelector();
  const toast = useToast();
  const { featureFlags } = useMgPrisConfig();
  const featureContext = useFeatureContext();

  const navigate = useNavigate();
  const navigateToItem = useCallback(
    (mgItemNumber: string) =>
      navigate(`${mgPrisRoutes.items.item.getFullLinkPath({ itemNumber: mgItemNumber })}`),
    [navigate]
  );

  const {
    onToggle: toggleMonitorItemsModal,
    isOpen: isMonitorItemsModalOpen,
    onOpen: onOpenMonitorItemsModal,
  } = useDisclosure(false);

  const { draftAssortmentId: memberDraftAssortmentId, memberId } = useMemberAssortments();

  const { invalidateAssortmentDraft } = useRefetchDraftDrawer();

  const { mutateAsync: addItems, isLoading: isLoadingAdd } = useMutateAddItemsToDraft({
    memberId,
    assortmentId: memberDraftAssortmentId,
  });

  const onAddItemsToAssortment = useCallback(() => {
    const items = checkedItems.map((item) => {
      return {
        mgSupplierId: item.mgParticipantAccountId,
        mgItemNumber: item.mgItemNumber,
        priority: 'ZERO',
      } as ItemsDTO;
    });

    addItems({ body: items })
      .then(() => {
        invalidateAssortmentDraft();

        toast.success(
          checkedItems.length === 1
            ? t('products.mgItem.itemsAddedToDraftSingular', { count: checkedItems.length })
            : t('products.mgItem.itemsAddedToDraftPlural', { count: checkedItems.length })
        );
        setCheckedItems([]);
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('products.errors.generic'));
      });
  }, [checkedItems, addItems, invalidateAssortmentDraft, toast, t, setCheckedItems]);

  return (
    <Stack flexGrow={1}>
      <Stack flexDirection="row" justifyContent="end" alignItems="center" p={4} pt={0} gap={1}>
        <Typography>{t('products.search.result', { count: totalItems })}</Typography>
        <Typography flex={1} textAlign="center">
          {t('products.mgItem.selectedItems', {
            selected: checkedItems.length,
            total: supplierItems.length,
          })}
        </Typography>
        {includeAddButton && (
          <>
            <Feature
              features={featureFlags}
              name="my-assortment:items:write"
              context={featureContext}
              renderEnabled={() => (
                <AddItemsMenu
                  onAddItemsToAssortment={onAddItemsToAssortment}
                  onAddItemsToMonitoringAssortment={onOpenMonitorItemsModal}
                  disabled={checkedItems.length === 0}
                  isLoading={isLoadingAdd}
                />
              )}
              renderDisabled={() => (
                <ContainedButton
                  color="secondary"
                  onClick={onOpenMonitorItemsModal}
                  startIcon={<Add />}
                  disabled={checkedItems.length === 0}
                >
                  {t('products.mgItem.addToMonitor')}
                </ContainedButton>
              )}
            />
            <MonitorMgSupplierItemsDialog
              open={isMonitorItemsModalOpen}
              onClose={toggleMonitorItemsModal}
              selectedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
          </>
        )}

        {!showFilters && (
          <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleFilters} />
        )}
      </Stack>
      <SearchFilterChips
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        resetFilters={resetFilters}
      />
      <MgSupplierItemsTableWithCheckbox
        items={supplierItems}
        totalItems={totalItems}
        clickableRow={clickableRow}
        language={language}
        selection={{ selectedItems: checkedItems, selectItem: setCheckedItems }}
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        setPageSize={setPageSize}
        onRowClick={navigateToItem}
      />
    </Stack>
  );
}
