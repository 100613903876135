import { QueryOptions } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { RetailerDTO } from '@retail/retailer/types';
import { useMPEFetch } from '@retail/app/hooks';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

export function useFetchRetailers(queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<RetailerDTO[]>({
    method: 'GET',
    queryKey: ['retailers'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.retailers.getFullLinkPath(),
    }),
    ...queryOptions,
  });
}
