import { useController, useFormContext } from 'react-hook-form';
import { SelectChangeEvent, Stack } from '@mui/material';
import { MgSupplierItemSearchResult, OverGroup } from '@retail/products/types';
import { CreateFreightConditionSchema, FreightConditionFormBase } from '../FreightConditionForm';
import { LevelSelectForm } from '@retail/calculus/components';
import { ItemLevelFreightForm } from './ItemLevelFreightForm';
import { DiscountGroup } from '@retail/calculus/types';

interface Props {
  productGroups: OverGroup[];
  discountGroups: DiscountGroup[];
  mgSupplierItems?: MgSupplierItemSearchResult[];
  isLoadingFetchItems: boolean;
}

export function CreateFreightConditionForm({
  productGroups,
  discountGroups,
  mgSupplierItems,
  isLoadingFetchItems,
}: Props) {
  const { control, resetField } = useFormContext<CreateFreightConditionSchema>();
  const levelController = useController({ name: 'level', control });
  const overGroupController = useController({ name: 'overGroup', control });
  const discountGroupController = useController({ name: 'discountGroup', control });
  const itemController = useController({ name: 'item', control });

  const handleLevelSelectChange = (value: SelectChangeEvent<unknown>) => {
    resetField('percentAddon');
    resetField('freightFactor');
    resetField('item');
    resetField('overGroup');
    resetField('mainGroup');
    resetField('productGroup');
    resetField('discountGroup');
    resetField('fromDate');
    resetField('toDate');
    levelController.field.onChange(value);
  };

  return (
    <form>
      <Stack gap={3} pb={1}>
        <LevelSelectForm
          productGroups={productGroups}
          discountGroups={discountGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          handleLevelSelectChange={handleLevelSelectChange}
        />

        {(levelController.field.value === 'supplier' ||
          !!overGroupController.field.value ||
          !!discountGroupController.field.value) && <FreightConditionFormBase />}

        {!!itemController.field.value && (
          <ItemLevelFreightForm selectedItem={itemController.field.value} />
        )}
      </Stack>
    </form>
  );
}
