import { ButtonProps, Typography, useTheme } from '@mui/material';
import { OutlinedButton } from '@shared/components';

interface Props {
  name: string;
}

export const OrgUnitHeading: React.FC<ButtonProps & Props> = ({ name, sx, ...buttonProps }) => {
  const { palette } = useTheme();

  return (
    <OutlinedButton
      sx={{
        whiteSpace: 'nowrap',
        cursor: 'default',
        '&:hover': {
          background: palette.common.white,
          border: `1.5px solid ${palette.primary.main}`,
        },
        border: `1.5px solid ${palette.grey.A100}`,
        background: palette.common.white,
        height: 42,
        ...sx,
      }}
      size="small"
      {...buttonProps}
    >
      <Typography variant="body1" color={palette.text.primary}>
        {name}
      </Typography>
    </OutlinedButton>
  );
};
