import { PropsWithChildren, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useFetchMe, useAuthContext } from '@retail/auth/data-access';
import { BodyLoader } from '@retail/components';
import { Auth0Token, MGNamespace } from '@retail/auth/types';
import { LandingPage } from '../Landing';
import { PublicErrorPage } from '../PublicError';
import { NotAuthorizedPage } from '../NotAuthorized';

export enum AuthStatus {
  Loading = 'LOADING',
  NotAuthenticated = 'NOT_AUTHENTICATED',
  Authorized = 'Authorized',
  NoMPEUser = 'NO_MPE_USER',
  NoAppAccess = 'NO_APP_ACCESS',
  Error = 'ERROR',
}

const APP_KEY = 'mgpris2';

const hasAppAccess = (accessToken: string) => {
  const decoded = jwtDecode<Auth0Token>(accessToken);
  const tokenMeta = decoded[MGNamespace];

  return tokenMeta.apps.includes(APP_KEY);
};

export function AuthContainerPage({ children }: PropsWithChildren) {
  const { isAuthenticated, isLoading: isAuth0Loading, token } = useAuthContext();

  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useFetchMe({ suspense: false, enabled: !!token });

  const authStatus: AuthStatus = useMemo(() => {
    if (token && user) {
      return hasAppAccess(token) ? AuthStatus.Authorized : AuthStatus.NoAppAccess;
    }

    if (!isAuthenticated && !isAuth0Loading) {
      return AuthStatus.NotAuthenticated;
    }

    if (!user && !isUserLoading) {
      return AuthStatus.NoMPEUser;
    }

    if (userError) {
      return AuthStatus.Error;
    }

    return AuthStatus.Loading;
  }, [token, user, isAuthenticated, isAuth0Loading, isUserLoading, userError]);

  switch (authStatus) {
    case AuthStatus.Error:
      return <PublicErrorPage />;
    case AuthStatus.Loading:
      return <BodyLoader />;
    case AuthStatus.NoAppAccess:
      return <NotAuthorizedPage reason="noAppAccess" />;
    case AuthStatus.NoMPEUser:
      return <NotAuthorizedPage reason="noMpeUser" />;
    case AuthStatus.NotAuthenticated:
      return <LandingPage />;
  }

  return children;
}
