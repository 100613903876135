import { FC } from 'react';
import { ProductCardContainer } from './ProductCardContainer';
import { ProductCardPaper } from './ProductCardPaper';
import { MgItemInfo } from '@retail/my-assortment/components';
import { useDisclosure } from '@shared/hooks';
import { MgItemDialogContainer } from '@retail/products/containers';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useUserBrands } from '@retail/app/stores/selected-context';
import { WholesalerProductCardContainer } from './WholeSalerProductCardContainer';

interface Props {
  item: {
    primaryText: string;
    secondaryText?: string;
    mgItemNumber: number;
    mgSupplierId: number;
  };
  closeProductCard: () => void;
}

export const ProductCardSuspenseContainer: FC<Props> = ({
  item: { primaryText, secondaryText, mgItemNumber, mgSupplierId },
  closeProductCard,
}) => {
  const { isOpen: openFullProductCard, onToggle: toggleProductCard } = useDisclosure();
  const { selectedBrand } = useUserBrands();

  return (
    <ProductCardPaper>
      <MgItemInfo
        primaryText={primaryText}
        secondaryText={secondaryText}
        closeProductCard={closeProductCard}
        openProductCardModal={toggleProductCard}
      />
      <SuspenseWithSentryErrorBoundary>
        {selectedBrand.wholesaler ? (
          <WholesalerProductCardContainer mgSupplierId={mgSupplierId} mgItemNumber={mgItemNumber} />
        ) : (
          <ProductCardContainer mgSupplierId={mgSupplierId} mgItemNumber={mgItemNumber} />
        )}
      </SuspenseWithSentryErrorBoundary>
      <MgItemDialogContainer
        open={openFullProductCard}
        onClose={toggleProductCard}
        itemNumber={mgItemNumber}
        showAddItem={false}
      />
    </ProductCardPaper>
  );
};
