import { SvgIcon, SvgIconProps } from '@mui/material';

const EyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9996 18.5C16.1251 18.5 19.0588 15.9076 20.7768 13.7486C21.3727 12.9998 21.3727 12.0002 20.7768 11.2514C19.0588 9.09239 16.1251 6.5 11.9996 6.5C7.87408 6.5 4.94037 9.09238 3.22231 11.2514C2.62642 12.0002 2.62642 12.9998 3.22231 13.7486C4.94037 15.9076 7.87408 18.5 11.9996 18.5ZM22.3418 14.994C23.5178 13.5163 23.5178 11.4837 22.3418 10.006C20.4735 7.65826 17.0261 4.5 11.9996 4.5C6.97304 4.5 3.52563 7.65826 1.65736 10.006C0.481402 11.4837 0.481402 13.5163 1.65736 14.994C3.52563 17.3417 6.97304 20.5 11.9996 20.5C17.0261 20.5 20.4735 17.3417 22.3418 14.994Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 12.5C14 13.6046 13.1046 14.5 12 14.5C10.8954 14.5 10 13.6046 10 12.5C10 12.4716 10.0006 12.4434 10.0018 12.4153C10.1577 12.4701 10.3253 12.5 10.5 12.5C11.3284 12.5 12 11.8284 12 11C12 10.8253 11.9701 10.6577 11.9153 10.5018C11.9434 10.5006 11.9716 10.5 12 10.5C13.1046 10.5 14 11.3954 14 12.5ZM16 12.5C16 14.7091 14.2091 16.5 12 16.5C9.79086 16.5 8 14.7091 8 12.5C8 10.2909 9.79086 8.5 12 8.5C14.2091 8.5 16 10.2909 16 12.5Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default EyeIcon;
