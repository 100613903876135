import { Divider, Stack, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

interface Props {
  priceVariant: string;
  price: number;
  unit: string;
}

export const RecommendedPriceValue = ({ priceVariant, price, unit }: Props) => {
  const t = useMyAssortmentTFunction();
  return (
    <Stack flexDirection="row" gap={3}>
      <Typography width={90} variant="body2" color="text.secondary">
        {priceVariant}
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography variant="subtitle2">
        {t('myAssortment.recommendedPrices.value', { value: price })}
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography variant="body2">{t('myAssortment.recommendedPrices.unit', { unit })}</Typography>
    </Stack>
  );
};
