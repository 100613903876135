import { Divider, List, Stack, Typography } from '@mui/material';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';

import { AssortmentListItem } from './AssortmentListItem';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';

interface Props {
  assortments: MonitoringAssortment[];
  linkTo: (assortmentId: number) => string;
  selectedOrgUnitId: number;
  brandLogoUri: string;
}

export const AssortmentsList: React.FC<Props> = ({
  assortments,
  linkTo,
  selectedOrgUnitId,
  brandLogoUri,
}) => {
  const t = useAssortmentTFunction();
  const noAssormentsCreated = !assortments || assortments.length === 0;
  if (noAssormentsCreated) {
    return (
      <Typography alignSelf="center">
        {t('assortment.assortmentsOverview.noAssortments')}
      </Typography>
    );
  }

  return (
    <Stack component={List} divider={<Divider sx={{ mx: 1 }} />}>
      {assortments.map((assortment) => {
        return (
          <AssortmentListItem
            key={assortment.id}
            assortment={assortment}
            linkTo={linkTo}
            selectedOrgUnitId={selectedOrgUnitId}
            brandLogoUri={brandLogoUri}
          />
        );
      })}
    </Stack>
  );
};
