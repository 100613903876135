import { useMemo } from 'react';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { Conditions } from '../Conditions';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { useFetchConditions } from '@retail/calculus/data-access';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { ConditionState, HierarchyLevels, PurchaseCondition } from '@retail/calculus/types';
import { Supplier } from '@retail/suppliers/types';
interface Props {
  supplier: Supplier;
  typesToShow: ConditionState;
  agreements: PurchaseAgreement[];
}

export function ConditionsContainer({ supplier, typesToShow, agreements }: Props) {
  const { draftAssortmentId, baseAssortmentId } = useDepartmentAssortments();

  const { selectedProductGroupNumber, selectedMgItemId, selectedLevel } = useLevelSearchParams();

  const { data: draftConditionsDTO = [] } = useFetchConditions({
    assortmentId: typesToShow === ConditionState.ACTIVE ? baseAssortmentId : draftAssortmentId,
    conditionTypes: ['PURCHASE'],
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: [supplier.mgSupplierId],
    groupNumbers: selectedProductGroupNumber ? [selectedProductGroupNumber] : undefined,
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : undefined,
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
    includeAgreements:
      typesToShow === ConditionState.ALL ||
      (typesToShow === ConditionState.ACTIVE && selectedLevel === HierarchyLevels.supplier),
  });

  const purchaseConditions = useMemo(
    () => (draftConditionsDTO[0]?.purchaseConditions ?? []).map(PurchaseCondition.fromDto),
    [draftConditionsDTO]
  );

  const activePurchaseConditions = useMemo(
    () => purchaseConditions.filter((condition) => condition.operation !== 'DELETE'),
    [purchaseConditions]
  );

  return (
    <Conditions supplier={supplier} agreements={agreements} conditions={activePurchaseConditions} />
  );
}
