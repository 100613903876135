export const STANDARD_VAT_FACTOR = 1.25;

export const calculateSalesCoverage = (salesFactor: number) => {
  return ((100 * (salesFactor - 1)) / salesFactor).toFixed(2);
};

export function calculateToPercentage(number: number) {
  const decimalPart = number - 1;
  const percentage = decimalPart * 100;

  return percentage;
}

export function calculateFromPercentage(percentageValue: number) {
  return percentageValue / 100 + 1;
}

export function calculateVatPrice(priceExVat: number, includeVat: boolean) {
  return includeVat ? priceExVat * STANDARD_VAT_FACTOR : priceExVat;
}

export function calculateCoverageFromSalesFactor(salesFactor: number) {
  return 100 * ((salesFactor - 1) / salesFactor);
}

export function calculateSalesFactorFromDG(DG: number) {
  return 1 / (1 - DG / 100);
}

export function calculateNewPriceFromDG(costPrice: number, DG: number) {
  return costPrice * calculateSalesFactorFromDG(DG);
}

export function calculatePriceFromSalesFactor(costPrice: number, newSalesFactor: number) {
  return costPrice * newSalesFactor;
}

export function calculateNewSalesFactor(costPrice: number, newPrice: number, vatFactor?: number) {
  return newPrice / (vatFactor || 1) / costPrice;
}

export function calculateSalesCoverageFromCostAndSalesPrice(
  costPrice: number,
  salesPrice: number,
  vatFactor?: number
) {
  const salesFactor = calculateNewSalesFactor(costPrice, salesPrice, vatFactor);
  return calculateCoverageFromSalesFactor(salesFactor);
}

export function roundToMaxDecimalCount(number: number, decimalCount: number) {
  return Number(number.toFixed(decimalCount));
}
