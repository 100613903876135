import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { NewAssortmentForm, newAssortmentSchema } from '../newAssortmentForm';
import NewAssortmentFields from '../NewAssortmentFields';
import { NewAssortment } from '../ImportAssortment';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (newAssortment: NewAssortment) => void;
  isLoading?: boolean;
  hideAddItemsLater?: boolean;
}

export const CreateAssortmentManuallyDialog: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  isLoading,
  hideAddItemsLater,
}) => {
  const t = useAssortmentTFunction();
  const { handleSubmit, control } = useForm<NewAssortmentForm>({
    resolver: yupResolver(newAssortmentSchema),
    shouldUnregister: false,
  });
  const onSubmitForm = handleSubmit(({ title }) => {
    onSubmit({
      title,
      itemCodes: {},
    });
  });
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('assortment.admin.create.title')}</DialogTitle>
      <DialogContent>
        <NewAssortmentFields control={control} />
        {!hideAddItemsLater && (
          <Typography>{t('assortment.admin.create.empty.description')}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <TextButton size="small" onClick={onClose} disabled={isLoading}>
          {t('assortment.common.cancel')}
        </TextButton>
        <ContainedButton size="small" onClick={onSubmitForm} loading={!!isLoading}>
          {!hideAddItemsLater
            ? t('assortment.admin.create.empty.save')
            : t('assortment.admin.create.assortmentWithItemsPlural')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
