import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ActiveTagPaper } from '@retail/components';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { CalculusInfoRow } from './CalculusInfoRow';
import { InfinityIcon } from '@shared/custom-icons';
import { useTranslation } from 'react-i18next';
import { FreightCondition } from '@retail/products/types';

interface Props {
  freightCondition?: FreightCondition;
}

export function FreightAccordion({ freightCondition }: Props) {
  const { i18n } = useTranslation();
  const t = useAppTFunction();

  const freightLabel = useMemo(() => {
    if (freightCondition?.percentAddon) {
      return `${freightCondition.percentAddon.toLocaleString(i18n.language, {
        maximumFractionDigits: 2,
      })}%`;
    }
    if (freightCondition?.freightFactor) {
      return freightCondition.freightFactor;
    }
    return 0;
  }, [freightCondition?.freightFactor, freightCondition?.percentAddon, i18n.language]);

  return (
    <Accordion>
      <AccordionSummary>
        <CalculusInfoRow label={t('myAssortment.freightFactor')} chipLabel={freightLabel} />
      </AccordionSummary>
      <AccordionDetails>
        <ActiveTagPaper>
          {freightCondition ? (
            <Grid container gap={2} flexDirection="column">
              <Stack gap={3} flexDirection="row" fontSize={14}>
                <Grid item fontWeight={600}>
                  {t('freight.columns.percentAddon')}
                </Grid>
                <Grid item>{freightCondition.percentAddon}</Grid>
              </Stack>
              <Stack gap={4} flexDirection="row" fontSize={14}>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('freight.columns.fromDate')}
                  </Grid>
                  <Grid item>{dayjs(freightCondition.condition.validFrom).format('ll')}</Grid>
                </Stack>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('freight.columns.toDate')}
                  </Grid>
                  <Grid item>
                    {freightCondition.condition.validTo ? (
                      dayjs(freightCondition.condition.validTo).format('ll')
                    ) : (
                      <InfinityIcon />
                    )}
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Typography>{t('myAssortment.productCard.noActiveFreightCondition')}</Typography>
          )}
        </ActiveTagPaper>
      </AccordionDetails>
    </Accordion>
  );
}
