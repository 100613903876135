import { SvgIcon, SvgIconProps } from '@mui/material';

const DownloadIconMenu = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1284 9.40381H14.7521C14.4921 9.40354 14.2387 9.48586 14.0284 9.6389C13.8182 9.79193 13.662 10.0078 13.5824 10.2553C13.3734 10.9083 12.9622 11.4779 12.408 11.8816C11.8539 12.2854 11.1857 12.5023 10.5001 12.5011C9.8145 12.5023 9.14629 12.2854 8.59217 11.8816C8.03805 11.4779 7.62677 10.9083 7.41785 10.2553C7.33784 10.0079 7.18147 9.79222 6.97121 9.63923C6.76096 9.48624 6.50763 9.40382 6.2476 9.40381H1.87135C1.54522 9.40388 1.23247 9.53346 1.00186 9.76407C0.771252 9.99468 0.641668 10.3074 0.641602 10.6336V17.6471C0.6418 17.9731 0.771443 18.2857 1.00204 18.5162C1.23263 18.7467 1.54531 18.8762 1.87135 18.8763H19.1284C19.4544 18.8762 19.7671 18.7467 19.9977 18.5162C20.2283 18.2857 20.3579 17.9731 20.3581 17.6471V10.6336C20.3581 9.95381 19.8081 9.40381 19.1284 9.40381Z"
          fill="currentColor"
        />
        <path
          d="M10.2423 10.0593C10.3041 10.1458 10.4051 10.1975 10.5113 10.1975H10.5121C10.6191 10.1975 10.7186 10.1458 10.7811 10.058L14.1821 5.27478C14.217 5.22517 14.2378 5.16693 14.242 5.10638C14.2462 5.04583 14.2338 4.98527 14.2061 4.93128C14.1783 4.87724 14.1361 4.83193 14.0841 4.80035C14.0322 4.76876 13.9726 4.75213 13.9118 4.75228H12.3121V0.455279C12.3121 0.271529 12.1638 0.123779 11.9813 0.123779H9.01982C8.83682 0.123779 8.68907 0.271529 8.68907 0.455279V4.75228H7.08807C7.02739 4.75237 6.96791 4.76915 6.91613 4.80078C6.86435 4.83242 6.82227 4.87769 6.7945 4.93163C6.76672 4.98558 6.75432 5.04613 6.75866 5.10665C6.76299 5.16718 6.78389 5.22534 6.81907 5.27478L10.2423 10.0593Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default DownloadIconMenu;
