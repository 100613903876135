import { MgSupplierItem } from './MgSupplierItem';
import {
  MgItemRawDTO,
  MgMediaRawDTO,
  MgPackageRawDTO,
  MgPropertiesRaw,
  MgSupplierItemPackagesRawDTO,
} from '../../dto';
import { DiscountGroup } from '@retail/calculus/types';

export interface MgSupplierItemRaw {
  aiDescription?: string;
  articleType?: number;
  basePrice?: number;
  basePriceDate?: string;
  basePriceUnit?: string;
  calculationGroup?: string;
  calculationGroupDescription?: string;
  commodityCode?: string;
  createdAt?: string;
  currency?: string;
  customerSpecificProductGroup?: string;
  dangerousGoods?: string;
  discontinuationCode?: number;
  discontinuationDate?: string;
  enumber?: number;
  finfoNumber?: number;
  finfoSupplierId?: number;
  fscPefcClaim?: string;
  logisticsSupplier?: boolean;
  mainSupplier?: boolean;
  manufacturer?: boolean;
  media?: MgMediaRawDTO;
  mgBaseSupplier?: boolean;
  mgCalculatedCountFactor?: number;
  mgCalculatedCountFactorUnit?: string;
  mgSupplierId?: number;
  mgSupplierItemId?: number;
  mgSupplierItemPackages?: MgSupplierItemPackagesRawDTO[];
  modelNumber?: string;
  nobbNumber?: number;
  normalLeadTime?: number;
  originCountry?: string;
  prevMgItemNumber?: number;
  priceGroup?: string;
  pricePer?: number;
  priceTypeCode?: number;
  primaryText?: string;
  productGroup?: string;
  productIdentityVilmaBas?: string;
  properties?: MgPropertiesRaw;
  registrationDate?: string;
  replacedBySupplierItemId?: string;
  rskNumber?: number;
  secondaryText?: string;
  source?: string;
  stockOnDemandProductionCode?: string;
  supplierArticleDescriptionLine1?: string;
  supplierArticleDescriptionLine2?: string;
  supplierItemId?: string;
  supplierProductName?: string;
  technicalDescription?: string;
  textFull?: string;
  textIngress?: string;
  tunNumber?: number;
  updatedAt?: string;
  vatCode?: string;
  discountGroup?: DiscountGroup;
  webProductName?: string;
  youtubeInstallation?: string;
}

export class MgItemRaw {
  createdAt?: string;
  gtinCodes?: string[];
  mgItemId?: number;
  mgItemNumber?: number;
  mgPackages?: MgPackageRawDTO[];
  mgSupplierItems?: MgSupplierItemRaw[];
  replacedByMgItemNumber?: number;
  updatedAt?: string;

  constructor({
    createdAt,
    gtinCodes,
    mgItemId,
    mgItemNumber,
    mgPackages,
    mgSupplierItems,
    replacedByMgItemNumber,
    updatedAt,
  }: MgItemRawDTO) {
    this.createdAt = createdAt;
    this.gtinCodes = gtinCodes;
    this.mgItemId = mgItemId;
    this.mgItemNumber = mgItemNumber;
    this.mgPackages = mgPackages;
    this.mgSupplierItems = mgSupplierItems;
    this.replacedByMgItemNumber = replacedByMgItemNumber;
    this.updatedAt = updatedAt;
  }

  getSuppliers = (): Partial<MgSupplierItem>[] =>
    this.mgSupplierItems?.map((supplierItems) => ({
      mgSupplierId: supplierItems.mgSupplierId,
      registrationDate: supplierItems.registrationDate,
      mgSupplierItemId: supplierItems.mgSupplierItemId,
    })) ?? [];

  getPrimarySupplierItem(): MgSupplierItemRaw | undefined {
    return (
      this.mgSupplierItems?.find((supplierItem) => supplierItem.mainSupplier) ??
      this.mgSupplierItems?.[0]
    );
  }

  static fromDto = (mgItemDto: MgItemRawDTO): MgItemRaw => {
    return new MgItemRaw({
      createdAt: mgItemDto.createdAt,
      gtinCodes: mgItemDto.gtinCodes,
      mgItemId: mgItemDto.mgItemId,
      mgItemNumber: mgItemDto.mgItemNumber,
      mgPackages: mgItemDto.mgPackages,
      mgSupplierItems: mgItemDto.mgSupplierItems,
      replacedByMgItemNumber: mgItemDto.replacedByMgItemNumber,
      updatedAt: mgItemDto.updatedAt,
    });
  };
}
