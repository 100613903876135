import { DatedBracketMarkupPriceDTO } from '../../dto';
import { BracketMarkupPrice } from './BracketMarkupPrice';

interface DatedBracketMarkupPriceProps {
  validFrom: Date;
  validTo?: Date;
  bracketMarkupPrice: BracketMarkupPrice;
}

export class DatedBracketMarkupPrice {
  validFrom: Date;
  validTo?: Date;
  bracketMarkupPrice: BracketMarkupPrice;

  constructor(props: DatedBracketMarkupPriceProps) {
    this.validFrom = props.validFrom;
    this.validTo = props.validTo;
    this.bracketMarkupPrice = props.bracketMarkupPrice;
  }

  static fromDto(dto: DatedBracketMarkupPriceDTO): DatedBracketMarkupPrice {
    return new DatedBracketMarkupPrice({
      validFrom: new Date(dto.validFrom),
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      bracketMarkupPrice: BracketMarkupPrice.fromDto(dto.bracketMarkupPrice),
    });
  }
}
