import { FC } from 'react';
import { Stack } from '@mui/material';
import { StyledTextField } from '../Calculus';
import { ConfirmEditButtons } from '../ConfirmEditButtons';
import { HelperText } from '@shared/components';
import { useForm } from 'react-hook-form';
import { salesPriceSchema, SalesPriceSchema } from '@retail/markup/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { TooltipError } from '../TooltipError';

interface Props {
  defaultValue: number;
  onSubmit: (value: number) => void;
  onCancel: () => void;
  errorPlacement?: 'bottom' | 'tooltipBottom';
}

export const SalesPriceInput: FC<Props> = ({
  defaultValue,
  onSubmit,
  onCancel,
  errorPlacement = 'bottom',
}) => {
  const t = useCalculusTFunction();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<SalesPriceSchema>({
    defaultValues: { salesPrice: defaultValue },
    resolver: yupResolver(salesPriceSchema(t)),
  });

  const _onSubmit = handleSubmit(({ salesPrice }) => {
    onSubmit(salesPrice);
  });

  return (
    <Stack alignItems="flex-end">
      <Stack direction="row" alignItems="center">
        <TooltipError
          message={
            errors.salesPrice?.message && errorPlacement === 'tooltipBottom'
              ? errors.salesPrice.message
              : undefined
          }
        >
          <StyledTextField
            {...register('salesPrice')}
            size="small"
            error={!!errors.salesPrice?.message}
          />
        </TooltipError>
        <ConfirmEditButtons onSubmit={_onSubmit} onCancel={onCancel} />
      </Stack>
      {errors.salesPrice?.message && errorPlacement === 'bottom' && (
        <HelperText id="salesPriceInputError" status="error">
          {errors.salesPrice?.message}
        </HelperText>
      )}
    </Stack>
  );
};
