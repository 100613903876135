import { SupplierItemConditionsDTO } from '../dtos';
import { SalesPrice, SalesPricesByDate } from './SalesPrice';

export interface SupplierItemConditionsProps {
  mgItemNumber: number;
  mgSupplierId: number;
  finfoNumber: number;
  groupNumber: string;
  groupDescription: string;
  finfoSupplierId: number;
  mgSupplierItemId: number;
  supplierItemId: number;
  mgSupplierName: string;
  primaryText: string;
  secondaryText: string;
  simulatedPrices: SalesPricesByDate[];
}

export class SupplierItemConditions {
  mgItemNumber: number;
  mgSupplierId: number;
  finfoNumber: number;
  groupNumber: string;
  groupDescription: string;
  finfoSupplierId: number;
  mgSupplierItemId: number;
  supplierItemId: number;
  mgSupplierName: string;
  primaryText: string;
  secondaryText: string;
  simulatedPrices: SalesPricesByDate[];

  constructor({
    mgItemNumber,
    mgSupplierId,
    finfoNumber,
    groupNumber,
    groupDescription,
    finfoSupplierId,
    mgSupplierItemId,
    supplierItemId,
    mgSupplierName,
    primaryText,
    secondaryText,
    simulatedPrices,
  }: SupplierItemConditionsProps) {
    this.mgItemNumber = mgItemNumber;
    this.mgSupplierId = mgSupplierId;
    this.finfoNumber = finfoNumber;
    this.groupNumber = groupNumber;
    this.groupDescription = groupDescription;
    this.finfoSupplierId = finfoSupplierId;
    this.mgSupplierItemId = mgSupplierItemId;
    this.supplierItemId = supplierItemId;
    this.mgSupplierName = mgSupplierName;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.simulatedPrices = simulatedPrices;
  }

  static fromDto = (dto: SupplierItemConditionsDTO): SupplierItemConditions =>
    new SupplierItemConditions({
      mgItemNumber: dto.mgItemNumber,
      mgSupplierId: dto.mgSupplierId,
      finfoNumber: dto.finfoNumber,
      groupNumber: dto.groupNumber,
      groupDescription: dto.groupDescription,
      finfoSupplierId: dto.finfoSupplierId,
      mgSupplierItemId: dto.mgSupplierItemId,
      supplierItemId: dto.supplierItemId,
      mgSupplierName: dto.mgSupplierName,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      simulatedPrices:
        dto.simulatedPrices?.map((dto) => ({
          priceDate: new Date(dto.priceDate),
          prices: dto.prices.map(SalesPrice.fromDto),
        })) || [],
    });
}
