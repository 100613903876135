import { Box, useTheme } from '@mui/material';
import { MGPrisIconWhite } from '@shared/custom-icons';
import { forwardRef } from 'react';

export const MGPrisLogo = forwardRef((props, ref) => {
  const { palette } = useTheme();
  return (
    <Box position="relative" height="100%" ref={ref} {...props}>
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          left: 115,
          zIndex: 1,
          color: palette.common.white,
          fontSize: '42px',
        }}
      >
        <MGPrisIconWhite fontSize="inherit" />
      </Box>
      <Box
        sx={{
          width: '120px',
          height: '100%',
          backgroundColor: palette.primary.main,
          transform: 'skew(-43deg)',
          marginLeft: '5rem',
          marginRight: '5rem',
        }}
      ></Box>
    </Box>
  );
}); 
