import { Autocomplete, TextField, useTheme } from '@mui/material';
import { useSelectLocalStorageSupplier } from '@retail/calculus/hooks';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  suppliers: Supplier[];
  selectedSupplier: Supplier;
  onSelectSupplier: (value: number) => void;
}

export function SelectSupplierAutocomplete({
  suppliers,
  selectedSupplier,
  onSelectSupplier,
}: Props) {
  const { palette } = useTheme();
  const { setSelectedSupplier } = useSelectLocalStorageSupplier();

  return (
    <Autocomplete
      id="supplierSelect"
      size="small"
      value={selectedSupplier}
      filterSelectedOptions
      disableClearable
      isOptionEqualToValue={(option, value) => option.mgSupplierId === value.mgSupplierId}
      onChange={(_, { mgSupplierId }) => {
        onSelectSupplier(mgSupplierId);
        setSelectedSupplier(mgSupplierId);
      }}
      options={suppliers}
      getOptionLabel={({ name }) => name}
      renderInput={(params) => (
        <TextField
          sx={{
            bgcolor: `${palette.grey['600']} !important`,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            p: 1.2,
            color: palette.common.white,
          }}
          variant="filled"
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
      sx={{
        '.MuiInputBase-root': {
          width: 478,
          p: 0,
          backgroundColor: `${palette.grey['600']} !important`,
          color: palette.common.white,
          '.MuiInputBase-input': {
            textAlign: 'center',
          },
          '.MuiSvgIcon-root': {
            color: palette.common.white,
          },
        },
      }}
    />
  );
}
