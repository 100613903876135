import { transformRoutes } from '@shared/utils';

const routeDefs = {
  root: {
    path: '/',
  },
  marketInsight: {
    path: '/marketinsight',
    sub: {
      assortments: {
        path: '/assortments',
        sub: {
          assortment: {
            path: '/:assortmentId',
          },
        },
      },
      competitors: {
        path: '/competitors',
        sub: {
          edit: {
            path: '/edit',
          },
        },
      },
    },
  },
  items: {
    path: '/items',
    sub: {
      item: {
        path: '/:itemNumber',
      },
    },
  },
  calculus: {
    path: '/calculus',
    sub: {
      supplier: {
        path: '/:supplierId',
      },
    },
  },
  myAssortment: {
    path: '/myAssortment',
  },
  superuser: {
    path: '/superuser',
    sub: {
      features: {
        path: '/features',
      },
    },
  },
  users: {
    path: '/users',
  },
  suppliers: {
    path: '/suppliers',
  },
  export: {
    path: '/export',
  },
} as const;

export const mgPrisRoutes = transformRoutes(routeDefs, '');
