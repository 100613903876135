import { BodyLoader } from '@retail/components';
import {
  Packages,
  PrioritizeSupplierDialog,
  SupplierTabs,
  useProductCardStore,
} from '@retail/my-assortment/components';
import { AssortmentItem, ItemsDTO, SupplierWithPriority } from '@retail/my-assortment/types';
import { MgItem, MgSupplierItem } from '@retail/products/types';
import { useDisclosure } from '@shared/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchMgItem } from '@retail/products/data-access';
import { useAssortmentActions, useBrandAssortments } from '@retail/mgpdm/context';

export interface ProductCardContainerProps {
  assortmentItem: AssortmentItem;
}

export function ProductCardContainer({ assortmentItem }: ProductCardContainerProps) {
  const { baseAssortmentId } = useBrandAssortments();
  const { updateAssortmentItems, updateItemsIsLoading } = useAssortmentActions();

  const { selectedPackageType, changeSelectedPackage } = useProductCardStore();

  const { data: mgItemDto } = useFetchMgItem(
    { mgItemNumber: assortmentItem.mgItemNumber, assortmentId: baseAssortmentId },
    { suspense: true }
  );
  const mgItem = MgItem.fromDto(mgItemDto!);

  const { isOpen: isPrioritizeDialogOpen, onToggle: togglePrioritizeDialog } = useDisclosure();

  const suppliers: SupplierWithPriority[] = useMemo(
    () =>
      mgItem.supplierItems.map((supplierItem) => {
        return {
          id: supplierItem.mgSupplierId,
          name: supplierItem.mgSupplierName,
          priority: supplierItem.priority,
        };
      }),
    [mgItem.supplierItems]
  );

  const [selectedSupplierId, selectSupplier] = useState(() => assortmentItem.mgSupplierId);

  useEffect(() => {
    selectSupplier(assortmentItem.mgSupplierId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assortmentItem.mgSupplierId]);

  const selectedSupplierItem = mgItem.supplierItems.find(
    (supplierItem) => supplierItem.mgSupplierId === selectedSupplierId
  );

  const selectedSupplierItemPackage = useMemo(
    () => selectedSupplierItem?.packages.find(({ type }) => type === selectedPackageType),
    [selectedPackageType, selectedSupplierItem?.packages]
  );

  const handlePostPriorityChanges = useCallback(
    (suppliers: SupplierWithPriority[]) => {
      const supplierItems = suppliers.map((supplier) => {
        return {
          mgSupplierId: supplier.id,
          mgItemNumber: mgItem.id,
          priority: supplier.priority,
        } as ItemsDTO;
      });
      updateAssortmentItems(supplierItems);
    },
    [mgItem.id, updateAssortmentItems]
  );

  if (updateItemsIsLoading || !selectedSupplierItem || !selectedSupplierItemPackage) {
    return <BodyLoader />;
  }

  const mgPackages = MgSupplierItem.getPackages(selectedSupplierItem.packages || []);

  return (
    <>
      <SupplierTabs
        suppliers={suppliers}
        clickedSupplier={assortmentItem.mgSupplierId}
        selectedSupplier={selectedSupplierId}
        selectSupplier={selectSupplier}
        openPrioritizeDialog={togglePrioritizeDialog}
      />

      <Packages
        packages={mgPackages}
        selectedPackage={selectedPackageType}
        basePriceUnit={selectedSupplierItem.basePriceUnit}
        setSelectedPackage={changeSelectedPackage}
      />

      <PrioritizeSupplierDialog
        suppliers={suppliers}
        isOpen={isPrioritizeDialogOpen}
        onClose={togglePrioritizeDialog}
        handlePostPriorityChanges={handlePostPriorityChanges}
        isLoading={updateItemsIsLoading}
      />
    </>
  );
}
