import { HierarchyLevels, SelectedLevel } from '@retail/calculus/types';
import { mgItemSearchParamKey, productGroupSearchParamKey } from '@retail/products/utils';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useLevelSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedProductGroupNumber: string | undefined = useMemo(() => {
    const value = searchParams.get(productGroupSearchParamKey);
    return value !== 'undefined' && value !== null ? value : undefined;
  }, [searchParams]);

  const selectedMgItemId = useMemo(
    () => Number(searchParams.get(mgItemSearchParamKey)) || undefined,
    [searchParams]
  );

  const selectLevel = useCallback(
    (selectedLevel: SelectedLevel) => {
      setSearchParams(() => {
        if (selectedLevel.level === HierarchyLevels.supplier) {
          searchParams.delete(productGroupSearchParamKey);
          searchParams.delete(mgItemSearchParamKey);
        } else if (selectedLevel.level === HierarchyLevels.item) {
          searchParams.delete(productGroupSearchParamKey);
          searchParams.set(mgItemSearchParamKey, selectedLevel.mgSupplierItem?.id + '');
        } else {
          searchParams.set(
            productGroupSearchParamKey,
            selectedLevel.productGroup?.groupNumber + ''
          );
          searchParams.delete(mgItemSearchParamKey);
        }
        return searchParams;
      });
    },
    [searchParams, setSearchParams]
  );

  const selectedLevel: HierarchyLevels = useMemo(() => {
    if (selectedMgItemId) {
      return HierarchyLevels.item;
    } else if (selectedProductGroupNumber) {
      return selectedProductGroupNumber.length > 3
        ? HierarchyLevels.productGroup
        : selectedProductGroupNumber.length > 2
        ? HierarchyLevels.mainGroup
        : HierarchyLevels.overGroup;
    }
    return HierarchyLevels.supplier;
  }, [selectedMgItemId, selectedProductGroupNumber]);

  return {
    selectedProductGroupNumber,
    selectedMgItemId,
    selectedLevel: selectedLevel,
    selectLevel,
  };
};
