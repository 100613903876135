import { List, Typography } from '@mui/material';
import { Coordinates, Retailer } from '@retail/retailer/types';
import { Image } from '@retail/components';
import { useMemo } from 'react';
import { calculateDistanceFromCompetitorInKm } from '@retail/retailer/utils';
import { sortByLowerPropValue } from '@shared/utils';
import { CompetitorView } from './CompetitorView';

interface Props {
  myCoordinates: Coordinates | undefined;
  retailer: Retailer;
  removeCompetitor: (competitorId: number) => void;
}

export function RetailerWithCompetitorsList({ retailer, myCoordinates, removeCompetitor }: Props) {
  const selectedStores = retailer.stores.filter((store) => store.isSelected);
  const storesWithDistance = useMemo(() => {
    return selectedStores
      .map((store) => {
        return {
          id: store.id,
          name: store.name,
          distance:
            store.coordinates &&
            myCoordinates &&
            calculateDistanceFromCompetitorInKm(store.coordinates, myCoordinates),
        };
      })
      .sort(sortByLowerPropValue('distance'));
  }, [myCoordinates, selectedStores]);

  return selectedStores.length !== 0 ? (
    <List
      sx={{ py: 2 }}
      subheader={
        <Image src={retailer.logoUrl()} fallback={() => <Typography>{retailer.name}</Typography>} />
      }
    >
      {storesWithDistance.map((store) => {
        return (
          <CompetitorView
            key={store.id}
            {...store}
            url={retailer.circularLogoUrl()}
            removeCompetitor={() => removeCompetitor(store.id)}
          />
        );
      })}
    </List>
  ) : null;
}
