import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { DeletionFromDateForm } from './DeletionFromDateForm';
import { useFormContext } from 'react-hook-form';
import { DeletionFromDateSchema } from './deletionFromDateSchema';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  numberOfItems: number;
  onDelete: (validFrom: string) => void;
  isDeleting: boolean;
}

export function DeleteItemsDialog({ numberOfItems, isOpen, onClose, isDeleting, onDelete }: Props) {
  const { handleSubmit } = useFormContext<DeletionFromDateSchema>();
  const onSubmit = handleSubmit(({ fromDate }) => {
    onDelete(fromDate.toISOString());
  });
  const t = useAppTFunction();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {numberOfItems === 1
          ? t('assortment.deleteItems.titleSingular', { numItems: numberOfItems })
          : t('assortment.deleteItems.titlePlural', { numItems: numberOfItems })}
      </DialogTitle>

      <DialogContent>
        <DeletionFromDateForm />
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onClose} disabled={isDeleting} size="small">
          {t('common.cancel')}
        </TextButton>
        <ContainedButton loading={isDeleting} onClick={onSubmit} size="small">
          {t('assortment.deleteItems.confirm')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
