import { searchQuerySearchParamKey } from '@retail/products/utils';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSelectSearchQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = useMemo(() => searchParams.get(searchQuerySearchParamKey), [searchParams]);

  const setQuery = useCallback(
    (query: string) =>
      setSearchParams((prevParams) => {
        if (query && query !== '') {
          prevParams.set(searchQuerySearchParamKey, query);
        } else {
          prevParams.delete(searchQuerySearchParamKey);
        }

        return prevParams;
      }),
    [setSearchParams]
  );

  return {
    query: query ?? undefined,
    setQuery,
  };
};
