export type envType = 'dev' | 'stage' | 'prod' | 'localDev' | 'localStage';

export const mgPrisFeatures = [
  'bracket-price-table',
  'my-assortment:items:write',
  'fixed-price',
] as const;

export type MgPrisFeature = (typeof mgPrisFeatures)[number];

export interface FeatureContext {
  locationType: 'retail' | 'wholesale';
}

export interface FeatureFlag {
  name: MgPrisFeature;
  enabled: boolean | ((context: FeatureContext) => boolean);
}

export interface BaseConfig {
  env: envType;
  version: string;
  buildTime?: number;
  appInfo: {
    name: string;
    key: string;
  };
  muiLicenseKey: string;
  sentryDsn?: string;
  country: 'NO' | 'SE';
  retailerLogoBaseUrl: string;
}

export interface MgPrisConfig extends BaseConfig {
  mpe: {
    url: string;
  };
  auth0: {
    clientId: string;
    domain: string;
    audience: string;
    mpeAudience?: string;
  };
  featureFlags: FeatureFlag[];
  gaMeasurementId?: string;
  hotjar?: {
    id: number;
    version: number;
  };
  clarity?: {
    id: string;
  };
}
