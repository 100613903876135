import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useMutateMPEFetch } from '@retail/app/hooks';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

interface PostProps extends QueryOptions {
  memberId: string;
  assortmentId: number;
}

export function useMutateActivateItemChanges({
  memberId,
  assortmentId,
  ...queryOptions
}: PostProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, ItemsDTO[]>({
    method: 'POST',
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.members.member.assortments.assortment.activateItems.getFullLinkPath({
        memberId,
        assortmentId,
      }),
    }),
    token,
    ...queryOptions,
  });
}
