import { Stack } from '@mui/material';
import { FilterChip, RemoveAllFiltersChip } from '@retail/components';
import { FilterOptions, SetFilterOptions } from '@retail/products/types';

interface Props {
  searchFilters: FilterOptions;
  setSearchFilters: SetFilterOptions;
  resetFilters: () => void;
}

export function SearchFilterChips({ searchFilters, setSearchFilters, resetFilters }: Props) {
  const { selectedSuppliers, selectedDiscountGroups, selectedProductGroups, articleIds, query } = {
    ...searchFilters,
  };

  const { selectSupplier, selectProductGroups, selectDiscountGroups, removeArticleId, setQuery } = {
    ...setSearchFilters,
  };

  return (
    <Stack flexDirection="row" gap={1} alignItems="center" pb={1} px={2}>
      {selectedSuppliers &&
        selectedSuppliers.map((supplier) => (
          <FilterChip
            key={supplier.mgSupplierId}
            id={supplier.mgSupplierId}
            name={supplier.name}
            onDelete={() =>
              selectSupplier(
                selectedSuppliers.filter(
                  (prevSupplier) => prevSupplier.mgSupplierId !== supplier.mgSupplierId
                )
              )
            }
          />
        ))}
      {selectedDiscountGroups &&
        selectedDiscountGroups.map((discountGroup) => (
          <FilterChip
            key={discountGroup.id}
            id={discountGroup.id}
            name={discountGroup.name ?? ''}
            onDelete={() =>
              selectDiscountGroups(
                selectedDiscountGroups.filter(
                  (prevDiscountGroup) => prevDiscountGroup.id !== discountGroup.id
                )
              )
            }
          />
        ))}

      {selectedProductGroups &&
        selectedProductGroups.map((productGroup) => (
          <FilterChip
            key={productGroup.groupNumber}
            id={productGroup.groupNumber}
            name={productGroup.getLabel()}
            onDelete={() =>
              selectProductGroups(
                selectedProductGroups.filter(
                  (prevProductGroup) => prevProductGroup.groupNumber !== productGroup.groupNumber
                )
              )
            }
          />
        ))}
      {articleIds &&
        articleIds.map((id) => (
          <FilterChip id={id} name={id} onDelete={() => removeArticleId(id)} />
        ))}

      {query && <FilterChip id={query} name={query} onDelete={() => setQuery('')} />}

      {(!!selectedSuppliers?.length ||
        !!selectedDiscountGroups?.length ||
        !!selectedProductGroups?.length ||
        !!articleIds.length ||
        query) && <RemoveAllFiltersChip resetFilters={resetFilters} />}
    </Stack>
  );
}
