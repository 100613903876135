import { ConditionDatesForm, ConditionDatesSchema } from '@retail/calculus/components';
import { useFormContext } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';
import { PriceType } from '@retail/calculus/types';
import { useCallback } from 'react';
import { usePurchaseActions } from '@retail/purchase-agreements/context';
import { useAppTFunction } from '@retail/app/i18n';
import { useLevelSearchParams } from '@retail/calculus/hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  supplierId: number;
  agreementId: number;
  agreementText: string;
  priceType?: PriceType;
}

export function ActivateSelectedAgreementDialog({
  isOpen,
  onClose,
  supplierId,
  agreementId,
  agreementText,
  priceType,
}: Props) {
  const t = useAppTFunction();

  const { selectedProductGroupNumber, selectedMgItemId } = useLevelSearchParams();
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = useFormContext<ConditionDatesSchema>();

  const { activateOrChangeCondition, isLoadingPostChanges } = usePurchaseActions({
    mgSupplierId: supplierId,
  });

  const submitAgreements = useCallback(
    (data: ConditionDatesSchema) => {
      const fromDate = data.fromDate;
      activateOrChangeCondition({
        agreementId,
        groupNumber: selectedProductGroupNumber,
        mgItemNumber: selectedMgItemId,
        supplierLevel: !selectedProductGroupNumber && !selectedMgItemId,
        fromDate,
        priceType,
      });
      reset();
      onClose();
    },
    [
      activateOrChangeCondition,
      agreementId,
      selectedProductGroupNumber,
      selectedMgItemId,
      priceType,
      reset,
      onClose,
    ]
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('purchaseAgreements.agreement.activateAgreement') + agreementText}
      </DialogTitle>

      <DialogContent>
        <ConditionDatesForm labelAlignment="row" excludeToDate />
      </DialogContent>

      <DialogActions>
        <ContainedButton
          disabled={Object.keys(errors).length > 0}
          loading={isLoadingPostChanges}
          onClick={handleSubmit(submitAgreements)}
          size="small"
        >
          {t('purchaseAgreements.agreement.actions.setToApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
