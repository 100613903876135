import { IconButton, Tooltip } from '@mui/material';
import { GridFilterAltIcon } from '@mui/x-data-grid';

interface Props {
  onFilterClick: () => void;
  filterTitle: string;
}

export const FilterCellAction = ({ filterTitle, onFilterClick }: Props) => {
  return (
    <Tooltip title={filterTitle}>
      <IconButton
        onClick={onFilterClick}
        sx={{
          padding: 0,
          paddingBottom: '2px',
        }}
      >
        <GridFilterAltIcon
          sx={{
            fontSize: 15,
          }}
        />
      </IconButton>
    </Tooltip>
  );
};
