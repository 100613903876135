import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';
import { useCallback, useState } from 'react';
import { usePurchaseActions } from '@retail/purchase-agreements/context';
import { useAppTFunction } from '@retail/app/i18n';
import { PurchaseCondition } from '@retail/calculus/types';
import { AgreementSelect } from '@retail/purchase-agreements/components';
import { ItemAgreement, PurchaseAgreement } from '@retail/purchase-agreements/types';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { SelectItemAgreements } from './SelectItemAgreements';

interface Props {
  open: boolean;
  onClose: () => void;
  assortmentId: number;
  agreements: PurchaseAgreement[];
  agreementToEdit: PurchaseCondition;
  supplierId: number;
}

function ChangeSelectedAgreementDialog({
  open,
  assortmentId,
  agreements,
  agreementToEdit,
  onClose,
  supplierId,
}: Props) {
  const t = useAppTFunction();

  const { activateOrChangeCondition, isLoadingPostChanges } = usePurchaseActions({
    mgSupplierId: supplierId,
  });
  const [selectedAgreement, selectAgreement] = useState<number>(agreementToEdit.agreementId);
  const [selectedItemAgreement, selectItemAgreement] = useState<ItemAgreement | undefined>(
    undefined
  );

  const submitAgreements = useCallback(() => {
    activateOrChangeCondition({
      agreementId: selectedItemAgreement?.agreementId ?? selectedAgreement,
      groupNumber: agreementToEdit.productGroup,
      mgItemNumber: agreementToEdit.mgItemNumber,
      supplierLevel: !agreementToEdit.productGroup && !agreementToEdit.mgItemNumber,
      fromDate: new Date(),
      priceType: selectedItemAgreement?.priceType,
    });
    onClose();
  }, [
    activateOrChangeCondition,
    agreementToEdit.mgItemNumber,
    agreementToEdit.productGroup,
    onClose,
    selectedAgreement,
    selectedItemAgreement,
  ]);

  const conditionLevelName =
    agreementToEdit.primaryText ??
    agreementToEdit.productGroupName ??
    agreementToEdit.mgSupplierName;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t('purchaseAgreements.agreement.actions.editConditionFor', { name: conditionLevelName })}
      </DialogTitle>

      <DialogContent>
        {agreementToEdit.mgItemNumber ? (
          <SelectItemAgreements
            supplierId={supplierId}
            assortmentId={assortmentId}
            mgItemNumber={agreementToEdit.mgItemNumber}
            selectAgreement={selectItemAgreement}
          />
        ) : (
          <AgreementSelect
            agreements={agreements}
            selectedAgreement={selectedAgreement}
            prevSelectedAgreement={agreementToEdit}
            selectAgreement={selectAgreement}
          />
        )}
      </DialogContent>

      <DialogActions>
        <ContainedButton
          loading={isLoadingPostChanges}
          disabled={selectedAgreement === agreementToEdit.agreementId && !selectedItemAgreement}
          onClick={submitAgreements}
          size="small"
        >
          {t('purchaseAgreements.agreement.actions.setToApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(ChangeSelectedAgreementDialog);
