import { useProductsTFunction } from '@retail/products/i18n';
import { Menu, MenuButton } from '@retail/components';
import { Add } from '@mui/icons-material';
import { MenuItem } from '@mui/material';

interface Props {
  onAddItemsToAssortment: () => void;
  onAddItemsToMonitoringAssortment: () => void;
  disabled: boolean;
  isLoading: boolean;
}

export const AddItemsMenu = ({
  onAddItemsToAssortment,
  onAddItemsToMonitoringAssortment,
  disabled,
  isLoading,
}: Props) => {
  const t = useProductsTFunction();
  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton
            disabled={disabled}
            // @ts-ignore
            loading={isLoading}
            size="small"
            color="secondary"
            startIcon={<Add />}
          >
            {t('products.mgItem.addSelected')}
          </MenuButton>
          <MenuItem
            onClick={() => {
              onClose();
              onAddItemsToAssortment();
            }}
          >
            {t('products.mgItem.addToMy')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClose();
              onAddItemsToMonitoringAssortment();
            }}
          >
            {t('products.mgItem.addToMonitor')}
          </MenuItem>
        </>
      )}
    </Menu>
  );
};
