import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import {
  ContainedButton,
  Dialog,
  HelperText,
  TextButton,
  withFormProvider,
} from '@shared/components';
import { BracketPriceTable, BracketPriceTableProps } from './BracketPriceTable';
import { ExcludeVatSwitch } from '../../ExcludeVatSwitch';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { Markup, useBracketPriceStore } from './store';
import React, { useMemo } from 'react';
import { MgSalesPackageClassCode } from '@retail/products/types';
import {
  ConditionDatesForm,
  ConditionDatesSchema,
  conditionDatesSchema,
} from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import { useFormContext } from 'react-hook-form';

export interface CreatedSalesFactor {
  salesFactor: number;
}

export interface CreatedSalesPrice {
  salesPriceWithoutVat: number;
  salesPriceIncVat: number;
}

type CreatedMarkup = { gtin: string } & (CreatedSalesFactor | CreatedSalesPrice);

type PackageMarkup = {
  [key in MgSalesPackageClassCode]?: CreatedMarkup;
};

export interface DatedPackageMarkups {
  packageMarkups: PackageMarkup;
  validFrom: Date;
}

interface Props extends BracketPriceTableProps {
  open: boolean;
  onClose: () => void;
  includeVat: boolean;
  onToggleIncludeVat: () => void;
  saveMarkups: (markups: DatedPackageMarkups) => void;
  isSavingMarkups: boolean;
  t: CalculusTFunction;
}

function mapToCreatedMarkup(markup: Markup): CreatedMarkup | undefined {
  switch (markup.editedField) {
    case 'coverage':
      return { salesFactor: markup.salesFactor, gtin: markup.gtin };
    case 'price':
    case 'discount':
      return {
        salesPriceWithoutVat: markup.salesPrice!,
        salesPriceIncVat: markup.salesPriceIncVat!,
        gtin: markup.gtin,
      };
    case undefined:
      return undefined;
  }
}

const EditBracketPricesDialogRaw: React.FC<Props> = ({
  open,
  onClose,
  includeVat,
  onToggleIncludeVat,
  saveMarkups,
  isSavingMarkups,
  ...bracketPriceTableProps
}) => {
  const t = useMyAssortmentTFunction();
  const { newMarkups, editingFields } = useBracketPriceStore((state) => state);

  const {
    formState: { errors },
    handleSubmit,
  } = useFormContext<ConditionDatesSchema>();

  const isEditingAnyFields = useMemo(
    () => Object.values(editingFields).some((field) => !!field),
    [editingFields]
  );

  const priceValidationErrors = useMemo(() => {
    const errors = [];
    const fPakMarkupState = bracketPriceTableProps.packagePrices.find(
      ({ packageClassCode }) => packageClassCode === 'F-PAK'
    )?.salesPrice;
    const dPakMarkupState = bracketPriceTableProps.packagePrices.find(
      ({ packageClassCode }) => packageClassCode === 'D-PAK'
    )?.salesPrice;
    const tPakMarkupState = bracketPriceTableProps.packagePrices.find(
      ({ packageClassCode }) => packageClassCode === 'T-PAK'
    )?.salesPrice;
    const fPakSalesPrice = fPakMarkupState?.newValue?.value || fPakMarkupState?.currentValue || 0;
    const dPakSalesPrice = dPakMarkupState?.newValue?.value || dPakMarkupState?.currentValue || 0;
    const tPakSalesPrice = tPakMarkupState?.newValue?.value || tPakMarkupState?.currentValue || 0;

    if (newMarkups?.['D-PAK']?.editedField && dPakSalesPrice > fPakSalesPrice) {
      errors.push(
        t('myAssortment.discounts.errors.packagePriceValidation', {
          packageClassCode: 'D-PAK',
          packageClassCodeComparison: 'F-PAK',
        })
      );
    }
    if (newMarkups?.['T-PAK']?.editedField && tPakSalesPrice > dPakSalesPrice) {
      errors.push(
        t('myAssortment.discounts.errors.packagePriceValidation', {
          packageClassCode: 'T-PAK',
          packageClassCodeComparison: 'D-PAK',
        })
      );
    } else if (newMarkups?.['T-PAK']?.editedField && tPakSalesPrice > fPakSalesPrice) {
      errors.push(
        t('myAssortment.discounts.errors.packagePriceValidation', {
          packageClassCode: 'T-PAK',
          packageClassCodeComparison: 'F-PAK',
        })
      );
    }

    return errors;
  }, [bracketPriceTableProps.packagePrices, newMarkups, t]);

  const onSubmit = handleSubmit(({ fromDate }) => {
    const fPakMarkup = newMarkups?.['F-PAK'];
    const dPakMarkup = newMarkups?.['D-PAK'];
    const tPakMarkup = newMarkups?.['T-PAK'];

    saveMarkups({
      packageMarkups: {
        'F-PAK': fPakMarkup && mapToCreatedMarkup(fPakMarkup),
        'D-PAK': dPakMarkup && mapToCreatedMarkup(dPakMarkup),
        'T-PAK': tPakMarkup && mapToCreatedMarkup(tPakMarkup),
      },
      validFrom: fromDate,
    });
  });

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <span>{t('myAssortment.discounts.edit')}</span>
        <ExcludeVatSwitch toggleVat={includeVat} handleToggleVat={onToggleIncludeVat} />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <BracketPriceTable {...bracketPriceTableProps} />
          {priceValidationErrors.length > 0 && (
            <Stack spacing={1}>
              {priceValidationErrors.map((error) => (
                <HelperText id={error} key={error} status="error">
                  <span>{error}</span>
                </HelperText>
              ))}
            </Stack>
          )}
          <ConditionDatesForm excludeToDate labelAlignment="row" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <TextButton size="small" onClick={onClose} loading={isSavingMarkups}>
          {t('myAssortment.cancel')}
        </TextButton>
        <ContainedButton
          size="small"
          loading={isSavingMarkups}
          onClick={onSubmit}
          disabled={
            !newMarkups ||
            isEditingAnyFields ||
            !!errors.fromDate?.message ||
            priceValidationErrors.length > 0
          }
        >
          {t('myAssortment.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export const EditBracketPricesDialog = withFormProvider(EditBracketPricesDialogRaw, ({ t }) => ({
  schema: conditionDatesSchema(t),
  defaultValues: { fromDate: new Date() },
}));
