import { MgPackage, MgPackageClassCode, MgPackageType, salesPackages } from "./MgPackage";
import { VilmaUnit } from './VilmaUnit';
import { MpeMgSupplierItemPackageDTO } from '../../dto';

interface ConstructorProps {
  gtinCode?: string;
  calculatedCount: number;
  deliverable: boolean;
  vilmaUnit: VilmaUnit;
  basePrice?: number;
  basePriceUnit?: boolean;
  recommendedSalesPrice?: number;
  mgPackage?: MgPackage;
  priceComparisonUnit?: boolean;
  classCode: MgPackageClassCode;
  type: MgPackageType;
}

export class MgSupplierItemPackage {
  gtinCode?: string;
  calculatedCount: number;
  deliverable: boolean;
  vilmaUnit: VilmaUnit;
  basePrice?: number;
  basePriceUnit?: boolean;
  recommendedSalesPrice?: number;
  mgPackage?: MgPackage;
  priceComparisonUnit?: boolean;
  classCode: MgPackageClassCode;
  type: MgPackageType;

  constructor({
    gtinCode,
    calculatedCount,
    deliverable,
    vilmaUnit,
    basePrice,
    basePriceUnit,
    recommendedSalesPrice,
    mgPackage,
    priceComparisonUnit,
    classCode,
    type,
  }: ConstructorProps) {
    this.gtinCode = gtinCode;
    this.calculatedCount = calculatedCount;
    this.deliverable = deliverable;
    this.vilmaUnit = vilmaUnit;
    this.basePrice = basePrice;
    this.basePriceUnit = basePriceUnit;
    this.recommendedSalesPrice = recommendedSalesPrice;
    this.mgPackage = mgPackage;
    this.priceComparisonUnit = priceComparisonUnit;
    this.classCode = classCode;
    this.type = type;
  }

  static fromDto = ({
    gtinCode,
    calculatedCount,
    vilmaUnit,
    basePriceUnit,
    priceComparisonUnit,
    type,
    deliverable,
    basePrice,
    recommendedSalesPrice,
    classCode,
  }: MpeMgSupplierItemPackageDTO): MgSupplierItemPackage => {
    const mgPackage: MgPackage | undefined = {
      calculatedCount: calculatedCount,
      gtinCode: gtinCode,
      vilmaUnit: vilmaUnit,
      packageType: type,
    };

    return new MgSupplierItemPackage({
      calculatedCount,
      vilmaUnit,
      priceComparisonUnit,
      mgPackage,
      type,
      deliverable,
      basePrice,
      basePriceUnit,
      recommendedSalesPrice,
      classCode,
      gtinCode,
    });
  };

  isSalesPackage = (): boolean => salesPackages.some((p) => p === this.type);
}
