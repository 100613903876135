import { Box, useTheme } from '@mui/material';
import * as XLSX from 'xlsx';
import { FC, PropsWithChildren, useCallback } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { Paper } from '@shared/components';
import { UploadIcon } from '@shared/custom-icons';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

interface Props {
  onUpload: (rows: (string | number)[][], file: File) => void;
  onRejectFiles: (rejectedFiles: FileRejection[]) => void;
  showUploadIcon: boolean;
  accept?: Accept;
  sx?: SystemStyleObject;
}

const defaultAccept: Accept = {
  'text/csv': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-excel': [],
};

export const SpreadsheetInput: FC<PropsWithChildren<Props>> = ({
  onUpload,
  onRejectFiles,
  accept = defaultAccept,
  showUploadIcon = true,
  children,
  sx = {},
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const acceptedFile = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = function (event) {
        const readData = XLSX.read(event.target?.result, { type: 'binary' });
        const wsname = readData.SheetNames[0];
        const ws = readData.Sheets[wsname];

        const parsedData = XLSX.utils.sheet_to_json<(string | number)[]>(ws, {
          header: 1,
          raw: false,
        });

        onUpload(parsedData, acceptedFile);
      };

      if (acceptedFile) {
        reader.readAsArrayBuffer(acceptedFile);
      } else if (rejectedFiles.length) {
        onRejectFiles(rejectedFiles);
      }
    },
    [onRejectFiles, onUpload]
  );
  const { palette } = useTheme();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <Paper
      component="label"
      paddingY="dense"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: palette.background.default,
        border: `1px dashed ${isDragActive ? palette.primary.main : palette.grey[100]}`,
        justifyContent: 'center',
        gap: 1,
        '&:hover': {
          cursor: 'pointer',
          borderColor: ({ palette }) => palette.primary.main,
        },
        ...sx,
      }}
      {...getRootProps()}
    >
      <Box component="input" flexGrow="1" {...getInputProps()} />
      {showUploadIcon && <UploadIcon fontSize="large" color={palette.grey[600]} />}
      {children}
    </Paper>
  );
};
