import { useCallback, useEffect, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { Box, SxProps, Theme } from '@mui/material';
import {
  APP_BAR_HEIGHT,
  Navigation,
  NavRoute,
  NavWithSubRoutes,
  Sidebar,
  SidebarHeader,
  SidebarUserMenu,
} from '@shared/components';
import { useSidebarDisclosure } from '@shared/stores';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import {
  isSuperUserAtom,
  useAuthContext,
  useFetchMe,
  useIsSuperUser,
} from '@retail/auth/data-access';
import {
  BriefcaseIcon,
  CalculatorIcon,
  DownloadIconMenu,
  MarketIcon,
  ShoppingCartIcon,
  StorageUnitIcon,
} from '@shared/custom-icons';
import { useSharedTranslation } from '@shared/i18n';
import { ToggleOn } from '@mui/icons-material';
import { useSetRecoilState } from 'recoil';

type Route = NavRoute | NavWithSubRoutes;
export const APP_BAR_MGP_MARGIN_TOP = 16;

export function MgpAppSidebar() {
  const t = useAppTFunction();

  const { t: sharedT } = useSharedTranslation();

  const {
    isOpen: isSidebarOpen,
    onToggle: toggleSidebar,
    onOpen: openSidebar,
  } = useSidebarDisclosure();

  const { data: user } = useFetchMe({ refetchOnWindowFocus: false });
  const userName = useMemo(() => `${user?.givenName} ${user?.familyName}`, [user]);

  const isSuperUser = useIsSuperUser();
  const setIsSuperUser = useSetRecoilState(isSuperUserAtom);

  const { logout } = useAuthContext();
  const logoutDefault = useCallback(() => logout(), [logout]);

  const marketInsightRouteMatch = useMatch(`${mgPrisRoutes.marketInsight.fullRoutePath}/*`);
  const itemsRouteMatch = useMatch(`${mgPrisRoutes.items.fullRoutePath}/*`);
  const myAssortmentRouteMatch = useMatch(`${mgPrisRoutes.myAssortment.fullRoutePath}/*`);
  const suppliersRouteMatch = useMatch(`${mgPrisRoutes.suppliers.fullRoutePath}/*`);
  //const userRouteMatch = useMatch(`${mgPrisRoutes.users.fullRoutePath}/*`);
  const pricesRouteMatch = useMatch(`${mgPrisRoutes.calculus.fullRoutePath}/*`);
  const exportRouteMatch = useMatch(`${mgPrisRoutes.export.fullRoutePath}/*`);
  const featureTogglesRouteMatch = useMatch(`${mgPrisRoutes.superuser.features.fullRoutePath}/*`);

  const navRoutes: Route[] = useMemo(() => {
    const routes: Route[] = [
      {
        title: t('app.routes.myAssortment'),
        to: mgPrisRoutes.myAssortment.getFullLinkPath(),
        Icon: StorageUnitIcon,
        isActive: !!myAssortmentRouteMatch,
      },
      {
        title: t('app.routes.calculus'),
        Icon: CalculatorIcon,
        to: mgPrisRoutes.calculus.getFullLinkPath(),
        isActive: !!pricesRouteMatch,
      },
      {
        title: t('app.routes.products'),
        to: mgPrisRoutes.items.getFullLinkPath(),
        Icon: ShoppingCartIcon,
        isActive: !!itemsRouteMatch,
      },
      {
        title: t('app.routes.marketInsight'),
        to: mgPrisRoutes.marketInsight.getFullLinkPath(),
        Icon: MarketIcon,
        isActive: !!marketInsightRouteMatch,
      },
      {
        title: t('app.routes.suppliers'),
        to: mgPrisRoutes.suppliers.getFullLinkPath(),
        Icon: BriefcaseIcon,
        isActive: !!suppliersRouteMatch,
      },
      {
        title: t('app.routes.export'),
        to: mgPrisRoutes.export.getFullLinkPath(),
        Icon: DownloadIconMenu,
        isActive: !!exportRouteMatch,
      },
    ];
    if (isSuperUser) {
      routes.push({
        title: t('app.routes.features'),
        to: mgPrisRoutes.superuser.features.getFullLinkPath(),
        Icon: ToggleOn,
        isActive: !!featureTogglesRouteMatch,
      });
    }

    return routes;
  }, [
    t,
    myAssortmentRouteMatch,
    pricesRouteMatch,
    itemsRouteMatch,
    marketInsightRouteMatch,
    suppliersRouteMatch,
    exportRouteMatch,
    isSuperUser,
    featureTogglesRouteMatch,
  ]);

  useEffect(() => {
    setIsSuperUser(isSuperUser);
  }, [isSuperUser, setIsSuperUser]);

  return (
    <Sidebar
      isOpen={isSidebarOpen}
      toggleOpen={toggleSidebar}
      appBarHeight={APP_BAR_HEIGHT + APP_BAR_MGP_MARGIN_TOP}
      hasBorderTopRightRadius
    >
      <SidebarHeader title={sharedT('shared.navigation')} expandedView={isSidebarOpen} />
      <Navigation
        navRoutes={navRoutes}
        expandedView={isSidebarOpen}
        expandView={openSidebar}
        overridingStyles={listButtonStyles}
      />

      <Box marginTop="auto" display="flex" p={3} justifyContent="center">
        <SidebarUserMenu userName={userName} logOut={logoutDefault} expandedView={isSidebarOpen} />
      </Box>
    </Sidebar>
  );
}

const listButtonStyles: SxProps<Theme> = ({ palette }) => ({
  color: palette.grey.A400,
  background: 'none',

  '&.Mui-selected': {
    color: palette.common.white,
    background: palette.primary.main,
  },
});
