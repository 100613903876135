import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';

type Level = 'supplier' | 'productGroup' | 'discountGroup' | 'item' | '';

export const levelSelectSchema = (t: CalculusTFunction) => {
  return yup.object({
    level: yup.mixed<Level>().default('').required(t('calculus.error.chooseLevel')),
    overGroup: yup
      .object()
      .default(null)
      .nullable()
      .when('level', {
        is: 'productGroup',
        then: yup.object().nullable().required(t('calculus.error.chooseProductGroup')),
      }),
    mainGroup: yup.object().default(null).nullable().when('level', {
      is: 'productGroup',
      then: yup.object().nullable().optional(),
    }),
    productGroup: yup.object().default(null).nullable().when('level', {
      is: 'productGroup',
      then: yup.object().nullable().optional(),
    }),
    discountGroup: yup
      .object()
      .default(null)
      .nullable()
      .when('level', {
        is: 'discountGroup',
        then: yup.object().nullable().required(t('calculus.error.chooseDiscountGroup')),
      }),
    itemSearch: yup.string().default(''),
    item: yup
      .object()
      .default(null)
      .nullable()
      .when('level', {
        is: 'item',
        then: yup.object().nullable().required(t('calculus.error.chooseMgSupplierItem')),
      }),
  });
};

export type LevelSelectSchema = yup.InferType<ReturnType<typeof levelSelectSchema>>;
