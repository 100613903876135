import { DiscountGroupDTO } from '../dtos';

export class DiscountGroup {
  id: number;
  code: string;
  name: string;
  mgSupplierId: number;

  constructor({ id, name, code, mgSupplierId }: DiscountGroup) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.mgSupplierId = mgSupplierId;
  }

  static fromDto = (dto: DiscountGroupDTO): DiscountGroup =>
    new DiscountGroup({
      id: dto.id,
      code: dto.code,
      name: dto.name,
      mgSupplierId: dto.mgSupplierId,
    });
}
