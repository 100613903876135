import { conditionDatesSchema, levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';

export const followFixedPriceSchema = (t: CalculusTFunction) =>
  levelSelectSchema(t)
    .concat(conditionDatesSchema(t))
    .concat(
      yup.object({
        fixedPriceId: yup.string().default('').required(),
      })
    );

export type FollowFixedPriceSchema = yup.InferType<ReturnType<typeof followFixedPriceSchema>>;
