import { CompetitorPackagePriceDTO, CompetitorPriceDTO } from '../dto';

export class CompetitorPackagePrice {
  storeId: number;
  mgItemNumber?: number;
  price?: number;
  unit?: string;
  basePriceUnit?: boolean;
  priceComparisonUnit?: boolean;
  primaryUnit?: boolean;
  gtinCode?: string;

  constructor({
    storeId,
    mgItemNumber,
    price,
    unit,
    basePriceUnit,
    priceComparisonUnit,
    primaryUnit,
    gtinCode,
  }: CompetitorPackagePriceDTO) {
    this.storeId = storeId;
    this.mgItemNumber = mgItemNumber;
    this.price = price;
    this.unit = unit;
    this.basePriceUnit = basePriceUnit;
    this.priceComparisonUnit = priceComparisonUnit;
    this.primaryUnit = primaryUnit;
    this.gtinCode = gtinCode;
  }
}

export class CompetitorPrice {
  id: number;
  storeId: number;
  scrapedPrice?: number;
  estimatedUnitDistance?: number;
  retailProductId?: number;
  productUrl?: string;
  unitSource?: string;
  scrapedUnit?: string;
  onDiscount: boolean;
  latest?: boolean;
  firstObservedAt: string;
  lastObservedAt: string;
  fromEquivalent?: boolean;
  mgItemNumber?: number;
  eqMgItemNumber?: number;
  packagePrices: CompetitorPackagePrice[];

  constructor({
    id,
    storeId,
    scrapedPrice,
    estimatedUnitDistance,
    retailProductId,
    productUrl,
    unitSource,
    scrapedUnit,
    onDiscount,
    latest,
    firstObservedAt,
    lastObservedAt,
    fromEquivalent,
    mgItemNumber,
    eqMgItemNumber,
    packagePrices,
  }: CompetitorPriceDTO) {
    this.id = id;
    this.storeId = storeId;
    this.scrapedPrice = scrapedPrice;
    this.estimatedUnitDistance = estimatedUnitDistance;
    this.retailProductId = retailProductId;
    this.productUrl = productUrl;
    this.unitSource = unitSource;
    this.scrapedUnit = scrapedUnit;
    this.onDiscount = onDiscount;
    this.latest = latest;
    this.firstObservedAt = firstObservedAt;
    this.lastObservedAt = lastObservedAt;
    this.fromEquivalent = fromEquivalent;
    this.mgItemNumber = mgItemNumber;
    this.eqMgItemNumber = eqMgItemNumber;
    this.packagePrices = packagePrices
      ? packagePrices.map((pp) => new CompetitorPackagePrice(pp))
      : [];
  }

  getDefaultPrice = (): { price: number | undefined; unit: string | undefined } => {
    if (!this.packagePrices.length) return { price: this.scrapedPrice, unit: this.scrapedUnit };

    const priceComparisonPackage = this.packagePrices.find(
      ({ priceComparisonUnit }) => !!priceComparisonUnit
    );
    if (priceComparisonPackage)
      return { price: priceComparisonPackage.price, unit: priceComparisonPackage.unit };

    const basePricePackage = this.packagePrices.find(({ basePriceUnit }) => !!basePriceUnit);

    if (basePricePackage) return { price: basePricePackage?.price, unit: basePricePackage?.unit };

    return { price: this.scrapedPrice, unit: this.scrapedUnit };
  };
}
