import { useFetchAssortmentItems } from '@retail/my-assortment/data-access';
import { AssortmentItem } from '@retail/my-assortment/types';
import { Pagination } from '@shared/hooks';
import { useMemo } from 'react';
import { MgPackageType } from '@retail/products/types';

interface Props {
  assortmentId: number;
  selectedSupplier?: number;
  selectedProductGroup?: string;
  selectedDiscountGroup?: number;
  searchQuery?: string;
  withAgreementPrices?: boolean;
  inAssortment?: boolean;
  nFuturePrices?: number;
  calculationPackages?: MgPackageType[];
  pagination: Pagination;
}

export const useAssortmentItems = ({
  assortmentId,
  selectedSupplier,
  selectedProductGroup,
  selectedDiscountGroup,
  searchQuery,
  withAgreementPrices,
  inAssortment,
  nFuturePrices = 0,
  calculationPackages,
  pagination,
}: Props) => {
  const { data: itemsDTO, isLoading: isLoadingFetchItems } = useFetchAssortmentItems({
    assortmentId: assortmentId,
    supplierIds: selectedSupplier ? [selectedSupplier] : [],
    groupNumbers: selectedProductGroup ? [selectedProductGroup] : [],
    discountGroupIds: selectedDiscountGroup ? [selectedDiscountGroup] : [],
    query: searchQuery,
    includeOwner: true,
    inAssortment,
    withAgreementPrices,
    nPrices: nFuturePrices + 1, // current price counts as 1
    calculationPackages,
    page: pagination.page,
    size: pagination.pageSize,
  });

  const totalItems = useMemo(() => itemsDTO?.totalCount || 0, [itemsDTO]);
  const itemsDto = useMemo(() => itemsDTO?.result || [], [itemsDTO]);
  const assortmentItems = itemsDto.map(AssortmentItem.fromDTO);

  return {
    assortmentItems,
    totalItems,
    isLoadingFetchItems,
  };
};
