import { ChipTabs } from '@shared/components';
import { CalculusTabOptions } from '@retail/calculus/types';
import { Stack } from '@mui/material';

interface Props {
  tabs: {
    label: string;
    value: CalculusTabOptions;
  }[];
  currentCalculusArea: CalculusTabOptions;
  setCalculusArea: (option: CalculusTabOptions) => void;
}

export const CalculusTabs = ({ tabs, currentCalculusArea, setCalculusArea }: Props) => {
  return (
    <Stack>
      <ChipTabs
        id="item"
        tabs={tabs}
        currentTab={currentCalculusArea}
        onChange={(_, value) => setCalculusArea(value)}
      />
    </Stack>
  );
};
