import { Dispatch, SetStateAction, useState } from 'react';

interface Props {
  initPageSize?: number;
  initPage?: number;
}

export interface Pagination {
  page: number;
  pageSize: number;
  setPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
}

const defaultProps: Required<Props> = {
  initPageSize: 2000,
  initPage: 0,
};

export function usePagination({
  initPageSize = defaultProps.initPageSize,
  initPage = defaultProps.initPage,
}: Props = defaultProps): Pagination {
  const [pageSize, setPageSize] = useState(initPageSize);
  const [page, setPage] = useState(initPage);

  return {
    pageSize,
    page,
    setPageSize,
    setPage,
  };
}
