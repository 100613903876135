import { SvgIcon, SvgIconProps } from '@mui/material';

const EditButtonIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
      <path
        d="M3.33325 16.6663H16.6666M11.3217 5.04539C11.3217 5.04539 11.3217 6.25622 12.5325 7.46705C13.7434 8.67789 14.9542 8.67789 14.9542 8.67789M6.53298 14.4353L9.07573 14.072C9.44251 14.0196 9.7824 13.8497 10.0444 13.5877L16.165 7.46705C16.8338 6.79833 16.8338 5.71411 16.165 5.04538L14.9542 3.83455C14.2855 3.16583 13.2013 3.16583 12.5325 3.83455L6.41189 9.9552C6.1499 10.2172 5.97996 10.5571 5.92756 10.9239L5.56431 13.4666C5.48359 14.0317 5.96792 14.516 6.53298 14.4353Z"
        stroke={props.htmlColor || 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      </svg>
    </SvgIcon>
  );
};

export default EditButtonIcon;
