import { Box } from '@mui/material';
import { TabButton } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props<T> {
  suppliers: T[];
  selectedSupplier: T;
  selectSupplier: (supplier: T) => void;
  getSupplierName: (supplier: T) => string;
  getSupplierId: (supplier: T) => string;
}

export const SupplierTabSelect = <T,>({
  suppliers,
  selectedSupplier,
  selectSupplier,
  getSupplierName,
  getSupplierId,
}: Props<T>) => {
  const t = useProductsTFunction();

  const onChange = (id: string) => {
    const selected = suppliers.find((supplier) => getSupplierId(supplier) === id);
    if (selected) selectSupplier(selected);
  };

  return (
    <Box display="flex" gap={2}>
      {suppliers.map((supplier, index) => {
        const id = getSupplierId(supplier);

        return (
          <TabButton
            key={id}
            onClick={() => onChange(id)}
            state={getSupplierId(selectedSupplier as T) === id ? 'selected' : 'regular'}
          >
            {getSupplierName(supplier) || t('products.supplier.unknown')}
          </TabButton>
        );
      })}
    </Box>
  );
};
