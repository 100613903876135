import { Stack, Typography } from '@mui/material';
import { PriceListTable } from './PriceListTable';
import { Dispatch, SetStateAction } from 'react';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { DialogLayout, InfoChip, ToggleSwitch } from '@retail/components';
import { PriceListPageResult } from '@retail/purchase-agreements/types';
import { MgSupplierItemSearchField } from '@retail/products/components';

interface Props {
  open: boolean;
  onClose: () => void;
  result: PriceListPageResult;
  includeFuturePrices: boolean;
  toggleIncludeFuturePrices: (include: boolean) => void;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function PriceListDialog({
  open,
  onClose,
  result,
  includeFuturePrices,
  toggleIncludeFuturePrices,
  setSearchQuery,
  updatePage,
  updatePageSize,
}: Props) {
  const t = usePurchaseAgreementsTFunction();

  return (
    <DialogLayout
      open={open}
      onClose={onClose}
      width="lg"
      fullHeight
      header={t('purchaseAgreements.priceList.priceList')}
    >
      <Stack height="100%">
        <Stack px={3} flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack flexDirection="row" py={2} gap={1}>
            <Typography variant="body1">{t('purchaseAgreements.priceList.nrOfItems')}:</Typography>
            <InfoChip label={result.totalItems} />
          </Stack>
          <Stack gap={2} direction="row">
            <ToggleSwitch
              text={t('purchaseAgreements.priceList.includeFuturePrices')}
              toggle={includeFuturePrices}
              setToggle={toggleIncludeFuturePrices}
            />

            <MgSupplierItemSearchField
              isDisabled={false}
              width={340}
              onSubmitSearch={setSearchQuery}
              includeLabel={false}
              size="small"
            />
          </Stack>
        </Stack>

        <PriceListTable result={result} updatePage={updatePage} updatePageSize={updatePageSize} />
      </Stack>
    </DialogLayout>
  );
}
