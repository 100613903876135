import { Clear } from '@mui/icons-material';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { TextButton } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { SearchIcon } from '@shared/custom-icons';
import { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';

interface Props {
  isDisabled: boolean;
  searchTerm?: string;
  includeLabel?: boolean;
  size?: 'small' | 'medium';
  width?: number;
  setPage?: (newPage: number) => void;
  onSubmitSearch: (search: string) => void;
}

export function MgSupplierItemSearchField({
  isDisabled,
  searchTerm,
  includeLabel = true,
  size = 'medium',
  width,
  setPage,
  onSubmitSearch,
}: Props) {
  const t = useProductsTFunction();

  const [searchFieldInput, setSearchFieldInput] = useState('');

  const submitSearch = useCallback(() => {
    onSubmitSearch(searchFieldInput);
    setPage && setPage(0);
  }, [onSubmitSearch, searchFieldInput, setPage]);

  const onKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        submitSearch();
      }
    },
    [submitSearch]
  );

  useEffect(() => {
    if (!searchTerm) {
      setSearchFieldInput('');
    }
  }, [searchTerm]);

  return (
    <Stack gap={0.5}>
      {includeLabel && (
        <Typography component="label" htmlFor="freeSearch" variant="body2" color="text.secondary">
          {t('products.search.openSearch.label')}
        </Typography>
      )}

      <TextField
        id="freeSearch"
        sx={{ width }}
        size={size}
        variant="outlined"
        disabled={isDisabled}
        placeholder={t('products.search.searchItems')}
        value={searchFieldInput}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" color="disabled" />
            </InputAdornment>
          ),
          endAdornment: searchFieldInput ? (
            <Stack flexDirection="row">
              <TextButton onClick={submitSearch}>
                {t('products.search.openSearch.search')}
              </TextButton>
              <IconButton
                size="small"
                onClick={() => {
                  setSearchFieldInput('');
                  onSubmitSearch('');
                }}
              >
                <Clear />
              </IconButton>
            </Stack>
          ) : undefined,
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchFieldInput(event.target.value);
          event.target.value === '' && onSubmitSearch('');
        }}
      />
    </Stack>
  );
}
