import { QueryOptions } from '@shared/fetch-utils';
import { OverGroupDTO } from '@retail/products/types';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useMPEFetch } from '@retail/app/hooks';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';

export function useFetchBk04ProductGroups(queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<OverGroupDTO[]>({
    method: 'GET',
    queryKey: ['bk04'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.productGroups.getFullLinkPath(),
    }),
    ...queryOptions,
  });
}

interface SearchProps {
  supplierId: number;
}

export function useFetchBk04ProductGroupsForSupplier(
  queryOptions: QueryOptions = {},
  { supplierId }: SearchProps
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<OverGroupDTO[]>({
    method: 'GET',
    queryKey: ['bk04'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.suppliers.supplier.productGroups.getFullLinkPath({ supplierId }),
    }),
    ...queryOptions,
  });
}
