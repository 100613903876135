import { Stack, Typography } from '@mui/material';
import { useLanguageSelector } from '@retail/app/hooks';
import { BodyLoader, ToggleFiltersButton } from '@retail/components';
import {
  CentralAssortmentFilters,
  CentralAssortmentTable,
  SearchFilterChips,
} from '@retail/my-assortment/components';
import { useAssortmentItems } from '@retail/my-assortment/context';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ItemWithPriority } from '@retail/my-assortment/types';
import { useSelectProductGroupsInAssortment, useSelectSuppliers } from '@retail/products/context';
import { useDisclosure, usePagination } from '@shared/hooks';
import { useState } from 'react';

interface Props {
  assortmentId: number;
  assortmentName: string;
  checkedAssortmentItems: ItemWithPriority[];
  setCheckedAssortmentItems: (items: ItemWithPriority[]) => void;
}

export function CentralAssortmentItems({
  assortmentId,
  assortmentName,
  checkedAssortmentItems,
  setCheckedAssortmentItems,
}: Props) {
  const t = useMyAssortmentTFunction();
  const pagination = usePagination();
  const { language } = useLanguageSelector();
  const { isOpen: showFilters, onToggle: toggleFilters } = useDisclosure(true);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const { suppliers, selectedSupplierId, selectSupplierId } = useSelectSuppliers({
    assortmentId,
    inAssortment: true,
  });
  const { productGroups, selectedProductGroupNumber, selectProductGroupNumber } =
    useSelectProductGroupsInAssortment({ assortmentId });

  const { assortmentItems, totalItems, isLoadingFetchItems } = useAssortmentItems({
    assortmentId: assortmentId,
    pagination,
    searchQuery,
    selectedProductGroup: selectedProductGroupNumber ?? undefined,
    inAssortment: true,
    selectedSupplier: selectedSupplierId ?? undefined,
  });

  if (isLoadingFetchItems) return <BodyLoader />;
  return (
    <Stack flexGrow={1} flexDirection="row" justifyContent="space-between">
      <Stack flexGrow={1}>
        <Stack
          flexDirection="row"
          alignItems="center"
          height={32}
          justifyContent="space-between"
          m={2}
          mb={0}
        >
          <SearchFilterChips
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            selectedSupplier={suppliers.find(
              ({ mgSupplierId }) => mgSupplierId === selectedSupplierId
            )}
            setSelectedSupplier={selectSupplierId}
            selectedProductGroup={
              productGroups.find(({ groupNumber }) => groupNumber === selectedProductGroupNumber) ??
              null
            }
            selectProductGroupId={selectProductGroupNumber}
          />
          <Stack flexDirection="row" gap={1}>
            <Typography>
              {t('myAssortment.nrOfItems', {
                items: totalItems > pagination.pageSize ? pagination.pageSize : totalItems,
                totalItems,
              })}
            </Typography>
            {!showFilters && (
              <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleFilters} />
            )}
          </Stack>
        </Stack>
        <CentralAssortmentTable
          {...pagination}
          assortmentItems={assortmentItems}
          totalItems={totalItems}
          language={language}
          itemsChecked={checkedAssortmentItems}
          setItemsChecked={setCheckedAssortmentItems}
          setSupplier={selectSupplierId}
          setProductGroup={selectProductGroupNumber}
        />
      </Stack>
      {showFilters && (
        <CentralAssortmentFilters
          assortmentName={assortmentName}
          totalItems={totalItems}
          searchTerm={searchQuery}
          disableFields={isLoadingFetchItems}
          suppliers={suppliers}
          productGroups={productGroups}
          selectedSupplier={selectedSupplierId}
          selectedProductGroup={selectedProductGroupNumber}
          showFilters={showFilters}
          selectSupplier={selectSupplierId}
          selectProductGroup={selectProductGroupNumber}
          setSearchTerm={setSearchQuery}
          setPage={pagination.setPage}
          closeFilters={toggleFilters}
        />
      )}
    </Stack>
  );
}
