import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { usePurchaseActions } from '@retail/purchase-agreements/context';
import { useCallback, useState } from 'react';
import { SelectItemAgreements } from '../ActivateAgreement/SelectItemAgreements';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AssortmentItem } from '@retail/my-assortment/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useAppTFunction } from '@retail/app/i18n';
import { ItemAgreement } from '@retail/purchase-agreements/types';

interface Props {
  open: boolean;
  onClose: () => void;
  assortmentItem: AssortmentItem;
  assortmentId: number;
  mgSupplierId: number;
}

function ChangeSelectedItemAgreementDialog({
  onClose,
  assortmentItem,
  assortmentId,
  mgSupplierId,
}: Props) {
  const t = useAppTFunction();
  const { activateOrChangeCondition, isLoadingPostChanges } = usePurchaseActions({
    mgSupplierId,
  });

  const [selectedAgreement, selectAgreement] = useState<ItemAgreement | undefined>(undefined);

  const submitAgreements = useCallback(() => {
    activateOrChangeCondition({
      agreementId: selectedAgreement!.agreementId,
      mgItemNumber: assortmentItem.mgItemNumber,
      fromDate: new Date(),
      priceType: selectedAgreement?.priceType,
    });
    onClose();
  }, [activateOrChangeCondition, assortmentItem.mgItemNumber, onClose, selectedAgreement]);

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t('purchaseAgreements.agreement.actions.editConditionFor', {
          name: assortmentItem.primaryText,
        })}
      </DialogTitle>

      <DialogContent>
        <SelectItemAgreements
          mgItemNumber={assortmentItem.mgItemNumber}
          assortmentId={assortmentId}
          supplierId={mgSupplierId}
          selectAgreement={selectAgreement}
        />
      </DialogContent>

      <DialogActions>
        <TextButton size="small" onClick={onClose}>
          {t('common.cancel')}
        </TextButton>
        <ContainedButton
          size="small"
          color="secondary"
          disabled={!!selectedAgreement?.conditionId || !selectedAgreement}
          loading={isLoadingPostChanges}
          onClick={submitAgreements}
        >
          {t('purchaseAgreements.agreement.actions.setToApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(ChangeSelectedItemAgreementDialog);
